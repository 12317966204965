import * as React from 'react';

function SvgDeleteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 25" {...props}>
      <path
        fillRule="evenodd"
        d="M14.2 4.5c.551 0 1 .485 1 1.081l-.001 1.405H18.4c.301 0 .55.24.593.553l.007.096c0 .358-.269.649-.6.649l-.851-.001-.911 11.236c-.048.514-.429.92-.89.975l-.108.006H8.36c-.509 0-.946-.428-.997-.973L6.45 8.283H5.6c-.301 0-.55-.24-.593-.552L5 7.635c0-.358.269-.648.6-.648l3.199-.001.001-1.405c0-.556.39-1.016.891-1.075L9.8 4.5zm2.145 3.783H7.654l.887 10.92h6.918l.886-10.92zM14 5.797h-4l-.001 1.189h4V5.797z"
      />
    </svg>
  );
}

export default SvgDeleteIcon;
