import {
  INotificationSearchParams,
  INotificationSearchResponse,
  INotificationUpdateParams,
  INotificationUpdateResponse,
} from '@freelancelabs/teoreme-commons';
import { NOTIFICATION_SERVICE_URL } from '../../constantz';
import { fetcher } from '../helpers/fetcher';

export const notificationFindMany = (
  body: INotificationSearchParams,
  disableAlert?: boolean
) =>
  fetcher<INotificationSearchParams, INotificationSearchResponse>(
    NOTIFICATION_SERVICE_URL + `/search`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );

export const notificationUpdateOne = (
  body: INotificationUpdateParams,
  disableAlert?: boolean
) =>
  fetcher<INotificationUpdateParams, INotificationUpdateResponse>(
    NOTIFICATION_SERVICE_URL + `/update`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
