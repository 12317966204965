import { cleanSession } from '../helpers';
import { queryClient, deleteAllCognitoCookies } from '../';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getMe, logIn, logout, register, signOut } from '../routes';
import { jwtDecode } from 'jwt-decode';
import { signIn } from '../routes';

export const useLogin = () =>
  useMutation({
    mutationFn: logIn,
    onSuccess: data => {
      const decode = jwtDecode(data.token);
      //console.log('decode', decode);
      //@ts-ignore
      localStorage.exp = decode?.exp;
      localStorage.token = data?.token;
      localStorage.refreshToken = data?.refreshToken;
      localStorage.cognitoUserId = data?.user?.cognitoUserId;
      // set datas in cache
      if (data?.user) {
        queryClient.setQueryData(['me'], data?.user);
      }
    },
  });
export const useLogout = () =>
  useMutation({
    mutationFn: logout,
    onSuccess: data => {
      queryClient.clear();
      cleanSession();
    },
  });

export const useRegister = () => useMutation({ mutationFn: register });

export const useMe = (config?: any) => {
  const query = useQuery({
    queryKey: ['me'],
    queryFn: getMe,
    retry: config?.retry || false,
    retryDelay: 1000,
  });
  return { ...query, me: query.data };
};
export const useAmplifyLogin = () =>
  useMutation({
    mutationFn: signIn,
    onSuccess: data => {
      // localStorage.exp = data?.signInUserSession?.accessToken?.payload?.exp;
      // localStorage.token = data?.signInUserSession?.accessToken?.jwtToken;
      // localStorage.refreshToken = data?.signInUserSession?.refreshToken?.token;
      // localStorage.cognitoUserId = data?.username;
    },
    onError: err => {
      return err;
    },
  });
export const useAmplifyLogout = () =>
  useMutation({
    mutationFn: signOut,
    onSuccess: data => {
      queryClient.clear();
      deleteAllCognitoCookies();
      cleanSession();
    },
  });
