import { IContractSearchParams } from '@freelancelabs/teoreme-commons';
import { contractFindMany, contractSearchHistory } from '../routes';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

export const useContractFindMany = (variables?: IContractSearchParams) => {
  return useQuery({
    queryKey: ['contracts', variables],
    queryFn: async () => contractFindMany(variables as IContractSearchParams),
    enabled: variables && variables.filterObject ? true : false,
    placeholderData: keepPreviousData,
  });
};
export const useContractFindManyHistorical = (variables?: any) => {
  return useQuery({
    queryKey: ['contracts-historical', variables],
    queryFn: async () => contractSearchHistory(variables),
    enabled: variables && variables.filterObject ? true : false,
    placeholderData: keepPreviousData,
  });
};
