import React, { useState } from 'react';
import {
  IEstablishmentMinifiedUsers,
  IEstablishmentFullUsers,
  IFile,
  EFileType,
} from '@freelancelabs/teoreme-commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  uploadFile,
  useEstablishmentUpdateOne,
  queryClient,
  establishmentUseIban,
  extractVerifyRib,
  isNotEmpty,
} from '@commons';
import {
  Box,
  Button,
  FormControl,
  Input,
  Row,
  Text,
  FileInput,
  Link,
  Spinner,
  SpinnerBox,
  Flex,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import { useForm } from 'react-hook-form';
import { printFormat } from 'iban';
import {
  isValidIBAN,
  isValidBIC,
  electronicFormatIBAN,
  friendlyFormatIBAN,
} from 'ibantools';
import TextInformation from 'components/TextInformation';

type FormValues = {
  rib?: File;
  iban: string;
  bic: string;
  bankName: string;
  owner: string;
};
type AddPaiementProps = {
  establishment: IEstablishmentMinifiedUsers | IEstablishmentFullUsers;
  defaultValues?: any;
  onSubmit?: (values: any) => any | void;
  onClose: (bool: boolean) => any | void;
};

export const AddPaiementEstaProviderForm = ({
  establishment,
  defaultValues,
  onClose,
  onSubmit: onSubmitParent,
}: AddPaiementProps) => {
  const [isFetchingExtract, setIsFetchingExtract] = useState(false);
  const [loading, setLoading] = useState(false);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const {
    register,
    handleSubmit,
    clearErrors,
    getValues,
    setValue,
    setError,
    formState: { errors, isDirty, isSubmitSuccessful },
    reset,
  } = useForm<FormValues>({
    // change (establishment as any) ==> (establishment as IEstablishmentBase)
    defaultValues: {
      iban:
        (establishment as any)?.provider?.bankAccount?.iban &&
        printFormat((establishment as any)?.provider?.bankAccount?.iban, ' '),
      bic: (establishment as any)?.provider?.bankAccount?.bic?.toUpperCase(),
      bankName: (establishment as any)?.provider?.bankAccount?.bankName,
      owner: (establishment as any)?.provider?.bankAccount?.owner,
      rib: (establishment as any)?.provider?.bankAccount?.rib,
    },
  });
  const [ribFile, setRibFile] = React.useState<File | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rib, setRib] = React.useState<IFile | null>(
    (establishment as any)?.provider?.bankAccount?.rib
  );
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);

    if (!rib && !ribFile) {
      setError('rib', { message: 'Ce champs est requis' });
      setLoading(false);
      return;
    } else {
      clearErrors('rib');
    }
    const establishmentData = {
      uuid: establishment?.uuid,
      establishment: {
        provider: {
          bankAccount: {
            iban: electronicFormatIBAN(formValues?.iban) || undefined,
            bic: formValues.bic?.toUpperCase(),
            bankName: formValues?.bankName,
            owner: formValues?.owner,
            rib: formValues?.rib,
          },
        },
      },
    };

    try {
      if (ribFile) {
        const s3Response = await uploadFile({
          file: ribFile,
          fileType: EFileType.BANK_ACCOUNT,
          establishment: establishment?.uuid || '',
          actionType: 'upload',
        });
        establishmentData.establishment.provider.bankAccount.rib = {
          // @ts-ignore
          fileName: s3Response?.fileName,
          fileLocation: s3Response?.fileLocation as string,
          eTag: s3Response?.eTag,
        };
      } else {
        // @ts-ignore
        establishmentData.establishment.provider.bankAccount.rib =
          establishment?.provider?.bankAccount?.rib;
      }
      await updateEstablishment(establishmentData as any);
      queryClient.refetchQueries({ queryKey: [establishment.uuid] });
      onClose(true);
    } catch (e) {
      //
    }
    setLoading(false);
    reset(formValues);
  };

  const handleChangeRIB = async (files: File[]) => {
    // BANK_ACCOUNT = 'BANK_ACCOUNT', // document de coordonnée bancaire
    setRibFile(files[0]);
    clearErrors('rib');
    const s3Response = await uploadFile({
      //@ts-ignore
      file: files[0],
      fileType: EFileType.BANK_ACCOUNT,
      establishment: establishment?.uuid || '',
      actionType: 'upload',
    });
    if (establishmentUseIban(establishment)) {
      setIsFetchingExtract(true);
      const verifyFileData = await extractVerifyRib({
        fileLocation: s3Response?.fileLocation as string,
      });
      const iban = getValues()?.iban;
      if (verifyFileData?.extractedIban && !isNotEmpty(iban)) {
        if (isValidIBAN(verifyFileData?.extractedIban)) {
          //@ts-ignore
          setValue('iban', friendlyFormatIBAN(verifyFileData?.extractedIban));
        }
      }
      setIsFetchingExtract(false);
    }
  };
  const checkIban = (value: string): string | undefined => {
    const iban = electronicFormatIBAN(value);
    if (iban && isValidIBAN(iban)) {
      return undefined;
    } else {
      return 'Le code IBAN est invalide ';
    }
  };
  const checkBIC = (value: string): string | undefined => {
    if (isValidBIC(value)) {
      return undefined;
    } else {
      return 'Le code BIC est invalide ';
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Text variant="h2">Modifier les coordonnées bancaires</Text>
      {establishmentUseIban(establishment) && (
        <>
          <Row spacing={20}>
            <FormControl label="" errorMessage={errors?.rib?.message}>
              <FileInput
                label="Relevé d'identité bancaire"
                accept=".pdf"
                subLabel="Format accepté : PDF"
                required
                onSelect={handleChangeRIB}
              >
                <Link iconLeft={<AddIcon />}>
                  {ribFile || rib?.fileName
                    ? `Modifier le rib ${
                        ribFile?.name ? ribFile.name : rib?.fileName
                      }`
                    : "Ajouter un relevé d'identité bancaire"}
                </Link>
              </FileInput>
            </FormControl>
            {isFetchingExtract ? (
              <Flex
                width={1 / 1}
                height={'100%'}
                display={'inline-flex'}
                flexWrap={'wrap'}
                justifyContent={'center'}
                alignContent={'center'}
                alignItems={'center'}
              >
                <Text>
                  Vérification du fichier <Spinner />
                </Text>
              </Flex>
            ) : (
              <></>
            )}
          </Row>
          <Row spacing={20}>
            <FormControl
              label="IBAN"
              required
              errorMessage={errors?.iban?.message}
            >
              <Input
                data-clarity-mask="True"
                isFullWidth
                type="text"
                isDisabled={isFetchingExtract}
                // TODO: check the real issue with ts error on iban input
                // @ts-ignore
                {...register('iban', {
                  validate: (value: any) => checkIban(value),
                })}
                onChange={(e: any) => {
                  e.target.value = printFormat(e.target.value, ' ');
                  if (
                    checkIban(printFormat(e.target.value, ' ')) === undefined
                  ) {
                    clearErrors('iban');
                  }
                }}
              />
            </FormControl>
            <FormControl
              label="BIC"
              required
              errorMessage={errors?.bic?.message}
            >
              <Input
                data-clarity-mask="True"
                isFullWidth
                type="text"
                isDisabled={isFetchingExtract}
                {...register('bic', {
                  validate: (value: any) => checkBIC(value),
                })}
              />
            </FormControl>
          </Row>
          <Row spacing={20}>
            <FormControl
              label="Nom de la banque"
              required
              errorMessage={
                errors?.bankName?.type === 'maxLength'
                  ? 'Veuillez saisir moins de 31 caractères'
                  : errors?.bankName?.message
              }
            >
              <Input
                isFullWidth
                type="text"
                isDisabled={isFetchingExtract}
                {...register('bankName', {
                  required: 'Ce champ est requis',
                  maxLength: 30,
                })}
              />
            </FormControl>
            <FormControl
              label="Titulaire du compte"
              required
              errorMessage={errors?.owner?.message}
            >
              <Input
                isFullWidth
                type="text"
                isDisabled={isFetchingExtract}
                {...register('owner', { required: 'Ce champ est requis' })}
              />
            </FormControl>
          </Row>
        </>
      )}
      {!establishmentUseIban(establishment) && (
        <Row spacing={20}>
          <FormControl label="" errorMessage={errors?.rib?.message}>
            <FileInput
              label="Relevé d'identité bancaire"
              accept=".pdf"
              subLabel="Format accepté : PDF"
              onSelect={handleChangeRIB}
            >
              <Link iconLeft={<AddIcon />}>
                {ribFile || rib?.fileName
                  ? `Modifier le rib ${
                      ribFile?.name ? ribFile.name : rib?.fileName
                    }`
                  : "Ajouter un relevé d'identité bancaire"}
              </Link>
            </FileInput>
          </FormControl>
          <></>
        </Row>
      )}
      <Box>
        <Button type="submit" isLoading={loading}>
          Enregistrer
        </Button>
      </Box>
    </form>
  );
};
