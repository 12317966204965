import {
  EInvoiceStatus,
  EInvoiceType,
  IAdditionalActivity,
  IJoinedInvoice,
} from '@freelancelabs/teoreme-commons';
import { add, getTime } from 'date-fns';
import { InvoiceStatusEnum } from './types';
import {
  INVOICE_CREDIT_NOTE_CUSTOMER_TYPES,
  INVOICE_CREDIT_NOTE_PROVIDER_TYPES,
  INVOICE_CUSTOMER_TYPES,
  INVOICE_PROVIDER_TYPES,
  isAdditionalActivityInvoice,
  isTimeSpentInvoice,
  INVOICE_SEARCH_TEXT_MIN_LENGTH,
} from '@commons';
import { Theme } from 'styles';
import { Flex, Text } from 'components/ui';
export const getInvoiceState = (
  filter: InvoiceStatusEnum | 'AWAITING',
  selectedState: EInvoiceStatus | 'ALL' | 'AWAITING'
): Array<EInvoiceStatus> => {
  if (filter === 'AWAITING') {
    if (
      !selectedState ||
      selectedState === 'ALL' ||
      selectedState === 'AWAITING'
    ) {
      return [
        EInvoiceStatus.TO_BE_FILLED,
        EInvoiceStatus.TO_BE_SUBMITTED,
        EInvoiceStatus.REJECTED,
        EInvoiceStatus.PROJECTED,
      ];
    }
    return [selectedState];
  }
  return [filter];
};
export const getDefaultInvoiceTypes = (
  filterInvoiceType:
    | 'PROVIDER'
    | 'CUSTOMER'
    | 'PROVIDER_CREDIT_NOTE'
    | 'CUSTOMER_CREDIT_NOTE'
) => {
  switch (filterInvoiceType) {
    case 'CUSTOMER':
      return INVOICE_CUSTOMER_TYPES;
    case 'PROVIDER':
      return INVOICE_PROVIDER_TYPES;
    case 'CUSTOMER_CREDIT_NOTE':
      return INVOICE_CREDIT_NOTE_CUSTOMER_TYPES;
    case 'PROVIDER_CREDIT_NOTE':
      return INVOICE_CREDIT_NOTE_PROVIDER_TYPES;
  }
};
type MultiValueType = {
  label: string;
  value: string;
};
export type getObjectFilterInvoiceParams = {
  debouncedFilterQuery: string;
  startDate?: Date | null;
  endDate?: Date | null;
  filterUrl: InvoiceStatusEnum | 'ALL';
  selectedStatus?: EInvoiceStatus | 'ALL' | 'AWAITING';
  selectedType?: EInvoiceType | 'ALL';
  accountManagerId?: string | number | null | undefined;
  commercialSelected?: string;
  structureSelected?: string;
  clientSelected?: string;
  providerSelected?: string;
  multiClientSelected?: MultiValueType[];
  multiProviderSelected?: MultiValueType[];
  contractorSelected?: string;
  invoiceDate?: Date | null;
  searchInKeys?: MultiValueType[];
  selectedCustomStatus?: 'TO_SENT' | 'ERROR_RPA' | 'ERROR_GESCOM' | 'ALL'; //invoiceTypeSelected?: string;
  locked?: boolean | undefined; // only for provider invoice
  createdFromGescomFlow?: boolean | undefined; // only for provider invoice
};
export const getObjectFilterInvoice = (
  params: getObjectFilterInvoiceParams,
  filterInvoiceType:
    | 'PROVIDER'
    | 'CUSTOMER'
    | 'PROVIDER_CREDIT_NOTE'
    | 'CUSTOMER_CREDIT_NOTE'
) => {
  const {
    debouncedFilterQuery,
    startDate,
    endDate,
    filterUrl,
    selectedStatus,
    selectedType,
    accountManagerId,
    commercialSelected,
    structureSelected,
    // clientSelected,
    // providerSelected,
    contractorSelected,
    multiClientSelected,
    multiProviderSelected,
    invoiceDate,
    selectedCustomStatus,
    locked,
    createdFromGescomFlow,
  } = params;
  let $and = [];
  if (invoiceDate) {
    $and?.push({
      $expr: {
        $gte: [{ $toLong: '$invoiceDate' }, getTime(invoiceDate)],
      },
    });
    $and.push({
      $expr: {
        $lte: [
          { $toLong: '$submittedAt' },
          getTime(add(invoiceDate, { days: 1 })),
        ],
      },
    });
  }
  if (startDate !== null && endDate !== null) {
    $and?.push({
      $expr: {
        $gte: [{ $toLong: '$normalizedDate' }, getTime(startDate as Date)],
      },
    });
    $and.push({
      $expr: {
        $lte: [
          { $toLong: '$normalizedDate' },
          getTime(add(endDate as Date, { months: 1 })),
        ],
      },
    });
  }
  const filterObject = {
    invoiceType:
      selectedType !== 'ALL' && selectedType
        ? selectedType
        : { $in: getDefaultInvoiceTypes(filterInvoiceType) },
    status:
      filterUrl === 'ALL'
        ? selectedStatus !== 'ALL' && selectedStatus !== 'AWAITING'
          ? selectedStatus
          : undefined
        : { $in: getInvoiceState(filterUrl, selectedStatus || 'ALL') },
    accountManager: accountManagerId || undefined,
    'mission.internalCommercial': commercialSelected
      ? commercialSelected
      : undefined,
    estCustomer:
      multiClientSelected && multiClientSelected?.length > 0
        ? { $in: multiClientSelected?.map((mps: any) => mps?.value) }
        : undefined,
    estProvider:
      multiProviderSelected && multiProviderSelected?.length > 0
        ? { $in: multiProviderSelected?.map((mps: any) => mps?.value) }
        : undefined,
    contractor: contractorSelected ? contractorSelected : undefined,
    'mission.billingInformation.structure': structureSelected
      ? structureSelected
      : undefined,
    createdFromGescomFlow: createdFromGescomFlow,
    locked: locked,
    $and: $and?.length > 0 ? $and : undefined,
  };

  let queryFilter = [
    {
      providerInvoiceRef: {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    },
  ];
  if (
    params?.searchInKeys?.find(sk => sk?.value?.includes('default_invoice'))
  ) {
    let searchObject = [
      {
        'sage.invoiceId': {
          $regex: debouncedFilterQuery,
          $options: 'i',
        },
      },
    ];
    queryFilter = [...queryFilter, ...searchObject] as any;
  }
  if (params?.searchInKeys?.find(sk => sk?.value?.includes('contractor'))) {
    let searchObject = [
      {
        'contractor.firstName': {
          $regex: debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'contractor.lastName': {
          $regex: debouncedFilterQuery,
          $options: 'i',
        },
      },
    ];
    queryFilter = [...queryFilter, ...searchObject] as any;
  }
  if (params?.searchInKeys?.find(sk => sk?.value?.includes('mission'))) {
    let searchObject = [
      {
        'mission.displayReference': {
          $regex: debouncedFilterQuery,
          $options: 'i',
        },
      },
    ];
    queryFilter = [...queryFilter, ...searchObject] as any;
  }
  if (params?.searchInKeys?.find(sk => sk?.value?.includes('estCustomer'))) {
    let searchObject = {
      'estCustomer.businessName': {
        $regex: debouncedFilterQuery,
        $options: 'i',
      },
    };
    queryFilter?.push(searchObject as any);
  }
  if (params?.searchInKeys?.find(sk => sk?.value?.includes('estProvider'))) {
    let searchObject = [
      {
        'estProvider.businessName': {
          $regex: debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'estProvider.tradeName': {
          $regex: debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'estProvider.signBoard1': {
          $regex: debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'estProvider.signBoard2': {
          $regex: debouncedFilterQuery,
          $options: 'i',
        },
      },
      {
        'estProvider.signBoard3': {
          $regex: debouncedFilterQuery,
          $options: 'i',
        },
      },
    ];
    queryFilter = [...queryFilter, ...searchObject] as any;
  }

  if (selectedCustomStatus === 'TO_SENT') {
    //@ts-ignore
    filterObject.rpaErrorDetails = { $exists: false };
    //@ts-ignore
    filterObject['sage.documentType'] = { $exists: true };
  }
  if (selectedCustomStatus === 'ERROR_RPA') {
    //@ts-ignore
    filterObject.rpaErrorDetails = { $exists: true };
  }
  if (selectedCustomStatus === 'ERROR_GESCOM') {
    //@ts-ignore
    filterObject['sage.documentType'] = { $exists: false };
  }
  if (filterObject?.$and) {
    if (debouncedFilterQuery.length > INVOICE_SEARCH_TEXT_MIN_LENGTH) {
      //@ts-ignore
      filterObject.$and.push({ $or: queryFilter });
    }
  } else {
    if (debouncedFilterQuery.length > INVOICE_SEARCH_TEXT_MIN_LENGTH) {
      //@ts-ignore
      filterObject.$or = queryFilter;
    }
  }

  return filterObject;
};

export const isValidableInvoice = (
  invoice: IJoinedInvoice & {
    additionalActivity?: IAdditionalActivity;
  }
) => {
  const notValidatedStatus = [
    'TO_BE_FILLED',
    'TO_BE_SUBMITTED',
    'REJECTED',
    'TO_BE_VALIDATED',
  ];
  if (invoice?.status === EInvoiceStatus?.TO_BE_VALIDATED) {
    if (
      isTimeSpentInvoice(invoice?.invoiceType) &&
      !notValidatedStatus?.includes(invoice?.cra?.state)
    ) {
      return true;
    }
    if (
      isAdditionalActivityInvoice(invoice?.invoiceType) &&
      //@ts-ignore
      !notValidatedStatus?.includes(invoice?.additionalActivity?.status)
    ) {
      return true;
    }
  }
  return false;
};
export const getSearchInKeysText = (
  searchInKeys: MultiValueType[] | [],
  onlyString?: boolean
) => {
  if (searchInKeys?.length > 0) {
    if (onlyString) {
      let str = '';
      searchInKeys?.forEach((sk, index) => {
        str = str + sk?.label;
        if (index + 1 !== searchInKeys?.length) {
          str = str + ' & ';
        }
      });
      return str;
    }
    return (
      <Flex>
        <Text variant="i" fontSize={12}>
          Rechercher dans :{' '}
          {searchInKeys?.map((sk, index) => (
            <>
              <Text
                variant="i"
                fontSize={12}
                style={{
                  color: Theme?.colors?.primary?.default,
                }}
              >
                {sk?.label}
              </Text>

              {index + 1 !== searchInKeys?.length && ' & '}
            </>
          ))}
        </Text>
      </Flex>
    );
  }
};
