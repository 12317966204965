import {
  transformText,
  formatAllObject,
  useEstablishmentUpdateOne,
  COUNTRIES_SELECT,
  isNotEmpty,
  getCountryData,
} from '@commons';
import {
  ICompanyGetResponse,
  ISigner,
  ESignerType,
} from '@freelancelabs/teoreme-commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  Box,
  Button,
  CheckBox,
  CheckSwitch,
  CustomToolTip,
  Flex,
  FormControl,
  Input,
  Link,
  Row,
  Text,
} from 'components/ui';
import { CancelIcon } from 'components/ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yousignNameRestrictionPattern } from '@commons';
import { StaticSelect } from 'components/selects/StaticSelect';
type FormValues = any;

type AddCustomerEstablishmentAdressFormProps = {
  onSubmit?: () => void;
  onClose: (action?: any | undefined) => void;
  establishment: ICompanyGetResponse;
  // defaultValues?: Partial<IInvoiceAddress>;
  defaultValues?: any;
  readOnly?: boolean;
  signer?: ISigner;
};

export const AddCustomerEstablishmentAdressForm = ({
  onClose,
  onSubmit: onSubmitParent,
  defaultValues,
  establishment,
  signer,
}: AddCustomerEstablishmentAdressFormProps) => {
  const getCountryFromList = (country: string) => {
    const res = COUNTRIES_SELECT?.find(
      e => e.value.toLowerCase() === country.toLowerCase()
    );
    return transformText(res?.value, 'capitalize-first');
  };
  const [loading, setLoading] = useState(false);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const address = {
    country: defaultValues?.country || undefined,
    countryCode: defaultValues?.countryCode || undefined,
    street: defaultValues?.street || undefined,
    city: defaultValues?.city || undefined,
    postalCode: defaultValues?.postalCode || undefined,
    recipient: defaultValues?.recipient || undefined,
    additional: defaultValues?.additional || undefined,
  };
  const [countrySelected, setCountrySelected] = React.useState<
    string | undefined
  >(
    getCountryFromList(
      address?.country || (establishment?.address?.country as string)
    )
  );
  // const forceDefault = !establishment?.customer?.invoiceAddress?.find(
  //   a => a.default
  // )
  //   ? true
  //   : false;
  const [isDefaultAddress, setIsDefaultAddress] = React.useState<
    boolean | undefined
  >(defaultValues?.default || false);

  const [isLocked, setIsLocked] = React.useState<boolean | undefined>(
    defaultValues?.locked || false
  );

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm<FormValues>({
    defaultValues: {
      signer: { ...signer, civility: String(signer?.civility) },
      address,
    },
  });
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    const values = formatAllObject(
      {
        ...formValues,
      },
      yousignNameRestrictionPattern
    );
    try {
      const locationCountryCode =
        countrySelected &&
        getCountryData('alpha2', {
          name: countrySelected as string,
        });
      //@ts-ignore
      await updateEstablishment({
        uuid: establishment?.uuid,
        upsertInvoiceAddress: {
          uuid: defaultValues?.uuid || undefined,
          ...formValues?.address,
          street: isNotEmpty(formValues?.address?.street?.trim())
            ? formValues?.address?.street
            : undefined,
          city: isNotEmpty(formValues?.address?.city?.trim())
            ? formValues?.address?.city
            : undefined,
          postalCode: isNotEmpty(formValues?.address?.postalCode?.trim())
            ? formValues?.address?.postalCode
            : undefined,
          recipient: isNotEmpty(formValues?.address?.recipient?.trim())
            ? formValues?.address?.recipient
            : undefined,
          additional: isNotEmpty(formValues?.address?.additional?.trim())
            ? formValues?.address?.additional
            : undefined,
          country: isNotEmpty(countrySelected?.trim())
            ? countrySelected
            : undefined,
          countryCode: isNotEmpty(locationCountryCode)
            ? locationCountryCode
            : undefined,
          default: isDefaultAddress,
          locked: isLocked,
        },
      });
      onClose({
        ...values?.signer,
        type: [ESignerType?.BCF_SIGNER],
      } as ISigner);
    } catch (e) {
      //
    }
    setLoading(false);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Text variant="h2">Informations sur l'adresse de facturation</Text>
      <Box>
        <Row spacing={20}>
          <Box>
            <FormControl label="">
              <CheckSwitch
                id="de"
                mb={10}
                onChange={e => {
                  setIsLocked(!isLocked);
                }}
                checked={!isLocked}
                isDisabled={isDefaultAddress}
              >
                <Text variant="h3">{isLocked ? 'Désactivée' : 'Activée'}</Text>
              </CheckSwitch>
            </FormControl>
          </Box>
          <Box>
            <CustomToolTip
              id={defaultValues?.uuid}
              text={
                defaultValues?.default
                  ? "Pour modifier l'adresse par défaut veuillez cocher la case directement sur l'adresse en question."
                  : false
              }
            >
              <CheckBox
                isDisabled={defaultValues?.default}
                checked={isDefaultAddress}
                onChange={e => {
                  if (!isDefaultAddress) setIsLocked(false);
                  setIsDefaultAddress(!isDefaultAddress);
                }}
                mt={30}
                key={'updateMission'}
                id={'updateMission'}
              >
                <Text variant="h3" mt={-2}>
                  Adresse par Défaut
                </Text>
              </CheckBox>
            </CustomToolTip>
          </Box>
        </Row>
        <Box my={20}>
          <Box>
            <FormControl
              label={'Destinataire'}
              // @ts-ignore
              errorMessage={errors?.address?.recipient?.message}
              required
            >
              <Input
                data-cy="errors?.address.recipient.message"
                isFullWidth
                {...register('address.recipient', {
                  required: 'Ce champ est requis',
                })}
                type="text"
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl
              label={'Adresse'}
              // @ts-ignore
              errorMessage={errors?.address?.street?.message}
              required
            >
              <Input
                data-cy="errors?.address.street.message"
                isFullWidth
                {...register('address.street', {
                  required: 'Ce champ est requis',
                })}
                type="text"
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl
              label="Complément d'adresse"
              errorMessage={errors?.address?.additional?.message}
            >
              <Input isFullWidth {...register('address.additional')} />
            </FormControl>
          </Box>
          <Flex>
            <Box width={3 / 12}>
              <FormControl
                label={'Code postal'}
                // @ts-ignore
                errorMessage={errors?.address?.postalCode?.message}
                required
              >
                <Input
                  data-cy="errors?.address.postalCode.message"
                  isFullWidth
                  {...register('address.postalCode', {
                    required: 'Ce champ est requis',
                  })}
                  type="text"
                />
              </FormControl>
            </Box>
            <Box width={3 / 12} />
            <Box width={6 / 12}>
              <FormControl
                label={'Ville'}
                // @ts-ignore
                errorMessage={errors?.address?.city?.message}
                required
              >
                <Input
                  data-cy="errors?.address.city.message"
                  isFullWidth
                  {...register('address.city', {
                    required: 'Ce champ est requis',
                  })}
                  type="text"
                />
              </FormControl>
            </Box>
          </Flex>
          <Box>
            <FormControl
              label={'Sélectionner un Pays'}
              required
              errorMessage={errors?.address?.country?.message}
            >
              <StaticSelect
                options={COUNTRIES_SELECT}
                onChange={value => {
                  setCountrySelected(value as string);
                  setValue('address.country', value as string);
                }}
                name="address.country"
                value={countrySelected}
                placeholder="Pays"
                required
              />
              {/* </Box> */}
            </FormControl>
          </Box>
        </Box>
        <Row spacing={20} width={'300px'}>
          <Button key="submit" type="submit" isLoading={loading}>
            Enregistrer
          </Button>
          <Link
            iconLeft={<CancelIcon />}
            mt={'20px'}
            onClick={() => onClose(false)}
          >
            Annuler
          </Link>
        </Row>
      </Box>
    </form>
  );
};
