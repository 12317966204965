import * as React from 'react';
import { useEffect } from 'react';
import { useAlertStore } from 'store/alert';
import { ToastContainer, toast } from 'react-toastify';
import {
  INTERNAL_ERROR,
  INVALID_PARAMETER,
  MISSING_PARAMETERS,
  NOT_FOUND,
  INVALID_SEARCH_PARAMS,
  NOTHING_TO_UPDATE,
  MISSION_OUT_OF_RANGE_END_DATE,
} from '@freelancelabs/teoreme-commons';

import 'react-toastify/dist/ReactToastify.css';
import ReactJson from '@microlink/react-json-view';

type Props = {};

const ERRORS_WITH_DETAILS = [
  INTERNAL_ERROR,
  INVALID_PARAMETER,
  MISSING_PARAMETERS,
  NOT_FOUND,
  INVALID_SEARCH_PARAMS,
  NOTHING_TO_UPDATE,
  MISSION_OUT_OF_RANGE_END_DATE,
].map(error => error.code);

export const Notifications: React.FC<React.PropsWithChildren<Props>> = () => {
  const { message, open, variant, errorLogs, updateAlert } = useAlertStore();
  const closeNotification = React.useCallback(async (): Promise<void> => {
    updateAlert({
      message: '',
      open: false,
      variant: 'success',
    });
  }, [updateAlert]);

  const autoCloseNotification = React.useCallback(async (): Promise<void> => {
    await setTimeout(function () {
      closeNotification();
    }, 5000);
  }, [closeNotification]);

  const NotifError = (props: any) => {
    const [showDetails, setShowDetails] = React.useState<boolean>(false);
    const { error, message, details, ...objectJson } = errorLogs ?? {};

    const errorMessage =
      typeof details === 'string' ? details : details?.message ?? message;
    objectJson.errorMessage = errorMessage;
    return (
      <div>
        <p
          onClick={() => {
            setShowDetails(!showDetails);
          }}
          style={{ cursor: 'pointer' }}
        >
          {props?.message || error}
          <br />
          <span style={{ fontWeight: 'bold' }}>
            {' '}
            {`${showDetails ? 'Masquer' : 'Afficher'} `} les details
          </span>
        </p>
        {showDetails && (
          <ReactJson
            src={objectJson}
            theme="harmonic"
            collapsed={false}
            displayDataTypes={false}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (open === true) {
      if (
        variant === 'error' &&
        message &&
        message !== '' &&
        ERRORS_WITH_DETAILS.includes(errorLogs?.errorCode)
      ) {
        toast.error(<NotifError message={message} />, {});
      } else toast?.[variant](message);
      autoCloseNotification();
    }
  }, [autoCloseNotification, open, message]);
  return (
    <div>
      <ToastContainer
        style={{ top: 80 }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Notifications;
