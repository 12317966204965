import * as React from 'react';
import { CustomToolTip, Radio, StatusVariantColor } from 'components/ui';
import Avatar from '../ui/Avatar';
import { Box } from '../ui/Box';
import { Card, CardBody, CardProps } from '../ui/Card';
import { Flex } from '../ui/Flex';
import { Text } from '../ui/Text';
import { Status } from '../ui/Status';
import { useHistory } from 'react-router-dom';
import {
  CREATED_SF_STATUS,
  NOT_CREATED_SF_STATUS,
  DISPLAY_SF_STATUS,
  IcheckSageStructure,
} from '@commons';
import { LockIcon } from 'components/ui/icons';
import { Theme } from 'styles';
export type StructuredCardProps = CardProps & {
  avatarLetter: string;
  primaryTopText?: string | null;
  secondaryTopText?: string | null;
  primaryBottomText?: string | null;
  secondaryBottomText?: string | null;
  secondLineBottomText?: string | null;
  renderActions?: React.ReactNode;
  checkSage?: boolean;
  checkSaleForce?: boolean;
  sage?: IcheckSageStructure;
  salesforce?: any;
  isSelectable?: boolean;
  isSelected?: boolean;
  onChangeSelected?: () => void;
  redirect?: string | false;
  style?: React.CSSProperties;
  isLock?: boolean;
  statusText?: string;
  statusColorVariant?: StatusVariantColor;
};

export const StructuredCard = ({
  avatarLetter,
  primaryTopText,
  secondaryTopText,
  primaryBottomText,
  secondaryBottomText,
  secondLineBottomText,
  renderActions,
  checkSage,
  checkSaleForce,
  sage,
  salesforce,
  isSelectable,
  isSelected,
  onChangeSelected,
  redirect,
  isLock,
  statusText,
  statusColorVariant,
  ...props
}: StructuredCardProps) => {
  const history = useHistory();
  return (
    <Card
      style={{ cursor: isSelectable ? 'pointer' : 'inherit' }}
      {...props}
      onClick={() =>
        isSelectable && onChangeSelected ? onChangeSelected() : false
      }
    >
      <Flex>
        <CardBody
          flex={1}
          onClick={() => (redirect ? history.push(redirect) : false)}
        >
          <Flex>
            {isSelectable && (
              <Box>
                <Radio checked={isSelected}>
                  <></>
                </Radio>
              </Box>
            )}
            <Box>
              <Avatar text={avatarLetter} size={80} mr={20} />
            </Box>
            <Box>
              <Box mb={10}>
                <Box display={'inline-flex'}>
                  {isLock && (
                    <CustomToolTip
                      id={avatarLetter}
                      color={Theme?.colors?.error?.default}
                      text="Cet établissement est désactivé"
                    >
                      <LockIcon
                        fill={Theme?.colors?.error?.default}
                        style={{ marginTop: 4, marginRight: 5 }}
                      />
                    </CustomToolTip>
                  )}
                  <Text variant="h3" data-clarity-mask="True">
                    {primaryTopText}
                  </Text>
                  {checkSage ? (
                    <Status
                      ml={3}
                      variantColor={!sage?.isReady ? 'warning' : 'success'}
                    >
                      {sage && !sage?.isReady
                        ? `À créer dans GESCOM ( ${sage?.missingStructure?.map(
                            ms => ms
                          )} )`
                        : 'Créé dans GESCOM'}
                    </Status>
                  ) : (
                    <></>
                  )}
                  {checkSaleForce && DISPLAY_SF_STATUS ? (
                    <Status
                      ml={3}
                      variantColor={!salesforce ? 'grey' : 'success'}
                    >
                      {!salesforce ? NOT_CREATED_SF_STATUS : CREATED_SF_STATUS}
                    </Status>
                  ) : (
                    <></>
                  )}
                </Box>
                <Text color="primary.light">{secondaryTopText}</Text>
              </Box>
              {(primaryBottomText || secondaryBottomText) && (
                <Box>
                  <Text>
                    {[primaryBottomText, secondaryBottomText]
                      .filter(t => !!t)
                      .join(' - ')}
                  </Text>
                  {secondLineBottomText && <Text>{secondLineBottomText}</Text>}
                </Box>
              )}
              {statusText && (
                <Box pt={'2px'}>
                  <Flex>
                    <Text>Statut d'embarquement : </Text>
                    {statusText ? (
                      <Status
                        ml={2}
                        fontSize={13}
                        variantColor={statusColorVariant}
                        circleHidden={true}
                      >
                        {statusText ?? 'neant'}
                      </Status>
                    ) : (
                      <></>
                    )}
                  </Flex>
                </Box>
              )}
            </Box>
          </Flex>
        </CardBody>
        <Flex padding="0 30px" alignItems="center">
          {renderActions}
        </Flex>
      </Flex>
    </Card>
  );
};

export default Card;
