import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import {
  EContractorAsEmployee,
  ESocialStatus,
  ECraStatus,
  EInvoiceStatus,
  EInvoiceType,
  EContractorSocialStatus,
  EDpaeStatus,
  EPurchaseOrderStatus,
  EStandByDutyUnits,
  EStandByDutyFormulaVars,
  EadditionalActivityType,
  EExpenseType,
  ECustomerReferencePatternVars,
  EMissionType,
  EadditionalActivityStatus,
} from '@freelancelabs/teoreme-commons';
export const BUDGET_COMPARISON_TOLERANCE = 0.01;
export const MAX_FILE_SIZE_ANNEXE_MB = 4750000;
export const MAX_PAYMENT_DEADLINE_END_OF_MONTH = 45;
export const MAX_PAYMENT_DEADLINE_FIXED = 60;
export const MAX_IMPORT_FILES_NUMBER = 3;
export const OPEN_MODAL_INACTIVITY_MINUTES = 5;
export const REFRESH_TOKEN_BEFORE_MINUTES = 10;
export const CHECK_BUILD_BEFORE_MS = 600000 * 3; // 30 minutes
// additional files number you can add as additional activity justification files
export const ADDIONAL_FILES_NUMBER = 5;
export const yousignNameRestrictionPattern =
  /[^a-z0-9àáâãäåæçèéêëìíîïñòóôõöùúûüýÿß.’'()\- ]/gi;
export const REACT_QUERY_STALETIME = 100000;
export const REACT_QUERY_CACHETIME = 250000;
export const REACT_QUERY_RETRYDELAY = 60000; // 1 minute
// SEARCH CONFIG
export const DEFAULT_SEARCH_DEBOUNCE_DELAY = 500; // 0.5seoncdes
export const DEFAULT_SEARCH_TEXT_MIN_LENGTH = 0;
export const INVOICE_SEARCH_TEXT_MIN_LENGTH = 3;
export const INVOICE_SEARCH_DEBOUNCE_DELAY = 750; // 0.75 seoncdes
// SAGE
export const MAX_DEFAULT_FIELD_LENGTH = 69;
export const MAX_DEFAULT_SPLIT_FIELD_LENGTH = 35;
export const MAX_CUSTOMER_REFERENCE_FIELD_LENGTH = MAX_DEFAULT_FIELD_LENGTH;
export const MAX_REFERENCE_CONTRACT_FIELD_LENGTH = MAX_DEFAULT_FIELD_LENGTH;
// MAINTENANCE
export const REACT_APP_MAINTENANCE_MODE =
  process.env.REACT_APP_MAINTENANCE_MODE;

export const REACT_APP_FONE_PURGATORY_DATE =
  process.env.REACT_APP_FONE_PURGATORY_DATE;
export const REACT_APP_MARKET_REQUIRED =
  process.env.REACT_APP_MARKET_REQUIRED === 'true';
export const PRIMARY_COLOR_NO_PRODUCTION = '#e2433a';
export const REACT_APP_SEED_STAGE_NAME = process.env.REACT_APP_SEED_STAGE_NAME;
export const HOME_URL = window.location.protocol + '//' + window.location.host;
export const REACT_APP_LOCALHOST_SPACE = process.env.REACT_APP_LOCALHOST_SPACE;
export const REACT_APP_SEED_BUILD_ID = process.env.REACT_APP_SEED_BUILD_ID;
export const REACT_APP_CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY;
export const GET_HOLIDAYS_API_KEY = process.env.REACT_APP_GET_HOLIDAYS_API_KEY;
export const SERVICE_URL = process.env.REACT_APP_SERVICE_URL;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
//AMPLIFY
export const REACT_APP_COGNITO_CLIENT_ID =
  process.env.REACT_APP_COGNITO_CLIENT_ID;
export const REACT_APP_COGNITO_POOL_ID = process.env.REACT_APP_COGNITO_POOL_ID;
export const REACT_APP_COGNITO_DOMAIN_PREFIX =
  process.env.REACT_APP_COGNITO_DOMAIN_PREFIX;
export const COOKIES_DOMAIN = 'freelance.com';
//
export const MAXBOX_LIMIT = 10;
export const MAPBOX_MAX_ALLOWED_TOKENS = 20;
export const MAX_COUNT_REMINDER = 2;
export const MAPBOX_TOKEN_URL = process.env.REACT_APP_MAPBOX_TOKEN;
export const MAPBOX_SERVICE_URL = `https://api.mapbox.com/geocoding/v5/{endpoint}/{search_text}.json?access_token=${MAPBOX_TOKEN_URL}&limit=${MAXBOX_LIMIT}&country=fr&types=address&fuzzyMatch=false&proximity=ip&autocomplete=true`;
export const AUTH_SERVICE_URL = SERVICE_URL + '/auth';
export const USER_SERVICE_URL = SERVICE_URL + '/user';
export const COMPANY_SERVICE_URL = SERVICE_URL + '/company';
export const FILE_SERVICE_URL = SERVICE_URL + '/files';
export const CONTRACT_SERVICE_URL = SERVICE_URL + '/contract';
export const CONTRACTOR_SERVICE_URL = SERVICE_URL + '/contractor';
export const DPAE_SERVICE_URL = SERVICE_URL + '/dpae';
export const MISSION_SERVICE_URL = SERVICE_URL + '/mission';
export const SAGE_SERVICE_URL = SERVICE_URL + '/sage';
export const YOUSING_SERVICE_URL = SERVICE_URL + '/yousign';
export const NOTIFICATION_SERVICE_URL = SERVICE_URL + '/notification';
export const CONTACT_SERVICE_URL = SERVICE_URL + '/contact';
export const ONBOARDING_SERVICE_URL = SERVICE_URL + '/onboarding';
export const CRA_SERVICE_URL = SERVICE_URL + '/cra';
export const INVOICE_SERVICE_URL = SERVICE_URL + '/invoice';
export const COMMENT_SERVICE_URL = SERVICE_URL + '/comment';
export const TRACER_SERVICE_URL = SERVICE_URL + '/tracer';
export const STATISTICS_SERVICE_URL = SERVICE_URL + '/statistics';
export const ADMIN_SERVICE_URL = SERVICE_URL + '/admin';
export const THIRD_PARTY_SERVICE_URL = SERVICE_URL + '/third-party-apps';
export const ADDTIONAL_ACTIVITY_SERVICE_URL =
  SERVICE_URL + '/additional-activity';
export const RENDER_SERVICE_URL = SERVICE_URL + '/render';
export const SALEFORCE_SERVICE_URL = SERVICE_URL + '/salesforce';
export const REACT_APP_INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
//url
export const PUBLIC_APP_MANAGER_URL =
  'https://manager.connecteed.freelance.com';
export const PUBLIC_APP_PROVIDER_URL = 'https://connecteed.freelance.com';
//emails
export const PUBLIC_APP_CONTACT_EMAIL = 'adv@freelance.com ';
export const PUBLIC_APP_DPO_EMAIL = 'dpo@freelance.com ';
export const PUBLIC_APP_CONTACT_PHONE = '01 80 48 70 00';
export const PUBLIC_APP_TEAM_CONNECTEED = 'team-connecteed@freelance.com';
//Names
export const PUBLIC_APP_MANAGER_NAME = 'Connecteed';
export const PUBLIC_APP_PROVIDER_NAME = 'Connecteed';
// Talent
export const APP_MOBILE_TALENT_LINK = 'https://talent.freelance.com';
export const ERoles = RolesTypes;
export const Roles = [
  'ADMIN',
  'ACCOUNT_MANAGER',
  'PROVIDER_CONTACT',
  'PROVIDER_RESPONSIBLE',
  'FREELANCE_PARTNER',
  'BUSINESS_PARTNER',
  'CUSTOMER',
  'CONTRACTOR',
  'INTERNAL_COMMERCIAL',
  'TEAM_LEADER',
  'LEGAL_SQUAD',
  'SUPPORT',
  'PORTAL_CLIENT_ADMIN',
];
export const PUBLIC_MANAGER_PATH = [
  'reset-password',
  '404',
  'login',
  'get-access',
  'old-login',
];
export const COMMON_MANAGER_PATH = [...PUBLIC_MANAGER_PATH, 'user', 'file'];
export * from './manager';
export const PERMISSION_MANAGER_PATH_ROLES = {
  ADMIN: '*',
  PORTAL_CLIENT_ADMIN: [...COMMON_MANAGER_PATH, 'clients'],
  MANAGER_READ_ONLY: [
    ...COMMON_MANAGER_PATH,
    'dashboard',
    'clients',
    'providers',
    'delivery',
    'orders',
    'others',
    'notifications',
    'traces',
  ],
  ACCOUNT_MANAGER: [
    ...COMMON_MANAGER_PATH,
    'dashboard',
    'clients',
    'providers',
    'delivery',
    'orders',
    'others',
    'notifications',
    'traces',
  ],
  TEAM_LEADER: [
    ...COMMON_MANAGER_PATH,
    'dashboard',
    'clients',
    'providers',
    'delivery',
    'orders',
    'others',
    'notifications',
    'traces',
  ],
  LEGAL_SQUAD: [...COMMON_MANAGER_PATH, 'legals'],
  SUPPORT: [...COMMON_MANAGER_PATH, 'support'],
};
export const FILE_GUIDE_LOCATION =
  'documents/guide/provider/guide_utilisateur_connecteed.pdf';
export const FILE_GUIDE_NAME = 'guide_utilisateur_connecteed';

export const TRANSLATE_ROLES = {
  ADMIN: 'Administrateur',
  ACCOUNT_MANAGER: 'Chargé de compte',
  PROVIDER_CONTACT: 'Contact fournisseur',
  PROVIDER_RESPONSIBLE: 'Responsable de compte',
  FREELANCE_PARTNER: 'Freelance partner',
  BUSINESS_PARTNER: "Apporteur d'affaires",
  CUSTOMER: 'Client',
  CONTRACTOR: 'Intervenant',
  INTERNAL_COMMERCIAL: 'Commercial interne',
  TEAM_LEADER: "Chef d'équipe ( chargé de compte )",
  LEGAL_SQUAD: 'Équipe juridique',
  SUPPORT: 'Support',
  MANAGER_READ_ONLY: 'Visiteur ( lecture seul )',
  PORTAL_CLIENT_ADMIN: 'Administrateur portail client',
  INVOICING_RECOVERY: 'Recouvrement',
  MANAGER_RIB: 'Gestionnaire de RIB',
};
export const NOTIFICATION_TYPES = {
  ALERT: 'Alerte',
  INFO: 'Information',
  ACTION: 'Action',
};
export const FR_STATUS = {
  ABORTED: 'Annulé',
  DELETED: 'Supprimé',
  PENDING: 'En attente',
  SENT: 'Envoyé',
  IMPORTED: 'Importé',
  DRAFT: 'Brouillon',
  TERMINATED: 'Terminée',
  PROJECTED: 'Prévisionnel',

  TO_SIGN_FCOM: 'En attente de signature',
  TO_SIGN_PROVIDER: 'En cours de signature',
  DONE: 'Signé',
  ARCHIVED: 'Archivé',
  SIGNED_BY_FCOM: 'Signé',
  SIGNED_BY_PROVIDER: 'En attente de signature',
  REFUSED_BY_FCOM: 'Refusé',
  REFUSED_BY_MANAGER: 'Refusé',
  REFUSED_BY_PROVIDER: 'Refusé par le fournisseur',

  UNRECEIVED: 'Non reçu',
  TO_SIGN: 'En attente de signature',
  SIGNED: 'Signé',
  SIGNED_IMPORT: 'Importé signé',

  SIMPLIFIED: 'Simplifié',
  GROUP_GENERIC: 'CCFRA Groupe',
  PORTAGE_GENERIC: 'CCFRA Portage',
  SIMPLIFIED_45: 'Simplifié 45j',
  OTHER: 'Autre',
  AMENDMENT: 'Avenant',
  CLASSIC: 'Classique',
  LOREAL: "Mandat ( l'Oreal )",
  LOREAL_4_5: "Mandat ( l'Oreal ) 4,5 %",
  LOREAL_5: "Mandat ( l'Oreal ) 5%",
  ATOS: 'Atos',
  SPECIFIC: 'Spécifique',
  LOREAL_MANDATE_GENERIC: 'Mandat de gestion',

  PROFESSIONAL_INDEMNITY_INSURANCE: 'Responsabilité Civile Pro',
  FAST_CASH: 'Fast Cash : paiement à 7 jours',
  GDPR: 'RGPD',
  MISC: 'Divers',

  REJECTED: 'Refusé',
  TO_BE_FILLED: 'Non saisi',
  TO_BE_SUBMITTED: 'Non soumis',
  TO_BE_VALIDATED: 'À valider',
  VALIDATED: 'Validé',
  TRAINING: 'Formation',

  BANK_TRANSFER: 'Virement',
  DIRECT_DEBIT: 'Prélévement',
  END_OF_MONTH: 'Fin de mois',
  FIXED: "Date d'émission la facture",

  //tasks
  ONGOING: 'En cours',
  COMPLETED: 'Terminé',
  ERROR: 'En erreur',
  SKIPPED: 'Sauté',
  CANCELLED: 'Annulé',
  RESOLVED: 'Résolu',

  // history
  CREATE: 'Création',
  UPDATE: 'Modification',
  DELETE: 'Suppression',
  ROLLBACK: 'Retour en arrière',
  REMINDER: 'Rappel',
};
export const ADDITIONAL_SERVICES_TYPE_FR = {
  PROFESSIONAL_INDEMNITY_INSURANCE: 'Responsabilité Civile Pro',
  FAST_CASH: 'Fast Cash : paiement à {deadline} jours',
  GDPR: 'RGPD',
  MISC: 'Divers',
  HANDLING_FEE: 'Frais de dossier',
};

export const STATUS_COLOR = {
  SIGNED: 'success',
  DONE: 'success',
  SIGNED_IMPORT: 'success',
  IMPORTED: 'success',
  TO_SIGN: 'warning',
  ARCHIVED: 'grey',
  UNRECEIVED: 'grey',
  REJECTED: 'error',
  TO_BE_SUBMITTED: 'grey',
  TO_BE_FILLED: 'grey',
  VALIDATED: 'success',
  TO_BE_VALIDATED: 'warning',
} as { [status: string]: 'success' | 'warning' | 'error' | 'grey' | 'primary' };

// Options

export const contractTypesOptions = [
  { value: 'SIMPLIFIED_FR', label: 'Simplifié' },
  { value: 'SIMPLIFIED_ENG', label: 'Simplifié ENG' },
  { value: 'OTHER_FR', label: 'Autre' },
  { value: 'CLASSIC_FR', label: 'Classique' },
  { value: 'CLASSIC_ENG', label: 'Classique ENG' },
];

// To replace by back types

export const ADDITIONAL_SERVICE_TYPE = [
  'PROFESSIONAL_INDEMNITY_INSURANCE',
  'FAST_CASH',
  'GDPR',
  'MISC',
];

export const MISSION_TYPES = {
  [EMissionType.RESOURCE_MANAGEMENT]: 'RM',
  [EMissionType.CLASSIC]: 'TA',
};

export const ADDITIONAL_SERVICE_TYPE_SELECT = [
  {
    label: 'Responsabilité Civile Pro',
    value: ADDITIONAL_SERVICE_TYPE[0],
    key: ADDITIONAL_SERVICE_TYPE[0],
  },
  {
    label: 'Fast Cash : paiement à 7 jours',
    value: ADDITIONAL_SERVICE_TYPE[1],
    key: ADDITIONAL_SERVICE_TYPE[1],
  },
  { label: 'RGPD', value: 'GDPR', key: ADDITIONAL_SERVICE_TYPE[2] },
  {
    label: 'Divers',
    value: ADDITIONAL_SERVICE_TYPE[3],
    key: ADDITIONAL_SERVICE_TYPE[3],
  },
];
export const EXECUTIVE_CONTRACTS_SELECT = [
  { value: 'TRAINING_FR', label: 'Formation FR' },
  { value: 'GROUP_GENERIC_FR', label: 'CCFRA Groupe FR' },
  { value: 'GROUP_GENERIC_ENG', label: 'CCFRA Groupe EN' },
  { value: 'PORTAGE_GENERIC_FR', label: 'CCFRA Portage FR' },
  // { value: 'OTHER_FR', label: 'Autre FR' },
  // { value: 'OTHER_ENG', label: 'Autre ENG' },
  {
    value: 'SPECIFIC_FR',
    label: 'Spécifique FR',
  },
  {
    value: 'SPECIFIC_ENG',
    label: 'Spécifique ENG',
  },
];
export const MANDAT_CONTRACTS_SELECT = [
  {
    value: 'LOREAL_MANDATE_GENERIC_FR',
    label: 'Mandat de gestion FR',
  },
  {
    value: 'LOREAL_MANDATE_GENERIC_ENG',
    label: 'Mandat de gestion EN',
  },
];
export const PRICE_UNIT = [
  'PERCENT_INVOICE',
  'FLAT_PRICE',
  'PRICE_PER_DAY',
  'PRICE_PER_WORKING_DAY',
  'PRICE_PER_MONTH',
  'PRICE_PER_INVOICE',
  'PRICE_PER_YEAR',
  'ONE_TIME_PRICE',
  'ONE_TIME_PERCENT',
];
export const PRICE_UNIT_SELECT = [
  {
    label: '% du montant de la facture du payeur',
    value: PRICE_UNIT[0],
    key: PRICE_UNIT[0],
  },
  //{ label: 'Forfait', value: PRICE_UNIT[1], key: PRICE_UNIT[1] },
  //{ label: 'Par jour', value: PRICE_UNIT[2], key: PRICE_UNIT[2] },
  { label: 'Par mois', value: PRICE_UNIT[4], key: PRICE_UNIT[4] },
  //{ label: 'Par an', value: PRICE_UNIT[5], key: PRICE_UNIT[5] },
  //{ label: 'Par facture', value: PRICE_UNIT[4], key: PRICE_UNIT[4] },
  // {
  //   label: 'Paiement unique',
  //   value: PRICE_UNIT[6],
  //   key: PRICE_UNIT[6],
  // },
  // {
  //   label: '% du montant de la facture en paiement unique',
  //   value: PRICE_UNIT[7],
  //   key: PRICE_UNIT[7],
  // },
];

export const SOCIAL_STATUS_SELECT = [
  {
    label: 'Salarié',
    value: ESocialStatus.EMPLOYEE,
    key: ESocialStatus.EMPLOYEE,
  },
  {
    label: 'Micro-entrepreneur, dirigeant',
    value: ESocialStatus.RULER,
    key: ESocialStatus.RULER,
  },
  {
    label: 'Gérant salarié',
    value: ESocialStatus.MINOTORY_MANAGER,
    key: ESocialStatus.MINOTORY_MANAGER,
  },
  {
    label: 'Gérant non Salarié',
    value: ESocialStatus.MAJORITORY_MANAGER,
    key: ESocialStatus.MAJORITORY_MANAGER,
  },
  {
    label: 'Autre',
    value: ESocialStatus.OTHER,
    key: ESocialStatus.OTHER,
  },
];

export const CONTRACTOR_SOCIAL_STATUS = {
  [EContractorSocialStatus.EMPLOYEE]: 'Salarié',
  [EContractorSocialStatus.COMPANY_MANAGER]: 'Micro-entrepreneur, dirigeant',
  [EContractorSocialStatus.MINORITY_MANAGER]: 'Gérant salarié',
  [EContractorSocialStatus.MAJORITY_MANAGER]: 'Gérant non Salarié',
  [EContractorSocialStatus.SUBCONTRACTOR]: 'Sous-traitant',
};
export const CONTRACTOR_SOCIAL_STATUS_SELECT = [
  {
    label: 'Salarié',
    value: EContractorSocialStatus.EMPLOYEE,
    key: EContractorSocialStatus.EMPLOYEE,
  },
  {
    label: 'Micro-entrepreneur, dirigeant',
    value: EContractorSocialStatus.COMPANY_MANAGER,
    key: EContractorSocialStatus.COMPANY_MANAGER,
  },
  {
    label: 'Gérant salarié',
    value: EContractorSocialStatus.MINORITY_MANAGER,
    key: EContractorSocialStatus.MINORITY_MANAGER,
  },
  {
    label: 'Gérant non salarié',
    value: EContractorSocialStatus.MAJORITY_MANAGER,
    key: EContractorSocialStatus.MAJORITY_MANAGER,
  },
  {
    label: 'Sous-traitant',
    value: EContractorSocialStatus.SUBCONTRACTOR,
    key: EContractorSocialStatus.SUBCONTRACTOR,
  },
];

export const CONTRACTOR_AS_EMPLOYEE_SELECT = [
  {
    label: `Tous sont salariés de l'établissement renseigné`,
    value: EContractorAsEmployee.ALL,
    key: EContractorAsEmployee.ALL,
  },
  {
    label: `Au moins un est salarié de l'établissement renseigné`,
    value: EContractorAsEmployee.AT_LEAST_ONE,
    key: EContractorAsEmployee.AT_LEAST_ONE,
  },
  {
    label: `Aucun n'est salarié de l'établissement renseigné`,
    value: EContractorAsEmployee.NONE,
    key: EContractorAsEmployee.NONE,
  },
];

export const CRA_STATUS_SELECT = [
  {
    label: 'Tous les statuts',
    value: 'ALL',
    key: 'ALL',
  },
  {
    label: `Validé`,
    value: ECraStatus.VALIDATED,
    key: ECraStatus.VALIDATED,
  },
  {
    label: `Rejeté`,
    value: ECraStatus.REJECTED,
    key: ECraStatus.REJECTED,
  },
  {
    label: `Archivé`,
    value: ECraStatus.ARCHIVED,
    key: ECraStatus.ARCHIVED,
  },
  {
    label: `A saisir`,
    value: ECraStatus.TO_BE_FILLED,
    key: ECraStatus.TO_BE_FILLED,
  },
  {
    label: `A soumettre`,
    value: ECraStatus.TO_BE_SUBMITTED,
    key: ECraStatus.TO_BE_SUBMITTED,
  },
  {
    label: `En attente de validation`,
    value: ECraStatus.TO_BE_VALIDATED,
    key: ECraStatus.TO_BE_VALIDATED,
  },
];
export const ADDITIONAL_ACTIVITY_STATUS_SELECT = [
  {
    label: 'Tous les statuts',
    value: 'ALL',
    key: 'ALL',
  },
  {
    label: `Validé`,
    value: EadditionalActivityStatus.VALIDATED,
    key: EadditionalActivityStatus.VALIDATED,
  },
  {
    label: `Refusé`,
    value: EadditionalActivityStatus.REJECTED,
    key: EadditionalActivityStatus.REJECTED,
  },
  {
    label: `Archivé`,
    value: EadditionalActivityStatus.ARCHIVED,
    key: EadditionalActivityStatus.ARCHIVED,
  },
  {
    label: `A saisir`,
    value: EadditionalActivityStatus.TO_BE_FILLED,
    key: EadditionalActivityStatus.TO_BE_FILLED,
  },
  {
    label: `A soumettre`,
    value: EadditionalActivityStatus.TO_BE_SUBMITTED,
    key: EadditionalActivityStatus.TO_BE_SUBMITTED,
  },
  {
    label: `En attente de validation`,
    value: EadditionalActivityStatus.TO_BE_VALIDATED,
    key: EadditionalActivityStatus.TO_BE_VALIDATED,
  },
];

export const INVOICE_STATUS_SELECT = [
  {
    label: 'Tous les statuts',
    value: 'ALL',
    key: 'ALL',
  },
  {
    label: `Prévisionnelle`,
    value: EInvoiceStatus.PROJECTED,
    key: EInvoiceStatus.PROJECTED,
  },
  {
    label: `Validée`,
    value: EInvoiceStatus.VALIDATED,
    key: EInvoiceStatus.VALIDATED,
  },
  {
    label: `Rejetée`,
    value: EInvoiceStatus.REJECTED,
    key: EInvoiceStatus.REJECTED,
  },
  {
    label: `Archivée`,
    value: EInvoiceStatus.ARCHIVED,
    key: EInvoiceStatus.ARCHIVED,
  },
  {
    label: `A saisir`,
    value: EInvoiceStatus.TO_BE_FILLED,
    key: EInvoiceStatus.TO_BE_FILLED,
  },
  {
    label: `Non soumise`,
    value: EInvoiceStatus.TO_BE_SUBMITTED,
    key: EInvoiceStatus.TO_BE_SUBMITTED,
  },
  {
    label: `En attente de validation`,
    value: EInvoiceStatus.TO_BE_VALIDATED,
    key: EInvoiceStatus.TO_BE_VALIDATED,
  },
  {
    label: `Annulée`,
    value: EInvoiceStatus.CANCELLED,
    key: EInvoiceStatus.CANCELLED,
  },
  {
    label: `Envoyée`,
    value: EInvoiceStatus.SENT,
    key: EInvoiceStatus.SENT,
  },
  {
    label: `Payée`,
    value: EInvoiceStatus.PAID,
    key: EInvoiceStatus.PAID,
  },
];

export const MONTH_FR = [
  'janvier',
  'février',
  'mars',
  'avril',
  'mai',
  'juin',
  'juillet',
  'août',
  'septembre',
  'octobre',
  'novembre',
  'décembre',
];
export const DPAE_STATUS = {
  [EDpaeStatus.ACTIVE]: 'DPAE active',
  [EDpaeStatus.ARCHIVED]: 'DPAE archivé',
  [EDpaeStatus.TO_BE_VALIDATED]: 'DPAE en attente de validation',
  [EDpaeStatus.PENDING]: 'DPAE en attente', // pas de file
  [EDpaeStatus.REJECTED]: 'DPAE refusée',
  //undefined: 'En attente de DPAE',
};
export const DPAE_STATUS_SELECT = [
  {
    label: 'Active',
    value: EDpaeStatus.ACTIVE,
    key: EDpaeStatus.ACTIVE,
  },
  {
    label: 'Archivé',
    value: EDpaeStatus.ARCHIVED,
    key: EDpaeStatus.ARCHIVED,
  },
  {
    label: 'En attente',
    value: EDpaeStatus.PENDING,
    key: EDpaeStatus.PENDING,
  },
  {
    label: 'Refusée',
    value: EDpaeStatus.REJECTED,
    key: EDpaeStatus.REJECTED,
  },
];
export const PURCHASE_ORDER_STATUS_SELECT = [
  {
    label: 'Non envoyé',
    value: EPurchaseOrderStatus.UNRECEIVED,
    key: EPurchaseOrderStatus.UNRECEIVED,
  },
  {
    label: 'En attente de signature',
    value: EPurchaseOrderStatus.TO_SIGN,
    key: EPurchaseOrderStatus.TO_SIGN,
  },
  {
    label: 'Importé et signé',
    value: EPurchaseOrderStatus.SIGNED_IMPORT,
    key: EPurchaseOrderStatus.SIGNED_IMPORT,
  },

  {
    label: 'À contresigner',
    value: EPurchaseOrderStatus.SIGNED,
    key: EPurchaseOrderStatus.SIGNED,
  },
  {
    label: 'Actif',
    value: EPurchaseOrderStatus.DONE,
    key: EPurchaseOrderStatus.DONE,
  },
  {
    label: 'Importé',
    value: EPurchaseOrderStatus.IMPORTED,
    key: EPurchaseOrderStatus.IMPORTED,
  },

  {
    label: 'Annulé',
    value: EPurchaseOrderStatus.ABORTED,
    key: EPurchaseOrderStatus.ABORTED,
  },

  {
    label: 'Supprimé',
    value: EPurchaseOrderStatus.DELETED,
    key: EPurchaseOrderStatus.DELETED,
  },
  {
    label: 'Archivé',
    value: EPurchaseOrderStatus.ARCHIVED,
    key: EPurchaseOrderStatus.ARCHIVED,
  },
  {
    label: 'Refusé par le Chargé de compte',
    value: EPurchaseOrderStatus.REFUSED_BY_MANAGER,
    key: EPurchaseOrderStatus.REFUSED_BY_MANAGER,
  },
  {
    label: 'Refusé par le Fournisseur',
    value: EPurchaseOrderStatus.REFUSED_BY_PROVIDER,
    key: EPurchaseOrderStatus.REFUSED_BY_PROVIDER,
  },
];
export const PURCHASE_ORDER_CLIENT_STATUS_SELECT = [
  {
    label: 'Tous les statuts',
    value: 'N/A',
    key: 'N/A',
  },
  {
    label: 'Non reçu',
    value: EPurchaseOrderStatus.UNRECEIVED,
    key: EPurchaseOrderStatus.UNRECEIVED,
  },

  {
    label: 'Importé',
    value: EPurchaseOrderStatus.IMPORTED,
    key: EPurchaseOrderStatus.IMPORTED,
  },
];
export const DISPLAY_SF_STATUS =
  REACT_APP_SEED_STAGE_NAME === 'localhost'
    ? true
    : process.env.REACT_APP_ACTIVE_SF_STATUS === 'true'
      ? true
      : false;
export const CREATED_SF_STATUS = 'Créé dans Salesforce';
export const NOT_CREATED_SF_STATUS = 'Non créé dans Salesforce';
export const FCOM_CAPITAL = process.env.REACT_APP_FCOM_CAPITAL;
export const MISSION_LABEL_TYPES = {
  CLASSIC: 'Talent Acquisition',
  RESOURCE_MANAGEMENT: 'Ressource management',
};
export const MISSION_BILLING_TYPE = {
  DAY: 'Temps passé',
  FLAT_RATE: 'Forfait',
};
export const MISSION_MARGIN_TYPE = {
  MARGIN: 'Taux de marque',
  MARKUP: 'Taux de marge',
};
export const FREELANCE_STRUCTURES = {
  FCOM: {
    complete: 'Freelance.com',
    short: 'FCOM',
    address: {
      street: '1, Parvis de la Défense,Paroi Nord de la Grande Arche',
      city: 'La Défense',
      postalCode: '92044',
      country: 'France',
    },
    siren: '384174348',
  },
  FONE: {
    complete: 'Freelance One',
    short: 'FONE',
    address: {
      street: '1, Parvis de la Défense,Paroi Nord de la Grande Arche',
      city: 'La Défense',
      postalCode: '92044',
      country: 'France',
    },
    siren: '852264431',
  },
  INOPS: {
    complete: "Inop's",
    short: "INOP'S",
    address: {
      street: '1, Parvis de la Défense,Paroi Nord de la Grande Arche',
      city: 'La Défense',
      postalCode: '92044',
      country: 'France',
    },
    siren: '513082503',
  },
  COWORKEES: {
    complete: 'Coworkees',
    short: 'COWORKEES',
    address: {
      street: '3, Esplanade Augustin Aussedat',
      city: 'Cran-Gevrier',
      postalCode: '74960',
      country: 'France',
    },
    siren: '824544977',
  },
};
export const PROVIDER_FORMULA_TAG_WHITELIST = [
  {
    id: 1,
    value: 'TJM fournisseur',
    var: `{{${EStandByDutyFormulaVars?.PROVIDER_RATE}}}`,
    key: 'contractorRate',
  },
  {
    id: 4,
    value: 'SAISIE',
    var: `{{${EStandByDutyFormulaVars?.INPUT}}}`,
    key: 'input',
  },
  {
    id: 5,
    value: 'TAUX MARQUE MISSION',
    var: `{{${EStandByDutyFormulaVars?.I_RATE}}}`,
    key: 'missionMargin',
  },
];
export const CUSTOMER_FORMULA_TAG_WHITELIST = [
  {
    id: 7,
    value: 'TJM client',
    var: `{{${EStandByDutyFormulaVars?.CUSTOMER_RATE}}}`,
    key: 'clientRate',
  },
  {
    id: 10,
    value: 'SAISIE',
    var: `{{${EStandByDutyFormulaVars?.INPUT}}}`,
    key: 'input',
  },
  {
    id: 13,
    value: 'TAUX MARQUE MISSION',
    var: `{{${EStandByDutyFormulaVars?.I_RATE}}}`,
    key: 'missionMargin',
  },
];
export const DURATION_TAG_WHITELIST = [
  {
    id: 1,
    value: 'Jours',
    var: `{{DAYS}}`,
    key: 'DAYS',
  },
  {
    id: 2,
    value: 'Mois',
    var: `{{MONTHS}}`,
    key: 'MONTHS',
  },
  {
    id: 3,
    value: 'Années',
    var: `{{YEARS}}`,
    key: 'YEARS',
  },
  {
    id: 4,
    value: 'Heures',
    var: `{{HOURS}}`,
    key: 'HOURS',
  },
  {
    id: 5,
    value: 'Minutes',
    var: `{{MINUTES}}`,
    key: 'MINUTES',
  },
  {
    id: 6,
    value: 'Secondes',
    var: `{{SECONDS}}`,
    key: 'SECONDS',
  },
];
export const MIXED_TAG_INPUT_SETTING = {
  pattern: /@/, // <- must define "patten" in mixed mode
  mode: 'mix', // <--  Enable mixed-content
  dropdown: {
    enabled: 1,
    position: 'text',
    editTags: false,
    enforceWhitelist: false,
  },
  duplicates: true,
  editTags: false,
  enforceWhitelist: false,
  onChangeAfterBlur: false,
  whitelist: [],
};
// PATTERN CUSTOMER REFERENCE
export const CUSTOMER_REFERENCE_PATTERN_TAG_WHITELIST = [
  {
    id: 1,
    value: 'Mois du CRA',
    var: `{{${ECustomerReferencePatternVars?.CRA_MONTH}}}`,
    key: ECustomerReferencePatternVars?.CRA_MONTH,
  },
  {
    id: 2,
    value: 'Année du CRA',
    var: `{{${ECustomerReferencePatternVars?.CRA_YEAR}}}`,
    key: ECustomerReferencePatternVars?.CRA_YEAR,
  },
  {
    id: 3,
    value: 'Année complète du CRA',
    var: `{{${ECustomerReferencePatternVars?.CRA_FULL_YEAR}}}`,
    key: ECustomerReferencePatternVars?.CRA_FULL_YEAR,
  },
  {
    id: 4,
    value: 'Référence client',
    var: `{{${ECustomerReferencePatternVars?.CUSTOMER_REF}}}`,
    key: ECustomerReferencePatternVars?.CUSTOMER_REF,
  },
  {
    id: 5,
    value: 'Référence mission',
    var: `{{${ECustomerReferencePatternVars?.MISSION_REF}}}`,
    key: ECustomerReferencePatternVars?.MISSION_REF,
  },
];
export const STAND_BY_DUTY_UNIT_SELECT = [
  {
    label: 'Heures',
    value: EStandByDutyUnits.HOURS,
    key: EStandByDutyUnits.HOURS,
  },
  {
    label: 'Jours',
    value: EStandByDutyUnits.DAYS,
    key: EStandByDutyUnits.DAYS,
  },
  {
    label: 'Nuits',
    value: EStandByDutyUnits.NIGHT,
    key: EStandByDutyUnits.NIGHT,
  },
  {
    label: 'Week-end',
    value: EStandByDutyUnits?.WEEKENDS,
    key: EStandByDutyUnits?.WEEKENDS,
  },
  {
    label: 'Semaines',
    value: EStandByDutyUnits.WEEKS,
    key: EStandByDutyUnits.WEEKS,
  },
  {
    label: 'Euros',
    value: EStandByDutyUnits.EUROS,
    key: EStandByDutyUnits.EUROS,
  },
  {
    label: 'Tranches',
    value: EStandByDutyUnits.TRANCHES,
    key: EStandByDutyUnits.TRANCHES,
  },
];

//EInvoiceSendMethod
export const SEND_METHOD_TRANSLATE_FR = {
  EMAIL: 'Email',
  MAIL: 'Courrier',
  OTHER: 'Autre',
};

export const STAND_BY_DUTY_UNIT_TRANSLATE_FR = {
  [EStandByDutyUnits.HOURS]: 'Heures',
  [EStandByDutyUnits.DAYS]: 'Jours',
  [EStandByDutyUnits.NIGHT]: 'Nuits',
  [EStandByDutyUnits.WEEKENDS]: 'Week-end',
  [EStandByDutyUnits.WEEKS]: 'Semaines',
  [EStandByDutyUnits.EUROS]: 'Euros',
  [EStandByDutyUnits.TRANCHES]: 'Tranches',
};

export const INVOICE_PROVIDER_TYPES = [
  EInvoiceType.PROVIDER,
  EInvoiceType.PROVIDER_EXPENSE,
  EInvoiceType.PROVIDER_STAND_BY_DUTY,
  EInvoiceType.PROVIDER_MILESTONE,
];

export const INVOICE_PROVIDER_TYPE_SELECT = [
  {
    label: 'Tous les types',
    value: 'ALL',
    key: 'ALL',
  },
  {
    label: `Prestations standard`,
    value: INVOICE_PROVIDER_TYPES[0],
    key: INVOICE_PROVIDER_TYPES[0],
  },
  {
    label: `Frais`,
    value: INVOICE_PROVIDER_TYPES[1],
    key: INVOICE_PROVIDER_TYPES[1],
  },
  {
    label: `Prestations complémentaires`,
    value: INVOICE_PROVIDER_TYPES[2],
    key: INVOICE_PROVIDER_TYPES[2],
  },
  {
    label: `Jalons`,
    value: INVOICE_PROVIDER_TYPES[3],
    key: INVOICE_PROVIDER_TYPES[3],
  },
];

export const INVOICE_CUSTOMER_TYPES = [
  EInvoiceType.CUSTOMER,
  EInvoiceType.CUSTOMER_EXPENSE,
  EInvoiceType.CUSTOMER_STAND_BY_DUTY,
  EInvoiceType.CUSTOMER_MILESTONE,
];

export const INVOICE_CUSTOMER_TYPE_SELECT = [
  {
    label: 'Tous les types',
    value: 'ALL',
    key: 'ALL',
  },
  {
    label: `Prestations standard`,
    value: INVOICE_CUSTOMER_TYPES[0],
    key: INVOICE_CUSTOMER_TYPES[0],
  },
  {
    label: `Frais`,
    value: INVOICE_CUSTOMER_TYPES[1],
    key: INVOICE_CUSTOMER_TYPES[1],
  },
  {
    label: `Prestations complémentaires`,
    value: INVOICE_CUSTOMER_TYPES[2],
    key: INVOICE_CUSTOMER_TYPES[2],
  },
  {
    label: `Jalons`,
    value: INVOICE_CUSTOMER_TYPES[3],
    key: INVOICE_CUSTOMER_TYPES[3],
  },
];

export const INVOICE_CREDIT_NOTE_CUSTOMER_TYPES = [
  EInvoiceType.CUSTOMER_CREDIT_NOTE,
  EInvoiceType.CUSTOMER_EXPENSE_CREDIT_NOTE,
  EInvoiceType.CUSTOMER_STAND_BY_DUTY_CREDIT_NOTE,
  EInvoiceType.CUSTOMER_MILESTONE_CREDIT_NOTE,
];

export const INVOICE_CREDIT_NOTE_CUSTOMER_TYPE_SELECT = [
  {
    label: 'Tous les types',
    value: 'ALL',
    key: 'ALL',
  },
  {
    label: `Prestations standard`,
    value: INVOICE_CREDIT_NOTE_CUSTOMER_TYPES[0],
    key: INVOICE_CREDIT_NOTE_CUSTOMER_TYPES[0],
  },
  {
    label: `Frais`,
    value: INVOICE_CREDIT_NOTE_CUSTOMER_TYPES[1],
    key: INVOICE_CREDIT_NOTE_CUSTOMER_TYPES[1],
  },
  {
    label: `Prestations complémentaires`,
    value: INVOICE_CREDIT_NOTE_CUSTOMER_TYPES[2],
    key: INVOICE_CREDIT_NOTE_CUSTOMER_TYPES[2],
  },
  {
    label: `Jalons`,
    value: INVOICE_CREDIT_NOTE_CUSTOMER_TYPES[3],
    key: INVOICE_CREDIT_NOTE_CUSTOMER_TYPES[3],
  },
];

// All invoices types about provider credit note
export const INVOICE_CREDIT_NOTE_PROVIDER_TYPES = [
  EInvoiceType.PROVIDER_CREDIT_NOTE,
  EInvoiceType.PROVIDER_EXPENSE_CREDIT_NOTE,
  EInvoiceType.PROVIDER_STAND_BY_DUTY_CREDIT_NOTE,
  EInvoiceType.PROVIDER_MILESTONE_CREDIT_NOTE,
];

export const INVOICE_CREDIT_NOTE_PROVIDER_TYPE_SELECT = [
  {
    label: 'Tous les types',
    value: 'ALL',
    key: 'ALL',
  },
  {
    label: `Prestations standard`,
    value: INVOICE_CREDIT_NOTE_PROVIDER_TYPES[0],
    key: INVOICE_CREDIT_NOTE_PROVIDER_TYPES[0],
  },
  {
    label: `Frais`,
    value: INVOICE_CREDIT_NOTE_PROVIDER_TYPES[1],
    key: INVOICE_CREDIT_NOTE_PROVIDER_TYPES[1],
  },
  {
    label: `Prestations complémentaires`,
    value: INVOICE_CREDIT_NOTE_PROVIDER_TYPES[2],
    key: INVOICE_CREDIT_NOTE_PROVIDER_TYPES[2],
  },
  {
    label: `Jalons`,
    value: INVOICE_CREDIT_NOTE_PROVIDER_TYPES[3],
    key: INVOICE_CREDIT_NOTE_PROVIDER_TYPES[3],
  },
];

// All invoices Prestation types
export const INVOICE_PRESTATION_TYPES = [
  'STANDARD',
  'EXPENSE',
  'STAND_BY_DUTY',
  'MILESTONE',
];
export const INVOICE_PRESTATION_TYPE_SELECT = [
  {
    label: 'Tous les types',
    value: 'ALL',
    key: 'ALL',
  },
  {
    label: `Prestations standard`,
    value: INVOICE_PRESTATION_TYPES[0],
    key: INVOICE_PRESTATION_TYPES[0],
  },
  {
    label: `Frais`,
    value: INVOICE_PRESTATION_TYPES[1],
    key: INVOICE_PRESTATION_TYPES[1],
  },
  {
    label: `Prestations complémentaires`,
    value: INVOICE_PRESTATION_TYPES[2],
    key: INVOICE_PRESTATION_TYPES[2],
  },
  {
    label: `Jalons`,
    value: INVOICE_PRESTATION_TYPES[3],
    key: INVOICE_PRESTATION_TYPES[3],
  },
];

export const INVOICE_PROVIDER_ALL_TYPE_SELECT = [
  {
    label: 'Tous les types',
    value: 'ALL',
    key: 'ALL',
  },
  {
    label: `Prestations standard`,
    value: INVOICE_PROVIDER_TYPES[0],
    key: INVOICE_PROVIDER_TYPES[0],
  },
  {
    label: `Frais`,
    value: INVOICE_PROVIDER_TYPES[1],
    key: INVOICE_PROVIDER_TYPES[1],
  },
  {
    label: `Prestations complémentaires`,
    value: INVOICE_PROVIDER_TYPES[2],
    key: INVOICE_PROVIDER_TYPES[2],
  },
  {
    label: `Avoir Prestations standard`,
    value: INVOICE_CREDIT_NOTE_PROVIDER_TYPES[0],
    key: INVOICE_CREDIT_NOTE_PROVIDER_TYPES[0],
  },
  {
    label: `Avoir Frais`,
    value: INVOICE_CREDIT_NOTE_PROVIDER_TYPES[1],
    key: INVOICE_CREDIT_NOTE_PROVIDER_TYPES[1],
  },
  {
    label: `Avoir Prestations complémentaires`,
    value: INVOICE_CREDIT_NOTE_PROVIDER_TYPES[2],
    key: INVOICE_CREDIT_NOTE_PROVIDER_TYPES[2],
  },
];

export const ADDITIONAL_ACTIVITY_TYPES = [
  EadditionalActivityType.STAND_BY_DUTY,
  EadditionalActivityType.EXPENSE,
  EadditionalActivityType.MILESTONE,
];

export const ADDITIONAL_ACTIVITY_TYPES_FR = {
  [EadditionalActivityType.STAND_BY_DUTY]: 'Prestations complémentaires',
  [EadditionalActivityType.EXPENSE]: 'Frais',
  [EadditionalActivityType.MILESTONE]: 'Jalon',
};

export const ADDITIONAL_ACTIVITY_TYPE_SELECT = [
  {
    label: 'Tous les types',
    value: 'ALL',
    key: 'ALL',
  },
  {
    label: `Prestations complémentaires`,
    value: ADDITIONAL_ACTIVITY_TYPES[0],
    key: ADDITIONAL_ACTIVITY_TYPES[0],
  },
  {
    label: `Frais`,
    value: ADDITIONAL_ACTIVITY_TYPES[1],
    key: ADDITIONAL_ACTIVITY_TYPES[1],
  },
];

export const EXPENSES_TYPE_TRANSLATE_FR = {
  [EExpenseType.HOTEL]: 'Hotel',
  [EExpenseType.IT_HARDWARE]: 'Matériel informatique',
  [EExpenseType.MEAL]: 'Repas',
  [EExpenseType.MILEAGE]: 'Frais Kilométriques',
  [EExpenseType.OTHER]: 'Autre',
  [EExpenseType.PLANE]: 'Avion',
  [EExpenseType.SEMINAR]: 'Salon/Séminaire',
  [EExpenseType.SOFTWARE_SUBSCRIPTION]: 'Abonnement logiciel',
  [EExpenseType.TAXI_VH]: 'Taxi/VTC',
  [EExpenseType.TELECOM_PLAN]: 'Abonnement téléphone/internet',
  [EExpenseType.TOLL]: 'Péage',
  [EExpenseType.TRAIN]: 'Train',
  [EExpenseType.VEHICLE_RENTAL]: 'Location de véhicules',
};

export const EXPENSES_TYPE_SELECT = [
  {
    label: EXPENSES_TYPE_TRANSLATE_FR[EExpenseType.HOTEL],
    value: EExpenseType.HOTEL,
    key: EExpenseType.HOTEL,
  },
  {
    label: EXPENSES_TYPE_TRANSLATE_FR[EExpenseType.IT_HARDWARE],
    value: EExpenseType.IT_HARDWARE,
    key: EExpenseType.IT_HARDWARE,
  },
  {
    label: EXPENSES_TYPE_TRANSLATE_FR[EExpenseType.MEAL],
    value: EExpenseType.MEAL,
    key: EExpenseType.MEAL,
  },
  {
    label: EXPENSES_TYPE_TRANSLATE_FR[EExpenseType.MILEAGE],
    value: EExpenseType.MILEAGE,
    key: EExpenseType.MILEAGE,
  },
  {
    label: EXPENSES_TYPE_TRANSLATE_FR[EExpenseType.PLANE],
    value: EExpenseType.PLANE,
    key: EExpenseType.PLANE,
  },
  {
    label: EXPENSES_TYPE_TRANSLATE_FR[EExpenseType.SEMINAR],
    value: EExpenseType.SEMINAR,
    key: EExpenseType.SEMINAR,
  },
  {
    label: EXPENSES_TYPE_TRANSLATE_FR[EExpenseType.SOFTWARE_SUBSCRIPTION],
    value: EExpenseType.SOFTWARE_SUBSCRIPTION,
    key: EExpenseType.SOFTWARE_SUBSCRIPTION,
  },
  {
    label: EXPENSES_TYPE_TRANSLATE_FR[EExpenseType.TAXI_VH],
    value: EExpenseType.TAXI_VH,
    key: EExpenseType.TAXI_VH,
  },
  {
    label: EXPENSES_TYPE_TRANSLATE_FR[EExpenseType.TELECOM_PLAN],
    value: EExpenseType.TELECOM_PLAN,
    key: EExpenseType.TELECOM_PLAN,
  },
  {
    label: EXPENSES_TYPE_TRANSLATE_FR[EExpenseType.TOLL],
    value: EExpenseType.TOLL,
    key: EExpenseType.TOLL,
  },
  {
    label: EXPENSES_TYPE_TRANSLATE_FR[EExpenseType.TRAIN],
    value: EExpenseType.TRAIN,
    key: EExpenseType.TRAIN,
  },
  {
    label: EXPENSES_TYPE_TRANSLATE_FR[EExpenseType.VEHICLE_RENTAL],
    value: EExpenseType.VEHICLE_RENTAL,
    key: EExpenseType.VEHICLE_RENTAL,
  },
  {
    label: EXPENSES_TYPE_TRANSLATE_FR[EExpenseType.OTHER],
    value: EExpenseType.OTHER,
    key: EExpenseType.OTHER,
  },
];

export const MAINTENANCE_MODE_TYPE = {
  ALL: 'ALL',
  MANAGER: 'MANAGER',
  PROVIDER: 'PROVIDER',
};

export * from './mapping';
export * from './countries';
export * from './manager';
