import {
  ICra,
  ICraGetParams,
  ICraSearchParams,
  ICraSendReminderParams,
  ICraUpdateParams,
  ICraGetResponse,
  ICraSearchResponse,
  ICraSendReminderResponse,
  ICraCreateParams,
  ICraCreateResponse,
  ICraDeleteParams,
  ICraDeleteResponse,
  ICraPatchParams,
  ICraPatchResponse,
  ICraVerifyCustomerTokenParams,
  ICraVerifyCustomerTokenResponse,
  ICraCustomerValidationParams,
  //ICraCustomerValidationResponse,
  IActivityReportSearchParams,
  IActivityReportSearchResponse,
  ICraCustomerReminderParams,
  ICraCustomerReminderResponse,
} from '@freelancelabs/teoreme-commons';

import { CRA_SERVICE_URL } from '../../constantz';
import { fetcher, fetcherNoBearerToken } from '../helpers/fetcher';

export const craFindOne = (
  params: { identifier: string; joinInvoice?: boolean },
  disableAlert?: boolean
) =>
  fetcher<ICraGetParams, ICraGetResponse>(
    CRA_SERVICE_URL +
      `/get?refCra=${params?.identifier}${
        params?.joinInvoice ? `&joinInvoice=true` : ''
      }`,
    undefined,
    disableAlert
  );

export const craFindMany = (searchParams: any, disableAlert?: boolean) =>
  fetcher<ICraSearchParams, ICraSearchResponse>(
    CRA_SERVICE_URL + `/search`,
    {
      method: 'POST',
      body: searchParams,
    },
    disableAlert
  );

export type ICraUpdateResponse = ICra;

export const craUpdateOne = (body: ICraUpdateParams, disableAlert?: boolean) =>
  fetcher<ICraUpdateParams, ICraUpdateResponse>(
    CRA_SERVICE_URL + `/update`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );

export const craSendReminder = (
  body: ICraSendReminderParams,
  disableAlert?: boolean
) =>
  fetcher<ICraSendReminderParams, ICraSendReminderResponse>(
    `${CRA_SERVICE_URL}/send-reminder`,
    { method: 'post', body },
    disableAlert
  );

export const createCra = (body: ICraCreateParams, disableAlert?: boolean) =>
  fetcher<ICraCreateParams, ICraCreateResponse>(
    `${CRA_SERVICE_URL}/create`,
    { method: 'post', body },
    disableAlert
  );
export const deleteCra = (body: ICraDeleteParams, disableAlert?: boolean) =>
  fetcher<ICraDeleteParams, ICraDeleteResponse>(
    `${CRA_SERVICE_URL}/delete`,
    { method: 'post', body },
    disableAlert
  );
export const patchCra = (body: ICraPatchParams, disableAlert?: boolean) =>
  fetcher<ICraPatchParams, ICraPatchResponse>(
    `${CRA_SERVICE_URL}/patch`,
    { method: 'post', body },
    disableAlert
  );
export const verifyCustomerToken = (body: any, disableAlert?: boolean) =>
  fetcherNoBearerToken<
    ICraVerifyCustomerTokenParams,
    ICraVerifyCustomerTokenResponse
  >(
    `${CRA_SERVICE_URL}/verify-customer-token`,
    { method: 'post', body },
    disableAlert
  );
export const validateCustomerCra = (body: any, disableAlert?: boolean) =>
  fetcherNoBearerToken<ICraCustomerValidationParams, any>(
    `${CRA_SERVICE_URL}/customer-validation`,
    { method: 'post', body },
    disableAlert
  );
export const refreshCustomerToken = (body: any, disableAlert?: boolean) =>
  fetcherNoBearerToken<ICraCustomerValidationParams, any>(
    `${CRA_SERVICE_URL}/refresh-customer-token`,
    { method: 'post', body },
    disableAlert
  );
export const activitiesFindMany = (searchParams: any, disableAlert?: boolean) =>
  fetcher<IActivityReportSearchParams, IActivityReportSearchResponse>(
    CRA_SERVICE_URL + `/activity-report/search`,
    {
      method: 'POST',
      body: searchParams,
    },
    disableAlert
  );
export const customerValidationReminder = (
  searchParams: ICraCustomerReminderParams,
  disableAlert?: boolean
) =>
  fetcher<ICraCustomerReminderParams, ICraCustomerReminderResponse>(
    CRA_SERVICE_URL + `/customer-validation-reminder`,
    {
      method: 'POST',
      body: searchParams,
    },
    disableAlert
  );
