import {
  sageCreateDeliveryOrders,
  getFullName,
  getTradeNameSafe,
  displayMonth,
  MAX_CUSTOMER_REFERENCE_FIELD_LENGTH,
  getCustomerRefPattern,
  isAdditionalActivityInvoice,
} from '@commons';
import {
  IJoinedCraWithInvoice,
  ICra,
  IMission,
  IMinifiedUser,
  IJoinedInvoice,
  EMissionType,
  IContractor,
  IEstablishment,
  ECustomerReferencePatternVars,
  EInvoiceType,
} from '@freelancelabs/teoreme-commons';
import {
  ALink,
  Link,
  Text,
  Flex,
  Box,
  Button,
  FormControl,
  Input,
  LabelField,
} from 'components/ui';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import { queryClient } from '@commons';
import { useShowMessage } from 'hooks/useShowMessage';
import { CloseIcon } from 'components/ui/icons';
type Props = ModalProps & {
  beforeValidation?: (customerReference?: string) => void;
  invoice?: IJoinedInvoice; // REQUIRED FROM INVOICE !!
  optionalText?: string;
  mission:
    | IMission
    | (Omit<IMission, 'accountManager'> & { accountManager: IMinifiedUser });
  cra: IJoinedCraWithInvoice | ICra;
  customerReference: string;
  delivreyOrders: { bdlProvider: boolean; bdlCustomer: boolean };
  refetchQueryKey: string;
};
type FormValues = any;
export const CreateGescomBDLModal = ({
  onResolve,
  beforeValidation,
  invoice,
  mission,
  isOpen,
  optionalText,
  cra,
  customerReference,
  delivreyOrders,
  refetchQueryKey,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const customerRefPattern =
    (cra as IJoinedCraWithInvoice)?.mission?.customer?.billingOptions
      ?.customerRefPattern ||
    (cra as IJoinedCraWithInvoice)?.estCustomer?.customer?.billingOptions
      ?.customerRefPattern;
  const craDateMonth = new Date(cra?.month);
  const customerRef = getCustomerRefPattern(customerRefPattern, {
    [ECustomerReferencePatternVars.CRA_FULL_YEAR]: craDateMonth
      ?.getUTCFullYear()
      ?.toString(),
    [ECustomerReferencePatternVars.CRA_YEAR]: craDateMonth
      ?.getUTCFullYear()
      ?.toString()
      ?.substring(2),

    [ECustomerReferencePatternVars.CRA_MONTH]:
      craDateMonth?.getUTCMonth() + 1 < 10
        ? `0${craDateMonth?.getUTCMonth() + 1}`
        : `${craDateMonth?.getUTCMonth() + 1}`,
    [ECustomerReferencePatternVars.MISSION_REF]:
      //@ts-ignore
      cra?.mission?.displayReference,
    [ECustomerReferencePatternVars.CUSTOMER_REF]: customerReference,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      customerReference: customerRef || customerReference,
    },
  });

  const showMessage = useShowMessage();
  const onSubmit = async () => {
    setIsLoading(true);
    const formValues = getValues();
    const isAAInvoice = isAdditionalActivityInvoice(
      invoice?.invoiceType as EInvoiceType
    );
    try {
      await sageCreateDeliveryOrders({
        cra: isAAInvoice ? undefined : cra?.refCra,
        additionalActivity: isAAInvoice
          ? typeof invoice?.additionalActivity === 'string'
            ? invoice?.additionalActivity
            : //@ts-ignore
              invoice?.additionalActivity?.uuid
          : undefined,
        customerReference: formValues?.customerReference,
      });
      queryClient.refetchQueries({
        queryKey: [refetchQueryKey],
        type: 'active',
      });
      if (!invoice) {
        showMessage('success', 'Bon de livraison crée dans GESCOM');
      }
      if (beforeValidation) {
        await beforeValidation(formValues?.customerReference);
      }
      onResolve(true);
    } catch (err) {
      //console.log(err);
    }
    setIsLoading(false);
    onResolve(false);
  };
  const checkAvalaibleCreate = (): {
    estProvider: IEstablishment;
    estCustomer: IEstablishment;
    contractor: IContractor;
    contractorReady: boolean;
    estProviderReady: boolean;
    estCustomerReady: boolean;
    ready: boolean;
  } => {
    let contractor;
    const structure = mission?.billingInformation?.structure;
    const labelType =
      mission?.billingInformation?.missionType?.label ===
      EMissionType.RESOURCE_MANAGEMENT
        ? 'RM'
        : 'CL';
    let estProvider;
    let estCustomer;

    if (invoice) {
      estProvider = invoice?.estProvider;
      estCustomer = invoice?.estCustomer;
      contractor = invoice?.contractor;
    } else {
      estProvider = cra?.estProvider;
      estCustomer = cra?.estCustomer;
      contractor = cra?.contractor;
    }
    const contractorReady = (
      contractor as IContractor
    )?.sage?.contractorIds?.find(
      sage => sage?.structure === structure && sage?.type === labelType
    )
      ? true
      : false;
    //@ts-ignore
    const estProviderReady = estProvider?.provider?.sage?.references?.find(
      //@ts-ignore
      sage => sage?.structure === structure
    )
      ? true
      : false;
    //@ts-ignore
    const estCustomerReady = estCustomer?.customer?.sage?.references?.find(
      //@ts-ignore
      sage => sage?.structure === structure
    )
      ? true
      : false;
    return {
      estProvider: estProvider as IEstablishment,
      estCustomer: estCustomer as IEstablishment,
      contractor: contractor as IContractor,
      contractorReady,
      estProviderReady,
      estCustomerReady,
      ready: contractorReady && estProviderReady && estCustomerReady,
    };
  };

  const avalaibleStatusToCreate = checkAvalaibleCreate();
  if (avalaibleStatusToCreate?.ready) {
    const month =
      cra?.month || invoice?.month
        ? displayMonth(cra?.month || invoice?.month)
        : undefined;
    return (
      <ModalFrame
        isOpen={true}
        onClose={() => onResolve(false)}
        width={550}
        closeIcon
      >
        <Box>
          <Text variant="h1" mb={20}>
            {optionalText ? (
              <>{`${optionalText}`}</>
            ) : (
              <>
                Créer le bon de livraison fournisseur{' '}
                {month ? `(${month})` : ''}
              </>
            )}
          </Text>
          {cra?.nbWorkingDays === 0 && (
            <Text variant="p">
              Les CRA soumis avec 0 jour ne généreront pas de factures
              Connecteed ni de bon de livraison dans GESCOM.
            </Text>
          )}
          <Text variant="p" mb={20}>
            Si vous souhaitez créer le bon de livraison fournisseur dans GESCOM,
            merci de confirmer la référence client ci-dessous. En cas de
            modification, cela n’affectera que le {month ? 'CRA' : 'Jalon'} et
            la facture client concernés.
          </Text>
          {cra && (
            <LabelField mb={20} label="CRA concerné :" value={cra?.refCra} />
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              required
              label="Référence client"
              errorMessage={
                errors?.customerReference?.type === 'maxLength'
                  ? `Veuillez saisir moins de ${MAX_CUSTOMER_REFERENCE_FIELD_LENGTH} caractères`
                  : errors?.customerReference?.message
              }
            >
              <Input
                isFullWidth
                type="text"
                {...register('customerReference', {
                  required: 'Ce champs est requis',
                  maxLength: MAX_CUSTOMER_REFERENCE_FIELD_LENGTH,
                })}
              />
            </FormControl>
            <Flex alignItems="center">
              <Button type="submit" isLoading={isLoading}>
                Valider
              </Button>
              <Link
                ml={20}
                onClick={() => onResolve(false)}
                iconLeft={<CloseIcon />}
              >
                Créer plus tard
              </Link>
            </Flex>
          </form>
        </Box>
      </ModalFrame>
    );
  }
  return (
    <ModalFrame
      isOpen={true}
      onClose={() => onResolve(false)}
      width={670}
      closeIcon
    >
      <Box>
        <Text variant="h1" mb={20}>
          Impossible de créer le bon de livraison
        </Text>
        <Text>
          Ce bon de livraison ne peut être crée car certains liens avec la
          Gescom ne sont pas créés. Veuillez mettre à jour les liens Gescom
          suivants avant de crée ce bon de livraison.
          <Box mt={20} ml={20}>
            <ul>
              {!avalaibleStatusToCreate?.contractorReady && (
                <li>
                  <Flex>
                    L'intervenant &nbsp;
                    <ALink
                      ml={'5px'}
                      href={`/providers/contractors/${avalaibleStatusToCreate?.contractor?.uuid}`}
                      target="_blank"
                    >
                      <Link>
                        {getFullName(avalaibleStatusToCreate?.contractor)}
                      </Link>
                    </ALink>
                  </Flex>
                </li>
              )}
              {!avalaibleStatusToCreate?.estProviderReady && (
                <li>
                  <Flex>
                    L'établissement fournisseur &nbsp;
                    <ALink
                      ml={'5px'}
                      href={`/providers/establishments/${avalaibleStatusToCreate?.estProvider?.uuid}/information`}
                      target="_blank"
                    >
                      <Link>
                        {getTradeNameSafe(avalaibleStatusToCreate?.estProvider)}
                      </Link>
                    </ALink>
                  </Flex>
                </li>
              )}
              {!avalaibleStatusToCreate?.estCustomerReady && (
                <li>
                  <Flex>
                    L'établissement client &nbsp;
                    <ALink
                      ml={'5px'}
                      href={`/clients/establishments/${avalaibleStatusToCreate?.estCustomer?.uuid}/information`}
                      target="_blank"
                    >
                      <Link>
                        {getTradeNameSafe(avalaibleStatusToCreate?.estCustomer)}
                      </Link>
                    </ALink>
                  </Flex>
                </li>
              )}
            </ul>
          </Box>
        </Text>
        <Flex mt={20} alignItems="center">
          <Button
            type="button"
            isLoading={isLoading}
            onClick={() => onResolve(false)}
          >
            J'ai bien compris
          </Button>
        </Flex>
      </Box>
    </ModalFrame>
  );
};

export const showCreateGescomBDLModal = create<Props, boolean>(
  CreateGescomBDLModal
);
