export const DEFAULT_MISSION_DISPLAYCOL = [
  { label: 'Date de début', value: 'startAt' },
  { label: 'Date de fin', value: 'endAt' },
  { label: 'Référence', value: 'displayReference' },
  { label: 'Structure', value: 'sage.structure' },
  { label: 'Type de tarification', value: 'billing.type' },
  { label: 'Type de mission', value: 'isAmendment' },
  { label: 'Statut', value: 'status' },
  { label: 'Client', value: 'customerEstablishment' },
  { label: 'Fournisseur', value: 'providerEstablishment' },
  { label: 'Intervenant', value: 'contractor' },
  { label: 'Devis', value: 'quoteStatus' },
  { label: 'BDC Client', value: 'customerPurchaseOrderStatus' },
  { label: 'Facturé / Commandé', value: 'nbWorkingDays' },
];
// INDEX 0 MUST BE DEFAULT VALUE !!!
export const INVOICE_FOREIGN_SEARCH_FIELDS = [
  { label: 'N° Facture GESCOM', value: 'default_invoice' },
  { label: 'Mission', value: 'mission' },
  //{ label: 'GESCOM', value: 'sage' },
  { label: 'Client', value: 'estCustomer' },
  { label: 'Fournisseur', value: 'estProvider' },
  { label: 'Intervenant', value: 'contractor' },
];
