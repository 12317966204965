import {
  IContractorCreateParams,
  IContractorCreateResponse,
  IContractorGetParams,
  IContractorGetResponse,
  IContractorSearchParams,
  IContractorSearchResponse,
  IContractorUpdateParams,
  IContractorUpdateResponse,
} from '@freelancelabs/teoreme-commons';
import { fetcher } from '../helpers/fetcher';
import { CONTRACTOR_SERVICE_URL } from '../../constantz';
import { transformText } from '../../helpers/transform';

export const contractorFindMany = (
  body: IContractorSearchParams,
  disableAlert?: boolean
) =>
  fetcher<IContractorSearchParams, IContractorSearchResponse>(
    `${CONTRACTOR_SERVICE_URL}/search`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );

export const contractorFindOne = (uuid: string, disableAlert?: boolean) =>
  fetcher<IContractorGetParams, IContractorGetResponse>(
    `${CONTRACTOR_SERVICE_URL}/get?uuid=` + uuid,
    undefined,
    disableAlert
  );
export const contractorFindOneByCognitoUserId = (
  cognitoUserId: string,
  disableAlert?: boolean
) =>
  fetcher<IContractorGetParams, IContractorGetResponse>(
    `${CONTRACTOR_SERVICE_URL}/get?user=` + cognitoUserId,
    undefined,
    disableAlert
  );

export const contractorCreateOne = (
  body: IContractorCreateParams,
  disableAlert?: boolean
) =>
  fetcher<IContractorCreateParams, IContractorCreateResponse>(
    CONTRACTOR_SERVICE_URL + `/create`,
    {
      method: 'post',
      body: {
        ...body,
        contractor: {
          ...body.contractor,
          firstName: transformText(
            body.contractor.firstName,
            'capitalize'
          ).trim(),
          lastName: transformText(body.contractor.lastName, 'uppercase').trim(),
        },
      },
    },
    disableAlert
  );
export const contractorUpdateOne = (
  body: IContractorUpdateParams,
  disableAlert?: boolean
) =>
  fetcher<IContractorUpdateParams, IContractorUpdateResponse>(
    CONTRACTOR_SERVICE_URL + `/update`,
    {
      method: 'PUT',
      body,
    },
    disableAlert
  );
