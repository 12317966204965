import { isNotEmpty, useMissionFindMany } from '@commons';
import {
  IJoinedMission,
  IMissionSearchParams,
} from '@freelancelabs/teoreme-commons';
import { Box, Itemize, SpinnerBox, Table, TableColumn } from 'components/ui';
import { useDebounce } from 'hooks/useDebounce';
import * as React from 'react';

type MissionsListProps = {
  columns: () => TableColumn<Itemize<IJoinedMission>>[];
  filterObject: IMissionSearchParams['filterObject'];
  hookStore: any;
  filterComponents?: React.ReactNode;
};
export const MissionsList = ({
  filterObject,
  columns,
  hookStore,
  filterComponents,
}: MissionsListProps) => {
  const {
    updateStore,
    resetStore,
    statusSelect,
    searchQuery,
    limit,
    page,
    sortedBy,
    order,
  } = hookStore();
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  // const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  React.useEffect(() => {
    // RESET GLOBAL STATE ON COMPONENT FIRST LOADING
    return resetStore({});
  }, []);
  const buildQuery = () => {
    return {
      ...filterObject,
      //accountManager: isNotEmpty(managerSelected) ? managerSelected : undefined,
      status: statusSelect,
      $or: isNotEmpty(debouncedFilterQuery)
        ? [
            {
              title: {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'sage.missionId': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // customer
            {
              'customer.establishment.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // provider
            {
              'provider.establishment.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.contractor.firstName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.contractor.lastName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.purchaseOrder.reference': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
          ]
        : undefined,
    };
  };
  const {
    data: missions,
    status,
    isFetching,
  } = useMissionFindMany({
    filterObject: buildQuery(),
    limit: limit,
    skip: limit * page,
    sort: `-month -reference`,
  });

  const loading = status === 'pending';
  const totalCount = missions?.totalCount || 0;
  const items = missions?.missions?.map(activity => {
    return {
      ...activity,
      key: 'Mission_' + activity.reference + '_' + new Date()?.getTime() || '',
    };
  });
  const onSortedChange = React.useCallback(
    (sortedBy: string, order: 'asc' | 'desc'): void => {
      //@ts-ignore
      updateStore({ page: 0, sortedBy, order });
    },
    []
  );

  //   React.useEffect(() => {
  //     //resetInvoiceStore({});
  //     //queryClient.invalidateQueries('Invoices');
  //   }, [filterParams]);

  return (
    <Box>
      <Box>{filterComponents}</Box>
      {loading && <SpinnerBox />}
      <Box>
        {!loading && (
          <Table<Itemize<IJoinedMission>>
            isLoading={isFetching}
            columns={columns()}
            items={items as Itemize<IJoinedMission>[]}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount && totalCount < limit ? totalCount : limit}
            //opacity={filterParams === 'archived' ? 0.5 : 1}
            onSortedChange={onSortedChange}
            onChangePage={page => updateStore({ page: page })}
          />
        )}
      </Box>
    </Box>
  );
};
