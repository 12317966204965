import {
  INotificationSearchParams,
  INotificationUpdateParams,
  INotificationUpdateResponse,
} from '@freelancelabs/teoreme-commons';
import { useMutation, useQuery, keepPreviousData } from '@tanstack/react-query';
import { notificationFindMany, notificationUpdateOne } from '../routes';

export const useNotificationFindMany = (
  variables: INotificationSearchParams
) => {
  return useQuery({
    queryKey: ['notifications', variables],
    queryFn: async () => notificationFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    placeholderData: keepPreviousData,
  });
};

export const useNotificationUpdateOne = (params: INotificationUpdateParams) =>
  useMutation<INotificationUpdateResponse, unknown, INotificationUpdateParams>({
    mutationFn: () => notificationUpdateOne(params),
    // onSuccess: (data) => queryClient.refetchQueries({ queryKey: [data.uuid] }),
  });
