import { useParams } from 'react-router-dom';
import { TopBar } from 'components/TopBar';
import { Text, Box, SpinnerBox } from 'components/ui';
import { useBreadCrumb } from 'hooks/breadCrumb';
import React, { useState, useEffect } from 'react';
import { useDownloadFile } from '@commons'; // moved
import styled from 'styled-components';
export type Props = {};
const FileContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: block;
  z-index: 101;
`;
export const FileReaderRoute: React.FC<React.PropsWithChildren<Props>> = () => {
  const { fileLocation } = useParams<{ fileLocation: string }>();
  const { setCrumbs } = useBreadCrumb();
  useEffect(() => {
    setCrumbs([
      {
        label: `Fichier`,
        path: '/file',
      },
    ]);
  }, []);

  // load file
  const [file, setFile] = useState<string>();
  const { status, data } = useDownloadFile(fileLocation.replaceAll('§', '/'));

  useEffect(() => {
    // use for download file
    if (data) {
      const reader = new FileReader();
      reader.readAsDataURL(data.data); // converts the blob to base64 and calls onload
      reader.onload = () => {
        setFile(reader.result as string);
      };
    }
  }, [data]);

  const loading = status === 'pending';
  useEffect(() => {
    if (!file && fileLocation) {
      const uploadFile = async (url: string) => {
        await fetch(url)
          .then(response => response.blob())
          .then(blob => {
            // Create a new FileReader innstance
            const reader = new FileReader();
            reader.readAsDataURL(blob); // converts the blob to base64 and calls onload

            reader.onload = () => {
              setFile(reader.result as string);
            };
          });
      };
      uploadFile(fileLocation);
    }
  }, [file]);
  return (
    <Box>
      <Box>
        <TopBar />
      </Box>
      {loading && (
        <Box mt={100} zIndex={100} width="100%">
          <SpinnerBox color="#fff" />
        </Box>
      )}
      {!loading && !data && (
        <Box mt={100} zIndex={100} width="100%">
          <Text variant="h2" textAlign={'center'}>
            Impossible de lire le fichier
          </Text>
        </Box>
      )}
      {!loading && data && (
        <FileContainer>
          <iframe
            //@ts-ignore
            src={data && (data as any)?.config?.url}
            frameBorder="0"
            title="iframe"
            style={{ width: '100%', height: 'calc(100vh - 80px)' }}
          ></iframe>
        </FileContainer>
      )}
    </Box>
  );
};

export default FileReaderRoute;
