import { useState } from 'react';
import {
  buildOneLineAddress,
  COUNTRIES_SELECT,
  getFullName,
  getHumanDateMonthAndYear,
  getTradeNameSafe,
  useAdminTaskFindOne,
  useEstablishmentFindOne,
  useInvoicePatchCustomerOne,
} from '@commons';
import {
  ETaskStatus,
  FlatUser,
  IInvoiceAddress,
  IJoinedInvoice,
  IJoinedMission,
  IMission,
  ITask,
} from '@freelancelabs/teoreme-commons';
import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import {
  Input,
  Box,
  Button,
  Row,
  Flex,
  FormControl,
  Text,
  EstablishmentSelectControlled,
  LabelField,
  StaticSelectControlled,
  Link,
  ALink,
  Spinner,
} from 'components/ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import { create } from 'react-modal-promise';
import { queryClient } from '@commons';
import TextInformation from 'components/TextInformation';
import { StaticSelect } from 'components/selects/StaticSelect';
import { useShowMessage } from 'hooks/useShowMessage';
import { AddIcon } from 'components/ui/icons';
import { useGlobalStore } from 'store';
import { ProgressBar } from 'react-bootstrap';

type FormValues = {
  mission: string;
  customer: string;
  invoicingAddress: {
    street: string;
    country: string;
    city: string;
    postalCode: string;
    additional: string;
  };
};
type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
  me?: FlatUser;
  mission: IJoinedMission;
};

export const PatchCustomerInvoiceModal = ({
  onResolve,
  isOpen,
  layer,
  mission,
}: Props) => {
  const showMessage = useShowMessage();
  const [loading, setLoading] = React.useState(false);
  const currentInvoiceAddress =
    mission?.customer?.establishment?.customer?.invoiceAddress?.find(
      a => a?.default === true
    ) || mission?.customer?.billingOptions?.invoiceAddress;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    control,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      customer: mission?.customer?.establishment?.uuid,
    },
  });
  const customer = watch('customer');

  const { data: establishment, status: statusEsta } =
    useEstablishmentFindOne(customer);
  let INVOICE_ADRESSES_SELECT =
    establishment?.customer?.invoiceAddress?.map(ia => {
      return {
        label: buildOneLineAddress(ia) as string,
        value: ia?.uuid,
        data: ia,
      };
    }) || [];

  const establishmentAddress = {
    street: establishment?.address?.street || '',
    country: establishment?.address?.country || '',
    city: establishment?.address?.city || '',
    postalCode: establishment?.address?.postalCode || '',
    additional: establishment?.address?.additional || '',
    uuid: `DEFAULT_${customer}`,
  };
  if (establishment) {
    INVOICE_ADRESSES_SELECT?.push({
      label:
        buildOneLineAddress(establishmentAddress) +
        " (Adresse de l'établissement)",
      value: establishmentAddress?.uuid,
      //@ts-ignore
      data: establishmentAddress,
    });
  }
  const { tasksInProgress, updateGlobalStore } = useGlobalStore();
  const [invoicingAddress, setInvoicingAddress] = useState<any>(
    currentInvoiceAddress?.uuid
  );
  const [refetchInterval, setRefetchInterval] = useState<Number | undefined>(
    5000
  );
  const [taskUuid, setTaskUuid] = useState<string | undefined>();
  const {
    data: task,
    status,
    isFetching,
  } = useAdminTaskFindOne(taskUuid, { refetchInterval: refetchInterval });
  const { mutateAsync: patchCustomerInvoice } = useInvoicePatchCustomerOne();
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    if (!formValues?.customer) {
      setError('customer', {
        message: 'Veuillez séléctionner un établissement',
      });
      setLoading(false);
      return;
    }
    let invoicingAddressFind: any = INVOICE_ADRESSES_SELECT?.find(
      ias => ias?.value === invoicingAddress
    )?.data;
    let countryIso =
      invoicingAddressFind?.countryCode ||
      COUNTRIES_SELECT?.find(
        c =>
          c?.value?.toLocaleLowerCase() ===
          invoicingAddressFind?.country?.toLocaleLowerCase()
      )?.data?.alpha2;
    if (!countryIso) {
      setError('invoicingAddress.country', {
        message: 'Veuillez séléctionner une adresse valide',
      });
      setLoading(false);
      console.warn(
        `No CountryCodeIso ( alpha2 ) found in: `,
        invoicingAddressFind
      );
      return;
    }
    clearErrors('invoicingAddress.country');
    const submitData = {
      mission: mission?.reference,
      customer: formValues?.customer,
      invoicingAddress: {
        street: invoicingAddressFind?.street,
        country: countryIso,
        city: invoicingAddressFind?.city,
        postalCode: invoicingAddressFind?.postalCode,
        additional: invoicingAddressFind?.additional,
        sage: invoicingAddressFind?.sage,
      },
    };

    try {
      const response = await patchCustomerInvoice(submitData);
      const taskUuid = response?.taskUuid;
      setTaskUuid(taskUuid);

      queryClient.refetchQueries({ queryKey: ['Invoices'], type: 'active' });
      //onResolve(true);
    } catch (e) {
      onResolve(e);
    }
    setLoading(false);
  };
  const getLabel = (task: ITask) => {
    let currentStep = task?.currentStep || 0;
    let currentTaskName = task?.steps?.[currentStep - 1]?.name;
    let progress = task?.progress || 1;
    if (task?.status === ETaskStatus.ONGOING) {
      if (progress <= 33) {
        return `${currentTaskName} ${task?.currentStep || '1'} / ${task?.totalSteps || '1'} `;
      } else {
        return `Veuillez patentier ${task?.currentStep || '1'} / ${task?.totalSteps || '1'} (${currentTaskName})`;
      }
    }
    if (task?.status === ETaskStatus.ERROR) {
      return `Une erreur est survenue étape ${task?.currentStep || '1'} / ${task?.totalSteps || '1'} (${currentTaskName})`;
    }
    if (task?.status === ETaskStatus.COMPLETED) {
      return `Avoir terminé étape ${task?.currentStep || '1'} / ${task?.totalSteps || '1'} (${currentTaskName})`;
    }
  };
  React.useEffect(() => {
    if (task) {
      if (
        task.status === ETaskStatus.ONGOING ||
        task.status === ETaskStatus.PENDING
      ) {
        if (Array.isArray(tasksInProgress)) {
          if (!tasksInProgress?.find(t => t === task?.uuid)) {
            updateGlobalStore({
              tasksInProgress: [...tasksInProgress, task?.uuid],
            });
          }
        } else {
          updateGlobalStore({ tasksInProgress: [task?.uuid] });
        }
        setRefetchInterval(5000);
      } else {
        queryClient.refetchQueries({
          queryKey: [mission?.reference],
          type: 'active',
        });
        if (refetchInterval && task.status === ETaskStatus.ERROR) {
          showMessage('error', 'Une erreur est survenue', {
            ...task?.output?.validationErrorDetails,
            error: task?.output?.error,
            errorMessage: task?.output?.errorMessage,
            errorDetails: task?.output?.errorDetails,
            errorCode: 'TEOR-ALL-001',
          });
        }
        if (refetchInterval && task.status === ETaskStatus.COMPLETED) {
          showMessage('success', 'Avoir réussi');
          onResolve(true);
        }
        setRefetchInterval(undefined);
      }
    }
  }, [task, refetchInterval]);
  React.useEffect(() => {
    setInvoicingAddress(null);
  }, [establishment]);
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={840}
      closeIcon
      layer={layer}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text mb={10} variant="h1">
          Modification des informations du client
        </Text>
        <TextInformation variant="warning" width={1 / 1} mb={10}>
          <p>
            <b>
              Attention, la validation de la modification de l’entité client et
              de l’adresse de facturation entrainera :
            </b>
            <ul style={{ marginTop: '5px', marginLeft: '10px' }}>
              <li>
                La création d’avoirs annulant l’ensemble des factures clients
                émises pour cette mission
              </li>
              <li>
                La création de factures de remplacement émises pour la nouvelle
                entité
              </li>
              <li>Le passage du statut de la mission à “Terminée”</li>
            </ul>
          </p>
        </TextInformation>
        <Box width={1 / 1}>
          <Flex
            display={'inline-flex'}
            justifyContent={'center'}
            flexWrap={'wrap'}
          >
            <Box width={2 / 3} pr={10}>
              <Box>
                <FormControl
                  label="Etablissement client"
                  errorMessage={errors?.customer?.message}
                  required
                >
                  <EstablishmentSelectControlled
                    control={control}
                    name="customer"
                    filter={{
                      'customer.accountManager': { $exists: true },
                    }}
                    width="100%"
                    referenceValue="uuid"
                    placeholder="Rechercher un N° SIRET, raison sociale ..."
                  />
                </FormControl>
                <FormControl
                  label={'Sélectionner une adresse de facturation'}
                  required
                  errorMessage={errors?.invoicingAddress?.country?.message}
                >
                  <>
                    {statusEsta !== 'pending' ? (
                      <StaticSelect
                        key="a"
                        options={INVOICE_ADRESSES_SELECT}
                        value={invoicingAddress}
                        onChange={value => {
                          clearErrors('invoicingAddress.country');
                          if (value) {
                            setInvoicingAddress(
                              INVOICE_ADRESSES_SELECT?.find(
                                e => e?.value === value
                              )?.value as string
                            );
                          } else {
                            setInvoicingAddress(undefined);
                          }
                        }}
                        name="invoicingAddress"
                        placeholder="Sélectionner une adresse"
                        required
                      />
                    ) : (
                      <StaticSelect
                        key="b"
                        isLoading
                        options={INVOICE_ADRESSES_SELECT}
                        name="invoicingAddress"
                        placeholder="adresse"
                        required
                      />
                    )}
                    <ALink href={`/clients/establishments/${customer}/billing`}>
                      <Link width={1 / 1} iconLeft={<AddIcon />}>
                        Ajouter une adresse de facturation
                      </Link>
                    </ALink>
                  </>
                </FormControl>
              </Box>
              <Flex justifyContent={'flex-start'} alignItems={'center'}>
                {task?.status === ETaskStatus.ONGOING && <Spinner size={10} />}
                {task && (
                  <Box width={1 / 1}>
                    <ProgressBar
                      label={getLabel(task)}
                      variant={
                        task?.status === ETaskStatus.ERROR
                          ? 'danger'
                          : task.progress === 100
                            ? 'success'
                            : 'primary'
                      }
                      animated={
                        task?.status !== ETaskStatus.ERROR &&
                        task.progress !== 100
                      }
                      now={
                        task?.status === ETaskStatus.ERROR
                          ? 100
                          : task.progress || 33
                      }
                    />
                  </Box>
                )}
              </Flex>
            </Box>
            <Box width={1 / 3}>
              <Flex
                height={'auto'}
                p={10}
                flexWrap={'wrap'}
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="flex-start"
                backgroundColor="#edf3ff"
                borderRadius="8px"
              >
                <Box width={1 / 1}>
                  <LabelField
                    underline
                    label="Mission"
                    value={mission?.displayReference}
                  />
                </Box>
                <Box width={1 / 1}>
                  <LabelField
                    underline
                    label="Client"
                    value={getTradeNameSafe(
                      mission?.customer?.establishment,
                      60
                    )}
                  />
                </Box>
                <Box width={1 / 1}>
                  <LabelField
                    underline
                    label="Adresse de facturation client"
                    value={
                      buildOneLineAddress(
                        (mission?.customer?.billingOptions
                          ?.invoiceAddress as IInvoiceAddress) ||
                          mission?.customer?.establishment?.address
                      ) || 'N/A'
                    }
                  />
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
        {(!task || loading) && (
          <Button
            mt={20}
            type="submit"
            isLoading={
              loading ||
              task?.status === ETaskStatus?.ONGOING ||
              task?.status === ETaskStatus?.PENDING
            }
          >
            Valider
          </Button>
        )}
        {((task && task?.status === ETaskStatus?.ERROR) ||
          task?.status === ETaskStatus?.COMPLETED) && (
          <Button mt={20} type="button" onClick={() => onResolve(false)}>
            Fermer
          </Button>
        )}
      </form>
    </ModalFrame>
  );
};

export const showPatchCustomerInvoiceModal = create<Props>(
  PatchCustomerInvoiceModal
);
