import {
  EadditionalActivityType,
  EInvoiceStatus,
  IJoinedAdditionalActivity,
  EAdditionalActivityPatchActions,
  IAdditionalActivityPatchResponse,
} from '@freelancelabs/teoreme-commons';
import {
  useInvoiceFindMany,
  additionalActivityPatch,
  transformText,
  getTradeNameSafe,
  getFullName,
  getHumanDateMonthAndYear,
  isCustomerInvoice,
  isProviderInvoice,
  queryClient,
  MAX_CUSTOMER_REFERENCE_FIELD_LENGTH,
  getAdditionActivityTextType,
  CREDIT_NOTE_INVOICE_TYPES,
} from '@commons';
import {
  Button,
  Flex,
  Box,
  Text,
  Input,
  FormControl,
  TextAreaControlled,
  LabelField,
  SpinnerBox,
  Status,
  CheckSwitch,
} from 'components/ui';
import React, { useState } from 'react';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
import TextInformation from 'components/TextInformation';
import { useForm } from 'react-hook-form';
import { useShowMessage } from 'hooks/useShowMessage';
import { getStatusData } from 'helpers/string';
import { LockIcon } from 'components/ui/icons';
import { Theme } from 'styles';
type FormValues = {
  customerCreditNoteReference?: string;
  rejectReason: string;
  sendProviderNotifications?: string;
};
type Props = ModalProps & {
  beforeValidation?: () => void;
  additionalActivity: IJoinedAdditionalActivity;
};

export const PatchAdditionalActivityModal = ({
  onResolve,
  isOpen,
  additionalActivity,
}: Props) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {},
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPatched, setIsPatched] = useState<
    IAdditionalActivityPatchResponse | false
  >(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sendProviderNotif, setSendProviderNotif] = useState(true);
  const showMessage = useShowMessage();
  const { data: invoices, isFetching: isFetchingInvoices } = useInvoiceFindMany(
    {
      filterObject: {
        status: {
          $in: [
            EInvoiceStatus.VALIDATED,
            EInvoiceStatus.PAID,
            EInvoiceStatus.SENT,
          ],
        },
        invoiceType: {
          $nin: CREDIT_NOTE_INVOICE_TYPES,
        },
        additionalActivity: additionalActivity?.uuid,
      },
      limit: 20,
      skip: 0,
    }
  );

  const textType = getAdditionActivityTextType(additionalActivity);
  const onSubmit = async (formValues: FormValues) => {
    setIsLoading(true);
    try {
      await additionalActivityPatch({
        uuid: additionalActivity?.uuid as string,
        action: EAdditionalActivityPatchActions.REJECT,
        rejectReason: formValues?.rejectReason,
        customerCreditNoteReference: formValues?.customerCreditNoteReference,
        //@ts-ignore
        sendProviderNotifications: sendProviderNotif,
      });
      //setIsPatched(patchResponse);
      showMessage(
        'success',
        `${transformText(textType, 'capitalize-first')} à bien été refusé`
      );
      queryClient?.refetchQueries({
        queryKey: ['additionalActivities'],
        type: 'active',
      });
      onResolve(false);
    } catch (e) {
      onResolve(false);
    }
    setIsLoading(false);
  };
  const customerInvoice = invoices?.invoices?.filter(i =>
    isCustomerInvoice(i?.invoiceType)
  );
  const providerInvoice = invoices?.invoices?.filter(i =>
    isProviderInvoice(i?.invoiceType)
  );
  const findInvoiceLocked =
    providerInvoice?.find(i => i?.locked) ||
    customerInvoice?.find(i => i?.locked);
  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={900}
      closeIcon
    >
      {isFetchingInvoices ? (
        <SpinnerBox />
      ) : (
        <>
          {findInvoiceLocked ? (
            <Box width={1 / 1}>
              <Flex
                justifyContent="center"
                alignItems={'center'}
                height={'calc(100vh - 90px)'}
              >
                <Box mr={20}>
                  <LockIcon fontSize={50} />
                </Box>
                <Box>
                  <Text variant="h3">
                    Impossible de corriger {textType} est verrouillé !
                  </Text>
                </Box>
              </Flex>
            </Box>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box width={1 / 1}>
                <Text variant="h1">Refuser {textType}</Text>
                <Box width={10 / 10} mb={20} mt={20}>
                  <Flex
                    height={'100px'}
                    p={10}
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    backgroundColor="#edf3ff"
                    borderRadius="8px"
                  >
                    <Box width={2 / 10}>
                      <LabelField
                        underline
                        label="Période"
                        value={
                          additionalActivity.type !==
                          EadditionalActivityType.MILESTONE
                            ? getHumanDateMonthAndYear(
                                additionalActivity?.month as Date
                              )
                            : getHumanDateMonthAndYear(
                                additionalActivity?.createdAt as Date
                              )
                        }
                      />
                    </Box>
                    <Box width={2 / 10}>
                      <LabelField
                        underline
                        label="Mission"
                        value={additionalActivity?.mission?.displayReference}
                      />
                    </Box>
                    <Box width={2 / 10}>
                      <LabelField
                        underline
                        label="Fournisseur"
                        value={getTradeNameSafe(
                          additionalActivity?.estProvider
                        )}
                      />
                    </Box>
                    <Box width={2 / 10}>
                      <LabelField
                        underline
                        label="Client"
                        value={getTradeNameSafe(
                          additionalActivity?.estCustomer
                        )}
                      />
                    </Box>
                    <Box width={2 / 10}>
                      <LabelField
                        underline
                        label="Intervenant"
                        value={getFullName(additionalActivity?.contractor)}
                      />
                    </Box>
                  </Flex>
                </Box>
                {/* {isPatched && (
              <Box hidden={!isPatched}>
                <Text variant="h3" mb={10}>
                  Résumé
                </Text>
                <Flex
                  display="inline-flex"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                  alignItems="center"
                  width={1 / 1}
                >
                  <Flex width={1 / 1} mb={10}>
                    <ALink
                      href={`/orders/cra/${isPatched?.additionalActivity?.cra}/informations`}
                      target="_blank"
                    >
                      <Link iconLeft={<EyeIcon />}>
                        {transformText(textType, 'capitalize-first')} à été
                        refusé{' '}
                      </Link>
                    </ALink>
                  </Flex>
                  {isPatched?.customerCreditNote && (
                    <Flex width={1 / 1} mb={10}>
                      <ALink
                        href={`/orders/invoices/customer/time-spent/${isPatched?.customerCreditNote?.uuid}`}
                        target="_blank"
                      >
                        <Link iconLeft={<EyeIcon />}>
                          Un avoir client à été créé.
                        </Link>
                      </ALink>
                    </Flex>
                  )}
                  {isPatched?.newCustomerInvoice && (
                    <Flex width={1 / 1} mb={10}>
                      <ALink
                        href={`/orders/invoices/customer/time-spent/${isPatched?.newCustomerInvoice?.uuid}`}
                        target="_blank"
                      >
                        <Link iconLeft={<EyeIcon />}>
                          - Une nouvelle facture client à été créé.
                        </Link>
                      </ALink>
                    </Flex>
                  )}
                  {isPatched?.newProviderInvoice && (
                    <Flex width={1 / 1} mb={10}>
                      <ALink
                        href={`/orders/invoices/provider/time-spent/${isPatched?.newProviderInvoice?.uuid}`}
                        target="_blank"
                      >
                        <Link iconLeft={<EyeIcon />}>
                          - Une nouvelle facture fournisseur à été créé.
                        </Link>
                      </ALink>
                    </Flex>
                  )}
                  {isPatched?.providerCreditNote && (
                    <Flex width={1 / 1} mb={10}>
                      <ALink
                        href={`/orders/invoices/provider/time-spent/${isPatched?.providerCreditNote?.uuid}`}
                        target="_blank"
                      >
                        <Link iconLeft={<EyeIcon />}>
                          - Un avoir fournisseur à été créé.
                        </Link>
                      </ALink>
                    </Flex>
                  )}
                </Flex>
              </Box>
            )} */}
                <Box hidden={isPatched !== false}>
                  <TextInformation variant="warning">
                    <p>
                      <b>Refuser {textType} :</b> Cette option vous permet de
                      passer {textType} validé au statut “Refusé”. Le
                      fournisseur doit alors soumettre de nouveau {textType} à
                      la validation.
                    </p>
                  </TextInformation>
                  {providerInvoice && providerInvoice?.length > 0 && (
                    <Flex alignItems="center">
                      <LabelField
                        label="Facture Fournisseur concernée par l'avoir: "
                        value={providerInvoice[0]?.sage?.invoiceId}
                        linkValue={`/orders/invoices/customer/time-spent/${providerInvoice[0]?.uuid}`}
                      />
                      <Box ml={'5px'}>
                        <Status
                          variantColor={
                            getStatusData(providerInvoice[0])?.variantColor
                          }
                        >
                          {getStatusData(providerInvoice[0])?.statusText}
                        </Status>
                      </Box>
                    </Flex>
                  )}
                  {customerInvoice && customerInvoice?.length > 0 && (
                    <Box mt={20}>
                      <Flex
                        flexWrap="wrap"
                        justifyContent={'flex-start'}
                        alignItems="center"
                        width={1 / 1}
                      >
                        {customerInvoice?.map(i => {
                          const isMarginInvoice = i?.isMarginInvoice
                            ? ' (facture de marge) '
                            : '';
                          return (
                            <Box width={1 / 1} mb={20}>
                              <LabelField
                                label="Facture client concernée par l'avoir: "
                                value={i?.sage?.invoiceId + isMarginInvoice}
                                linkValue={`/orders/invoices/customer/time-spent/${i?.uuid}`}
                              />
                              <Box ml={'5px'}>
                                <Status
                                  variantColor={getStatusData(i)?.variantColor}
                                >
                                  {getStatusData(i)?.statusText}
                                </Status>
                              </Box>
                            </Box>
                          );
                        })}
                      </Flex>
                      {/* <Box width={2 / 5}>
                    <FormControl
                      errorMessage={
                        errors?.customerCreditNoteReference?.type ===
                        'maxLength'
                          ? `Veuillez saisir moins de ${MAX_CUSTOMER_REFERENCE_FIELD_LENGTH} caractères`
                          : errors?.customerCreditNoteReference?.message
                      }
                      label="Référence de l'avoir client"
                    >
                      <Input
                        isFullWidth
                        id="customerCreditNoteReference"
                        {...register('customerCreditNoteReference', {
                          maxLength: MAX_CUSTOMER_REFERENCE_FIELD_LENGTH,
                        })}
                      />
                    </FormControl>
                  </Box> */}
                    </Box>
                  )}
                  <Box width={1 / 1} mt={10}>
                    <FormControl label="Notification de refus au fournisseur">
                      <CheckSwitch
                        id="sendProviderNotifications"
                        my={10}
                        // {...register('sendProviderNotifications')}
                        onChange={e => {
                          setSendProviderNotif(!sendProviderNotif);
                        }}
                        checked={sendProviderNotif}
                      >
                        <Text variant="h3">
                          {sendProviderNotif
                            ? 'Notifier le fournisseur'
                            : 'Ne pas notifier le fournisseur'}
                        </Text>
                      </CheckSwitch>
                    </FormControl>
                  </Box>
                  <Flex>
                    <Box width={1 / 1}>
                      <FormControl
                        errorMessage={errors?.rejectReason?.message}
                        label="Motif de refus"
                        required
                      >
                        <TextAreaControlled
                          minRows={3}
                          name="rejectReason"
                          control={control}
                          rules={{ required: 'Ce champs est requis' }}
                        />
                      </FormControl>
                    </Box>
                  </Flex>
                </Box>
                {!isPatched && (
                  <Flex>
                    <Button isLoading={isLoading} type="submit">
                      Refuser {textType}
                    </Button>
                  </Flex>
                )}
              </Box>
            </form>
          )}
        </>
      )}
    </ModalFrame>
  );
};

export const showPatchAdditionalActivityModal = create<Props>(
  PatchAdditionalActivityModal
);
