import {
  useEstablishmentFindOne,
  ApiError,
  CREATED_SF_STATUS,
  NOT_CREATED_SF_STATUS,
  DISPLAY_SF_STATUS,
  useEstablishmentUpdateOne,
  getEstablishmentName,
  getFirstCaractsEstablisement,
  queryClient,
} from '@commons';
import { Header } from 'components/Header';
import { SideBar } from 'components/SideBar';
import { SideLink } from 'components/SideLink';
import {
  Avatar,
  Box,
  Container,
  Divider,
  Flex,
  Link,
  Spinner,
  Status,
  Text,
} from 'components/ui';
import { format } from 'date-fns';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { ClientsEstablishmentsContacts } from './Contacts';
import { ClientsEstablishmentsInformation } from './Information';
import { Comments } from './Comment';
import { ClientsEstablishmentsBilling } from './Billing';
import { StandByDuty } from './StandByDuty';
import { Expenses } from './Expenses';
import { Missions } from './Missions';
import { Invoices } from './Invoices';
import PageNotFound from 'routes/NotFound';
import { LockIcon, UnLockIcon } from 'components/ui/icons';
import { showDialogModal } from 'components/modals/DialogModal';
import { ELockAction } from '@freelancelabs/teoreme-commons';
import { Theme } from 'styles';

export const ClientsEstablishmentsDetail = () => {
  const { uuid } = useParams<{ uuid: string }>();

  const { data: establishment, status, error } = useEstablishmentFindOne(uuid);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();

  const loading = status === 'pending';
  const salesForce = establishment?.customer?.salesforce;
  if (status === 'error') {
    return (
      <PageNotFound
        top={80}
        customText="L'établissement que vous cherchez semble introuvable."
      />
    );
  }
  const isLocked = establishment?.customer?.locked;
  //const sfSiret = establishment?.provider?.salesforce?.siret;
  //const SfSiretDiff = sfSiret && establishment?.siret !== sfSiret;
  return (
    <Box>
      <Header>
        <Flex>
          <Avatar
            text={
              (establishment && getFirstCaractsEstablisement(establishment)) ||
              ''
            }
            mr={10}
          />
          <Box>
            <Text variant="h1">
              {(establishment && getEstablishmentName(establishment, 80)) || ''}
            </Text>
            <Status variantColor="success">
              Actif depuis le{' '}
              {establishment?.activeSince
                ? format(establishment.activeSince, 'dd/MM/yyyy')
                : ''}
            </Status>
            {DISPLAY_SF_STATUS && (
              <Status variantColor={salesForce ? 'success' : 'grey'}>
                {salesForce ? CREATED_SF_STATUS : NOT_CREATED_SF_STATUS}
              </Status>
            )}
            <Status
              circleHidden={true}
              variantColor={isLocked ? 'error' : 'success'}
            >
              {isLocked ? (
                <LockIcon
                  fill={Theme?.colors?.error?.default}
                  style={{ marginRight: 7 }}
                />
              ) : (
                <UnLockIcon
                  fill={Theme?.colors?.success?.default}
                  style={{ marginRight: 7 }}
                />
              )}{' '}
              Établissement {isLocked ? `désactivé` : 'actif'}
            </Status>
          </Box>
        </Flex>
      </Header>
      <Container pt={50}>
        <Flex>
          <SideBar>
            <SideLink href={`/clients/establishments/${uuid}/information`}>
              Informations générales
            </SideLink>
            <SideLink href={`/clients/establishments/${uuid}/contacts`}>
              Contacts
            </SideLink>
            <SideLink href={`/clients/establishments/${uuid}/billing`}>
              Facturation
            </SideLink>
            <SideLink href={`/clients/establishments/${uuid}/expenses`}>
              Frais
            </SideLink>
            <SideLink href={`/clients/establishments/${uuid}/stand-by-duty`}>
              Prestations complémentaires
            </SideLink>
            <SideLink href={`/clients/establishments/${uuid}/missions`}>
              Missions
            </SideLink>
            <SideLink href={`/clients/establishments/${uuid}/invoices`}>
              Factures
            </SideLink>
            <SideLink href={`/clients/establishments/${uuid}/comments`}>
              Commentaires
            </SideLink>
            <Divider />
            <SideLink>
              {isLocked ? (
                <Link
                  onClick={() =>
                    showDialogModal({
                      title: `Êtes-vous sûr de vouloir réactiver l'établissement ${getEstablishmentName(
                        establishment
                      )} ?`,
                      text: 'Cette établissement pourra étre séléctionné comme client pour une nouvelle mission.',
                      confirmLabel: 'Réactiver',
                      cancelLabel: 'Annuler',
                      beforeValidation: async () => {
                        try {
                          await updateEstablishment({
                            uuid: uuid,
                            establishment: {
                              lockAction: ELockAction?.UNLOCK_CUSTOMER,
                            },
                          });
                        } catch (e) {
                          //
                        }
                        queryClient?.refetchQueries({
                          queryKey: [uuid],
                          type: 'active',
                        });
                      },
                    })
                  }
                  iconLeft={<UnLockIcon style={{ marginTop: '5px' }} />}
                >
                  Réactiver l'établissement
                </Link>
              ) : (
                <Link
                  onClick={() =>
                    showDialogModal({
                      title: `Êtes-vous sûr de vouloir désactiver l'établissement ${getEstablishmentName(
                        establishment
                      )} ?`,
                      text: 'Cette établissement ne pourra plus étre séléctionné comme client pour une nouvelle mission.',
                      confirmLabel: 'Désactiver',
                      cancelLabel: 'Annuler',
                      beforeValidation: async () => {
                        try {
                          await updateEstablishment({
                            uuid: uuid,
                            establishment: {
                              lockAction: ELockAction?.LOCK_CUSTOMER,
                            },
                          });
                        } catch (e) {
                          //
                        }
                        queryClient?.refetchQueries({
                          queryKey: [uuid],
                          type: 'active',
                        });
                      },
                    })
                  }
                  iconLeft={<LockIcon style={{ marginTop: '5px' }} />}
                >
                  Désactiver l'établissement
                </Link>
              )}
            </SideLink>
          </SideBar>

          <Box padding="0 20px" flex={1}>
            {loading && <Spinner />}
            {!loading && establishment && (
              <Switch>
                <Route exact path="/clients/establishments/:uuid">
                  <Redirect
                    to={`/clients/establishments/${establishment.uuid}/information`}
                  />
                </Route>
                <Route path="/clients/establishments/:uuid/information">
                  <ClientsEstablishmentsInformation />
                </Route>
                <Route path="/clients/establishments/:uuid/contacts">
                  <ClientsEstablishmentsContacts />
                </Route>
                <Route path="/clients/establishments/:uuid/comments">
                  <Comments />
                </Route>
                <Route path="/clients/establishments/:uuid/billing">
                  <ClientsEstablishmentsBilling />
                </Route>
                <Route path="/clients/establishments/:uuid/stand-by-duty">
                  <StandByDuty />
                </Route>
                <Route path="/clients/establishments/:uuid/expenses">
                  <Expenses />
                </Route>
                <Route path="/clients/establishments/:uuid/missions">
                  <Missions />
                </Route>
                <Route path="/clients/establishments/:uuid/invoices">
                  <Invoices />
                </Route>
              </Switch>
            )}
            {error && <p className="error">{(error as ApiError).message}</p>}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
