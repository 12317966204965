import * as React from 'react';

function SvgExclamationCircleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        d="M7.99.002a8.137 8.137 0 00-5.674 2.435A7.866 7.866 0 00.001 8.139 7.852 7.852 0 007.867 16h.143A8.073 8.073 0 0016 7.862 7.843 7.843 0 007.99.002zM7 12.02A.984.984 0 017.967 11h.018c.547.001.995.434 1.015.98A.984.984 0 018.034 13h-.018A1.02 1.02 0 017 12.02zm0-3.645v-3.75C7 4.28 7.448 4 8 4s1 .28 1 .625v3.75C9 8.72 8.552 9 8 9s-1-.28-1-.625z"
      />
    </svg>
  );
}

export default SvgExclamationCircleIcon;
