import * as React from 'react';

function SvgTaskIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || '200'}
      height={props?.height || '200'}
      viewBox="0 0 200 200"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#EEF6FA"
          fillRule="nonzero"
          d="M169.93 66.953c-12.888-18.535-54.41-34.506-78.299-32.138-52.3 5.185-81.489 66.44-75.261 87.69 4.445 15.17 16.6 27.308 30.855 33.917 19.665 9.112 43.76 8.897 64.775 5.07 27.83-5.07 63.808-37.444 67.526-49.755 6.442-21.33 1.916-28.226-9.596-44.784z"
        />
        <g stroke="#004269">
          <path
            fill="#004269"
            strokeLinecap="round"
            d="M98.018 20.792l13.815 100.757 1.24 10.108c.4 3.256-1.915 6.22-5.172 6.62l-86.97 10.679c-3.257.4-6.22-1.916-6.62-5.172L.92 34.733c-.2-1.628.958-3.11 2.586-3.31l.03-.004 25.265-2.807"
            transform="translate(36 25)"
          />
          <path
            fill="#FFF"
            d="M102.403.5H32c-.966 0-1.841.392-2.475 1.025C28.892 2.16 28.5 3.034 28.5 4v116.752c0 .967.392 1.842 1.025 2.475.634.634 1.509 1.025 2.475 1.025h91.505c.966 0 1.841-.391 2.475-1.025.633-.633 1.025-1.508 1.025-2.475V25.09c0-.928-.37-1.819-1.026-2.475l-21.102-21.09C104.22.868 103.33.5 102.403.5z"
            transform="translate(36 25)"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M102.473.569v19.607c0 2.8 1.995 5.072 4.456 5.072h11.782"
            transform="translate(36 25)"
          />
          <g transform="translate(36 25) translate(50 37)">
            <g transform="translate(0 60)">
              <rect width="8" height="8" rx="1" />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 4.5L55 4.5"
              />
            </g>
            <rect width="8" height="8" rx="1" />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 4.5L55 4.5"
            />
            <g transform="translate(0 20)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 4.5L55 4.5"
              />
              <rect width="8" height="8" rx="1" />
            </g>
            <g transform="translate(0 40)">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 4.5L55 4.5"
              />
              <rect width="8" height="8" rx="1" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgTaskIcon;
