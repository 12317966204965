import {
  IAdditionalActivityGetParams,
  IAdditionalActivityGetResponse,
  IAdditionalActivityCreateParams,
  IAdditionnalActivityCreateResponse,
  IAdditionalActivityUpdateParams,
  IAdditionnalActivityUpdateResponse,
  IAdditionalActivitySearchParams,
  IAdditionalActivitySearchResponse,
  IAdditionalActivityDeleteParams,
  IAdditionalActivityDeleteResponse,
  IAdditionalActivityGenerateParams,
  IAdditionalActivityGenerateFileResponse,
  IAdditionalActivitySendFileParams,
  IAdditionalActivitySendFileResponse,
  IAdditionalActivityPatchParams,
  IAdditionalActivityPatchResponse,
  IAdditionalActivityMilestoneReportSearchParams,
  IAdditionalActivityMilestoneReportSearchResponse,
} from '@freelancelabs/teoreme-commons';

import { ADDTIONAL_ACTIVITY_SERVICE_URL } from '../../constantz';
import { fetcher } from '../helpers/fetcher';

export const createAdditionalActivity = (
  params: IAdditionalActivityCreateParams,
  disableAlert?: boolean
) =>
  fetcher<IAdditionalActivityCreateParams, IAdditionnalActivityCreateResponse>(
    ADDTIONAL_ACTIVITY_SERVICE_URL + `/create`,
    {
      method: 'POST',
      body: params,
    },
    disableAlert
  );
export const additionalActivityFindOne = (
  params: { uuid: string; joinInvoice?: boolean },
  disableAlert?: boolean
) =>
  fetcher<IAdditionalActivityGetParams, IAdditionalActivityGetResponse>(
    ADDTIONAL_ACTIVITY_SERVICE_URL +
      `/get?uuid=${params?.uuid}${
        params?.joinInvoice ? `&joinInvoice=true` : ''
      }`,
    {
      method: 'GET',
    },
    disableAlert
  );
export const additionalActivityFindMany = (
  searchParams: IAdditionalActivitySearchParams,
  disableAlert?: boolean
) =>
  fetcher<IAdditionalActivitySearchParams, IAdditionalActivitySearchResponse>(
    ADDTIONAL_ACTIVITY_SERVICE_URL + `/search`,
    {
      method: 'POST',
      body: searchParams,
    },
    disableAlert
  );
export const additionalActivityUpdateOne = (
  searchParams: IAdditionalActivityUpdateParams,
  disableAlert?: boolean
) =>
  fetcher<IAdditionalActivityUpdateParams, IAdditionnalActivityUpdateResponse>(
    ADDTIONAL_ACTIVITY_SERVICE_URL + `/update`,
    {
      method: 'POST',
      body: searchParams,
    },
    disableAlert
  );
export const additionalActivityDeleteMany = (
  searchParams: IAdditionalActivityDeleteParams,
  disableAlert?: boolean
) =>
  fetcher<IAdditionalActivityDeleteParams, IAdditionalActivityDeleteResponse>(
    ADDTIONAL_ACTIVITY_SERVICE_URL + `/delete`,
    {
      method: 'POST',
      body: searchParams,
    },
    disableAlert
  );
export const additionalActivityGenerate = (
  searchParams: IAdditionalActivityGenerateParams,
  disableAlert?: boolean
) =>
  fetcher<
    IAdditionalActivityGenerateParams,
    IAdditionalActivityGenerateFileResponse
  >(
    ADDTIONAL_ACTIVITY_SERVICE_URL + `/generate`,
    {
      method: 'POST',
      body: searchParams,
    },
    disableAlert
  );
export const additionalActivitySendFile = (
  searchParams: IAdditionalActivitySendFileParams,
  disableAlert?: boolean
) =>
  fetcher<
    IAdditionalActivitySendFileParams,
    IAdditionalActivitySendFileResponse
  >(
    ADDTIONAL_ACTIVITY_SERVICE_URL + `/send-file`,
    {
      method: 'POST',
      body: searchParams,
    },
    disableAlert
  );
export const additionalActivityPatch = (
  searchParams: IAdditionalActivityPatchParams,
  disableAlert?: boolean
) =>
  fetcher<IAdditionalActivityPatchParams, IAdditionalActivityPatchResponse>(
    ADDTIONAL_ACTIVITY_SERVICE_URL + `/patch`,
    {
      method: 'POST',
      body: searchParams,
    },
    disableAlert
  );
export const mileStoneReportFindMany = (
  searchParams: IAdditionalActivityMilestoneReportSearchParams,
  disableAlert?: boolean
) =>
  fetcher<
    IAdditionalActivityMilestoneReportSearchParams,
    IAdditionalActivityMilestoneReportSearchResponse
  >(
    ADDTIONAL_ACTIVITY_SERVICE_URL + `/milestone-report/search`,
    {
      method: 'POST',
      body: searchParams,
    },
    disableAlert
  );
