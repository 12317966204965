import React, { useState } from 'react';
import {
  IEstablishmentMinifiedUsers,
  IEstablishmentFullUsers,
  IFile,
  EFileType,
  EPaymentMethod,
} from '@freelancelabs/teoreme-commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import { cutLongText, uploadFile } from '@commons';
import { useEstablishmentUpdateOne } from '@commons';
import {
  Box,
  Button,
  FormControl,
  Input,
  Row,
  Text,
  FileInput,
  Link,
  CheckBox,
  FormLabel,
  DatePickerControlled,
  StaticSelectControlled,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import { useForm } from 'react-hook-form';
import { printFormat } from 'iban';
import { isValidIBAN, isValidBIC, electronicFormatIBAN } from 'ibantools';
import { queryClient } from '@commons';
import { useShowMessage } from 'hooks/useShowMessage';

type FormValues = {
  paymentMethod?: string;
  paymentDeadline?: number;
  rum?: string;
  recurrent?: number;
  prelevedAt?: string;
  signedAt: string;
  paymentDate: string;

  rib?: File;
  mandateFile?: File;
  iban: string;
  bic: string;
  bankName: string;
  owner: string;
};
type AddPaiementProps = {
  establishment: IEstablishmentMinifiedUsers | IEstablishmentFullUsers;
  defaultValues?: any;
  onSubmit?: (values: any) => any | void;
  onClose: (bool: boolean) => any | void;
};

export const AddPaiementEstaClientForm = ({
  establishment,
  defaultValues,
  onClose,
  onSubmit: onSubmitParent,
}: AddPaiementProps) => {
  const showMessage = useShowMessage();
  const [loading, setLoading] = useState(false);
  const { mutateAsync: updateEstablishment } = useEstablishmentUpdateOne();
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    formState: { errors, isDirty, isSubmitSuccessful },
    reset,
    watch,
    setError,
  } = useForm<FormValues>({
    // change (establishment as any) ==> (establishment as IEstablishmentBase)
    defaultValues: {
      paymentMethod:
        (establishment as any)?.customer?.paymentDetails?.paymentMethod ||
        EPaymentMethod.DIRECT_DEBIT,
      paymentDeadline:
        (establishment as any)?.customer?.paymentDetails?.paymentDeadline || 60,
      iban:
        (establishment as any)?.customer?.paymentDetails?.sepaMandate
          ?.bankAccount?.iban &&
        printFormat(
          (establishment as any)?.customer?.paymentDetails?.sepaMandate
            ?.bankAccount?.iban
        ),
      bic: (
        establishment as any
      )?.customer?.paymentDetails?.sepaMandate?.bankAccount?.bic?.toUpperCase(),
      bankName: (establishment as any)?.customer?.paymentDetails?.sepaMandate
        ?.bankAccount?.bankName,
      owner: (establishment as any)?.customer?.paymentDetails?.sepaMandate
        ?.bankAccount?.owner,
      rib: (establishment as any)?.customer?.paymentDetails?.sepaMandate
        ?.bankAccount?.rib,
      mandateFile: (establishment as any)?.customer?.paymentDetails?.sepaMandate
        ?.mandateFile,
      rum: (establishment as any)?.customer?.paymentDetails?.sepaMandate?.rum,
      recurrent: (establishment as any)?.customer?.paymentDetails?.sepaMandate
        ?.recurrent,
      signedAt: (establishment as any)?.customer?.paymentDetails?.sepaMandate
        ?.signedAt,
    },
  });
  const [ribFile, setRibFile] = React.useState<File | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rib, setRib] = React.useState<IFile | null>(
    (establishment as any)?.customer?.paymentDetails?.sepaMandate?.bankAccount
      ?.rib
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mandate, setMandate] = React.useState<IFile | null>(
    (establishment as any)?.customer?.paymentDetails?.sepaMandate?.mandateFile
  );
  const [mandateFile, setMandateFile] = React.useState<File | null>(null);
  const [isReccurent, setIsReccurent] = React.useState(
    //(establishment as any)?.customer?.paymentDetails?.sepaMandate?.recurrent
    true
  );
  // const [isPoncutel, setIsPoncutel] = React.useState(
  //   !(establishment as any)?.customer?.paymentDetails?.sepaMandate?.recurrent
  // );
  //@ts-ignore
  const paymentMethod = watch('paymentMethod');
  const onSubmit = async (formValues: FormValues) => {
    let dataSubmit;
    let onError = false;
    let s3ResponseRIB;
    let s3ResponseMandate;
    if (formValues.paymentMethod === EPaymentMethod.DIRECT_DEBIT) {
      const rib = watch('rib');
      const mandateFileDefault = watch('mandateFile');
      try {
        if (ribFile || rib) {
          clearErrors('rib');
        } else {
          onError = true;
          setError('rib', { message: 'Ce champ est requis' });
        }
        if (mandateFile || mandateFileDefault) {
          clearErrors('mandateFile');
        } else {
          onError = true;
          setError('mandateFile', { message: 'Ce champ est requis' });
        }
        if (onError === false) {
          setLoading(true);
          if (ribFile) {
            s3ResponseRIB = await uploadFile({
              file: ribFile as File,
              fileType: EFileType.BANK_ACCOUNT,
              establishment: establishment?.uuid || '',
              actionType: 'upload',
            });
            //@ts-ignore
            delete s3ResponseRIB.fields;
          }
          if (mandateFile) {
            s3ResponseMandate = await uploadFile({
              file: mandateFile as File,
              fileType: EFileType.BANK_ACCOUNT,
              establishment: establishment?.uuid || '',
              actionType: 'upload',
            });
            //@ts-ignore
            delete s3ResponseMandate.fields;
          }
        }

        dataSubmit = {
          paymentDetails: {
            paymentMethod: formValues.paymentMethod,
            sepaMandate: {
              rum: formValues.rum,
              signedAt: formValues.signedAt,
              recurrent: isReccurent,
              bankAccount: {
                iban: electronicFormatIBAN(formValues.iban),
                bic: formValues.bic,
                bankName: formValues.bankName,
                owner: formValues.owner,
                rib: ribFile
                  ? s3ResponseRIB
                  : (establishment as any)?.customer?.paymentDetails
                      ?.sepaMandate?.bankAccount?.rib,
              },
              mandateFile: mandateFile
                ? s3ResponseMandate
                : (establishment as any)?.customer?.paymentDetails?.sepaMandate
                    ?.mandateFile,
            },
          },
        };
      } catch {
        onError = true;
        showMessage('error', 'Une erreur est survenue');
        setLoading(false);
      }
    } else {
      dataSubmit = {
        paymentDetails: {
          paymentMethod: formValues.paymentMethod,
          paymentDeadline: Number(formValues.paymentDeadline),
        },
      };
    }
    if (onError === false) {
      setLoading(true);
      const establishmentData = {
        uuid: establishment?.uuid,
        establishment: {
          customer: {
            paymentDetails: dataSubmit?.paymentDetails,
          },
        },
      };
      try {
        await updateEstablishment(establishmentData as any);
        queryClient.refetchQueries({ queryKey: [establishment.uuid] });
        reset(formValues);
        onClose(true);
      } catch (e) {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const handleChangeRIB = (files: File[]) => {
    setRibFile(files[0]);
  };
  const handleChangeMandate = (files: File[]) => {
    setMandateFile(files[0]);
  };
  const checkIban = (value: string): string | undefined => {
    const iban = electronicFormatIBAN(value);
    if (iban && isValidIBAN(iban)) {
      return undefined;
    } else {
      return 'Le code IBAN est invalide ';
    }
  };
  const checkBIC = (value: string): string | undefined => {
    if (isValidBIC(value)) {
      return undefined;
    } else {
      return 'Le code BIC est invalide ';
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Text variant="h2">Modifier les Conditions de règlement </Text>
      <Box mt="20px">
        <FormControl label="Mode de règlement" required>
          <StaticSelectControlled
            control={control}
            name="paymentMethod"
            options={[
              { label: 'Virement', value: EPaymentMethod.BANK_TRANSFER },
              { label: 'Prélévement', value: EPaymentMethod.DIRECT_DEBIT },
            ]}
            placeholder="Type de service"
          />
        </FormControl>
      </Box>
      {paymentMethod !== EPaymentMethod.BANK_TRANSFER && (
        //   <Box>
        //     <FormControl
        //       label="Délai de paiement"
        //       required
        //       errorMessage={errors?.paymentDeadline?.message}
        //     >
        //       <Input
        //         isFullWidth
        //         type="number"
        //         {...register('paymentDeadline', {
        //           required: 'Ce champs est requis ',
        //           min: 1,
        //         })}
        //       />
        //     </FormControl>
        //   </Box>
        // ) : (
        <>
          <Row spacing={20}>
            <FormControl
              label="RUM (Référence Unique du Mandat)"
              required
              errorMessage={errors?.rum?.message}
            >
              <Input
                isFullWidth
                type="text"
                {...register('rum', {
                  required: 'Ce champs est requis ',
                  min: 1,
                })}
              />
            </FormControl>
            <></>
          </Row>
          <Row spacing={20}>
            <FormControl
              label="IBAN"
              required
              errorMessage={errors?.iban?.message}
            >
              <Input
                data-clarity-mask="True"
                isFullWidth
                type="text"
                {...register('iban', {
                  validate: (value: any) => checkIban(value),
                })}
                onChange={e => {
                  // @ts-ignore
                  e.target.value = printFormat(e.target.value, ' ');

                  if (
                    // @ts-ignore
                    checkIban(printFormat(e.target.value, ' ')) === undefined
                  ) {
                    clearErrors('iban');
                  }
                }}
              />
            </FormControl>
            <FormControl
              label="BIC"
              required
              errorMessage={errors?.bic?.message}
            >
              <Input
                data-clarity-mask="True"
                isFullWidth
                type="text"
                {...register('bic', {
                  validate: (value: any) => checkBIC(value),
                })}
              />
            </FormControl>
          </Row>
          <Row spacing={20}>
            <FormControl
              label="Nom de la banque"
              required
              errorMessage={
                errors?.bankName?.type === 'maxLength'
                  ? 'Veuillez saisir moins de 31 caractères'
                  : errors?.bankName?.message
              }
            >
              <Input
                isFullWidth
                type="text"
                {...register('bankName', {
                  required: 'Ce champ est requis',
                  maxLength: 30,
                })}
              />
            </FormControl>
            <FormControl
              label="Titulaire du compte"
              required
              errorMessage={errors?.owner?.message}
            >
              <Input
                isFullWidth
                type="text"
                {...register('owner', { required: 'Ce champ est requis' })}
              />
            </FormControl>
          </Row>
          <FormControl
            label="Type de paiement"
            required
            errorMessage={errors?.recurrent?.message}
          >
            <Box width={2 / 3}>
              {/* <Row spacing={20}> */}
              <CheckBox
                id={'reccurent'}
                mb={10}
                checked={true}
                disabled
                onChange={e => setIsReccurent(e.target.checked)}
              >
                <FormLabel>Paiements récurrents</FormLabel>
              </CheckBox>
              {/* <CheckBox
                  id={'ponctuel'}
                  mb={10}
                  checked={isPoncutel}
                  onChange={e => setIsPoncutel(e.target.checked)}
                >
                  <FormLabel>Paiements ponctuels</FormLabel>
                </CheckBox> */}
              {/* </Row> */}
            </Box>
          </FormControl>
          <Row spacing={20}>
            <FormControl
              label="Mandat sepa signé"
              errorMessage={errors?.mandateFile?.message}
              required
            >
              <FileInput
                accept=".pdf"
                subLabel="Format accepté : PDF"
                onSelect={handleChangeMandate}
              >
                <Link iconLeft={<AddIcon />}>
                  {mandateFile || mandate?.fileName
                    ? `Modifier le mandat ${
                        mandateFile?.name
                          ? cutLongText(mandateFile.name, 20)
                          : cutLongText(mandate?.fileName as string, 20)
                      }`
                    : 'Ajouter un mandat sepa sginé'}
                </Link>
              </FileInput>
            </FormControl>
            <FormControl
              label="Relevé d'identité bancaire"
              errorMessage={errors?.rib?.message}
              required
            >
              <FileInput
                accept=".pdf"
                subLabel="Format accepté : PDF"
                onSelect={handleChangeRIB}
              >
                <Link iconLeft={<AddIcon />}>
                  {ribFile || rib?.fileName
                    ? `Modifier le rib ${
                        ribFile?.name
                          ? cutLongText(ribFile.name, 20)
                          : cutLongText(rib?.fileName as string, 20)
                      }`
                    : "Ajouter un relevé d'identité bancaire"}
                </Link>
              </FileInput>
            </FormControl>
          </Row>
          <Box width={1 / 2}>
            <FormControl
              required
              label="Date de signature du mandat"
              errorMessage={errors?.signedAt?.message}
            >
              <DatePickerControlled
                control={control}
                valueName="selected"
                maxDate={new Date()}
                name="signedAt"
                rules={{ required: 'Ce champ est requis' }}
              />
            </FormControl>
            {/* <FormControl
              required
              label="Date de prélèvement"
              errorMessage={
                errors?.paymentDate?.type === 'min' ||
                errors?.paymentDate?.type === 'max'
                  ? 'Compris entre le 1 & 30'
                  : errors?.paymentDate?.message
              }
            >
              <Input
                isFullWidth
                name="paymentDate"
                type="number"
                ref={
                  register({ required: 'Ce champ est requis',min:1, max: 30 }) //register()
                }
              />
            </FormControl> */}
          </Box>
        </>
      )}

      <Box>
        <Button type="submit" isLoading={loading}>
          Enregistrer
        </Button>
      </Box>
    </form>
  );
};
