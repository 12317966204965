import * as React from 'react';
import { Theme } from 'styles';

function SvgStarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={props.fill || Theme.colors.grey.default}
        fillRule="evenodd"
        d="M12.001 4L9.624 10.127 4 10.127 8.231 13.896 7.056 19.989 12.001 16.668 16.944 20 15.737 13.802 20 10.127 14.312 10.127z"
      />
    </svg>
  );
}

export default SvgStarIcon;
