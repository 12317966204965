import { EditInvoiceService, getPriceByUnit } from '@commons';
import {
  EAdditionalServiceStatus,
  EAdditionalServiceType,
  EUpdateInvoiceServiceAction,
  EUpdateInvoiceServiceType,
  FlatUser,
  IInvoiceLine,
  IJoinedInvoice,
  IFastCashService,
} from '@freelancelabs/teoreme-commons';
import { ModalFrame, ModalProps } from 'components/modals/ModalFrame';
import { Box, Button, Flex, FormControl, Radio, Text } from 'components/ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import { create } from 'react-modal-promise';
import { queryClient } from '@commons';

type FormValues = {
  serviceSelected: string;
  activeFC: '0' | '1';
};
type Props = ModalProps & {
  beforeValidation?: () => void;
  layer?: number;
  me?: FlatUser;
  invoiceLine?: IInvoiceLine;
  invoice: IJoinedInvoice;
};

export const EditFastCashModal = ({
  onResolve,
  isOpen,
  layer,
  invoiceLine,
  invoice,
}: Props) => {
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      serviceSelected: invoiceLine?.metadata?.catalogItemUuid,
      activeFC: '1',
    },
  });
  const onSubmit = async (formValues: FormValues) => {
    setLoading(true);
    const submitData = {
      invoiceUuid: invoice?.uuid,
      //@ts-ignore
      refCra: invoice?.refCra,
      service: {
        type: EUpdateInvoiceServiceType.FAST_CASH,
        action:
          activeFC === '1'
            ? EUpdateInvoiceServiceAction.ENABLE
            : EUpdateInvoiceServiceAction.DISABLE,
        catalogItem: serviceSelected,
        serviceUuid: serviceSelected,
      },
    };

    try {
      await EditInvoiceService(submitData);
      queryClient.refetchQueries({ queryKey: [invoice?.uuid] });
      onResolve(true);
    } catch (e) {
      onResolve(e);
    }
    setLoading(false);
  };
  const activeFC = watch('activeFC');
  const serviceSelected = watch('serviceSelected');

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={868}
      closeIcon
      layer={layer}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text mb={10} variant="h3">
          {invoiceLine ? 'Modifier' : 'Activer'} le Fast Cash
        </Text>
        <Text mb={20}>
          Vous avez la possibilité d’activer/désactiver le service Fast Cash.
          Une fois activée, sélectionnez l'échéance de paiement souhaitée pour
          vos factures.
        </Text>
        <Box>
          {invoiceLine && (
            <FormControl label={''} errorMessage={errors?.activeFC?.message}>
              <Flex>
                <Box width={1 / 2}>
                  <Radio
                    {...register('activeFC', {
                      required: 'Ce champ est obligatoire',
                    })}
                    value="1"
                  >
                    J’active le Fast Cash
                  </Radio>
                </Box>
                <Box width={1 / 2}>
                  <Radio
                    {...register('activeFC', {
                      required: 'Ce champ est obligatoire',
                    })}
                    value="0"
                  >
                    Je désactive le Fast Cash
                  </Radio>
                </Box>
              </Flex>
            </FormControl>
          )}
          <Box display={activeFC === '1' ? 'block' : 'none'}>
            <FormControl
              label={'Je choisis parmi les options suivantes :'}
              errorMessage={errors?.serviceSelected?.message}
            >
              <>
                {(
                  invoice?.mission?.additionalServices?.find(
                    as =>
                      as?.type === EAdditionalServiceType.FAST_CASH &&
                      as?.status === EAdditionalServiceStatus.ACTIVE
                  ) as IFastCashService
                )?.catalog?.map(catalog => (
                  <Radio
                    {...register('serviceSelected', {
                      required: 'Ce champ est obligatoire',
                    })}
                    value={catalog?.uuid}
                  >
                    Paiement à <b>{catalog?.deadline}</b> jours (
                    {catalog?.price} {getPriceByUnit(catalog?.unit)})
                  </Radio>
                ))}
              </>
            </FormControl>
          </Box>
        </Box>
        <Button
          mt={20}
          type="submit"
          isLoading={loading}
          isDisabled={loading || (activeFC === '1' && !serviceSelected)}
        >
          Valider
        </Button>
      </form>
    </ModalFrame>
  );
};

export const showEditFastCashModal = create<Props>(EditFastCashModal);
