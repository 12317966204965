import {
  ICompanyCreateResponse,
  ICompanyUpdateParams,
  IEstablishment,
  ICompanySearchRcProParams,
} from '@freelancelabs/teoreme-commons';
import { useMutation, useQuery, keepPreviousData } from '@tanstack/react-query';
import { queryClient } from '../';
import {
  establishmentCreateOne,
  establishmentFindMany,
  establishmentFindOne,
  establishmentUpdateOne,
  headQuarterFindMany,
  headQuarterFindOne,
  establishmentFindRCPRO,
} from '../routes';

export const useEstablishmentFindOne = (identifier?: string) => {
  return useQuery({
    queryKey: [identifier, identifier],
    queryFn: async () => establishmentFindOne(identifier as string, 'uuid'),
    enabled: identifier ? true : false,
    // DISABLE CACHE
    refetchOnMount: false,
    staleTime: 0,
    gcTime: 0,
    retry: false,
  });
};

export const useEstablishmentUpdateOne = () =>
  useMutation<IEstablishment, unknown, ICompanyUpdateParams>({
    mutationFn: params => establishmentUpdateOne(params),
    onSuccess: data => {
      queryClient.setQueryData([data.uuid], data);
    },
  });

export const useEstablishmentCreateOne = () =>
  useMutation<ICompanyCreateResponse, unknown, Partial<IEstablishment>>({
    mutationFn: establishment => establishmentCreateOne(establishment),
    onSuccess: (data: any) => {
      queryClient.setQueryData([data.uuid], data);
    },
  });

export const useEstablishmentFindMany = (variables?: any) => {
  return useQuery({
    queryKey: ['establishments', variables],
    queryFn: async () => establishmentFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    placeholderData: keepPreviousData,
  });
};

export const useHeadQuarterFindMany = (variables?: any) => {
  return useQuery({
    queryKey: ['headquarters', variables],
    queryFn: async () => headQuarterFindMany(variables),
    //enabled: variables && variables.filterObject ? true : false,
    placeholderData: keepPreviousData,
  });
};
export const useHeadQuarterFindOne = (uuid?: string) => {
  return useQuery({
    queryKey: ['hq', uuid],
    queryFn: async () => headQuarterFindOne(uuid),
    enabled: uuid ? true : false,
    // DISABLE CACHE
    refetchOnMount: false,
    staleTime: 0,
    gcTime: 0,
  });
};

export const useEstablishmentFindManyRCPRO = (
  variables: ICompanySearchRcProParams
) => {
  return useQuery({
    queryKey: [variables.uuid ? 'rcpros' : 'false', variables],
    queryFn: async () => establishmentFindRCPRO(variables),
    enabled: variables?.uuid ? true : false,
  });
};
