import { PhoneInput } from 'components/PhoneInput';
import { useController } from 'react-hook-form';
export const PhoneInputControlled = (props: any) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name: props?.name,
    control: props?.control,
    rules: props?.rules,
    defaultValue: props?.defaultValue,
  });
  return <PhoneInput {...inputProps} {...props} inputRef={ref} />;
};
