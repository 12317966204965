import * as React from 'react';
import { Theme } from 'styles';

function SvgStarSlashIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={props.fill || Theme.colors.primary.default}
        fillRule="evenodd"
        d="M20 10.127l-4.263 3.675L16.944 20 12 16.668l-4.945 3.321.32-1.659 8.203-8.203H20zM12 4l1.86 4.932 3.504-3.503c.195-.195.512-.195.707 0 .195.195.195.512 0 .707l-3.824 3.823.024.062-6.557 6.557.02-.105-3.098 3.098c-.195.195-.512.195-.707 0-.195-.195-.195-.512 0-.707L8.07 14.72l.16-.824L4 10.127h5.624L12 4z"
      />
    </svg>
  );
}

export default SvgStarSlashIcon;
