import React from 'react';

export const useCombinedRefs = (...refs: any) => {
  const targetRef = React.useRef() as React.RefObject<HTMLInputElement>;

  React.useEffect(() => {
    refs.forEach((ref: any) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs, targetRef]);

  return targetRef;
};
