import {
  FREELANCE_STRUCTURES,
  MISSION_BILLING_TYPE,
  MISSION_LABEL_TYPES,
  MISSION_MARGIN_TYPE,
  REACT_APP_MARKET_REQUIRED,
  buildOneLineAddress,
  checkGescomAvalaibleGenerateInvoiceCustomer,
  contractFindMany,
  downloadFile,
  getEstablishmentName,
  getFullName,
  getHumanDate,
  missionAbort,
  missionCreateOne,
  missionDeleteOne,
  missionUpdateOne,
  queryClient,
  round,
  useMe,
  useMissionFindOne,
} from '@commons';
import {
  EBillingType,
  EContractState,
  EContractType,
  EMissionStatus,
  EMissionStructure,
  EMissionType,
  EPurchaseOrderStatus,
  IInvoiceAddress,
  IJoinedContract,
  IJoinedMission,
  IUpdateMissionData,
} from '@freelancelabs/teoreme-commons';
import { showDialogModal } from 'components/modals/DialogModal';
import { Box, Flex, Link, Text } from 'components/ui';
import { ReactNode, useCallback, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Theme } from 'styles';

// type MissionActionsReturn = {
//   onDisableMission: () => Promise<boolean>;
//   onDeleteMission: () => Promise<boolean>;
//   onValidateMission: () => Promise<boolean>;
//   onReactivateMission: () => Promise<void>;
//   loading: boolean;
// };

// Overload declaration
// export function useMissionActions(mission: undefined): undefined;
// export function useMissionActions(
//   mission: IJoinedMission
// ): MissionActionsReturn;
// export function useMissionActions(
//   mission: IJoinedMission | undefined
// ): MissionActionsReturn | undefined;

// function implementation

const isFrenchEstablishment = (establishment: any) => {
  if (establishment?.address?.country?.toLowerCase() === 'france') return true;
  return false;
};

const isProviderEstablishmentAvailable = (providerEstablishment: any) => {
  if (!providerEstablishment?.siret && !providerEstablishment?.identifier) {
    return false;
  } else {
    return true;
  }
};
export function useMissionActions(
  mission: IJoinedMission | undefined,
  contracts?: IJoinedContract[]
) {
  let isValideContract = false;
  const isMandateMode = mission?.isMandateMode;
  const isContractRequired =
    mission?.provider?.establishment?.provider?.isContractRequired;
  const [loading, setLoading] = useState(false);
  if (!mission) return undefined;
  const checkValidationAvalaible = async () => {
    if (!contracts && mission?.provider?.establishment) {
      contracts = (
        await contractFindMany({
          filterObject: mission?.provider?.establishment?.provider?.contracts
            ? {
                uuid: {
                  $in: [
                    ...(mission?.provider?.establishment?.provider
                      ?.contracts as string[]),
                  ],
                },
                state: EContractState.DONE,
              }
            : undefined,
        })
      )?.contracts;
    }
    if (isMandateMode) {
      if (
        contracts?.find(
          po =>
            po?.state === EContractState.DONE &&
            po.type?.includes(EContractType.LOREAL)
        )
      ) {
        isValideContract = true;
      }
    } else {
      if (isContractRequired) {
        if (
          contracts?.find(
            po =>
              !po.type?.includes(EContractType.LOREAL) &&
              po?.state === EContractState.DONE
          )
        ) {
          isValideContract = true;
        }
      } else {
        isValideContract = true;
      }
    }
    if (
      mission?.customer?.billingOptions?.isAutoBilling &&
      !checkGescomAvalaibleGenerateInvoiceCustomer(mission)?.ready
    ) {
      return false;
    }

    if (
      !isValideContract ||
      !mission?.description ||
      !isProviderEstablishmentAvailable(mission?.provider?.establishment) ||
      !mission?.billing?.clientRate ||
      !mission?.billing?.contractorRate ||
      !mission?.customer?.purchaseOrder?.reference ||
      !mission?.customer?.billingOptions?.iban ||
      !mission?.billing?.type ||
      (REACT_APP_MARKET_REQUIRED === true ? !mission?.marketId : false) ||
      !mission?.billingInformation?.structure ||
      !mission?.billingInformation.missionType?.label ||
      !mission?.internalCommercial ||
      //Aucun BDC fournisseur n’a été envoyé ET
      // (la mission est de type mandat de gestion OU le fournisseur est dispensé de la signature d’un contrat cadre)
      (!mission?.isMandateMode &&
        (mission?.provider?.establishment?.provider?.isContractRequired
          ? !checkProviderBdc()
          : false))
    ) {
      return false;
    }
    return true;
  };
  // check if provider bdc is generated and sent to provider
  const checkProviderBdc = () => {
    const allowedPoStatus = [
      EPurchaseOrderStatus.DONE,
      EPurchaseOrderStatus.IMPORTED,
      EPurchaseOrderStatus.SIGNED,
      EPurchaseOrderStatus.SIGNED_IMPORT,
      EPurchaseOrderStatus.TO_SIGN,
    ];
    const missionProviderPos = mission?.provider?.purchaseOrders || [];
    const res = missionProviderPos?.some(e =>
      allowedPoStatus.includes(e?.status)
    );

    return res;
  };
  const tableStyles = { border: '2px solid rgb(140 140 140)' };
  type TableElementsTypes = {
    label: string | ReactNode;
    value: string | ReactNode;
    children?: any;
  };
  const TableElements = ({
    children,
    type,
    scope,
    styles,
  }: {
    children: any;
    type: string;
    scope?: 'col' | 'row';
    styles?: any;
  }) => {
    if (type === 'tr') {
      return (
        <tr style={{ ...tableStyles, padding: '8px 10px', ...styles }}>
          {children}
        </tr>
      );
    }
    if (type === 'th') {
      return (
        <th scope={scope} style={{ ...tableStyles, ...styles }}>
          {children}
        </th>
      );
    }
    if (type === 'td') {
      return <td style={{ ...tableStyles, ...styles }}>{children}</td>;
    }
  };

  const TableData = (props: TableElementsTypes) => {
    return (
      <TableElements type="tr">
        <TableElements
          styles={{
            width: '40%',
            backgroundColor: !props.value
              ? Theme?.colors?.warning?.default
              : Theme?.colors?.primary?.light,
            fontWeight: 'bold',
            paddingLeft: 10,
            // color: !props.value ? Theme?.colors?.warning?.default : undefined,
          }}
          type="td"
        >
          {props.label}
        </TableElements>
        <TableElements styles={{ width: '60%', paddingLeft: 10 }} type="td">
          {props.value}
        </TableElements>
      </TableElements>
    );
  };
  const onValidateMission = async () => {
    let contractorEmail =
      mission?.provider?.establishment?.uuid &&
      mission?.provider?.contractor?.scopedData?.find(
        s => s?.establishment === mission?.provider?.establishment?.uuid
      )?.email;
    const fileLocation = mission?.customer?.purchaseOrder?.file?.fileLocation;
    const bdcFile = fileLocation && (await downloadFile(fileLocation));
    const getMissionInvoiceAdress = () => {
      const missionCustomerEstablishment = mission?.customer?.establishment;
      const missionCustomerEstablishmentExistingIvAdr =
        //@ts-ignore
        mission?.customer?.billingOptions?.invoiceAddress;
      if (missionCustomerEstablishmentExistingIvAdr)
        return buildOneLineAddress(
          missionCustomerEstablishmentExistingIvAdr as IInvoiceAddress
        );

      const defaultAdress = buildOneLineAddress(
        missionCustomerEstablishment?.address
      );
      const othersInvoiceAdresses =
        missionCustomerEstablishment?.customer?.invoiceAddress || [];
      const othersDefaultAdress = othersInvoiceAdresses?.filter(
        e => e.default && !e.locked
      )[0];
      const textAdress = buildOneLineAddress(othersDefaultAdress as any);

      return textAdress || defaultAdress || 'adress';
    };
    const confirm = await showDialogModal({
      width: 1350,
      title: `Êtes-vous sûr de vouloir valider la mission ?`,
      text: (await checkValidationAvalaible()) ? (
        // "Une fois validée, cette mission sera créée en l'état dans GESCOM."
        <Flex
          flexWrap={'wrap'}
          display={'inline-flex'}
          justifyContent={'flex-start'}
          width={1 / 1}
        >
          <Box width={1 / 1}>
            <Text variant="b">
              Une fois validée, cette mission sera créée en l'état dans GESCOM.
            </Text>
          </Box>

          <Box width={1 / 2}>
            <table style={{ marginTop: 10, width: '100%', ...tableStyles }}>
              <tbody>
                <TableData
                  label="Structure de facturation"
                  value={
                    FREELANCE_STRUCTURES?.[
                      mission?.billingInformation
                        ?.structure as EMissionStructure
                    ]?.complete
                  }
                />
                <TableData
                  label="Type de mission"
                  value={
                    MISSION_LABEL_TYPES?.[
                      mission?.billingInformation?.missionType
                        ?.label as EMissionType
                    ]
                  }
                />
                <TableData
                  label="Type de Tarification"
                  value={
                    MISSION_BILLING_TYPE?.[
                      mission?.billing?.type as EBillingType
                    ]
                  }
                />
                <TableData
                  label="Mandat de gestion"
                  value={mission?.isMandateMode ? 'Oui' : 'Non'}
                />

                <TableData
                  label="Paiement direct"
                  value={
                    mission?.customer?.billingOptions?.isDirectPayment
                      ? 'Oui'
                      : 'Non'
                  }
                />

                <TableData
                  label="Établissement client"
                  value={`${getEstablishmentName(mission?.customer?.establishment, undefined, true)}`}
                />
                <TableData
                  label="Contact client"
                  value={
                    mission?.customer?.contact && (
                      <Box>
                        {getFullName(mission?.customer?.contact)}{' '}
                        <a
                          href={`mailto: ${mission?.customer?.contact?.email}`}
                        >
                          <Link>{mission?.customer?.contact?.email}</Link>
                        </a>
                      </Box>
                    )
                  }
                />
                <TableData
                  label="Adresse de facturation client"
                  value={getMissionInvoiceAdress()}
                />
                <TableData
                  label="Marché"
                  value={
                    mission?.marketData?.marketId &&
                    `${mission?.marketData?.marketId} ${mission?.marketData?.marketName}`
                  }
                />
                <TableData
                  label="Référence BDC client"
                  value={mission?.customer?.purchaseOrder?.reference}
                />
                <TableData
                  label="Date de début de la mission"
                  value={mission?.startAt && getHumanDate(mission?.startAt)}
                />
                <TableData
                  label="Date de fin de la mission"
                  value={mission?.endAt && getHumanDate(mission?.endAt)}
                />
                {mission?.billing?.type === EBillingType.DAY && (
                  <TableData
                    label="Nombre de jours commandés"
                    value={mission?.billing?.estimatedDays}
                  />
                )}
                <TableData
                  label="Type de Taux"
                  value={
                    mission?.billing?.marginType &&
                    MISSION_MARGIN_TYPE?.[mission?.billing?.marginType]
                  }
                />
                <TableData
                  label="Marge"
                  value={
                    mission?.billing?.margin &&
                    `${round(mission?.billing?.margin)} %`
                  }
                />
                {mission?.billing?.type === EBillingType.DAY ? (
                  <TableData
                    label="TJM client (HT)"
                    value={
                      mission?.billing?.clientRate &&
                      `${mission?.billing?.clientRate} €`
                    }
                  />
                ) : (
                  //MONTANT PAYÉ PAR LE CLIENT (HT)
                  <TableData
                    label="Montant payé par le client (HT)"
                    value={
                      mission?.billing?.clientRate &&
                      `${mission?.billing?.clientRate} €`
                    }
                  />
                )}
                {mission?.billing?.type === EBillingType.DAY && (
                  <TableData
                    label="Montant commande client"
                    value={
                      mission?.billing?.clientRate &&
                      `${round(mission?.billing?.clientRate * (mission?.billing?.estimatedDays || 0))} €`
                    }
                  />
                )}
                {mission?.billing?.type === EBillingType.DAY && (
                  <TableData
                    label="Montant commande fournisseur"
                    value={
                      mission?.billing?.contractorRate &&
                      `${round(mission?.billing?.contractorRate * (mission?.billing?.estimatedDays || 0))} €`
                    }
                  />
                )}
                <TableData
                  label="Frais activé"
                  value={mission?.expensesConfig?.isEnabled ? 'Oui' : 'Non'}
                />
                {mission?.expensesConfig?.isEnabled &&
                  mission?.expensesConfig?.saleBudget &&
                  mission?.expensesConfig?.saleBudget > 0 && (
                    <TableData
                      label="Montant HT enveloppe de frais client"
                      value={mission?.expensesConfig?.saleBudget + '€'}
                    />
                  )}
                {mission?.expensesConfig?.isEnabled &&
                  mission?.expensesConfig?.saleBudget &&
                  mission?.expensesConfig?.saleBudget > 0 && (
                    <TableData
                      label="Montant HT enveloppe de frais fournisseur"
                      value={mission?.expensesConfig?.purchaseBudget + '€'}
                    />
                  )}
                {mission?.expensesConfig?.isEnabled &&
                  mission?.expensesConfig?.saleBudget &&
                  mission?.expensesConfig?.saleBudget > 0 &&
                  mission?.expensesConfig?.marginType && (
                    <TableData
                      label="Type de Taux"
                      value={
                        MISSION_MARGIN_TYPE?.[
                          mission?.expensesConfig?.marginType
                        ]
                      }
                    />
                  )}
                {mission?.expensesConfig?.isEnabled &&
                  mission?.expensesConfig?.saleBudget &&
                  mission?.expensesConfig?.saleBudget > 0 &&
                  mission?.expensesConfig?.marginType && (
                    <TableData
                      label="Marge"
                      value={
                        mission?.expensesConfig?.margin &&
                        `${round(mission?.expensesConfig?.margin)} %`
                      }
                    />
                  )}

                <TableData
                  label="Établissement fournisseur"
                  value={`${getEstablishmentName(mission?.provider?.establishment, undefined, true)}`}
                />
                <TableData
                  label="Intervenant"
                  value={
                    mission?.provider?.contractor && (
                      <Box>
                        {getFullName(mission?.provider?.contractor)}{' '}
                        {contractorEmail && (
                          <a href={`mailto: ${contractorEmail}`}>
                            <Link>{contractorEmail}</Link>
                          </a>
                        )}
                      </Box>
                    )
                  }
                />
                {mission?.billing?.type === EBillingType.DAY ? (
                  //TARIF JOUR PERÇU PAR L'INTERVENANT (HT)
                  <TableData
                    label="TJM fournisseur (HT)"
                    value={
                      mission?.billing?.contractorRate &&
                      `${mission?.billing?.contractorRate} €`
                    }
                  />
                ) : (
                  <TableData
                    label="Montant perçu par l'intervenant (HT)"
                    value={
                      mission?.billing?.contractorRate &&
                      `${mission?.billing?.contractorRate} €`
                    }
                  />
                )}
              </tbody>
            </table>
          </Box>
          <Box width={1 / 2} pl={10}>
            <iframe
              src={bdcFile && (bdcFile as any)?.config?.url + '#toolbar=1'}
              title="bdc"
              style={{ width: '100%', height: '100%' }}
            ></iframe>
          </Box>
        </Flex>
      ) : (
        <Box>
          <Text>
            L'une des informations suivantes n'ont pas été renseignées:
          </Text>
          <Box ml={20} mt={20}>
            <ul>
              {!isValideContract && (
                <li>
                  {isMandateMode
                    ? 'Aucun mandat de gestion actif'
                    : 'Aucun contrat-cadre actif'}
                </li>
              )}
              {!mission?.description && <li>Description de la mission</li>}
              {!mission?.internalCommercial && <li>Commercial interne</li>}
              {!isProviderEstablishmentAvailable(
                mission?.provider?.establishment
              ) && <li>Informations de l'établissement fournisseur</li>}
              {!mission?.provider?.contractor && (
                <li>Informations de l'intervenant</li>
              )}
              {!mission?.provider?.contact && (
                <li>Informations du contact fournisseur</li>
              )}
              {!mission?.billing?.type && <li>Type de tarification</li>}
              {!mission?.billing?.clientRate && (
                <li>Tarif Jour Payé par le client</li>
              )}
              {!mission?.billing?.contractorRate && (
                <li>Tarif jour perçu par l’intervenant</li>
              )}
              {REACT_APP_MARKET_REQUIRED === true && !mission?.marketId ? (
                <li>Accord Cadre</li>
              ) : (
                ''
              )}
              {!mission?.customer?.purchaseOrder?.reference && (
                <li>Bon de commande client</li>
              )}
              {!mission?.customer?.billingOptions?.iban && (
                <li>Coordonnées bancaire FREELANCE.COM</li>
              )}
              {!mission?.billingInformation?.structure && (
                <li>Structure de facturation</li>
              )}
              {!mission?.billingInformation?.missionType?.label && (
                <li>Type de mission</li>
              )}
              {/*
               //Aucun BDC fournisseur n’a été envoyé ET
              // (la mission est de type mandat de gestion OU le fournisseur est dispensé de la signature d’un contrat cadre)
              */}
              {!mission?.isMandateMode &&
                (mission?.provider?.establishment?.provider?.isContractRequired
                  ? !checkProviderBdc()
                  : false) && <li>Bon de commande fournisseur</li>}
              {/* {!mission?.isMandateMode && !checkProviderBdc() && (
                <li>Bon de commande fournisseur</li>
              )} */}
              {mission?.customer?.billingOptions?.isAutoBilling &&
                !checkGescomAvalaibleGenerateInvoiceCustomer(mission)
                  ?.ready && (
                  <li>
                    Pour valider la mission avec l'option de génération
                    automatique de la facture client veuillez créer dans la
                    GESCOM l'intervenant , l'établissement fournisseur et
                    l'établissement client rattaché à cette mission.
                  </li>
                )}
            </ul>
          </Box>
        </Box>
      ),
      cancelLabel: 'Annuler',
      isDisabled: !(await checkValidationAvalaible()),
    });
    if (confirm) {
      setLoading(true);
      await missionUpdateOne({
        reference: mission.reference,
        mission: {
          status: EMissionStatus.VALIDATED,
          incPurchaseOrderCounter: false,
          incQuoteCounter: false,
        },
      });
      queryClient.refetchQueries({ queryKey: ['missions'], type: 'active' });
      queryClient.refetchQueries({ queryKey: [mission.reference] });
    }

    setLoading(false);
    return confirm;
  };

  const onReactivateMission = async () => {
    setLoading(true);
    await missionUpdateOne({
      reference: mission.reference,
      mission: {
        status: EMissionStatus.DRAFT,
        incPurchaseOrderCounter: false,
        incQuoteCounter: false,
      },
    });
    queryClient.refetchQueries({ queryKey: ['missions'], type: 'active' });
    queryClient.refetchQueries({ queryKey: [mission.reference] });
    setLoading(false);
  };

  const onDeleteMission = async () => {
    const confirm = await showDialogModal({
      text: `En cas de suppression, cette mission sera définitivement supprimée de Connecteed sans conservation des éléments de cette prestation.`,
      title: 'Êtes-vous sûr(e) de vouloir supprimer cette mission ?',
      cancelLabel: 'Annuler',
      confirmLabel: 'Valider',
    });
    if (confirm) {
      setLoading(true);
      await missionDeleteOne(mission.reference);
      queryClient.refetchQueries({ queryKey: ['missions'], type: 'active' });
    }

    setLoading(false);
    return confirm;
  };

  const onDisableMission = async () => {
    const confirm = await showDialogModal({
      text: `En cas d’annulation, vous pourrez retrouver le détail de cette mission dans l’onglet “Prestations > missions > Annulées"`,
      title: 'Êtes-vous sûr(e) de vouloir annuler cette mission ?',
      cancelLabel: 'Annuler',
      confirmLabel: 'Valider',
    });
    if (confirm) {
      setLoading(true);
      await missionAbort({
        reference: mission.reference,
      });
      queryClient.refetchQueries({ queryKey: ['missions'], type: 'active' });
      queryClient.refetchQueries({ queryKey: [mission.reference] });
    }

    setLoading(false);
    return confirm;
  };

  return {
    onDisableMission,
    onDeleteMission,
    onReactivateMission,
    onValidateMission,
    loading,
  };
}

export const useCreateOrUpdateMission = () => {
  const history = useHistory();
  const { me } = useMe();
  const [loading, setLoading] = useState(false);

  // get current section from url
  const match = useRouteMatch<{ urlReference: string; section: string }>(
    '/delivery/missions/:urlReference/:section'
  );

  const createOrUpdate = useCallback(
    async (reference: string | undefined, mission: IUpdateMissionData) => {
      let shouldRedirect = false;
      // Type Guard
      if (!me?.cognitoUserId) return;

      setLoading(true);

      // if reference is null, this is a creation
      if (!reference) {
        const newMission = await missionCreateOne({
          // @ts-ignore
          mission: {
            accountManager: me?.cognitoUserId,
          },
        });
        shouldRedirect = true;
        reference = newMission.reference;
        await queryClient.refetchQueries({ queryKey: [newMission.reference] });
      }

      // now we allways have a reference to update our mission
      // So we can go on and update

      // Type Guard
      if (!reference) {
        setLoading(false);
        return;
      }

      const updatedMission = await missionUpdateOne({ reference, mission });
      await queryClient.refetchQueries({
        queryKey: [updatedMission.reference],
      });
      setLoading(false);
      if (shouldRedirect)
        history.push(
          `/delivery/missions/${reference}${
            match?.params.section ? '/' + match?.params.section : ''
          }`
        );
      return updatedMission;
    },
    [me]
  );

  return [createOrUpdate, { loading }] as const;
};

export const useGetMission = () => {
  // get ref from url
  const match = useRouteMatch<{ reference: string }>(
    '/delivery/missions/:reference'
  );

  // if ref is "new" then we are dealing with a new mission, and reference in undefined
  const isNew = !match?.params.reference || match?.params.reference === 'new';
  const reference = isNew ? undefined : match?.params.reference;

  const { data: mission, status } = useMissionFindOne(reference);

  return { reference, isNew, mission, loading: status === 'pending', status };
};
