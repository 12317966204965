import styled from 'styled-components';
import { boxProperties, BoxProps } from 'components/ui';

type ContainerTextInformationProps = {
  variant: 'primary' | 'error' | 'warning' | 'success';
};
export const ContainerTextInformation = styled.span<
  BoxProps & ContainerTextInformationProps
>`
  display: inline-flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors[props.variant || 'primary'].default};
  border: 2px solid
    ${props => props.theme.colors[props.variant || 'primary'].default};
  border-radius: ${boxProperties};
  background: ${p =>
    p.variant === 'success'
      ? 'rgba(234,249,242,0.5)'
      : p.variant === 'error'
        ? 'rgba(253,238,239,0.5)'
        : p.variant === 'warning'
          ? 'rgba(253,238,239,0.5)'
          : 'rgba(253,238,239,0.5)'};
  min-height: 60px;
  padding: 20px;
`;
