import { FlatUser, IUserSearchResponse } from '@freelancelabs/teoreme-commons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../';
import { establishmentFindMany } from '../routes';
import { userFindMany, userFindOne, userUpdateOne } from '../routes';

export const useUserFindOne = (id?: string) => {
  return useQuery({
    queryKey: [id, id],
    queryFn: async () => userFindOne(id as string),
    enabled: id ? true : false,
  });
};
export const useUserUpdateOne = () =>
  useMutation<
    FlatUser,
    unknown,
    {
      id: string;
      user?: Partial<FlatUser>;
    }
  >({
    mutationFn: params => userUpdateOne(params.id, params.user),
    onSuccess: data => {
      queryClient.setQueryData([data.cognitoUserId || ''], data);
    },
  });

export const useUserFindMany = (
  variables?: any,
  options?: {
    populateClientEstablishments?: boolean;
    populateProviderEstablishments?: boolean;
  },
  config?: any
): {
  data: any;
  error: any;
  status: any;
  isFetching: boolean;
  isPending: boolean;
} =>
  useQuery<IUserSearchResponse>({
    queryKey: ['users', variables],
    ...config,
    queryFn: async () => {
      const userResponse = await userFindMany(variables);

      const users = userResponse?.users;

      if (options?.populateClientEstablishments && !!users?.length) {
        const establishmentResponse = await establishmentFindMany({
          filterObject: {
            'customer.contacts': { $in: users.map(u => u.cognitoUserId) },
          },
        });

        const establishments = establishmentResponse?.establishments;
        userResponse.users = userResponse?.users?.map(u => ({
          ...u,
          clientEstablishmentsPopulated: establishments?.filter(establishment =>
            establishment?.customer?.contacts?.find(
              c => c.cognitoUserId === u.cognitoUserId
            )
          ),
        }));
      }

      if (options?.populateProviderEstablishments && !!users?.length) {
        const establishmentResponse = await establishmentFindMany({
          filterObject: {
            $or: [
              {
                'provider.contacts.cognitoUserId': {
                  $in: users.map(u => u.cognitoUserId),
                },
              },
              {
                'provider.manager.cognitoUserId': {
                  $in: users.map(u => u.cognitoUserId),
                },
              },
              {
                'provider.tempContacts.cognitoUserId': {
                  $in: users.map(u => u.cognitoUserId),
                },
              },
            ],
          },
        });

        const establishments = establishmentResponse?.establishments;
        userResponse.users = userResponse?.users?.map(u => ({
          ...u,
          providerEstablishmentsPopulated: establishments?.filter(
            establishment =>
              establishment?.provider?.contacts?.find(
                c => c.cognitoUserId === u.cognitoUserId
              ) ||
              u.cognitoUserId ===
                establishment.provider.manager.cognitoUserId ||
              establishment?.provider?.tempContacts?.find(
                // @ts-ignore
                c => c.cognitoUserId === u.cognitoUserId
              )
          ),
        }));
      }

      // preload user cache
      for (const user of userResponse?.users) {
        if (user?.cognitoUserId)
          queryClient.setQueryData([user.cognitoUserId], user);
      }

      return userResponse;
    },
  });
