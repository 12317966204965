import {
  FlatUser,
  IUserGetResponse,
  IUserSearchResponse,
  IUserUpdateResponse,
  IUserUpdateParams,
} from '@freelancelabs/teoreme-commons';
import { IUserWebAccess } from '@freelancelabs/inside-commons/dist/lib/models';
import { AUTH_SERVICE_URL, USER_SERVICE_URL } from '../../constantz';
import { fetcher } from '../helpers/fetcher';
import { transformText } from '../../helpers/transform';

export const userFindOne = (id: string, disableAlert?: boolean) =>
  fetcher<any, IUserGetResponse & IUserWebAccess>(
    USER_SERVICE_URL + `/get?cognitoUserId=${id}`,
    undefined,
    disableAlert
  );

export const userUpdateOne = (
  cognitoUserId: string,
  user?: Partial<FlatUser>,
  disableAlert?: boolean
) =>
  fetcher<IUserUpdateParams, IUserUpdateResponse>(
    USER_SERVICE_URL + `/update`,
    {
      method: 'PUT',
      body: {
        cognitoUserId,
        user: {
          civility: user?.civility,
          firstName: user?.firstName,
          lastName: user?.lastName,
        },
        /*
        onboardingStep?: any;
        phone?: string;
        jobTitle?: string;
        businessUnit?: string;
        alternateEmail?: string;
        accountManager?: string;
        termsOfUseAccepted?: boolean;
        isConfirmed?: boolean;
        deactivated?: boolean;
        deactivatedAt?: Date;
        */
        appScopedData: {
          stopSentInviteEmail: user?.stopSentInviteEmail,
          termsOfUseAccepted: user?.termsOfUseAccepted,
          isConfirmed: user?.isConfirmed,
          accountManager: user?.accountManager,
          onboardingStep: user?.onboardingStep,
          jobTitle:
            user?.jobTitle &&
            transformText(user?.jobTitle, 'capitalize-first').trim(),
          businessUnit:
            user?.businessUnit &&
            transformText(user?.businessUnit, 'capitalize-first').trim(),
          phone: user?.phone,
          alternateEmail: user?.alternateEmail,
          havePortalClientAccess: user?.havePortalClientAccess,
        },
      },
    },
    disableAlert
  );

export const userFindMany = (searchParams: any, disableAlert?: boolean) =>
  fetcher<any, IUserSearchResponse>(
    USER_SERVICE_URL + `/search`,
    {
      method: 'post',
      body: searchParams,
    },
    disableAlert
  );

export const userReSendInvitation = (
  email: string,
  cognitoUserId: string,
  disableAlert?: boolean
) =>
  fetcher<any>(
    AUTH_SERVICE_URL + `/refresh-activation-token`,
    {
      method: 'post',
      body: {
        email,
        cognitoUserId,
      },
    },
    disableAlert
  );

export const deleteUser = (cognitoUserId: string, disableAlert?: boolean) =>
  fetcher<any>(
    USER_SERVICE_URL + `/delete`,
    {
      method: 'post',
      body: {
        cognitoUserId,
      },
    },
    disableAlert
  );
export const changeUserEmail = (
  params: {
    cognitoUserId?: string;
    email?: string;
    action: 'REQUEST_CHANGE' | 'VALIDATE_CHANGE';
    cipher?: string;
  },
  disableAlert?: boolean
) =>
  fetcher<any>(
    USER_SERVICE_URL + `/change-user-email`,
    {
      method: 'post',
      body: params,
    },
    disableAlert
  );
