import {
  MAPBOX_SERVICE_URL,
  THIRD_PARTY_SERVICE_URL,
  GET_HOLIDAYS_API_KEY,
} from '../../constantz';
import { fetcherThirdParty, fetcher } from '../helpers/fetcher';
export type IMapBoxParams = {
  endpoint: 'mapbox.places' | 'mapbox.places-permanent';
  search_text: string;
};
export const searchGeocodingMapBox = (
  body: IMapBoxParams,
  disableAlert?: boolean
) => {
  return fetcherThirdParty<IMapBoxParams, any>(
    MAPBOX_SERVICE_URL.replaceAll('{endpoint}', body.endpoint).replaceAll(
      '{search_text}',
      body.search_text
    ),
    {
      method: 'get',
      body,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    },
    disableAlert
  );
};

export const getBuildFront = (disableAlert?: boolean) =>
  fetcher<any, any>(
    THIRD_PARTY_SERVICE_URL + `/front/get-build-number`,
    undefined,
    disableAlert
  );

export const getHolidays = () => {
  const headers = { 'x-api-key': GET_HOLIDAYS_API_KEY };
  const fetcherParams = { headers };
  return fetcher<any, any>(
    THIRD_PARTY_SERVICE_URL + `/front/get-holidays`,
    fetcherParams
  );
};
