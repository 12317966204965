import { countDiffStates, INVOICE_FOREIGN_SEARCH_FIELDS } from '@commons';
import { EInvoiceStatus, EInvoiceType } from '@freelancelabs/teoreme-commons';
import { create } from 'zustand';

enum sortType {
  ASC = 'asc',
  DESC = 'desc',
}
type MultiValueType = {
  label: string;
  value: string;
};
type State = {
  initialSate: boolean;
  page: number;
  selectedStatus: EInvoiceStatus | 'ALL';
  selectedType: EInvoiceType | 'ALL';
  startDate: Date | null;
  endDate: Date | null;
  order: sortType;
  sortedBy: string;
  searchQuery: string;
  accountManagerId?: string | number | null | undefined;
  commercialSelected?: string;
  structureSelected?: string;
  clientSelected?: string;
  providerSelected?: string;
  multiProviderSelected?: MultiValueType[];
  multiClientSelected?: MultiValueType[];
  contractorSelected?: string;
  invoiceTypeSelected?: string | string[];
  invoiceDate?: Date | null;
  searchInKeys: MultiValueType[];
  locked: boolean | undefined;
  createdFromGescomFlow: boolean | undefined;
  limit: number;
  updateStore: (state: Partial<State>) => void;
  resetStore: (state: Partial<State>) => void;
  getNbActiveFilters: (data: string[]) => number;
};

const initialStates = {
  initialSate: true,
  page: 0,
  selectedStatus: 'ALL' as const,
  selectedType: 'ALL' as const,
  startDate: null,
  endDate: null,
  order: sortType.ASC,
  sortedBy: 'month',
  searchQuery: '',
  accountManagerId: localStorage?.cognitoUserId,
  commercialSelected: undefined,
  structureSelected: undefined,
  providerSelected: undefined,
  clientSelected: undefined,
  multiProviderSelected: undefined,
  multiClientSelected: undefined,
  contractorSelected: undefined,
  invoiceDate: null,
  searchInKeys: [INVOICE_FOREIGN_SEARCH_FIELDS?.[0]],
  locked: undefined,
  createdFromGescomFlow: undefined,
  limit: 20,
};

const store = (set: any, getState: any) => ({
  ...initialStates,
  updateStore: (state: Partial<State>) =>
    set({ initialSate: false, page: 0, ...state }),
  resetStore: (state: Partial<State>) => set({ ...initialStates, ...state }),
  getNbActiveFilters: (additionalFiltersVars: string[]) => {
    const currentStates = getState();
    return countDiffStates(initialStates, currentStates, additionalFiltersVars);
  },
});

export const useCreditNoteCustomerListStore = create<State>(store);
