import {
  IAuthLogInParams,
  IAuthLogInResponse,
  IAuthLogOutParams,
  IAuthLogOutResponse,
  IAuthRegisterUserParams,
  IAuthRegisterUserResponse,
  IAuthConfirmUserParams,
  IAuthConfirmUserResponse,
  IAuthResetPassword,
  IAuthChangePasswordParams,
  IAuthRefreshActivationTokenUnsecureParams,
  IAuthRefreshActivationTokenUnsecureResponse,
  IAuthImpersonateParams,
  IAuthImpersonateResponse,
} from '@freelancelabs/teoreme-commons';
import {
  axiosInstance,
  fetcher,
  fetcherNoBearerToken,
} from '../helpers/fetcher';
import { IUserLocal } from '../types/local/user.local';
import { AxiosResponse } from 'axios';
import { AUTH_SERVICE_URL } from '../../constantz';
import { transformText } from '../../helpers/transform';
import { jwtDecode } from 'jwt-decode';
import { userFindOne } from './user.routes';
import { cleanSession, deleteAllCognitoCookies } from '../helpers';
import {
  HOME_URL,
  REACT_APP_SEED_STAGE_NAME,
  REACT_APP_LOCALHOST_SPACE,
} from '../../constantz';
import { isNotLocal } from '../../helpers';
export const logIn = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) =>
  axiosInstance
    .post<IAuthLogInParams, AxiosResponse<IAuthLogInResponse>>(
      AUTH_SERVICE_URL + '/login',
      {
        email,
        password,
      }
    )
    .then(res => res.data)
    .catch(err => {
      throw err;
    });

export const logout = () =>
  fetcher<IAuthLogOutParams, IAuthLogOutResponse>(AUTH_SERVICE_URL + '/logout');

export const getMe = async () => {
  const userId =
    localStorage.token && (jwtDecode(localStorage.token) as any)?.username;
  const exp = localStorage.token && (jwtDecode(localStorage.token) as any)?.exp;
  if (!userId) throw new Error('Not Logged In');
  try {
    if (exp < Date.now() / 1000) {
      //PERMISSION DENIED
      localStorage.clear();
      deleteAllCognitoCookies();
    } else {
      const user = await userFindOne(userId, true);
      let isProviderApp;
      if (isNotLocal(REACT_APP_SEED_STAGE_NAME)) {
        isProviderApp = !HOME_URL?.includes('manager.connecteed');
      } else {
        isProviderApp = REACT_APP_LOCALHOST_SPACE === 'provider';
      }
      let haveAccessSite = false;
      if (isProviderApp) {
        haveAccessSite = user?.apps?.find(
          app => app?.accessId === 'CONNECTEED_PROVIDER'
        )
          ? true
          : false;
      } else {
        haveAccessSite = user?.apps?.find(
          app => app?.accessId === 'CONNECTEED_MANAGER'
        )
          ? true
          : false;
      }
      if (!haveAccessSite) {
        cleanSession();
      }
      return user;
    }
  } catch (e: any) {
    deleteAllCognitoCookies();
    localStorage.clear();
    // const errorCode = e?.response?.data?.errorCode;
    // if (errorCode === 'TEOR-PERM-002') {
    //   //PERMISSION DENIED
    //   localStorage.clear();
    //   deleteAllCognitoCookies();
    // }
  }
};

export const register = ({
  user,
  roles,
  disableAlert,
}: {
  user: Partial<IUserLocal>;
  roles: string;
  disableAlert?: boolean;
}) =>
  fetcher<IAuthRegisterUserParams, IAuthRegisterUserResponse>(
    AUTH_SERVICE_URL + '/register',
    {
      method: 'POST',
      body: {
        user: {
          email: user.email?.trim(),
          firstName: user.firstName
            ? transformText(user.firstName, 'capitalize').trim()
            : undefined,
          lastName: user.lastName
            ? transformText(user.lastName, 'uppercase').trim()
            : undefined,
          civility: user.civility,
        },

        appScopedData: {
          establishment: user?.establishment ? user.establishment : undefined,
          isConfirmed: false,
          jobTitle: user.jobTitle
            ? transformText(user.jobTitle, 'capitalize-first').trim()
            : undefined,
          phone: user.phone,
          businessUnit: user.businessUnit
            ? transformText(user.businessUnit, 'capitalize-first').trim()
            : undefined,
          roles,
          alternateEmail: user.alternateEmail,
        },
      },
    },
    disableAlert
  );

interface TMPResetPasswordResponse {
  token: string;
}

export const resetPassword = (
  params: IAuthResetPassword,
  disableAlert?: boolean
) =>
  fetcher<IAuthResetPassword, TMPResetPasswordResponse>(
    AUTH_SERVICE_URL + '/reset-password',
    {
      method: 'POST',
      body: params,
    },
    disableAlert
  );

export const confirmPassword = (
  params: IAuthConfirmUserParams,
  disableAlert?: boolean
) =>
  fetcher<IAuthConfirmUserParams, IAuthConfirmUserResponse>(
    AUTH_SERVICE_URL + '/confirm',
    {
      method: 'POST',
      body: params,
    },
    disableAlert
  );

interface TMPIAuthVerifyTokenParams {
  token: string;
}
interface TMPIAuthVerifyTokenResponse {
  email: string;
}
export const verifyToken = (
  params: TMPIAuthVerifyTokenParams,
  disableAlert?: boolean
) =>
  fetcherNoBearerToken<TMPIAuthVerifyTokenParams, TMPIAuthVerifyTokenResponse>(
    AUTH_SERVICE_URL + '/verify-token',
    {
      method: 'POST',
      body: params,
    },
    disableAlert
  );

export const changePassword = (
  params: IAuthChangePasswordParams,
  disableAlert?: boolean
) =>
  fetcher<IAuthChangePasswordParams, string>(
    AUTH_SERVICE_URL + '/change-password',
    {
      method: 'POST',
      body: params,
    },
    disableAlert
  );

export const resendToken = (params: any, disableAlert?: boolean) =>
  fetcherNoBearerToken<
    IAuthRefreshActivationTokenUnsecureParams,
    IAuthRefreshActivationTokenUnsecureResponse
  >(
    AUTH_SERVICE_URL + '/refresh-activation-token-unsecure',
    {
      method: 'POST',
      body: params,
    },
    disableAlert
  );

export const impersonateFindOne = (
  params: IAuthImpersonateParams,
  disableAlert?: boolean
) =>
  fetcher<IAuthImpersonateParams, IAuthImpersonateResponse>(
    AUTH_SERVICE_URL + '/impersonate',
    {
      method: 'POST',
      body: params,
    },
    disableAlert
  );
