import {
  Flex,
  Box,
  Menu,
  MenuItem,
  Text,
  Spinner,
  Divider,
} from 'components/ui';
import {
  TaskLightIcon,
  DownloadIcon,
  TaskIcon,
  DocumentIcon,
  SuccessIcon,
  SendIcon,
  CheckIcon,
  DotsIcon,
} from 'components/ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
//@ts-ignore
import Jump from 'react-reveal/Tada';
import { useGlobalStore } from 'store/global';
import { Theme } from 'styles';
import {
  formatDateAndTimeComment,
  useAdminTaskFindMany,
  setDateTimeToZero,
  onDownloadFile,
} from '@commons';
import { add, sub, getTime } from 'date-fns';
import {
  EExportModel,
  ETaskStatus,
  ETaskType,
  ITask,
} from '@freelancelabs/teoreme-commons';
import { useHistory } from 'react-router-dom';
export type TaskInfoProps = {};

export const TaskInfo: React.FC<
  React.PropsWithChildren<TaskInfoProps>
> = () => {
  const history = useHistory();
  const [isBounce, setIsBounce] = useState(false);
  const intervalRef = useRef();
  const { tasksInProgress } = useGlobalStore();
  const [refetchInterval, setRefetchInterval] = useState<Number | undefined>(
    5000
  );
  const [IsDownloadFile, setIsDownloadFile] = useState<string | false>(false);
  const currentDate = setDateTimeToZero(add(new Date(), { days: 1 }));
  const { data: tasks } = useAdminTaskFindMany(
    {
      filterObject: {
        startedBy: localStorage?.cognitoUserId,
        type: {
          $in: [ETaskType.EXPORT_XLSX /*ETaskType?.PATCH_CUSTOMER_INVOICE*/],
        },
        $or: [
          {
            $and: [
              {
                $expr: {
                  $lte: [{ $toLong: `$createdAt` }, getTime(currentDate)],
                },
              },
              {
                $expr: {
                  $gte: [
                    { $toLong: `$createdAt` },
                    getTime(sub(currentDate, { months: 1 })),
                  ],
                },
              },
            ],
          },
        ],
      },
      limit: 5,
      skip: 0,
      sort: '-createdAt',
    },
    { refetchInterval: refetchInterval }
  );
  const onDeleteLocalExport = async (uuid: string) => {
    setIsDownloadFile(uuid);
    try {
      const task = tasks?.tasks?.find((t: any) => t?.uuid === uuid);
      if (task && task?.output?.file) {
        await onDownloadFile(task?.output?.file);
      }
    } catch (e) {
      setIsDownloadFile(false);
    }
    setIsDownloadFile(false);
  };
  useEffect(() => {
    if (isBounce) {
      //@ts-ignore
      intervalRef.current = setInterval(setIsBounce(false), 2000);
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [isBounce]);
  useEffect(() => {
    setIsBounce(true);
    setRefetchInterval(2000);
  }, [tasksInProgress]);
  React.useEffect(() => {
    if (
      tasks?.tasks?.find(
        (t: any) => t?.status === 'ONGOING' || t?.status === 'PENDING'
      )
    ) {
      setRefetchInterval(2000);
    } else {
      if (refetchInterval) {
        setRefetchInterval(undefined);
      }
    }
  }, [tasks]);
  const getErrorLabel = (task: ITask) => {
    let currentStep = task?.currentStep || 0;
    let currentTaskName = task?.steps?.[currentStep - 1]?.name;
    if (task?.status === ETaskStatus.ERROR) {
      if (task?.output?.noResult) {
        return 'Aucun résultat pour exporter le fichier';
      }
      if (task?.type === ETaskType?.PATCH_CUSTOMER_INVOICE) {
        const missionRef = task?.input?.mission;
        return `Une erreur est survenue étape ${task?.currentStep || '1'} / ${task?.totalSteps || '1'} (${currentTaskName})`;
      }
    }
  };
  const getTaskText = (task: ITask) => {
    switch (task.type) {
      case ETaskType.EXPORT_XLSX:
        if (task?.input?.model === EExportModel.MISSION) {
          return `Export fichier missions
          ${formatDateAndTimeComment(task?.startedAt as Date)}`;
        }
        if (task?.input?.model === EExportModel.CRA) {
          return `Export fichier CRA
          ${formatDateAndTimeComment(task?.startedAt as Date)}`;
        }
        if (task?.input?.model === EExportModel.INVOICE) {
          return `Export fichier Factures
          ${formatDateAndTimeComment(task?.startedAt as Date)}`;
        }
        if (task?.input?.model === EExportModel.ACTIVITY) {
          return `Export fichier activités
          ${formatDateAndTimeComment(task?.startedAt as Date)}`;
        }
        if (task?.input?.model === EExportModel.CONTACT) {
          return `Export fichier contacts
          ${formatDateAndTimeComment(task?.startedAt as Date)}`;
        }
        if (task?.input?.model === EExportModel.ESTABLISHMENT) {
          return `Export fichier Établissements
          ${formatDateAndTimeComment(task?.startedAt as Date)}`;
        }
        return `Export fichier mission
        ${formatDateAndTimeComment(task?.startedAt as Date)}`;
      case ETaskType.PATCH_CUSTOMER_INVOICE:
        return `Création d'un avoir client
          ${formatDateAndTimeComment(task?.startedAt as Date)}`;
    }
  };
  const getLink = (task: ITask) => {
    let missionRef = task?.input?.mission;
    if (task?.type === ETaskType.PATCH_CUSTOMER_INVOICE && missionRef) {
      return `/delivery/missions/${missionRef}/invoice`;
    }
  };
  return (
    <Box>
      <Menu
        align="right"
        openOnClick={false}
        menuItems={
          tasks && tasks?.tasks?.length > 0 ? (
            <Box width={550}>
              {tasks?.tasks?.map((t: any, index: number) => (
                <Box
                  key={t.uuid}
                  cursor={'pointer'}
                  onClick={() => {
                    let link = getLink(t);
                    if (link) {
                      history?.push(link);
                    }
                  }}
                >
                  <MenuItem onClick={() => onDeleteLocalExport(t?.uuid)}>
                    <Flex
                      width={1 / 1}
                      mb={'5px'}
                      display={'inline-flex'}
                      flexWrap={'wrap'}
                      justifyContent={'flex-start'}
                      alignItems={'center'}
                    >
                      <Box mr={10}>
                        {t?.type === ETaskType.EXPORT_XLSX &&
                          t?.status !== ETaskStatus.ERROR && (
                            <DownloadIcon fontSize={22} />
                          )}
                        {t?.type === ETaskType.PATCH_CUSTOMER_INVOICE && (
                          <DocumentIcon />
                        )}
                      </Box>

                      <Text variant="h3">{getTaskText(t)}</Text>
                      {IsDownloadFile === t?.uuid && (
                        <Box ml={10}>
                          <Spinner />
                        </Box>
                      )}
                      <Box width={1 / 1}>
                        <ProgressBar
                          label={getErrorLabel(t)}
                          variant={
                            t?.status === ETaskStatus.ERROR
                              ? 'danger'
                              : t.progress === 100
                                ? 'success'
                                : 'primary'
                          }
                          animated={
                            t?.status !== ETaskStatus.ERROR &&
                            t.progress !== 100
                          }
                          now={
                            t?.status === ETaskStatus.ERROR ? 100 : t.progress
                          }
                        />
                      </Box>
                    </Flex>
                  </MenuItem>
                  {index + 1 !== tasks?.tasks?.length && <Divider />}
                </Box>
              ))}
            </Box>
          ) : (
            <MenuItem>
              <Flex
                width={1 / 1}
                flexWrap={'wrap'}
                justifyContent={'center'}
                alignItems={'center'}
                alignContent={'center'}
              >
                <TaskIcon width={50} height={50} />
                <Text>Aucune tâche en cours</Text>
              </Flex>
            </MenuItem>
          )
        }
      >
        <Box mt={'5px'}>
          <Jump spy={tasksInProgress}>
            <TaskLightIcon
              fontSize={20}
              fill={Theme?.colors?.grey?.default}
              onClick={() => setIsBounce(!isBounce)}
            />
          </Jump>
        </Box>
      </Menu>
    </Box>
  );
};
