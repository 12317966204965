import {
  IContactProvider,
  IContactSendEmailWithAttachmentParams,
} from '@freelancelabs/teoreme-commons';
import { fetcher } from '../helpers/fetcher';
import { CONTACT_SERVICE_URL } from '../../constantz';

interface TMPIContactProviderResponse {
  message?: string;
}
export const contactUs = (body: IContactProvider, disableAlert?: boolean) =>
  fetcher<IContactProvider, TMPIContactProviderResponse>(
    `${CONTACT_SERVICE_URL}/contact-us`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );

export const sendEmail = (
  body: IContactSendEmailWithAttachmentParams,
  disableAlert?: boolean
) =>
  fetcher<IContactSendEmailWithAttachmentParams, any>(
    `${CONTACT_SERVICE_URL}/send-email-with-attachment`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
