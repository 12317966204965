import { TextArea } from 'components/ui';
import { useController } from 'react-hook-form';
export const TextAreaControlled = (props: any) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name: props?.name,
    control: props?.control,
    rules: props?.rules,
    defaultValue: props?.defaultValue,
  });

  return <TextArea {...inputProps} {...props} inputRef={ref} />;
};
