import {
  missionDeleteOne,
  missionFindMany,
  missionFindOne,
  missionGenerateFile,
  missionSendFile,
  missionUpdateOne,
  missionUpdateServiceOne,
  purchaseOrderFindMany,
} from '../routes';
import {
  IMissionUpdateParams,
  IMissionSendFileParams,
  IMissionGenerateFileParams,
  IMission,
  IMissionGenerateFileResponse,
  IMissionSendFileResponse,
  IMissionUpdateServiceParams,
  IMissionUpdateServiceResponse,
} from '@freelancelabs/teoreme-commons';
import { useMutation, useQuery, keepPreviousData } from '@tanstack/react-query';
import { queryClient } from '../';

export const useMissionFindMany = (variables?: any) => {
  return useQuery({
    queryKey: variables ? ['missions', variables] : [],
    queryFn: async () => missionFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    placeholderData: keepPreviousData,
  });
};

export const useMissionFindOne = (reference?: string) => {
  return useQuery({
    queryKey: [reference, reference],
    queryFn: async () => missionFindOne(reference as string),
    enabled: reference ? true : false,
    // DISABLE CACHE
    refetchOnMount: false,
    staleTime: 0,
    gcTime: 0,
  });
};

export const useMissionDeleteOne = (ref: string, disableAlert?: boolean) =>
  useMutation<string, unknown, string>({
    mutationFn: () => missionDeleteOne(ref, disableAlert),
    onSuccess: data => {
      queryClient.refetchQueries({ queryKey: ['missions'], type: 'active' });
    },
  });

export const useMissionUpdateOne = (
  params: IMissionUpdateParams,
  disableAlert?: boolean
) =>
  useMutation<IMission, unknown, IMissionUpdateParams>({
    mutationFn: () => missionUpdateOne(params, disableAlert),
    onSuccess: data =>
      queryClient.refetchQueries({ queryKey: [data.reference] }),
  });

export const useMissionGenerateFile = (params: IMissionGenerateFileParams) =>
  useMutation<
    IMissionGenerateFileResponse,
    unknown,
    IMissionGenerateFileParams
  >({ mutationFn: () => missionGenerateFile(params) });
export const useMissionSendFile = (params: IMissionSendFileParams) =>
  useMutation<IMissionSendFileResponse, unknown, IMissionSendFileParams>({
    mutationFn: () => missionSendFile(params),
  });

export const usePurchaseOrderFindMany = (variables?: any) => {
  return useQuery({
    queryKey: ['purchaseOrders', variables],
    queryFn: async () => purchaseOrderFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    placeholderData: keepPreviousData,
  });
};

export const useMissionUpdateServiceOne = (
  params: IMissionUpdateServiceParams,
  disableAlert?: boolean
) =>
  useMutation<
    IMissionUpdateServiceResponse,
    unknown,
    IMissionUpdateServiceParams
  >({
    mutationFn: () => missionUpdateServiceOne(params, disableAlert),
    onSuccess: (data: IMissionUpdateServiceResponse) =>
      queryClient.refetchQueries({ queryKey: [params?.missionRef] }),
    //TODO CHANGE PARAMS . MISSION AFTER
  });
