import { MAINTENANCE_MODE_TYPE } from '../constantz';

export const isProviderMaintenance = (maintenanceMode: any) => {
  const providerMaintenanceTypes = [
    MAINTENANCE_MODE_TYPE.ALL,
    MAINTENANCE_MODE_TYPE.PROVIDER,
  ];
  return providerMaintenanceTypes.includes(maintenanceMode);
};

export const isManagerMaintenance = (maintenanceMode: any) => {
  const providerMaintenanceTypes = [
    MAINTENANCE_MODE_TYPE.ALL,
    MAINTENANCE_MODE_TYPE.MANAGER,
  ];
  return providerMaintenanceTypes.includes(maintenanceMode);
};
