import {
  FR_STATUS,
  checkDefaultManagerSelected,
  getEstablishmentName,
  getFullName,
  transformText,
  useMe,
  useMissionFindMany,
  useUserFindOne,
} from '@commons';
import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import { EMissionStatus, IJoinedMission } from '@freelancelabs/teoreme-commons';
import { StaticSelect } from 'components/selects/StaticSelect';
import { UserSelect } from 'components/selects/UserSelect';
import {
  Box,
  Container,
  DatePickerRange,
  Flex,
  FormLabel,
  Input,
  Itemize,
  SpinnerBox,
  Status,
  Table,
  TableColumn,
  Text,
} from 'components/ui';
import { add, getTime } from 'date-fns';
import { format } from 'date-fns';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useDebounce } from 'hooks/useDebounce';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { MissionActions } from '../../../Delivery/Missions/MissionActions';
type Item = Itemize<IJoinedMission>;

export const MissionsList = () => {
  const { id } = useParams<{ id: string }>();
  const { data: provider } = useUserFindOne(id);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [managerSelected, setManagerSelected] = React.useState<
    string | undefined
  >();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [statusSelected, setStatusSelected] = React.useState<
    string | undefined
  >('N/A');
  const [typeSelected, setTypeSelected] = React.useState<string | undefined>(
    'N/A'
  );
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>();

  useSetCrumbs(
    [provider],
    [
      {
        label: 'fournisseurs',
        path: '/providers',
      },
      {
        label: 'contacts',
        path: '/providers/contacts',
      },
      {
        label: provider ? getFullName(provider) : '-',
        path: `/providers/contacts/${id}`,
      },
      {
        label: 'informations',
        path: `/providers/contacts/${id}/information`,
      },
    ]
  );

  const [displayDate, setDisplayDate] = React.useState<
    'createdAt' | 'validatedAt' | 'abortedAt' | 'startAt'
  >('startAt');

  const [order, setOrder] = React.useState<'desc' | 'asc'>('asc');

  const [sortedBy, setSortedBy] = React.useState<string>(displayDate);

  React.useEffect(() => {
    setDisplayDate('startAt');
    setOrder('desc');
    setSortedBy('startAt');
    setPage(0);
  }, [setDisplayDate]);

  const [page, setPage] = React.useState(0);
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const { me } = useMe();
  const {
    data: missionsQuery,
    status,
    isFetching,
  } = useMissionFindMany({
    filterObject: {
      'provider.contact': id,
      parent:
        typeSelected === 'Mission'
          ? { $exists: false }
          : typeSelected === 'Amendment'
            ? { $exists: true }
            : undefined,
      //status: filter.toUpperCase(),
      'customer.purchaseOrder.status':
        statusSelected !== 'N/A' ? statusSelected : undefined,
      accountManager: checkDefaultManagerSelected(me, managerSelected)
        ? managerSelected
        : undefined,
      $or: debouncedFilterQuery
        ? [
            {
              title: {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'sage.missionId': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // customer
            {
              'customer.establishment.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'customer.establishment.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // provider
            {
              'provider.establishment.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.establishment.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.contractor.firstName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'provider.contractor.lastName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
          ]
        : undefined,
      $and:
        startDate && endDate
          ? [
              {
                $expr: {
                  $gte: [{ $toLong: `$${displayDate}` }, getTime(startDate)],
                },
              },
              {
                $expr: {
                  $lte: [
                    { $toLong: `$${displayDate}` },
                    getTime(add(endDate, { months: 1 })),
                  ],
                },
              },
            ]
          : undefined,
    },
    limit: 20,
    skip: 20 * page,
    sort: sortString,
  });
  const totalCount = missionsQuery?.totalCount;
  const loading = status === 'pending';

  const items: Item[] =
    missionsQuery?.missions?.map(m => ({
      ...m,
      key: m.reference,
    })) || [];

  const onSortedChange = React.useCallback(
    (sortBy: string, order: 'asc' | 'desc'): void => {
      setSortedBy(sortBy);
      setOrder(order);
    },
    []
  );

  const columns: TableColumn<Item>[] = [
    {
      key: 'createdAt',
      text: 'DATE',
      sortKey: displayDate,
      render: item =>
        // @ts-ignore
        !!item[displayDate] && format(item[displayDate], 'dd/MM/yyyy'),
    },
    {
      key: 'status',
      text: 'Statut',
      sortKey: 'status',
      render: item => (
        <Status
          variantColor={
            item?.status === EMissionStatus.VALIDATED
              ? 'success'
              : item?.status === EMissionStatus.DRAFT
                ? 'warning'
                : item?.status === EMissionStatus.ARCHIVED
                  ? 'grey'
                  : 'error'
          }
        >
          {FR_STATUS[item?.status]}
        </Status>
      ),
    },
    { key: 'displayReference', text: 'RÉF.', sortKey: 'displayReference' },
    {
      key: 'sage',
      text: 'STRUCT.',
      sortKey: 'sage',
      render: item => {
        if (item.sage) {
          return item.sage.structure;
        } else {
          return 'N/A';
        }
      },
      //hidden: filter === 'validated' || filter === 'aborted' ? false : true,
    },
    {
      key: 'parent',
      text: 'TYPE',
      render: item => (item.parent ? 'Avenant' : 'Mission'),
    },
    {
      key: 'customerEstablishment',
      text: 'ÉTABLIS.CLIENT',
      sortKey: 'customer.establishment.businessName',
      render: item =>
        transformText(
          getEstablishmentName(item.customer.establishment),
          'capitalize'
        ),
    },
    {
      key: 'providerEstablishment',
      text: 'ÉTABLIS.FOURN.',
      render: item =>
        transformText(
          getEstablishmentName(item.provider.establishment),
          'capitalize'
        ),
    },
    {
      key: 'contractor',
      text: 'INTERVENANT',
      render: item => getFullName(item.provider.contractor),
    },
    {
      key: 'accountManagerName',
      text: 'SUIVI PAR',
      render: item => getFullName(item.accountManager),
    },
    {
      align: 'flex-end',
      key: 'actions',
      text: 'ACTIONS',
      render: item => <MissionActions mission={item} />,
    },
  ];
  React.useEffect(() => {
    setPage(0);
  }, [typeSelected, managerSelected]);
  return (
    <Box>
      <Text variant="h2" mb={20}>
        Missions
      </Text>
      {loading && <SpinnerBox />}
      {!loading && (
        <Container p="20px 0">
          <Flex justifyContent="space-between" ml={20} mr={20} mb={10}>
            <Box width={3 / 12} mr={10}>
              <FormLabel>RECHERCHER</FormLabel>
              <Input
                isFullWidth
                type="search"
                onChange={e => {
                  //@ts-ignore
                  setSearchQuery(e.target.value);
                }}
              />
            </Box>
            <Box width={3 / 12} mr={10}>
              <FormLabel>Type de mission</FormLabel>
              <StaticSelect
                placeholder="Type de mission"
                options={[
                  {
                    label: 'Tous les statuts',
                    value: 'N/A',
                    key: 'N/A',
                  },
                  {
                    label: 'Mission',
                    value: 'Mission',
                    key: 'Mission',
                  },
                  {
                    label: 'Avenant',
                    value: 'Amendment',
                    key: 'Amendment',
                  },
                ]}
                onChange={value => setTypeSelected(value as string)}
                value={typeSelected}
              />
            </Box>
            {/* <Box width={2 / 10} mr={10}>
              <FormLabel>Statut BDC client</FormLabel>
              <StaticSelect
                placeholder="Tous les Chargés de comptes"
                options={PURCHASE_ORDER_CLIENT_STATUS_SELECT}
                onChange={value => setStatusSelected(value as string)}
                value={statusSelected}
              />
            </Box> */}
            <Box width={3 / 12} mr={10}>
              <FormLabel>Chargés de comptes</FormLabel>
              <UserSelect
                placeholder="Tous les Chargés de comptes"
                role={RolesTypes.ACCOUNT_MANAGER}
                onChange={value => setManagerSelected(value)}
                value={managerSelected}
              />
            </Box>
            <Box width={3 / 12} mr={10}>
              <FormLabel pl={20}>PÉRIODE</FormLabel>
              <DatePickerRange
                startDate={startDate}
                endDate={endDate}
                isClearable={true}
                setStartDate={value => {
                  setStartDate(value as Date);
                  setPage(0);
                }}
                setEndDate={value => {
                  setEndDate(value as Date);
                  setPage(0);
                }}
              />
            </Box>
          </Flex>
          {items?.length > 0 ? (
            <Table<Item>
              columns={columns}
              items={items}
              sortedBy={sortedBy}
              order={order}
              page={page}
              total={totalCount}
              perPage={totalCount && totalCount < 20 ? totalCount : 20}
              onSortedChange={onSortedChange}
              onChangePage={page => setPage(page)}
              opacity={1}
              isLoading={isFetching}
            />
          ) : (
            <Flex
              display={'inline-flex'}
              flexWrap={'wrap'}
              justifyContent={'center'}
              width={1 / 1}
              mt={20}
            >
              <Text>Aucune mission</Text>
            </Flex>
          )}
        </Container>
      )}
    </Box>
  );
};
