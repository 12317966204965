import { useQuery } from '@tanstack/react-query';
import { getHolidays } from '../routes';
import { IHolidays } from '@freelancelabs/teoreme-commons';

export const useGetHolidays = (config?: any): { data: IHolidays[] } => {
  return useQuery({
    queryKey: ['holidays'],
    queryFn: async () => getHolidays(),
    // // DISABLE CACHE
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 5,
    retryDelay: 30 * 1000, // 30 secs,
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    gcTime: 24 * 60 * 60 * 1000, // 24 hours,
    ...config,
  });
};
