import * as React from 'react';

function NotFoundIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="400"
      height="400"
      viewBox="0 0 400 400"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#f1dcc7" fillRule="nonzero">
          <path d="M378.338 210.329c-.478-36.042-31.124-64.775-61.254-81.419-29.877-16.485-63.788-20.364-97.314-18.47-21.705 1.23-43.543 4.104-63.908 11.704-20.365 7.6-39.283 20.272-51.569 38.174-12.285 17.902-17.247 41.365-10.375 61.954 8.24 24.655 31.284 41.299 54.621 52.832 33.075 16.339 69.52 25.846 106.362 27.766 31.058 1.615 64.027-2.78 88.783-21.583 21.758-16.525 35.012-43.668 34.654-70.958z" />
          <path d="M59.295 201.293c-14.913 7.882-29.774 18.734-35.082 34.791-7.204 21.77 6.41 46.216 25.887 58.239 19.479 12.023 43.27 14.3 66.118 14.926 27.12.745 55.436-.68 79.28-13.674 23.843-12.996 41.742-40.557 35.652-67.093-3.198-13.927-12.446-25.857-23.512-34.858-21.946-17.842-36.104-17.895-62.362-16.111-30.027 2.05-59.284 9.667-85.98 23.78z" />
        </g>
        <g stroke="#004269">
          <g fill="#F24750" fillRule="nonzero">
            <path d="M175.954 177.264l-8.965 8.952-8.965-8.952a4 4 0 0 0-5.652 0l-.233.233a4 4 0 0 0 0 5.661l8.955 8.942-8.956 8.943a4 4 0 0 0 0 5.661l.234.233a4 4 0 0 0 5.652 0l8.965-8.952 8.965 8.952a4 4 0 0 0 5.652 0l.234-.233a4 4 0 0 0 0-5.661l-8.955-8.943 8.955-8.942a4 4 0 0 0 0-5.661l-.234-.233a4 4 0 0 0-5.652 0zM220.391 206.937l8.965-8.952 8.965 8.952a4 4 0 0 0 5.652 0l.234-.233a4 4 0 0 0 0-5.661l-8.955-8.943 8.955-8.942a4 4 0 0 0 0-5.661l-.234-.233a4 4 0 0 0-5.652 0l-8.965 8.952-8.965-8.952a4 4 0 0 0-5.652 0l-.233.233a4 4 0 0 0 0 5.661l8.955 8.942-8.956 8.943a4 4 0 0 0 0 5.661l.234.233a4 4 0 0 0 5.652 0zM167.015 250.354l.246.246a4 4 0 0 0 5.663 0l1.043-1.045c14.664-14.659 38.402-14.659 53.066 0l1.043 1.045a4 4 0 0 0 5.663 0l.246-.246a4 4 0 0 0 0-5.65l-1.056-1.058c-17.922-17.922-46.936-17.922-64.858 0l-1.056 1.058a4 4 0 0 0 0 5.65z" />
          </g>
          <path d="M168.446 125.413V75H284a4 4 0 0 1 4 4v242a4 4 0 0 1-4 4H117a4 4 0 0 1-4-4V129.413h51.446a4 4 0 0 0 4-4z" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M113 128.903L167.92 75"
          />
        </g>
      </g>
    </svg>
  );
}

export default NotFoundIcon;
