import {
  FlatUser,
  IJoinedCraWithInvoice,
} from '@freelancelabs/teoreme-commons';
import { Container, Box, Flex, Text } from 'components/ui';
import { Stepper } from 'components/Stepper';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';

import { usePatchValidatedCraStore } from 'store';
import { StepOne, StepTwo, StepTree } from 'forms/cra/PatchCraValidated';
//@ts-ignore
import jsonQ from 'jsonq';
import { LockIcon } from 'components/ui/icons';
type Props = ModalProps & {
  beforeValidation?: () => void;
  cra: IJoinedCraWithInvoice;
};

export const PatchCraValidated = ({ onResolve, isOpen, cra }: Props) => {
  const {
    updatePatchValidatedCraStore,
    resetPatchValidatedCraStore,
    step,
    steps,
  } = usePatchValidatedCraStore();
  const handleSubmitStepOne = (data: any) => {
    const updatedStep = jsonQ.clone(steps);
    updatedStep[0].data = data;
    updatedStep[0].validated = true;

    updatePatchValidatedCraStore({ steps: updatedStep, step: step + 1 });
  };
  const handleSubmitStepTwo = (data: any) => {
    const updatedStep = jsonQ.clone(steps);
    updatedStep[1].data = {
      ...updatedStep?.[1]?.data,
      ...data,
    };
    if (data?.tutorialFinish === true) {
      updatePatchValidatedCraStore({ steps: updatedStep });
    } else {
      updatedStep[1].validated = true;
      updatePatchValidatedCraStore({ steps: updatedStep, step: step + 1 });
    }
  };
  const handleSubmitStepTree = (data: any) => {
    const updatedStep = jsonQ.clone(steps);
    updatedStep[2].data = data;
    updatedStep[2].validated = true;
    updatePatchValidatedCraStore({ steps: updatedStep });
  };
  const handleStepChange = (nextStep: number, currentStep: number) => {
    // IMUTABLE FIX  ! CLONE 1 LEVEL OBJECT !!
    const updatedStep = jsonQ.clone(steps);
    if (currentStep === 1 || currentStep === 2) {
      const invoiceFileForm = jsonQ.clone(steps[1]?.data?.invoiceFileForm);
      const proofFile = jsonQ.clone(steps[1]?.data?.proofFile);
      updatedStep[1].data.invoiceFileForm = invoiceFileForm;
      updatedStep[1].data.proofFile = proofFile;
    }
    if (currentStep === 1) {
      updatedStep[1].data.tutorialFinish = false;
    }
    updatedStep[currentStep].validated = false;
    updatePatchValidatedCraStore({ step: nextStep, steps: updatedStep });
  };
  const findInvoiceLock =
    //@ts-ignore
    cra?.invoices?.provider?.locked || cra?.invoices?.customer?.locked;
  return (
    <ModalFrame
      fullScreen
      isOpen={isOpen}
      onClose={async () => {
        await resetPatchValidatedCraStore({});
        onResolve(true);
      }}
      closeIcon
    >
      <Stepper
        steps={steps}
        currentStep={step}
        onSelectStep={value => handleStepChange(value?.index, step)}
      />
      {findInvoiceLock === true ? (
        <Container>
          <Box flex={1} overflowY={'scroll'} maxHeight={'calc(100vh - 90px)'}>
            <Flex
              justifyContent="center"
              alignItems={'center'}
              height={'calc(100vh - 90px)'}
            >
              <Box mr={20}>
                <LockIcon fontSize={50} />
              </Box>
              <Text variant="h2">
                Impossible de corriger le CRA car il est verrouillé
              </Text>
            </Flex>
          </Box>
        </Container>
      ) : (
        <Container>
          {step === 0 && (
            <Box flex={1} overflowY={'scroll'} maxHeight={'calc(100vh - 90px)'}>
              <StepOne
                cra={cra}
                dataStep={steps?.[step]?.data}
                steps={steps}
                onSubmit={handleSubmitStepOne}
              />
            </Box>
          )}
          {step === 1 && (
            <Box flex={1} overflowY={'scroll'} maxHeight={'calc(100vh - 90px)'}>
              <StepTwo
                cra={cra}
                dataStep={steps?.[step]?.data}
                steps={steps}
                onSubmit={handleSubmitStepTwo}
                onChangeStep={() => handleStepChange(step - 1, step)}
              />
            </Box>
          )}
          {step === 2 && (
            <Box flex={1} overflowY={'scroll'} maxHeight={'calc(100vh - 90px)'}>
              <StepTree
                cra={cra}
                dataStep={steps?.[step]?.data}
                steps={steps}
                onSubmit={handleSubmitStepTree}
                onChangeStep={() => handleStepChange(step - 1, step)}
                onClose={() => {
                  onResolve();
                  resetPatchValidatedCraStore({});
                }}
              />
            </Box>
          )}
        </Container>
      )}
    </ModalFrame>
  );
};

export const showPatchCraValidatedModal = create<Props, FlatUser>(
  PatchCraValidated
);
