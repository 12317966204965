import {
  IFilesPresignedUrlDownloadResponse,
  IFilesPresignedUrlUploadParams,
  IFilesPresignedUrlUploadResponse,
  IFilesExportXlsxParams,
  IFilesExportXlsxResponse,
  IFilesExtractVerifyRibResponse,
  IFilesExtractVerifyRibParams,
} from '@freelancelabs/teoreme-commons';
import { fetcher, fetcherNoBearerToken } from '../helpers/fetcher';
import Axios from 'axios';
import { FILE_SERVICE_URL } from '../../constantz';

export const downloadFile = async (fileLocation: string) => {
  const fileLocationURI = encodeURIComponent(fileLocation);
  try {
    const presignedResponse = await fetcher<
      any,
      IFilesPresignedUrlDownloadResponse
    >(
      `${FILE_SERVICE_URL}/presigned-url?actionType=download&fileLocation=${fileLocationURI}`
    );
    // @ts-ignore
    const file = await Axios(presignedResponse?.url, {
      responseType: 'blob',
    });
    return file;
  } catch (err) {
    console.log(err);
  }
};
export const unsecuredDownloadFile = async (token: string) => {
  const fileLocationURI = encodeURIComponent(token);
  try {
    const presignedResponse = await fetcherNoBearerToken<
      any,
      IFilesPresignedUrlDownloadResponse
    >(
      `${FILE_SERVICE_URL}/presigned-url-unsecured?fileLocation=${fileLocationURI}`
    );
    // @ts-ignore
    const file = await Axios(presignedResponse?.url, {
      responseType: 'blob',
    });
    return file;
  } catch (err) {
    console.log(err);
  }
};

export const uploadFile = async ({
  file,
  ...params
}: IFilesPresignedUrlUploadParams & { file: File }) => {
  const query = Object.entries(params)
    .map(([key, value]) => key + '=' + value)
    .join('&');

  try {
    const presignedResponse = await fetcher<
      any,
      IFilesPresignedUrlUploadResponse
    >(`${FILE_SERVICE_URL}/presigned-url?${query}`);

    // @ts-ignore
    const uploadServiceFields = presignedResponse.fields;
    const formData = new FormData();

    for (const index in uploadServiceFields) {
      formData.append(index, uploadServiceFields[index]);
    }
    file?.name?.replaceAll(' ', '_');
    formData.append('file', file);

    // @ts-ignore
    const s3Response = await Axios.post(presignedResponse.url, formData);

    return {
      ...presignedResponse,
      eTag: s3Response.headers.etag.split('"').join(''),
    };
  } catch (err) {
    console.log(err);
  }
};

export const exportFileXlxs = (
  body: IFilesExportXlsxParams,
  disableAlert?: boolean
) =>
  fetcher<IFilesExportXlsxParams, IFilesExportXlsxResponse>(
    `${FILE_SERVICE_URL}/export/xlsx`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
export const extractVerifyRib = (
  body: IFilesExtractVerifyRibParams,
  disableAlert?: boolean
) =>
  fetcher<IFilesExtractVerifyRibParams, IFilesExtractVerifyRibResponse>(
    `${FILE_SERVICE_URL}/extract-verify-rib`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
