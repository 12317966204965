import {
  EXPENSES_TYPE_TRANSLATE_FR,
  FR_STATUS,
  STAND_BY_DUTY_UNIT_TRANSLATE_FR,
  buildOneLineAddress,
  getDefaultInvoiceAdress,
  getEstablishmentName,
  getFormulaDutyFormulaAmount,
  getFullName,
  isAdditionalActivityInvoice,
  isDisableFONEDate,
  isExpenseInvoice,
  isMileStoneInvoice,
  isStandByDutyInvoice,
  round,
  useAdditionalActivityFindOne,
  useMissionFindOne,
} from '@commons';
import {
  EChangeOwnerType,
  EInvoicePaymentLabel,
  EInvoiceStatus,
  EInvoiceType,
  EMissionStructure,
  EPaymentMethod,
  EStandByDutyUnits,
  IEstablishment,
  IJoinedInvoice,
  IStandByDutyFormulaLine,
} from '@freelancelabs/teoreme-commons';
import Accordion from 'components/Accordion';
import { Section } from 'components/Section';
import { HistoryList } from 'components/lists/HistoryList';
import { EditTimeSpentModal } from 'components/modals/invoices/EditTimeSpentModal';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  Input,
  LabelField,
  StaticSelectControlled,
  Tab,
  Text,
  Table,
  CheckSwitch,
} from 'components/ui';
import { EuroIcon, ExclamationCircleIcon, LockIcon } from 'components/ui/icons';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { columns } from '../HistoryListData';
import { InvoiceLinesForm } from '../InvoiceLinesForm';
import { Theme } from 'styles';

type FormValues = IJoinedInvoice;
type EditTimeSpentFormProps = {
  invoice?: IJoinedInvoice;
};

export const EditTimeSpentFormFromGescom = ({
  invoice,
}: EditTimeSpentFormProps) => {
  const [formSelected, setFormSelected] = useState<'INFORMATIONS' | 'HISTORY'>(
    'INFORMATIONS'
  );
  const t = useTranslationFormat();
  const { setValue, watch, control, register, handleSubmit } =
    useForm<FormValues>({
      defaultValues: {
        ...invoice,
        totalBeforeTaxes: invoice?.totalBeforeTaxes
          ? Number(invoice?.totalBeforeTaxes?.toFixed(2))
          : 0,
        totalWithTaxes: invoice?.totalWithTaxes
          ? Number(invoice?.totalWithTaxes?.toFixed(2))
          : 0,
        vat: invoice?.vat ? Number(invoice?.vat?.toFixed(2)) : 0,
      },
    });
  const { data: activity } = useAdditionalActivityFindOne(
    invoice?.additionalActivity
  );
  const { data: mission } = useMissionFindOne(invoice?.mission?.reference);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const isEditable =
    invoice?.status === EInvoiceStatus.REJECTED ||
    invoice?.status === EInvoiceStatus.TO_BE_VALIDATED;

  const onSubmit = async (formValues: FormValues) => {
    setIsLoading(true);

    setIsLoading(false);
  };
  const copyOfReceivedSageInvoice = invoice?.sage?.copyOfReceivedSageInvoice;
  const isVat = watch('isVat');
  const vat = watch('vat');
  const totalBeforeTaxes = watch('totalBeforeTaxes');
  const deductionAmount = watch('deductionAmount');
  const isMarginInvoice = invoice?.isMarginInvoice ? ' (marge)' : '';
  const getRate = () => {
    if (invoice?.mission?.isMandateMode) {
      if (invoice?.isMarginInvoice) {
        return invoice?.clientRate || 0;
      } else {
        return invoice?.contractorRate || 0;
      }
    } else {
      return invoice?.clientRate || 0;
    }
  };

  const gescomActionTexte =
    copyOfReceivedSageInvoice?.action === 'CREATE'
      ? 'Créée  depuis GESCOM'
      : 'Modifiée  depuis GESCOM';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditTimeSpentModal
        invoiceType="CUSTOMER"
        isEditable={false}
        isLoading={isLoading}
        // title={`Facture client ${t(
        //   `texts:INVOICETYPE_${invoice?.invoiceType}`
        // )} ${isMarginInvoice}`}
        title={
          <>
            <Flex justifyContent="center">
              <Text variant="h1">{`Facture client ${t(
                `texts:INVOICETYPE_${invoice?.invoiceType}`
              )} ${isMarginInvoice}`}</Text>
            </Flex>
            <Flex justifyContent="center" alignItems={'center'}>
              <LockIcon fontSize={20} />
              <Text ml={'5px'} variant="h2">
                {gescomActionTexte}
              </Text>
            </Flex>
          </>
        }
        onReject={() => false}
        onClose={() => {
          history?.action === 'PUSH'
            ? history?.goBack()
            : history.push('/orders/invoices/customer/time-spent/projected');
        }}
      >
        <Box pl={'20px'} pr={'20px'} pt={'20px'}>
          <Flex mb={10}>
            <Tab
              onClick={() => setFormSelected('INFORMATIONS')}
              isSelected={formSelected === 'INFORMATIONS'}
              variant="secondary"
              fontSize={12}
            >
              Informations
            </Tab>
            <Tab
              onClick={() => setFormSelected('HISTORY')}
              isSelected={formSelected === 'HISTORY'}
              variant="secondary"
              fontSize={12}
            >
              Historique
            </Tab>
          </Flex>
          {formSelected === 'HISTORY' && (
            <Flex
              flexWrap={'wrap'}
              overflow={'hidden'}
              height={'calc(100vh - 90px)'}
            >
              <Box
                width={3 / 5}
                flex={1}
                overflow={'scroll'}
                height={'calc(100vh - 180px)'}
                pl={'20px'}
                pr={'20px'}
                pt={'20px'}
              >
                <HistoryList
                  columns={columns}
                  filterObject={{
                    ownerId: invoice?.uuid,
                    ownerType: EChangeOwnerType?.INVOICE,
                  }}
                />
              </Box>
            </Flex>
          )}
          {formSelected === 'INFORMATIONS' && (
            <>
              <Section mt={20} variant="h2" title={'1. Établissement Client'}>
                <Box>
                  <Flex
                    display={'inline-flex'}
                    justifyContent={'flex-start'}
                    flexWrap={'wrap'}
                    width={1 / 1}
                  >
                    <Box width={3 / 12} pr={10}>
                      <FormControl label="Référence client">
                        <Input
                          isFullWidth
                          isDisabled
                          value={
                            copyOfReceivedSageInvoice?.document
                              ?.customerReference
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box width={3 / 12} pr={10}>
                      <FormControl label="N° facture Gescom">
                        <Input
                          isFullWidth
                          isDisabled
                          value={
                            copyOfReceivedSageInvoice?.document?.documentId
                          }
                        />
                      </FormControl>
                    </Box>
                  </Flex>
                  <Flex>
                    <LabelField
                      underline
                      label="Raison sociale"
                      value={getEstablishmentName(
                        invoice?.estCustomer as IEstablishment,
                        80
                      )}
                    />
                    <LabelField
                      underline
                      label="N°Siret"
                      value={invoice?.estCustomer?.siret}
                    />
                    <LabelField
                      underline
                      label="N°TVA"
                      value={invoice?.vatNumber}
                    />
                  </Flex>
                  <Flex>
                    <LabelField
                      underline
                      label="Adresse de facturation"
                      value={buildOneLineAddress(
                        invoice?.invoiceIssue?.invoiceAddress ||
                          invoice?.mission?.customer?.billingOptions
                            ?.invoiceAddress ||
                          getDefaultInvoiceAdress(invoice?.estCustomer) ||
                          invoice?.estCustomer?.address
                      )}
                    />
                    <LabelField
                      underline
                      label="Conditions de règlement"
                      value={
                        <Flex flexWrap="wrap">
                          <Box width={1 / 1}>
                            Mode de règlement :{' '}
                            {FR_STATUS?.[
                              invoice?.estCustomer?.customer?.paymentDetails
                                ?.paymentMethod as EPaymentMethod
                            ] || 'N/A'}
                          </Box>
                          <Box width={1 / 1}>
                            Délai de paiement :{' '}
                            {invoice?.paymentDeadline as number} jour(s){' '}
                            {
                              FR_STATUS?.[
                                invoice?.invoiceIssue
                                  ?.paymentLabel as EInvoicePaymentLabel
                              ]
                            }
                          </Box>
                        </Flex>
                      }
                    />
                  </Flex>
                </Box>
              </Section>
              <Section mt={20} variant="h2" title={'2. Mission'}>
                <Flex>
                  <LabelField
                    underline
                    label="Référence"
                    value={invoice?.mission?.displayReference}
                  />
                  {!isMileStoneInvoice(invoice?.invoiceType as EInvoiceType) &&
                    (invoice?.month ? (
                      <LabelField
                        underline
                        label="Mois"
                        value={`${
                          invoice?.month && invoice?.month?.getUTCMonth() + 1
                        } / ${
                          invoice?.month && invoice?.month?.getUTCFullYear()
                        } `}
                      />
                    ) : (
                      ''
                    ))}
                  <LabelField
                    underline
                    label="Intervenant"
                    value={getFullName(invoice?.contractor)}
                  />
                </Flex>
                {/* <Flex mt={20} flexWrap="wrap" justifyContent="space-between">
                  <FormControl label="Montant HT">
                    <Input
                      isDisabled
                      value={invoice?.totalBeforeTaxes}
                      maxDecimal={2}
                      step={'0.01'}
                      type="number"
                      icon={<EuroIcon />}
                    />
                  </FormControl>
                </Flex> */}
              </Section>
              <Divider />
              <Section mt={20} variant="h2" title={'Lignes de facture'}>
                <Table
                  items={copyOfReceivedSageInvoice?.document?.lines}
                  total={copyOfReceivedSageInvoice?.document?.lines?.length}
                  perPage={copyOfReceivedSageInvoice?.document?.lines?.length}
                  columns={[
                    {
                      key: 'designation',
                      text: 'Designation',
                      render: (item: any) => item?.designation,
                    },
                    {
                      key: 'quantity',
                      text: 'Prix HT unitaire',
                      render: (item: any) => item?.priceRu + '€',
                    },
                    {
                      key: 'quantity',
                      text: 'Quantité',
                      render: (item: any) => item?.quantity,
                    },
                    {
                      key: 'unit',
                      text: 'Unité',
                      render: (item: any) => item?.unit || 'Jour(s)',
                    },
                    {
                      key: 'amount',
                      text: 'total',
                      render: (item: any) => item?.amount + '€',
                    },
                  ]}
                />
              </Section>

              <Flex justifyContent="flex-end">
                <Box width={6 / 10} />
                <Box width={3 / 10}>
                  <FormControl label={'Montant Net (HT)'}>
                    <Input
                      value={invoice?.totalBeforeTaxes}
                      isDisabled
                      isFullWidth
                      type="number"
                      maxDecimal={2}
                      step={'0.01'}
                      icon={<EuroIcon />}
                    />
                  </FormControl>
                </Box>
              </Flex>
              <Divider />
              <Box>
                <Flex mt={20} justifyContent="space-between">
                  <Box width={2 / 10} mt={30}>
                    <CheckSwitch
                      isDisabled={true}
                      id="isVat"
                      {...register('isVat')}
                    >
                      <Text variant="h3">TVA</Text>
                    </CheckSwitch>
                  </Box>
                  <Box width={2 / 10}>
                    <FormControl label={'N° de TVA'}>
                      <Input
                        isDisabled={true}
                        isFullWidth
                        {...register('vatNumber')}
                        maxDecimal={2}
                        step={'0.01'}
                      />
                    </FormControl>
                  </Box>
                  <Box width={2 / 10}>
                    <FormControl required label={'% TVA'}>
                      <StaticSelectControlled
                        isClearable={false}
                        control={control}
                        name="vatRate"
                        isDisabled={true}
                        options={[
                          { value: 20, label: '20%' },
                          { value: 10, label: '10%' },
                          { value: 5.5, label: '5,5%' },
                          { value: 2.1, label: '2,1%' },
                          { value: 0, label: '0%' },
                        ]}
                        placeholder="% TVA"
                      />
                    </FormControl>
                  </Box>
                  <Box width={2 / 10}>
                    <FormControl label={'Montant TVA'}>
                      <Input
                        isDisabled
                        isFullWidth
                        {...register('vat')}
                        maxDecimal={2}
                        step={'0.01'}
                        type="number"
                        icon={<EuroIcon />}
                      />
                    </FormControl>
                  </Box>
                </Flex>
                <Divider />
                <Flex justifyContent="flex-end">
                  <Box width={6 / 10} />
                  <Box width={3 / 10} mb={20}>
                    <FormControl label={'Montant Total TTC'}>
                      <Input
                        isDisabled
                        isFullWidth
                        {...register('totalWithTaxes')}
                        type="number"
                        maxDecimal={2}
                        step={'0.01'}
                        icon={<EuroIcon />}
                        //value={totalWithTaxes}
                      />
                    </FormControl>
                  </Box>
                </Flex>
                <Section mt={20} variant="h2" title={'Commentaire interne'}>
                  <Text variant="p">
                    {invoice?.comment || 'Aucun commentaire'}
                  </Text>
                </Section>
              </Box>
            </>
          )}
        </Box>
      </EditTimeSpentModal>
    </form>
  );
};
