import {
  IInvoice,
  IInvoiceLine,
  EPriceUnit,
  EAdditionalServiceType,
  IAdditionalActivity,
  IJoinedAdditionalActivity,
  EadditionalActivityType,
  IStandByDutyFormulaLine,
  EStandByDutyFormulaVars,
  IStandByDutyLine,
  IJoinedAdditionalActivityWithInvoice,
  IJoinedInvoice,
  EInvoiceType,
  IAdditionalService,
  EMarginType,
  EInvoiceLineType,
} from '@freelancelabs/teoreme-commons';
import { evaluate } from 'mathjs';
import {
  isCreditNoteInvoice,
  isCustomerInvoice,
  isExpenseInvoice,
  isMileStoneInvoice,
  isProviderInvoice,
  isStandByDutyInvoice,
  isTimeSpentInvoice,
} from '.';
import bigDecimal from 'js-big-decimal';

export const labelsUnits = {
  PERCENT_INVOICE: '% du montant ht',
  FLAT_PRICE: ' Montant',
  PRICE_PER_WORKING_DAY: 'Montant par jour travaillé',
  PRICE_PER_DAY: 'Montant par jour',
  PRICE_PER_MONTH: 'Montant par mois',
  PRICE_PER_INVOICE: 'Montant par facture',
  PRICE_PER_YEAR: 'Montant par an',
  ONE_TIME_PRICE: 'Paiement unique',
  ONE_TIME_PERCENT: '% du montant de la facture en paiement unique',
};
export const labelsTypes = {
  PROFESSIONAL_INDEMNITY_INSURANCE: 'Responsabilité Civile Professionnelle',
  FAST_CASH: 'Fast Cash : paiement à {deadline} jours',
  GDPR: 'RGPD',
  MISC: 'Divers',
  HANDLING_FEE: 'Frais de dossier',
  FREE_DAYS: 'Jours gratuits',
  PERFORMANCE: 'PERFORMANCE',
};
/**
 * Round the specified number to the closest number with the number of specified decimals.
 * @param nb The number to round
 * @param nbDecimals (Optional) The number of decimals, default to 2
 */
export const round = (nb: number, nbDecimals = 2): number => {
  return (
    Math.round((nb + Number.EPSILON) * 10 ** nbDecimals) / 10 ** nbDecimals
  );
};
export const getServiceValues = (
  totalAmountHT: number,
  nbWorkingDays: number,
  service: IInvoiceLine,
  invoice: IJoinedInvoice,
  customLabelUnit = labelsUnits
) => {
  const missionService =
    invoice?.mission?.additionalServices?.[
      invoice?.mission?.additionalServices?.findIndex(
        (ms: IAdditionalService) => ms.uuid === service.uuid
      )
    ];
  const paidByProvider = missionService?.paidByProvider;

  if (!paidByProvider && isProviderInvoice(invoice.invoiceType))
    totalAmountHT = 0;

  // for customer invoice where the fast_cash is paid by customer
  // the amount of fastcash is paid based on contractorRate * nbworkingDays
  if (
    !paidByProvider &&
    invoice.invoiceType === EInvoiceType.CUSTOMER &&
    service.type === EAdditionalServiceType.FAST_CASH
  ) {
    const contractorRate = invoice?.contractorRate || 0;
    totalAmountHT = nbWorkingDays * contractorRate;
  }

  const values = {
    labelType: labelsTypes[service.type],
    labelUnit: customLabelUnit[service.unit],
    price: 0,
  };

  switch (service.unit) {
    case EPriceUnit.PERCENT_INVOICE:
      values.price = round((totalAmountHT / 100) * service.price);
      break;
    case EPriceUnit.FLAT_PRICE:
      values.price = service.price;
      break;
    case EPriceUnit.PRICE_PER_INVOICE:
      values.price = service.price;
      break;
    case EPriceUnit.PRICE_PER_MONTH:
      values.price = service.price;
      break;
    case EPriceUnit.PRICE_PER_DAY:
      values.price = service.price;
      break;
    case EPriceUnit.PRICE_PER_WORKING_DAY:
      values.price = service.price * nbWorkingDays;
      break;
    case EPriceUnit.PRICE_PER_YEAR:
      values.price = service.price;
      break;
    case EPriceUnit.ONE_TIME_PRICE:
      values.price = service.price;
      break;
    case EPriceUnit.ONE_TIME_PERCENT:
      values.price = round((totalAmountHT / 100) * service.price);
      break;
  }

  if (service?.amount && service?.amount > 0) values.price *= service?.amount;
  return values;
};

export const getServiceAmount = (
  totalAmountHT: number,
  nbWorkingDays: number,
  invoice: IJoinedInvoice
): number => {
  const invoiceLines: IInvoiceLine[] | undefined = invoice?.invoiceLines;
  let ammount = 0;
  invoiceLines?.forEach(line => {
    ammount =
      ammount +
      getServiceValues(totalAmountHT, nbWorkingDays, line, invoice).price;
  });
  return round(ammount);
};

export const getTotalBeforeTaxesAndServices = (
  totalAmountHT: number,
  nbWorkingDays: number,
  invoice: IJoinedInvoice
): number => {
  return round(
    totalAmountHT - getServiceAmount(totalAmountHT, nbWorkingDays, invoice)
  );
};

export const getInvoiceTvaAmount = (
  totalAmountHT: number,
  nbWorkingDays: number,
  invoice: IJoinedInvoice,
  vatRate: number
): number => {
  const totalBeforeTaxeAndService = getTotalBeforeTaxesAndServices(
    totalAmountHT,
    nbWorkingDays,
    invoice
  );
  return round((totalBeforeTaxeAndService / 100) * vatRate);
};

export const getInvoiceWithTaxeAndDecution = (
  totalAmountHT: number,
  nbWorkingDays: number,
  invoice: IJoinedInvoice,
  vatRate: number,
  deductionAmount: number
): number => {
  const totalBeforeTaxeAndService = getTotalBeforeTaxesAndServices(
    totalAmountHT,
    nbWorkingDays,
    invoice
  );
  const tva = getInvoiceTvaAmount(
    totalAmountHT,
    nbWorkingDays,
    invoice,
    vatRate
  );

  return round(
    totalBeforeTaxeAndService + tva - (deductionAmount ? deductionAmount : 0)
  );
};

export const getFormulaDutyFormulaAmount = (
  standByDuty: {
    customerFormula: string;
    providerFormula: string;
    formulaParameters: any;
  },
  to: 'CUSTOMER' | 'PROVIDER',
  returnError?: any
) => {
  let formulaJs =
    standByDuty?.[to === 'CUSTOMER' ? 'customerFormula' : 'providerFormula'];
  Object?.keys(EStandByDutyFormulaVars)?.forEach(key => {
    formulaJs = formulaJs?.replaceAll(
      `{{${key}}}`,
      standByDuty?.formulaParameters[key]
    );
  });
  try {
    const result = evaluate(formulaJs);
    return result
      ? round(result)
      : returnError !== undefined
        ? returnError
        : 'N/A';
  } catch (e) {
    return returnError !== undefined ? returnError : 'N/A';
  }
};
export const getStandByDutiesTotalAmount = (
  standByDuties:
    | []
    | IStandByDutyLine[]
    | IStandByDutyFormulaLine[]
    | Omit<
        IStandByDutyFormulaLine,
        | 'active'
        | 'unit'
        | 'name'
        | 'description'
        | 'type'
        | 'shouldJoinAttachment'
        | 'createdBy'
        | 'updatedBy'
        | 'validityStart'
        | 'createdAt'
        | 'updatedAt'
      >[],
  to: 'CUSTOMER' | 'PROVIDER',
  returnError?: any
) => {
  let total = 0;
  standByDuties?.forEach((sbd: any) => {
    const rslt = getFormulaDutyFormulaAmount(sbd, to, returnError);
    if (rslt && !isNaN(rslt)) {
      total = total + rslt;
    }
  });
  return round(total);
};
export const getAdditionalActivitiesTotalAmount = (
  additionalActivities:
    | IAdditionalActivity[]
    | IJoinedAdditionalActivity[]
    | IJoinedAdditionalActivityWithInvoice[],
  type: EadditionalActivityType,
  to: 'CUSTOMER' | 'PROVIDER',
  returnError?: any,
  customerMargin?: number
) => {
  let total = 0;

  if (type === EadditionalActivityType?.MILESTONE) {
    // const milestoneAmount = additionalActivities?.[0]?.milestoneAmount || 0;
    // const customerMargin = additionalActivities?.[0]?.customerMargin || 0;
    additionalActivities?.forEach(aa => {
      const marginType = aa?.customerMarginType;
      const amount =
        to === 'PROVIDER'
          ? aa?.milestoneAmount || 0
          : getCustomerMileStoneAmount(
              aa?.milestoneAmount || 0,
              customerMargin !== undefined
                ? customerMargin
                : aa?.customerMargin || 0,
              marginType as EMarginType
            );
      total = total + amount;
    });
  }
  if (type === EadditionalActivityType?.STAND_BY_DUTY) {
    additionalActivities?.forEach(aa => {
      total =
        total +
        getStandByDutiesTotalAmount(
          aa?.standByDutyLines as IStandByDutyLine[],
          to,
          returnError
        );
    });
  }
  if (type === EadditionalActivityType?.EXPENSE) {
    additionalActivities?.forEach(aa =>
      aa?.expenseLines?.forEach(ex => (total = total + ex.amount))
    );
    const customerMargin = additionalActivities?.[0]?.customerMargin;
    //NEED CUSTOMER MARGIN
    if (to === 'CUSTOMER' && customerMargin) {
      const marginRateNotPercent = customerMargin / 100;
      total = total / (1 - marginRateNotPercent);
    }
  }
  return round(total);
};

export const getInvoiceTotalAmount = (
  invoice: IJoinedInvoice | IInvoice,
  to?: 'CUSTOMER' | 'PROVIDER',
  additionalActivity?: IJoinedAdditionalActivity
) => {
  to =
    to || (isCustomerInvoice(invoice?.invoiceType) ? 'CUSTOMER' : 'PROVIDER');
  const invoiceType = invoice.invoiceType;

  if (isTimeSpentInvoice(invoiceType)) {
    const rate =
      to === 'PROVIDER' ? invoice?.contractorRate : invoice.clientRate;
    return (invoice?.nbWorkingDays || 0) * (rate || 0);
  }

  if (isStandByDutyInvoice(invoiceType) && additionalActivity && to) {
    const result = getAdditionalActivitiesTotalAmount(
      [additionalActivity],
      EadditionalActivityType?.STAND_BY_DUTY,
      to
    );
    return isCreditNoteInvoice(invoiceType) ? -result : result;
  }
  if (isExpenseInvoice(invoiceType) && additionalActivity && to) {
    const result = getAdditionalActivitiesTotalAmount(
      [additionalActivity],
      EadditionalActivityType?.EXPENSE,
      to
    );
    return isCreditNoteInvoice(invoiceType) ? -result : result;
  }
  if (isMileStoneInvoice(invoiceType) && additionalActivity && to) {
    const result = getAdditionalActivitiesTotalAmount(
      [additionalActivity],
      EadditionalActivityType?.MILESTONE,
      to,
      false,
      invoice?.isMarginInvoice ? 0 : undefined
    );
    return isCreditNoteInvoice(invoiceType) ? -result : result;
  }
  // if (isAdditionalActivityInvoice(invoiceType) && !additionalActivity) {
  //   console.warn('Error on totalAmount no activity found', additionalActivity);
  // }

  return 0;
};

export const getMarginOrMarkup = (
  sale: number, //clientRate
  purchase: number, //ContractorRate
  marginType: EMarginType, // MARIN: Taux de marque / MARKUP: Taux de marge
  rounded?: boolean // round result
) => {
  let result = 0;
  if (marginType === EMarginType.MARKUP) {
    result = ((sale - purchase) / purchase) * 100;
  }
  if (marginType === EMarginType.MARGIN) {
    result = ((sale - purchase) / sale) * 100;
  }
  if (rounded) {
    return round(result);
  }
  return result;
};

/**
 * Calculate the sale price from the purchase price and the margin rate (taux de marque).
 * @param marginRate The margin rate, in percentage (0 < marginRate < 100)
 * @param purchase The price/rate of the purchase (for a mission, the contractor price or rate)
 * @return number The sale price/rate calculated, rounded to 2 decimals.
 */
export const calculateSalePriceFromMarginRate = (
  marginRate: number,
  purchase: number
): number => {
  // Assume the marginRate is in %, so 0 < marginRate < 100, but we need to express it as a 0 < marginRate < 1 value
  const marginRateNotPercent = marginRate / 100;

  return purchase / (1 - marginRateNotPercent);
};

/**
 * Calculate the sale price from the purchase price and the markup rate (taux de marge)
 * @param markupRate The markup rate, in percentage (0 < markupRate < 100)
 * @param purchase The price/rate of the purchase (for a mission, the contractor price or rate)
 * @return number The sale price/rate calculated, rounded to 2 decimals.
 */
export const calculateSalePriceFromMarkupRate = (
  markupRate: number,
  purchase: number
): number => {
  // Assume the markup rate is in %, so 0 < markupRate < 100, but we need to express it as a 0 < markupRate < 1 value
  const markupRateNotPercent = markupRate / 100;

  return purchase * (1 + markupRateNotPercent);
};
//TODO fix
export const getCustomerMileStoneAmount = (
  milestoneAmount: number, // provider
  customerMargin: number, // %
  marginType: EMarginType, // MARGIN: Taux de marque / MARKUP: Taux de marge
  rounded?: boolean // round result
) => {
  let result = 0;
  if (marginType === EMarginType.MARGIN) {
    result = calculateSalePriceFromMarginRate(customerMargin, milestoneAmount);
  }
  if (marginType === EMarginType.MARKUP) {
    result = calculateSalePriceFromMarkupRate(customerMargin, milestoneAmount);
  }

  if (rounded) {
    return round(result);
  }
  return result;
};

export const calculateClientRateFromMarginAndContractorRate = (
  contractorRate: number,
  margin: number,
  marginType: EMarginType, // MARGIN: Taux de marque / MARKUP: Taux de marge
  rounded?: boolean // round result
) => {
  let result = 0;
  if (marginType === EMarginType.MARGIN) {
    result = contractorRate / (1 - margin / 100);
  }
  if (marginType === EMarginType.MARKUP) {
    result = contractorRate * (1 + margin / 100);
  }
  if (rounded) {
    return round(result);
  }
  return result;
};
export const calculateContractorRateFromMarginAndClientRate = (
  clientRate: number,
  margin: number,
  marginType: EMarginType, // MARGIN: Taux de marque / MARKUP: Taux de marge
  rounded?: boolean // round result
) => {
  let result = 0;
  if (marginType === EMarginType.MARGIN) {
    result = clientRate * (1 - margin / 100);
  }
  if (marginType === EMarginType.MARKUP) {
    result = clientRate / (1 + margin / 100);
  }
  if (rounded) {
    return round(result);
  }
  return result;
};

/**
 * Compares 2 numbers and consider them equal if they are exactly equal, or if they are within the tolerance specified.
 * @param nb1 The first number
 * @param nb2 The second number
 * @param tolerance The tolerance, if the absolute difference of the two number is below the specified value, the
 * numbers will be considered equals. If tolerance is not specified, will use a tolerance of 0, and will thus check
 * exact equality.
 * @return Returns true if nb1 and nb2 are exactly equal, or almost equal within the specified tolerance
 */
export const lenientEq = (
  nb1: number,
  nb2: number,
  tolerance?: number
): boolean => {
  // If the equality can be done directly, return directly
  if (nb1 === nb2) return true;

  // We use the bigDecimal lib to avoid the native inaccuracy of Javascript operations on floats/double
  const delta = new bigDecimal(tolerance || 0);
  const bdNb1 = new bigDecimal(nb1);
  const bdNb2 = new bigDecimal(nb2);

  return (
    Number(bdNb1.subtract(bdNb2).abs().getValue()) <= Number(delta.getValue())
  );
};

/**
 * Compares 2 numbers and consider them lower or equal within the margin of tolerance specified.
 * @param nb1 The first number
 * @param nb2 The second number
 * @param tolerance The tolerance, if omitted, use 0
 * @return Returns true if nb1 is lower or equals to nb2, with a tolerance of the specified number,
 * or 0 if the tolerance is not specified.
 */
export const lenientLte = (nb1: number, nb2: number, tolerance?: number) => {
  const delta = new bigDecimal(tolerance || 0);
  const bdNb1 = new bigDecimal(nb1);
  const bdNb2 = new bigDecimal(nb2);
  const compareResult = bdNb2
    .subtract(bdNb1)
    .compareTo(new bigDecimal(0).subtract(delta));

  return compareResult === 1 || compareResult === 0;
};

/**
 * Compares 2 numbers and consider them greater or equal within the margin of tolerance specified.
 * @param nb1 The first number
 * @param nb2 The second number
 * @param tolerance The tolerance, if omitted, use 0 + Number.EPSILON (to account for floating number inaccuracy)
 * @return Returns true if nb1 is greater or equals to nb2, with a tolerance of the specified number,
 * or 0 if the tolerance is not specified.
 */
export const lenientGte = (nb1: number, nb2: number, tolerance?: number) => {
  const delta = new bigDecimal(tolerance || 0);
  const bdNb1 = new bigDecimal(nb1);
  const bdNb2 = new bigDecimal(nb2);
  const compareResult = bdNb2.subtract(bdNb1).compareTo(delta);

  return compareResult === -1 || compareResult === 0;
};
