import {
  COUNTRY_LIST,
  euCountries,
  eeaCountries,
} from '@freelancelabs/teoreme-commons';
export const COUNTRIES_WORLD_ISO = COUNTRY_LIST;
export const COUNTRIES = COUNTRIES_WORLD_ISO?.map(c => c?.name);
export const COUNTRIES_USE_IBAN = [
  { country: 'Allemagne', code: 'de' },
  { country: 'Autriche', code: 'at' },
  { country: 'Belgique', code: 'be' },
  { country: 'Bulgarie', code: 'bg' },
  { country: 'Chypre', code: 'cy' },
  { country: 'Danemark', code: 'dk' },
  { country: 'Espagne', code: 'es' },
  { country: 'Estonie', code: 'ee' },
  { country: 'Finlande', code: 'fi' },
  { country: 'France', code: 'fr' },
  { country: 'Grande-Bretagne', code: 'gb' },
  { country: 'Grèce', code: 'gr' },
  { country: 'Hongrie', code: 'hu' },
  { country: 'Irlande', code: 'ie' },
  { country: 'Italie', code: 'it' },
  { country: 'Lettonie', code: 'lv' },
  { country: 'Lituanie', code: 'lt' },
  { country: 'Luxembourg', code: 'lu' },
  { country: 'Malte', code: 'mt' },
  { country: 'Pays-Bas', code: 'nl' },
  { country: 'Pologne', code: 'pl' },
  { country: 'Portugal', code: 'pt' },
  { country: 'République Tchèque', code: 'cz' },
  { country: 'Roumanie', code: 'ro' },
  { country: 'Slovaquie', code: 'sk' },
  { country: 'Slovénie', code: 'si' },
  { country: 'Suède', code: 'se' },
  { country: 'Gibraltar', code: 'gi' },
  { country: 'Guadeloupe', code: 'gp' },
  { country: 'Guyane Française', code: 'gf' },
  { country: 'Martinique', code: 'mq' },
  { country: 'Réunion', code: 're' },
  { country: 'Andorre', code: 'ad' },
  { country: 'Bosnie Herzégovine', code: 'ba' },
  { country: 'Croatie', code: 'hr' },
  { country: 'Gröenland', code: 'gl' },
  { country: 'Guernesey', code: 'gg' },
  { country: 'Île Bouvet', code: 'bv' },
  { country: 'Îles Féroé', code: 'fo' },
  { country: 'Île de Man', code: 'im' },
  { country: 'Îles Svalbard et Jan Mayen', code: 'sj' },
  { country: 'Islande', code: 'is' },
  { country: 'Jersey', code: 'je' },
  { country: 'Liechtenstein', code: 'li' },
  { country: 'Macédoine', code: 'mk' },
  { country: 'Maurice', code: 'mu' },
  { country: 'Mayotte', code: 'yt' },
  { country: 'Monaco', code: 'mc' },
  { country: 'Montenegro', code: 'me' },
  { country: 'Norvège', code: 'no' },
  { country: 'Nouvelle Calédonie', code: 'nc' },
  { country: 'Polynésie Française', code: 'pf' },
  { country: 'Saint Marin', code: 'sm' },
  { country: 'Saint Pierre et Miquelon', code: 'pm' },
  { country: 'Serbie', code: 'cs' },
  { country: 'Suisse', code: 'ch' },
  { country: 'Terres australes et antarctiques', code: 'tf' },
  { country: 'Tunisie', code: 'tn' },
  { country: 'Turquie', code: 'tr' },
  { country: 'Vatican', code: 'va' },
  { country: 'Wallis et Futuna', code: 'wf' },
];
export const COUNTRIES_SELECT = COUNTRIES_WORLD_ISO.map(country => {
  return {
    label: country?.name,
    value: country?.name?.toLocaleUpperCase(),
    data: country,
  };
});
// List of the European Union countries, made on February 19th, 2020
// French and English names are available. French names also include variants without the accents.
// This list should be updated when a country join or leave the union
export const EU_COUNTRIES: string[] = euCountries;

// List of the countries part of the European Economic Area (EEA) or "Espace économique européen (EEE)"
export const EEE_COUNTRIES: string[] = eeaCountries;

export const EU_COUNTRIES_SELECT = EU_COUNTRIES.map(country => {
  return { label: country, value: country };
});
export const EEE_COUNTRIES_SELECT = EEE_COUNTRIES.map(country => {
  return { label: country, value: country };
});
