import { NotFoundIcon } from 'components/ui/icons';
import { Container, Flex, Text } from 'components/ui';

export default function Maintenance() {
  return (
    <div className="App">
      <Container>
        <Text
          fontSize={42}
          p={40}
          variant="h1"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontWeight={500}
        >
          {''} MAINTENANCE EN COURS
        </Text>
        <Flex justifyContent={'center'} alignItems="center">
          <NotFoundIcon width={703} height={647} />
        </Flex>

        <Flex justifyContent={'center'} alignItems="center">
          <Text
            fontSize={36}
            p={40}
            variant="h1"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight={500}
          >
            Une opération de maintenance est en cours sur Connecteed.
            <br /> {''}
            La plateforme sera de nouveau disponible prochainement.
            <br /> {''}
          </Text>
        </Flex>
      </Container>

      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Flex
          top={0}
          left={0}
          bottom={0}
          right={0}
          alignItems="center"
          justifyContent="center"
          position="fixed"
        ></Flex>
      </Flex>
    </div>
  );
}
