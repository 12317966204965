import {
  IContractorCreateParams,
  IContractorUpdateParams,
} from '@freelancelabs/teoreme-commons';
import {
  contractorCreateOne,
  contractorFindMany,
  contractorFindOne,
  contractorFindOneByCognitoUserId,
  contractorUpdateOne,
} from '../routes';
import { useMutation, useQuery, keepPreviousData } from '@tanstack/react-query';
import { queryClient } from '../';

export const useContractorFindMany = (variables?: any) => {
  return useQuery({
    queryKey: ['contractors', variables],
    queryFn: async () => contractorFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    placeholderData: keepPreviousData,
  });
};

export const useContractorFindOne = (uuid?: string) => {
  return useQuery({
    queryKey: [uuid, uuid],
    queryFn: async () => contractorFindOne(uuid as string),
    enabled: uuid ? true : false,
    // DISABLE CACHE
    refetchOnMount: false,
    staleTime: 0,
    gcTime: 0,
  });
};
export const useContractorFindOneByCognitoUserId = (cognitoUserId?: string) => {
  return useQuery({
    queryKey: [cognitoUserId, cognitoUserId],
    queryFn: async (...[key, cognitoUserId]: any) =>
      contractorFindOneByCognitoUserId(cognitoUserId),
    enabled: cognitoUserId ? true : false,
    // DISABLE CACHE
    refetchOnMount: false,
    staleTime: 0,
    gcTime: 0,
  });
};

export const useContractorCreateOne = (
  params: IContractorCreateParams,
  disableAlert?: boolean
) => {
  useMutation({
    mutationFn: () => contractorCreateOne(params, disableAlert),
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData([data.uuid], data);
    },
  });
};

export const useContractorUpdateOne = (
  params: IContractorUpdateParams,
  disableAlert?: boolean
) => {
  useMutation({
    mutationFn: () => contractorUpdateOne(params, disableAlert),
    onSuccess: (data, variables, context) => {
      queryClient.refetchQueries({ queryKey: [data.uuid] });
    },
  });
};
