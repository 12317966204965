import { IUserMinimal, validateFormPhoneFormatFR } from '@commons';
import { ConfirmNavigationModal } from 'components/modals/ConfirmNavigationModal';
import {
  BlocInformation,
  Box,
  FormControl,
  Input,
  Radio,
  Row,
  Text,
  FileInput,
  Link,
  PhoneInputControlled,
} from 'components/ui';
import { AddIcon } from 'components/ui/icons';
import React from 'react';
import { useForm } from 'react-hook-form';
import { printFormat } from 'iban';

type FormValues = any; /* {
  civility: string;
};*/
type ContactFormProps = {
  defaultValues?: any;
  onSubmit?: (user: Partial<IUserMinimal>) => any | void;
  onboardingStep: any;
};

export const OnBoardingEstablishmentForm = ({
  defaultValues,
  onSubmit: onSubmitParent,
  onboardingStep,
}: ContactFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitSuccessful },
    watch,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
    },
  });
  const signOfficerType = watch('establishment.provider.signOfficerType');
  const delegatedSignOfficer = watch(
    'establishment.provider.delegatedSignOfficer'
  );
  const bankAccount = watch('establishment.provider.bankAccount');
  const rib = bankAccount?.rib;
  const delegationOrder = delegatedSignOfficer?.delegationOrder;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [contractFile, setContractFile] = React.useState<File | null>(
    delegationOrder
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ribFile, setRibFile] = React.useState<File | null>(rib);

  const onSubmit = async (formValues: FormValues) => {
    reset(formValues);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfirmNavigationModal active={isDirty && !isSubmitSuccessful} />
      <Text variant="h2" mb={20}>
        Établissement
      </Text>
      {defaultValues ? (
        <Box>
          <Box mt={20}>
            <Row spacing={20}>
              <FormControl
                label="N° SIRET"
                errorMessage={errors?.establishment?.siret?.message}
              >
                <Input
                  isDisabled
                  isFullWidth
                  {...register('establishment.siret')}
                />
              </FormControl>
              <FormControl
                label="RAISON SOCIALE"
                errorMessage={errors?.establishment?.businessName?.message}
              >
                <Input
                  isDisabled
                  isFullWidth
                  {...register('establishment.businessName')}
                />
              </FormControl>
            </Row>
            <Row spacing={20}>
              <FormControl
                label="ADRESSE"
                errorMessage={errors?.establishment?.address?.street?.message}
              >
                <Input
                  isDisabled
                  isFullWidth
                  {...register('establishment.address.street')}
                />
              </FormControl>
              <FormControl
                label="CODE POSTALE"
                errorMessage={
                  errors?.establishment?.address?.postalCode?.message
                }
              >
                <Input
                  isDisabled
                  isFullWidth
                  {...register('establishment.address.postalCode')}
                />
              </FormControl>
            </Row>
            <Row spacing={20}>
              <FormControl
                label="VILLE"
                errorMessage={errors?.establishment?.address?.country?.message}
              >
                <Input
                  isDisabled
                  isFullWidth
                  {...register('establishment?.address.city')}
                />
              </FormControl>
              <FormControl
                label="PAYS"
                errorMessage={errors?.establishment?.address?.country?.message}
              >
                <Input
                  isDisabled
                  isFullWidth
                  {...register('establishment.address.country')}
                />
              </FormControl>
            </Row>
            <Row spacing={20}>
              <FormControl
                label="N° TVA"
                errorMessage={errors?.establishment?.vatNumber?.message}
              >
                <Input
                  isDisabled
                  isFullWidth
                  {...register('establishment.vatNumber')}
                />
              </FormControl>
              <></>
            </Row>
          </Box>
          <Text variant="h2" mb={20}>
            Coordonnées bancaires
          </Text>
          <Box mt={20}>
            <Row spacing={20}>
              <FormControl
                label="IBAN"
                required
                errorMessage={errors?.iban?.message}
              >
                <Input
                  data-clarity-mask="True"
                  isDisabled
                  isFullWidth
                  type="text"
                  {...register('establishment.provider.bankAccount.iban')}
                  onChange={e => {
                    // @ts-ignore
                    e.target.value = printFormat(e.target.value, ' ');
                  }}
                />
              </FormControl>
              <FormControl
                label="BIC"
                required
                errorMessage={errors?.bic?.message}
              >
                <Input
                  data-clarity-mask="True"
                  isDisabled
                  isFullWidth
                  type="text"
                  {...register('establishment.provider.bankAccount.bic', {
                    required: 'Ce champ est requis',
                  })}
                />
              </FormControl>
            </Row>
            <Row spacing={20}>
              <FormControl
                label="Nom de la banque"
                required
                errorMessage={
                  errors?.bankName?.type === 'maxLength'
                    ? 'Veuillez saisir moins de 31 caractères'
                    : errors?.bankName?.message
                }
              >
                <Input
                  data-clarity-mask="True"
                  isDisabled
                  isFullWidth
                  type="text"
                  {...register('establishment.provider.bankAccount.bankName', {
                    required: 'Ce champ est requis',
                    maxLength: 30,
                  })}
                />
              </FormControl>
              <FormControl
                label="Titulaire du compte"
                required
                errorMessage={errors?.owner?.message}
              >
                <Input
                  data-clarity-mask="True"
                  isDisabled
                  isFullWidth
                  type="text"
                  {...register('establishment.provider.bankAccount.owner', {
                    required: 'Ce champ est requis',
                  })}
                />
              </FormControl>
            </Row>
            <Row spacing={20}>
              <FormControl label="" errorMessage={errors?.rib?.message}>
                <FileInput
                  data-clarity-mask="True"
                  isDisabled
                  label="Relevé d'identité bancaire"
                  accept=".pdf"
                  subLabel="Format accepté : PDF"
                  //onSelect={handleChangeRIB}
                >
                  <Link iconLeft={<AddIcon />} variantColor={'grey'}>
                    {ribFile?.name ||
                      `${
                        rib?.fileName
                          ? `Remplacer le rib '${rib.fileName}' `
                          : 'Ajouter un rib'
                      }`}
                  </Link>
                </FileInput>
              </FormControl>
              <></>
            </Row>
          </Box>
          <Box>
            <Text variant="h2">Informations du signataire</Text>
            <Box>
              <FormControl label="">
                <>
                  <Radio
                    disabled
                    {...register('establishment.provider.signOfficerType')}
                    value={'CORPORATE_OFFICER'}
                  >
                    Le mandataire social
                  </Radio>
                  <Radio
                    disabled
                    {...register('establishment.provider.signOfficerType')}
                    value={'DELEGATED_OFFICER'}
                  >
                    Un tiers muni d'une délégation de signature
                  </Radio>
                </>
              </FormControl>
              <Text variant="h3">Informations du mandataire social</Text>
              <Box mt={20}>
                <FormControl
                  required={true}
                  label={'Civilité'}
                  errorMessage={errors?.signOfficer?.civility?.message}
                >
                  <>
                    <Radio
                      disabled
                      {...register(
                        'establishment.provider.signOfficer.civility',
                        {
                          required: 'Ce champ est requis',
                        }
                      )}
                      value="1"
                    >
                      Monsieur
                    </Radio>
                    <Radio
                      disabled
                      {...register(
                        'establishment.provider.signOfficer.civility',
                        {
                          required: 'Ce champ est requis',
                        }
                      )}
                      value="2"
                    >
                      Madame
                    </Radio>
                  </>
                </FormControl>
              </Box>
              <Row spacing={20}>
                <FormControl
                  required={true}
                  label="Prénom"
                  errorMessage={errors?.signOfficer?.firstName?.message}
                >
                  <Input
                    isDisabled
                    isFullWidth
                    {...register(
                      'establishment.provider.signOfficer.firstName',
                      {
                        required: 'Ce champ est requis',
                      }
                    )}
                  />
                </FormControl>
                <FormControl
                  required={true}
                  label="Nom"
                  errorMessage={errors?.signOfficer?.lastName?.message}
                >
                  <Input
                    isDisabled
                    isFullWidth
                    {...register(
                      'establishment.provider.signOfficer.lastName',
                      {
                        required: 'Ce champ est requis',
                      }
                    )}
                  />
                </FormControl>
              </Row>
              <Row spacing={20}>
                <FormControl
                  required={signOfficerType === 'CORPORATE_OFFICER'}
                  label="Email"
                  errorMessage={
                    signOfficerType === 'CORPORATE_OFFICER'
                      ? errors?.signOfficer?.email?.message
                      : undefined
                  }
                >
                  <Input
                    isDisabled
                    isFullWidth
                    {...register('establishment.provider.signOfficer.email', {
                      required:
                        signOfficerType === 'CORPORATE_OFFICER'
                          ? 'Ce champ est requis'
                          : undefined,
                    })}
                  />
                </FormControl>
                <FormControl
                  label="Téléphone"
                  errorMessage={errors?.signOfficer?.phone?.message}
                >
                  <PhoneInputControlled
                    disabled
                    control={control}
                    rules={{
                      validate: (value: string) => {
                        return validateFormPhoneFormatFR(value);
                      },
                    }}
                    isFullWidth
                    name="establishment.provider.signOfficer.phone"
                  />
                </FormControl>
              </Row>
              <Row spacing={20}>
                <FormControl
                  required={true}
                  label="Fonction"
                  errorMessage={errors?.signOfficer?.jobTitle?.message}
                >
                  <Input
                    isFullWidth
                    isDisabled
                    {...register(
                      'establishment.provider.signOfficer.jobTitle',
                      { required: 'Ce champ est requis' }
                    )}
                  />
                </FormControl>
                <></>
              </Row>
              {signOfficerType === 'DELEGATED_OFFICER' && (
                <>
                  <Text variant="h3">
                    Informations de la personne habilitée à signer
                  </Text>
                  <Box mt={20}>
                    <FormControl
                      required={true}
                      label={'Civilité'}
                      errorMessage={
                        errors?.delegatedSignOfficer?.civility?.message
                      }
                    >
                      <>
                        <Radio
                          disabled
                          {...register(
                            'establishment.provider.delegatedSignOfficer.civility',
                            {
                              required: 'Ce champ est requis',
                            }
                          )}
                          value="1"
                        >
                          Monsieur
                        </Radio>
                        <Radio
                          disabled
                          {...register(
                            'establishment.provider.delegatedSignOfficer.civility',
                            {
                              required: 'Ce champ est requis',
                            }
                          )}
                          value="2"
                        >
                          Madame
                        </Radio>
                      </>
                    </FormControl>
                  </Box>
                  <Row spacing={20}>
                    <FormControl
                      required={true}
                      label="Prénom"
                      errorMessage={
                        errors?.delegatedSignOfficer?.firstName?.message
                      }
                    >
                      <Input
                        isDisabled
                        isFullWidth
                        {...register(
                          'establishment.provider.delegatedSignOfficer.firstName',
                          {
                            required: 'Ce champ est requis',
                          }
                        )}
                      />
                    </FormControl>
                    <FormControl
                      required={true}
                      label="Nom"
                      errorMessage={
                        errors?.delegatedSignOfficer?.lastName?.message
                      }
                    >
                      <Input
                        isDisabled
                        isFullWidth
                        {...register(
                          'establishment.provider.delegatedSignOfficer.lastName',
                          {
                            required: 'Ce champ est requis',
                          }
                        )}
                      />
                    </FormControl>
                  </Row>
                  <Row spacing={20}>
                    <FormControl
                      required={signOfficerType === 'DELEGATED_OFFICER'}
                      label="Email"
                      errorMessage={
                        signOfficerType === 'DELEGATED_OFFICER'
                          ? errors?.delegatedSignOfficer?.email?.message
                          : undefined
                      }
                    >
                      <Input
                        isDisabled
                        isFullWidth
                        {...register(
                          'establishment.provider.delegatedSignOfficer.email',
                          {
                            required:
                              signOfficerType === 'DELEGATED_OFFICER'
                                ? 'Ce champ est requis'
                                : undefined,
                          }
                        )}
                      />
                    </FormControl>
                    <FormControl
                      label="Téléphone"
                      errorMessage={
                        errors?.delegatedSignOfficer?.phone?.message
                      }
                    >
                      <PhoneInputControlled
                        disabled
                        control={control}
                        rules={{
                          validate: (value: string) => {
                            return validateFormPhoneFormatFR(value);
                          },
                        }}
                        isFullWidth
                        name="establishment.provider.delegatedSignOfficer.phone"
                      />
                    </FormControl>
                  </Row>
                  <Row spacing={20}>
                    <FormControl
                      label="Fonction"
                      errorMessage={
                        errors?.delegatedSignOfficer?.jobTitle?.message
                      }
                    >
                      <Input
                        isFullWidth
                        isDisabled
                        {...register(
                          'establishment.provider.delegatedSignOfficer.jobTitle'
                        )}
                      />
                    </FormControl>
                    <></>
                  </Row>
                  <Box mb={40} width={delegationOrder ? 1 / 1 : 1 / 2}>
                    <FormControl
                      label=""
                      errorMessage={
                        errors?.establishment?.provider?.delegatedSignOfficer
                          ?.delegationOrder?.message
                      }
                    >
                      <FileInput
                        isDisabled
                        required
                        label="Delégation de signature"
                        accept=".pdf"
                        subLabel="Format accepté : PDF"
                        //onSelect={handleContractFileChange}
                      >
                        <Link iconLeft={<AddIcon />} variantColor={'grey'}>
                          {contractFile?.name ||
                            `${
                              delegationOrder
                                ? `Remplacer la delégation de signature '${delegationOrder?.fileName}' `
                                : 'Ajouter une delégation de signature'
                            }`}
                        </Link>
                      </FileInput>
                    </FormControl>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <BlocInformation>
          Le contact fournisseur n'a pas encore renseigné d'informations sur
          l'étape 2.
        </BlocInformation>
      )}
    </form>
  );
};
