import * as React from 'react';

function TimeLapsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.8273 5.81717C10.0473 5.03717 9.02732 4.64384 8.00065 4.64384V8.64384L5.17398 11.4705C6.73398 13.0305 9.26732 13.0305 10.834 11.4705C12.394 9.91051 12.394 7.37717 10.8273 5.81717ZM8.00065 1.97717C4.32065 1.97717 1.33398 4.96384 1.33398 8.64384C1.33398 12.3238 4.32065 15.3105 8.00065 15.3105C11.6807 15.3105 14.6673 12.3238 14.6673 8.64384C14.6673 4.96384 11.6807 1.97717 8.00065 1.97717ZM8.00065 13.9772C5.05398 13.9772 2.66732 11.5905 2.66732 8.64384C2.66732 5.69717 5.05398 3.31051 8.00065 3.31051C10.9473 3.31051 13.334 5.69717 13.334 8.64384C13.334 11.5905 10.9473 13.9772 8.00065 13.9772Z"
        fill={props?.fill || '#808080'}
      />
    </svg>
  );
}

export default TimeLapsIcon;
