import {
  checkGescomAvalaibleGenerateInvoiceCustomer,
  checkUserHasRole,
  CUSTOMER_REFERENCE_PATTERN_TAG_WHITELIST,
  ERoles,
  MAX_PAYMENT_DEADLINE_END_OF_MONTH,
  MAX_PAYMENT_DEADLINE_FIXED,
  missionUpdateOne,
  MIXED_TAG_INPUT_SETTING,
  queryClient,
  useInvoiceIbanFindMany,
  useMe,
  transformFormulaToTag,
  isNotSameContacts,
  buildOneLineAddress,
  getCountryData,
} from '@commons';
import {
  EInvoicePaymentLabel,
  EInvoiceSendMethod,
  EMissionStatus,
  IJoinedMission,
  IUpdateMissionData,
  IEmailContact,
  EEmailRecipientType,
  IInvoiceAddress,
  EMissionStructure,
} from '@freelancelabs/teoreme-commons';
import { showAddICustomerRefPatternModal } from 'components/modals/AddICustomerRefPatternModal';
import { showUpdateCustomMissionDescription } from 'components/modals/missions/UpdateCustomInvoiceDescription';
import { Section } from 'components/Section';
import {
  BlocInformation,
  Box,
  Button,
  CheckSwitch,
  CustomToolTip,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Radio,
  Row,
  StaticSelectControlled,
  Text,
} from 'components/ui';
import { Theme } from 'styles';
import { MixedModeTagify } from 'components/InputTags';
import { EditIcon, InfoIcon } from 'components/ui/icons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useCreateOrUpdateMission, useGetMission } from 'hooks/missionHooks';
import { useShowMessage } from 'hooks/useShowMessage';
import { electronicFormat } from 'iban';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AddEmailContactForm } from 'forms/AddEmailContactForm';
import { showAddPaymentDeadlinesModal } from 'components/modals/missions/AddPaymentDeadlineModal';

type ClientProps = {
  isDisabled: boolean;
  amendments: Array<IJoinedMission> | [];
};
export const InvoicingOptions = ({ isDisabled, amendments }: ClientProps) => {
  const { me } = useMe();
  const [loading, setLoading] = useState(false);
  const [hasSetDefaultIban, setHasSetDefaultIban] = useState(false);
  const { mission, reference } = useGetMission();
  const { data: ibans } = useInvoiceIbanFindMany({
    filterObject: {
      'scopes.structure': mission?.billingInformation?.structure,
    },
  });
  const [createOrUpdate] = useCreateOrUpdateMission();
  const [additionalInvoiceContacts, setAdditionalInvoiceContacts] = useState(
    mission?.customer?.billingOptions?.additionalInvoiceContacts || []
  );
  const [editAInvoiceContactEmail, setEditAInvoiceContactEmail] = useState<
    string | boolean
  >(false);
  const [editInvoiceAdress, setEditInvoiceAdress] = useState<boolean>(false);

  const showMessage = useShowMessage();
  useSetCrumbs(
    [],
    [
      { label: 'prestations', path: '/delivery' },
      { label: 'missions', path: '/delivery/missions' },
      {
        label: mission?.displayReference || 'nouvelle mission',
        path: `/delivery/missions/${reference}`,
      },
      {
        label: 'client',
        path: `/delivery/missions/${reference}/client`,
      },
    ]
  );
  type FormValues = any;
  const customerEstablishment = mission?.customer?.establishment;
  const getMissionInvoiceAdress = () => {
    const missionCustomerEstablishment = mission?.customer?.establishment;
    const missionCustomerEstablishmentExistingIvAdr =
      //@ts-ignore
      mission?.customer?.billingOptions?.invoiceAddress;
    if (missionCustomerEstablishmentExistingIvAdr)
      return buildOneLineAddress(
        missionCustomerEstablishmentExistingIvAdr as IInvoiceAddress
      );

    const defaultAdress = buildOneLineAddress(
      missionCustomerEstablishment?.address
    );
    const othersInvoiceAdresses =
      missionCustomerEstablishment?.customer?.invoiceAddress || [];
    const othersDefaultAdress = othersInvoiceAdresses?.filter(
      e => e.default && !e.locked
    )[0];
    const textAdress = buildOneLineAddress(othersDefaultAdress as any);

    return textAdress || defaultAdress || 'adress';
  };
  // const getMissionInvoiceAdressText = () =>
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      customer: {
        ...mission?.customer,
        billingOptions: {
          ...mission?.customer?.billingOptions,
          invoiceAddress: getMissionInvoiceAdress(),
        },
      },
    },
  });

  const isIbanDisabled = (ibanUuid: string | undefined) => {
    return ibans?.ibans.some(ib => ib.uuid === ibanUuid && ib.disabled);
  };

  const customerEstablishmentInvoiceAdrs =
    customerEstablishment?.customer?.invoiceAddress
      ?.filter((adr: any) => {
        return !adr?.locked;
      })
      ?.map(adr => {
        return {
          label: buildOneLineAddress(adr),
          value: buildOneLineAddress(adr),
          data: adr as any,
        };
      }) || [];
  const INVOICE_ADRESSES_SELECT: {
    value: string;
    label: string;
    data?: any;
  }[] = [
    {
      value: buildOneLineAddress(customerEstablishment?.address),
      label: buildOneLineAddress(customerEstablishment?.address),
      data: customerEstablishment?.address,
    },
    ...customerEstablishmentInvoiceAdrs,
  ].reduce((acc: any, curr: any) => {
    if (!acc.find((item: any) => item?.value === curr.value)) {
      acc.push(curr);
    }
    return acc;
  }, []);

  const onSubmit = async (formValues: FormValues) => {
    const ibanParam = formValues?.customer?.billingOptions?.iban;

    if (isIbanDisabled(ibanParam)) {
      setError('customer.billingOptions.iban', {
        message:
          'l`IBAN sélectionné est marqué comme "Désactivé", vous devriez peut-être le remplacer si la mission est toujours active',
      });
      showMessage(
        'error',
        'l`IBAN sélectionné est marqué comme "Désactivé", vous devriez peut-être le remplacer si la mission est toujours active'
      );
      return;
    }

    setLoading(true);
    const shouldMergeProofWithInvoice =
      formValues?.customer?.billingOptions?.shouldMergeProofWithInvoice;
    /*
        désactiver: remove
        activer : upsert
        supprimer :remove
        créer : upsert
        modifier : upsert
        */
    const defaultInvoiceContacts =
      mission?.customer?.billingOptions?.additionalInvoiceContacts;
    // Contact is created  or updated or activated (ignore active true => active false)
    const upsertInvoiceContacts = isNotSameContacts(
      defaultInvoiceContacts as any,
      additionalInvoiceContacts
    )?.filter((c: any) => c?.active !== false);
    // Contact is deactivated
    const removeInvoiceContacts = additionalInvoiceContacts
      // eslint-disable-next-line array-callback-return
      ?.filter(newContact => {
        const oldContact = defaultInvoiceContacts?.find(
          old => old?.email === newContact?.email
        );
        if (oldContact && oldContact?.active === true) {
          return newContact?.active === false;
        }
      })
      ?.map(c => {
        return c.email;
      });
    // Contact is deleted
    defaultInvoiceContacts?.forEach(oldContact => {
      const noFound = additionalInvoiceContacts?.find(
        newContact => oldContact?.email === newContact?.email
      );
      if (!noFound) {
        removeInvoiceContacts?.push(oldContact?.email);
      }
    });
    const fullAdress: any = INVOICE_ADRESSES_SELECT?.find(
      (e: any) =>
        e.value === formValues?.customer?.billingOptions?.invoiceAddress
    )?.data;
    const locationCountryCode =
      fullAdress?.country &&
      getCountryData('alpha2', {
        name: fullAdress?.country as string,
      });
    const invoiceAdressParams = fullAdress
      ? {
          country: fullAdress?.country,
          countryCode: locationCountryCode,
          city: fullAdress?.city,
          street: fullAdress?.street,
          postalCode: fullAdress?.postalCode,
          additional: fullAdress?.additional,
          sage: fullAdress?.sage,
        }
      : undefined;
    const submitValues = {
      upsertCustomerInvoiceContacts:
        upsertInvoiceContacts?.length > 0 ? upsertInvoiceContacts : undefined,
      removeCustomerInvoiceContacts:
        removeInvoiceContacts?.length > 0 ? removeInvoiceContacts : undefined,
      customer: {
        billingOptions: {
          //...mission?.customer?.billingOptions,
          ...formValues?.customer?.billingOptions,
          invoiceAddress: invoiceAdressParams,
          additionalInvoiceContacts: undefined,
          shouldMergeProofWithInvoice: shouldJoinProofToInvoice
            ? shouldMergeProofWithInvoice
            : false,
        },
      },
    };
    try {
      await missionUpdateOne({
        reference: mission?.reference || '',
        mission: submitValues,
      });
      queryClient.refetchQueries({ queryKey: [mission?.reference] });
      setEditInvoiceAdress(false);
      showMessage('success', 'La mission à été mise à jour ');
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);
    //
  };
  const getDefaultIban = () => {
    let matchIbanUuid = false;
    if (!matchIbanUuid) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const primaryMatch = ibans?.ibans?.forEach((IB: any) => {
        IB?.scopes?.forEach((scope: any) => {
          // TODO SEARCH BY STRCUTURE FCOM /FONE
          if (
            scope?.primary &&
            scope?.structure === mission?.billingInformation?.structure
          ) {
            matchIbanUuid = IB?.uuid;
            return IB?.uuid;
          }
        });
      });
    }
    return matchIbanUuid;
  };
  const missionIban = getDefaultIban();

  const IBAN_SELECT = ibans?.ibans
    ?.map(iban => {
      return {
        label: `NOM : ${iban?.displayName} | NOM DE LA BANQUE : ${iban?.bankName} | IBAN: ${electronicFormat(
          iban?.iban
        )}`,
        value: iban?.uuid,
        key: iban?.uuid,
        disabled: iban.disabled,
      };
    })
    .filter(iban => {
      if (iban.disabled && iban?.key !== `${missionIban}`) {
        return false;
      }
      return true;
    });

  const client = mission?.customer;
  const establishment = client?.establishment;
  const isAutoSendEmail = watch('customer.billingOptions.isAutoSendEmail');
  const isAutoBilling = watch('customer.billingOptions.isAutoBilling');
  const isDirectPayment = watch('customer.billingOptions.isDirectPayment');
  const ibanEst = watch('customer.billingOptions.iban');
  const sendMethod = watch('customer.billingOptions.sendMethod');
  const shouldJoinProofToInvoice = watch(
    'customer.billingOptions.shouldJoinProofToInvoice'
  );
  const shouldMergeProofWithInvoice = watch(
    'customer.billingOptions.shouldMergeProofWithInvoice'
  );

  const allGescomAvalaible = checkGescomAvalaibleGenerateInvoiceCustomer(
    mission as IJoinedMission
  );
  const checkEnableCustomerBillingOptions = () => {
    if (
      !mission?.customer?.establishment?.customer?.billingOptions?.isAutoBilling
    ) {
      return false;
    }
    if (mission?.status === EMissionStatus?.DRAFT) {
      return true;
    } else {
      if (mission?.customer?.billingOptions?.isAutoBilling) {
        if (allGescomAvalaible) {
          return true;
        }
      } else {
        return true;
      }
    }
  };
  const checkFormPaymentDeadline = (value: number): string | undefined => {
    const payLabel = getValues().customer.billingOptions.paymentLabel;

    if (payLabel === EInvoicePaymentLabel.END_OF_MONTH) {
      if (value > MAX_PAYMENT_DEADLINE_END_OF_MONTH)
        return `Le délai d'échéance ne peut pas dépasser ${MAX_PAYMENT_DEADLINE_FIXED} jours à date d'émission de la facture ou ${MAX_PAYMENT_DEADLINE_END_OF_MONTH} jours fin de mois`;
    }
    if (payLabel === EInvoicePaymentLabel.FIXED) {
      if (value > MAX_PAYMENT_DEADLINE_FIXED)
        return `Le délai d'échéance ne peut pas dépasser ${MAX_PAYMENT_DEADLINE_FIXED} jours à date d'émission de la facture ou ${MAX_PAYMENT_DEADLINE_END_OF_MONTH} jours fin de mois`;
    }
    return undefined;
  };
  const enableCustomerBillingOptions = checkEnableCustomerBillingOptions();

  const checkIsDirectPaymentEnabled = () => {
    if (mission?.isMandateMode) return false;
    if (mission?.billingInformation?.structure === EMissionStructure.COWORKEES)
      return false;
    if (mission?.customer?.billingOptions?.isDirectPayment) return true;
    return mission?.customer?.establishment?.customer?.billingOptions
      ?.isDirectPayment;
  };

  const isDirectPaymentDisabledText = () => {
    if (mission?.isMandateMode) return 'impossible en mode mandat';
    if (mission?.billingInformation?.structure === EMissionStructure.COWORKEES)
      return 'impossible pour une mission COWORKEES';
    if (
      !mission?.customer?.establishment?.customer?.billingOptions
        ?.isDirectPayment
    )
      return "Pour pouvoir activer la facturation automatique veuillez activer l'option depuis la fiche de l'Établissement client.";
    return undefined;
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (!loading) {
      if (
        (!isAutoBilling && isAutoSendEmail) ||
        (sendMethod !== EInvoiceSendMethod?.EMAIL && isAutoSendEmail)
      ) {
        setValue('customer.billingOptions.isAutoSendEmail', false);
      }
      if (isIbanDisabled(ibanEst)) {
        setError('customer.billingOptions.iban', {
          message:
            'l`IBAN sélectionné est marqué comme "Désactivé", vous devriez peut-être le remplacer si la mission est toujours active',
        });
      }
    }
  }, [isAutoBilling, isAutoSendEmail, sendMethod, loading, ibans]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (
      ibans &&
      ibans?.ibans?.length > 0 &&
      !mission?.customer?.billingOptions?.iban &&
      !hasSetDefaultIban
    ) {
      setHasSetDefaultIban(true);
      const iban = getDefaultIban();

      setValue('establishment.customer.billingOptions.iban', iban);
    }
  }, [ibans, hasSetDefaultIban]);
  const onUpdateEmailContacts = (
    contact: {
      email: string;
      recipientType?: EEmailRecipientType;
    },
    oldContact?: {
      email: string;
      recipientType?: EEmailRecipientType;
    }
  ) => {
    const contacts = additionalInvoiceContacts;
    const indexUpdatedContact = contacts?.findIndex(
      (oldC: any) =>
        oldC?.email === oldContact?.email || oldC?.email === contact?.email
    );
    if (indexUpdatedContact !== -1) {
      const updateC = contacts[indexUpdatedContact];
      contacts[indexUpdatedContact] = { ...updateC, ...contact };
      setAdditionalInvoiceContacts(contacts);
    } else {
      contacts?.push(contact);
      setAdditionalInvoiceContacts(contacts);
    }
    setEditAInvoiceContactEmail(false);
  };
  const onDeleteEmailContacts = (email: string) => {
    let contacts = additionalInvoiceContacts;
    contacts = contacts?.filter((c: any) => c?.email !== email);
    setAdditionalInvoiceContacts(contacts);
  };
  const onActiveEmailContacts = (
    contact: IEmailContact & {
      inherited?: boolean | undefined;
      active?: boolean | undefined;
    },
    active: boolean
  ) => {
    const contacts = [...additionalInvoiceContacts];
    const indexUpdatedContact = contacts?.findIndex(
      (oldC: any) => oldC?.email === contact?.email
    );
    if (indexUpdatedContact !== -1) {
      contacts[indexUpdatedContact] = { ...contact, active: active };
      setAdditionalInvoiceContacts(contacts);
    }
  };
  const customInvoicesDescription = watch('customer.customInvoicesDescription');
  const paymentDeadlines = mission?.provider?.paymentDeadlines;
  const clientPaymentDeadline =
    mission?.customer?.establishment?.customer?.paymentDetails?.paymentDeadline;
  return (
    <Box>
      <Box>
        <Section variant="h2" title="Options de facturation fournisseur">
          {clientPaymentDeadline && (
            <BlocInformation mb={20}>
              Échéance du client : {clientPaymentDeadline} jours
            </BlocInformation>
          )}
          {mission?.startAt && (
            <Box mb={20}>
              <FormLabel>
                Échéance{' '}
                <Link
                  style={{ fontWeight: 'lighter', textTransform: 'none' }}
                  ml={20}
                  isDisabled={isDisabled}
                  onClick={() => {
                    if (isDisabled) return;
                    showAddPaymentDeadlinesModal({ mission: mission });
                  }}
                >
                  Modifier
                </Link>
              </FormLabel>
              {paymentDeadlines &&
                paymentDeadlines.map(paiDead => (
                  <Flex>
                    <Box>
                      {`À partir de : ${
                        paiDead?.startAt?.getUTCMonth() + 1
                      } / ${paiDead?.startAt?.getUTCFullYear()}`}
                      &nbsp;
                    </Box>
                    <Box
                      ml={30}
                    >{`Nombre de jours nets : ${paiDead.nbDays}`}</Box>
                  </Flex>
                ))}
              <Flex>
                {!paymentDeadlines?.length && (
                  <>
                    <Box>
                      {`À partir de : ${
                        mission?.startAt?.getUTCMonth() + 1
                      } / ${mission?.startAt?.getUTCFullYear()}`}
                      &nbsp;
                    </Box>
                    <Box ml={30}>{`Nombre de jours nets : ${'60'}`}</Box>
                  </>
                )}
              </Flex>
            </Box>
          )}
        </Section>
        <Section mb={0} variant="h2" title="Options de facturation client">
          <>
            <Text variant="h3">Adresse de facturation de la mission</Text>
            {!editInvoiceAdress && (
              <Text my={20}>
                {getMissionInvoiceAdress()}
                <Link
                  ml={10}
                  mt={'-5px'}
                  iconLeft={<EditIcon />}
                  isDisabled={isDisabled}
                  onClick={() => {
                    if (isDisabled) return;
                    setEditInvoiceAdress(!editInvoiceAdress);
                  }}
                >
                  Modifier
                </Link>
              </Text>
            )}
            {editInvoiceAdress && (
              <FormControl
                label={'Sélectionner une adresse de facturation'}
                required
                errorMessage={errors?.address?.country?.message}
              >
                <StaticSelectControlled
                  options={INVOICE_ADRESSES_SELECT}
                  control={control}
                  name="customer.billingOptions.invoiceAddress"
                  placeholder="adresse"
                  required
                />
              </FormControl>
            )}

            {!mission?.customer?.establishment?.customer?.billingOptions
              ?.isAutoBilling ? (
              <BlocInformation mb={20}>
                <p>
                  Pour pouvoir activer la facturation automatique veuillez
                  activer l'option depuis la fiche de l'établissement client.
                </p>
              </BlocInformation>
            ) : (
              !enableCustomerBillingOptions && (
                <BlocInformation mb={20}>
                  <p>
                    Avant de pouvoir activer la facturation automatique veuillez
                    créer dans la GESCOM l'intervenant , l'établissement
                    fournisseur et l'établissement client.
                  </p>
                </BlocInformation>
              )
            )}
            <Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                  label={'Paiement Direct'}
                  errorMessage={
                    //@ts-ignore
                    errors?.customer?.billingOptions?.isDirectPayment?.message
                  }
                  required
                >
                  <Box width={'35%'}>
                    <CustomToolTip
                      color={Theme?.colors?.warning?.default}
                      text={isDirectPaymentDisabledText()}
                      id={'directPaymentMission'}
                    >
                      <CheckSwitch
                        isDisabled={
                          !checkIsDirectPaymentEnabled() || isDisabled
                        }
                        id="isDirectPayment"
                        {...register('customer.billingOptions.isDirectPayment')}
                        onChange={e => {
                          setValue(
                            'customer.billingOptions.isDirectPayment',
                            // @ts-ignore
                            e.target.checked
                          );
                          return e.target.checked;
                        }}
                      >
                        <Text variant="p">
                          {isDirectPayment ? 'Actif' : 'Inactif'}
                        </Text>
                      </CheckSwitch>
                    </CustomToolTip>
                  </Box>
                </FormControl>
                <FormControl
                  label={'Facturer automatiquement le client'}
                  errorMessage={
                    //@ts-ignore
                    errors?.customer?.billingOptions?.isAutoBilling?.message
                  }
                  required
                >
                  <CheckSwitch
                    isDisabled={
                      !mission?.customer?.establishment?.customer
                        ?.billingOptions?.isAutoBilling ||
                      !enableCustomerBillingOptions ||
                      isDisabled
                    }
                    id="isAutoBilling"
                    {...register('customer.billingOptions.isAutoBilling')}
                    onChange={e => {
                      setValue(
                        'customer.billingOptions.isAutoBilling',
                        // @ts-ignore
                        e.target.checked
                      );
                      return e.target.checked;
                    }}
                  >
                    <Text variant="p">
                      {isAutoBilling
                        ? 'Facturer automatiquement le client'
                        : 'Ne pas facturer automatiquement le client'}
                    </Text>
                  </CheckSwitch>
                </FormControl>
                <Flex>
                  <FormControl
                    label={
                      'Description de la prestation ( Affichée sur la facture client )'
                    }
                    errorMessage={
                      errors?.customer?.customInvoicesDescription?.message
                    }
                  >
                    <Text variant="p">
                      {customInvoicesDescription
                        ? customInvoicesDescription
                            ?.split('\n')
                            ?.map((text: string) => (
                              <>
                                {text}
                                <br />
                              </>
                            ))
                        : 'N/A'}
                    </Text>
                  </FormControl>
                  <Link
                    ml={10}
                    mt={'-5px'}
                    iconLeft={<EditIcon />}
                    isDisabled={isDisabled}
                    onClick={() => {
                      if (isDisabled) return;
                      showUpdateCustomMissionDescription({
                        mission: mission as IJoinedMission,
                      })?.then(desc => {
                        if (desc) {
                          setValue('customer.customInvoicesDescription', desc);
                        }
                      });
                    }}
                  >
                    Modifier
                  </Link>
                </Flex>
                <Box>
                  <Flex>
                    <FormControl
                      label={
                        <Flex alignItems="center">
                          Référence de facturation
                          {checkUserHasRole(me, ERoles?.ADMIN) && (
                            <Link
                              style={{ textTransform: 'capitalize' }}
                              fontWeight={100}
                              fontSize={11}
                              iconLeft={<EditIcon />}
                              ml={10}
                              isDisabled={isDisabled}
                              onClick={() => {
                                if (isDisabled) return;
                                showAddICustomerRefPatternModal({
                                  customerRefPattern:
                                    mission?.customer?.billingOptions
                                      ?.customerRefPattern,
                                  beforeValidation:
                                    async customerRefPattern => {
                                      setLoading(true);
                                      if (customerRefPattern !== undefined) {
                                        try {
                                          const missionData: IUpdateMissionData =
                                            {
                                              customer: {
                                                billingOptions: {
                                                  customerRefPattern,
                                                },
                                              },
                                            };
                                          await createOrUpdate(
                                            reference,
                                            missionData
                                          );
                                          queryClient?.refetchQueries({
                                            queryKey: [reference],
                                          });
                                        } catch (e) {
                                          setLoading(false);
                                        }
                                        setLoading(false);
                                      }
                                    },
                                });
                              }}
                            >
                              Modifier
                            </Link>
                          )}
                        </Flex>
                      }
                    >
                      <Box mb={20}>
                        {!loading &&
                        mission?.customer?.billingOptions
                          ?.customerRefPattern ? (
                          <>
                            <Flex alignItems="center" mt={'5px'}>
                              <Text mb={'10px'}>
                                {/*'Référence de facturation paramètré :'*/}
                              </Text>
                              <MixedModeTagify
                                settings={{
                                  ...MIXED_TAG_INPUT_SETTING,
                                  whitelist:
                                    CUSTOMER_REFERENCE_PATTERN_TAG_WHITELIST,
                                }}
                                value={
                                  transformFormulaToTag(
                                    mission?.customer?.billingOptions
                                      ?.customerRefPattern,
                                    CUSTOMER_REFERENCE_PATTERN_TAG_WHITELIST
                                  ) + ' '
                                }
                                readOnly
                              />
                            </Flex>
                          </>
                        ) : mission?.customer?.establishment?.customer
                            ?.billingOptions?.customerRefPattern ? (
                          <>
                            <BlocInformation>
                              <p>
                                Le paramètrage au niveau de l'établissement
                                client va étre appliqué.
                              </p>
                            </BlocInformation>
                            <Flex alignItems="center" mt={'5px'}>
                              <Text mb={'10px'}>
                                {/*'Référence de facturation paramètré :'*/}
                              </Text>
                              <MixedModeTagify
                                settings={{
                                  ...MIXED_TAG_INPUT_SETTING,
                                  whitelist:
                                    CUSTOMER_REFERENCE_PATTERN_TAG_WHITELIST,
                                }}
                                value={
                                  transformFormulaToTag(
                                    mission?.customer?.establishment?.customer
                                      ?.billingOptions?.customerRefPattern,
                                    CUSTOMER_REFERENCE_PATTERN_TAG_WHITELIST
                                  ) + ' '
                                }
                                readOnly
                              />
                            </Flex>
                          </>
                        ) : (
                          <Flex alignItems="center" mt={'5px'}>
                            <Text>
                              {' '}
                              Référence de facturation paramétrée : N/A
                            </Text>
                          </Flex>
                        )}
                      </Box>
                    </FormControl>
                  </Flex>
                </Box>
                <Box>
                  <Box
                  // hidden={
                  //   !mission?.customer?.establishment?.customer
                  //     ?.billingOptions?.isAutoBilling ||
                  //   !enableCustomerBillingOptions
                  // }
                  >
                    <FormControl
                      required
                      label={'Mode de distribution'}
                      errorMessage={
                        errors?.customer?.billingOptions?.sendMethod?.message
                      }
                    >
                      <>
                        <Radio
                          {...register('customer.billingOptions.sendMethod', {
                            required: 'Ce champ est requis',
                          })}
                          value={EInvoiceSendMethod?.EMAIL}
                        >
                          Email
                        </Radio>
                        <Radio
                          {...register('customer.billingOptions.sendMethod', {
                            required: 'Ce champ est requis',
                          })}
                          value={EInvoiceSendMethod?.OTHER}
                        >
                          Autre ( plateforme , fax etc .... )
                        </Radio>
                      </>
                    </FormControl>
                  </Box>
                  <Box hidden={sendMethod !== EInvoiceSendMethod?.EMAIL}>
                    <FormControl required label={'Email du destinataire'}>
                      <>
                        <Text>
                          {establishment?.customer?.invoiceContact?.email ||
                            'N/A'}
                        </Text>
                        <Text>
                          Commentaire :&nbsp;
                          <i>
                            {establishment?.customer?.invoiceContact?.comment ||
                              'N/A'}
                          </i>
                        </Text>
                      </>
                    </FormControl>
                    <FormControl
                      label={
                        'Envoyer automatiquement les factures client à cette email'
                      }
                      errorMessage={
                        errors?.customer?.billingOptions?.isAutoSendEmail
                          ?.message
                      }
                      required
                    >
                      <CheckSwitch
                        isDisabled={
                          !isAutoBilling ||
                          sendMethod !== EInvoiceSendMethod?.EMAIL ||
                          isDisabled
                        }
                        id="isAutoSendEmail"
                        {...register('customer.billingOptions.isAutoSendEmail')}
                        onChange={e => {
                          setValue(
                            'customer.billingOptions.isAutoSendEmail',
                            // @ts-ignore
                            e.target.checked
                          );
                          return e.target.checked;
                        }}
                      >
                        <Text variant="p">
                          {isAutoSendEmail
                            ? 'Envoyer automatiquement'
                            : 'Ne pas envoyer automatiquement'}
                        </Text>
                      </CheckSwitch>
                    </FormControl>
                    <Box mb={20} mt={20}>
                      <Text mb={20} variant="h3">
                        Ajouter des destinataires en cc ou cci
                      </Text>
                      {additionalInvoiceContacts &&
                        additionalInvoiceContacts?.map((contact: any) => (
                          <AddEmailContactForm
                            key={contact?.email}
                            onSubmit={newContact =>
                              onUpdateEmailContacts(newContact, contact)
                            }
                            contacts={additionalInvoiceContacts}
                            contact={contact}
                            readOnly={
                              editAInvoiceContactEmail !== contact.email
                            }
                            onEdit={() =>
                              setEditAInvoiceContactEmail(contact?.email)
                            }
                            onActive={onActiveEmailContacts}
                            onDelete={onDeleteEmailContacts}
                          />
                        ))}
                      {!editAInvoiceContactEmail ? (
                        <Link onClick={() => setEditAInvoiceContactEmail(true)}>
                          Ajouter un destinataire
                        </Link>
                      ) : editAInvoiceContactEmail === true ? (
                        <AddEmailContactForm
                          key={'NEW'}
                          contacts={additionalInvoiceContacts}
                          readOnly={false}
                          onSubmit={onUpdateEmailContacts}
                          onEdit={() => setEditAInvoiceContactEmail(false)}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>

                    {isAutoBilling && (
                      <>
                        <FormControl
                          label={
                            'Joindre les justificatifs à la facture client'
                          }
                          errorMessage={
                            errors?.customer?.billingOptions
                              ?.shouldJoinProofToInvoice?.message
                          }
                          required
                        >
                          <CheckSwitch
                            id="shouldJoinProofToInvoice"
                            {...register(
                              'customer.billingOptions.shouldJoinProofToInvoice'
                            )}
                            isDisabled={isDisabled}
                            onChange={e => {
                              setValue(
                                'customer.billingOptions.shouldJoinProofToInvoice',
                                e.target.checked
                              );
                              return e.target.checked;
                            }}
                          >
                            <Text variant="p">
                              {shouldJoinProofToInvoice
                                ? 'Joindre les justificatifs à la facture client'
                                : 'Ne pas Joindre les justificatifs à la facture client'}
                            </Text>
                          </CheckSwitch>
                        </FormControl>
                        <Box hidden={!shouldJoinProofToInvoice}>
                          <FormControl
                            required
                            label={
                              'Fusionner les justificatifs avec la facture'
                            }
                            errorMessage={
                              errors?.customer?.billingOptions
                                ?.shouldMergeProofWithInvoice?.message
                            }
                          >
                            <CheckSwitch
                              id="shouldMergeProofWithInvoice"
                              {...register(
                                'customer.billingOptions.shouldMergeProofWithInvoice'
                              )}
                              isDisabled={isDisabled}
                            >
                              <Text variant="p">
                                {shouldMergeProofWithInvoice
                                  ? 'Fusionner les justificatifs avec la facture'
                                  : ' Séparer les justificatifs et la facture'}
                              </Text>
                            </CheckSwitch>
                          </FormControl>
                        </Box>
                      </>
                    )}
                  </Box>

                  <Row spacing={20}>
                    <FormControl
                      label="Délai de paiement"
                      required
                      errorMessage={
                        errors?.customer?.billingOptions?.paymentDeadline
                          ?.message
                      }
                    >
                      <Input
                        isFullWidth
                        type="number"
                        isDisabled={isDisabled}
                        {...register(
                          'customer.billingOptions.paymentDeadline',
                          {
                            required: 'Ce champs est requis ',
                            min: 1,
                            validate: value => checkFormPaymentDeadline(value),
                            disabled: isDisabled,
                          }
                        )}
                      />
                    </FormControl>
                    <FormControl
                      required
                      label="Modalité de paiement"
                      errorMessage={
                        errors?.customer?.billingOptions?.paymentLabel?.message
                      }
                    >
                      <StaticSelectControlled
                        isClearable={false}
                        control={control}
                        isDisabled={isDisabled}
                        name="customer.billingOptions.paymentLabel"
                        options={[
                          {
                            label: `Fin de mois`,
                            value: EInvoicePaymentLabel?.END_OF_MONTH,
                          },
                          {
                            label: `Date d'émission la facture`,
                            value: EInvoicePaymentLabel?.FIXED,
                          },
                        ]}
                        rules={{ required: 'Ce champ est requis' }}
                        placeholder=""
                      />
                    </FormControl>
                  </Row>
                  <FormControl
                    required
                    label="Coordonnées bancaire Freelance.com"
                    errorMessage={
                      errors?.customer?.billingOptions?.iban?.message
                    }
                  >
                    <StaticSelectControlled
                      data-clarity-mask="True"
                      control={control}
                      isDisabled={isDisabled}
                      name="customer.billingOptions.iban"
                      options={IBAN_SELECT}
                      isOptionDisabled={(option: any) => {
                        return option.disabled;
                      }}
                      rules={{ required: 'Ce champ est requis' }}
                      placeholder=""
                    />
                  </FormControl>
                </Box>

                <Button
                  mt={'20px'}
                  key="submit"
                  type="submit"
                  isLoading={loading}
                  isDisabled={isDisabled}
                >
                  Enregistrer
                </Button>
              </form>
            </Box>
          </>
        </Section>
      </Box>
    </Box>
  );
};
