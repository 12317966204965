import {
  IDpae,
  IDpaeCreateParams,
  IDpaeUpdateParams,
} from '@freelancelabs/teoreme-commons';
import {
  dpaeCreateOne,
  dpaeDeleteOne,
  dpaeFindMany,
  dpaeFindOne,
  dpaeUpdateOne,
} from '../routes';
import { useMutation, useQuery, keepPreviousData } from '@tanstack/react-query';
import { queryClient } from '../';

export const useDpaeFindMany = (variables?: any) => {
  return useQuery({
    queryKey: ['dpaes', variables],
    queryFn: async () => dpaeFindMany(variables),
    enabled: !variables?.disabledFetch,
    placeholderData: keepPreviousData,
  });
};
export const useDpaeFindOne = (uuid: string) => {
  return useQuery({
    queryKey: [uuid, uuid],
    queryFn: async () => dpaeFindOne(uuid),
    enabled: uuid ? true : false,
    // DISABLE CACHE
    refetchOnMount: false,
    staleTime: 0,
    gcTime: 0,
  });
};

export const useDpaeCreateOne = (
  params: IDpaeCreateParams,
  disableAlert?: boolean
) =>
  useMutation<IDpae, unknown, IDpaeCreateParams>({
    mutationFn: () => dpaeCreateOne(params, disableAlert),
    onSuccess: dpae => {
      queryClient.refetchQueries({ queryKey: ['dpaes'] });
    },
  });

export const useDpaeDeleteOne = (uuid = '', disableAlert?: boolean) =>
  useMutation<string, unknown, string>({
    mutationFn: () => dpaeDeleteOne(uuid, disableAlert),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['dpaes'] });
    },
  });

export const useDpaeUpdateOne = (
  params: IDpaeUpdateParams,
  disableAlert?: boolean
) =>
  useMutation<IDpae, unknown, IDpaeUpdateParams>({
    mutationFn: () => dpaeUpdateOne(params, disableAlert),
    onSuccess: dpae => {
      queryClient.refetchQueries({ queryKey: ['dpaes'] });
      dpae.uuid && queryClient.refetchQueries({ queryKey: [dpae.uuid] });
    },
  });
