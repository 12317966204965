import {
  IDpaeCreateParams,
  IDpaeCreateResponse,
  IDpaeDeleteParams,
  IDpaeDeleteResponse,
  IDpaeResendEmailParams,
  IDpaeResendEmailResponse,
  IDpaeSearchParams,
  IDpaeSearchResponse,
  IDpaeUpdateParams,
  IDpaeUpdateResponse,
} from '@freelancelabs/teoreme-commons';
import { fetcher } from '../helpers/fetcher';
import { DPAE_SERVICE_URL } from '../../constantz';

export const dpaeFindMany = (body: IDpaeSearchParams, disableAlert?: boolean) =>
  fetcher<IDpaeSearchParams, IDpaeSearchResponse>(
    `${DPAE_SERVICE_URL}/search`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );

export const dpaeFindOne = (uuid: string, disableAlert?: boolean) =>
  dpaeFindMany(
    {
      limit: 1,
      skip: 0,
      sort: '',
      filterObject: {
        uuid,
      },
    },
    disableAlert
  );

export const dpaeCreateOne = (
  body: IDpaeCreateParams,
  disableAlert?: boolean
) =>
  fetcher<IDpaeCreateParams, IDpaeCreateResponse>(
    `${DPAE_SERVICE_URL}/create`,
    { method: 'post', body },
    disableAlert
  );

export const dpaeUpdateOne = (
  body: IDpaeUpdateParams,
  disableAlert?: boolean
) =>
  fetcher<IDpaeUpdateParams, IDpaeUpdateResponse>(
    `${DPAE_SERVICE_URL}/update`,
    { method: 'post', body },
    disableAlert
  );

export const dpaeDeleteOne = (uuid: string, disableAlert?: boolean) =>
  fetcher<IDpaeDeleteParams, IDpaeDeleteResponse>(
    `${DPAE_SERVICE_URL}/delete?uuid=${uuid}`,
    { method: 'delete' },
    disableAlert
  );

export const dpaeResendEmail = (
  body: IDpaeResendEmailParams,
  disableAlert?: boolean
) =>
  fetcher<IDpaeResendEmailParams, IDpaeResendEmailResponse>(
    `${DPAE_SERVICE_URL}/resend-email`,
    { method: 'post', body },
    disableAlert
  );
