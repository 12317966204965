import {
  ISalesForceGetMarketsParams,
  ISalesForceGetMarketsResponse,
  ISalesForceGetLinkParams,
  ISalesForceGetLinkResponse,
  ISalesForceGetOpportunityParams,
  ISalesForceGetOpportunityResponse,
  IGetContractDataResponse,
} from '@freelancelabs/teoreme-commons';
import { SALEFORCE_SERVICE_URL } from '../../constantz';
import { fetcher } from '../helpers/fetcher';

export const saleforceGetContractData = (
  identifier: string,
  disableAlert?: boolean
) =>
  fetcher<any, IGetContractDataResponse>(
    SALEFORCE_SERVICE_URL + `/get-contract-data?identifier=${identifier}`,
    undefined,
    disableAlert
  );

export const saleforceGetmarket = (
  body: ISalesForceGetMarketsParams,
  disableAlert?: boolean
) =>
  fetcher<ISalesForceGetMarketsParams, ISalesForceGetMarketsResponse>(
    SALEFORCE_SERVICE_URL + `/get-markets`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
export const saleforceGetLink = (
  body: ISalesForceGetLinkParams,
  disableAlert?: boolean
) =>
  fetcher<ISalesForceGetLinkParams, ISalesForceGetLinkResponse>(
    SALEFORCE_SERVICE_URL + `/get-link`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
export const saleforceGetOpp = (
  body: ISalesForceGetOpportunityParams,
  disableAlert?: boolean
) =>
  fetcher<ISalesForceGetOpportunityParams, ISalesForceGetOpportunityResponse>(
    SALEFORCE_SERVICE_URL + `/get-opp`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );

export const saleforceRestartOppCreation = (
  body: { externalId: string },
  disableAlert?: boolean
) =>
  fetcher<any, any>(
    SALEFORCE_SERVICE_URL + `/restart-opp-creation`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );
