import {
  IContractCreateParams,
  IContractCreateResponse,
  IContractSearchParams,
  IContractSearchResponse,
  IContractGenerateParams,
  IContractGenerateResponse,
  IYousignContractReminderParams,
  IYousignContractReminderResponse,
  IContractCancelParams,
  IContractHistorySearchParams,
  IContractReplaceParams,
} from '@freelancelabs/teoreme-commons';
import { fetcher } from '../helpers/fetcher';
import { CONTRACT_SERVICE_URL, YOUSING_SERVICE_URL } from '../../constantz';

export const contractFindMany = (
  body: IContractSearchParams,
  disableAlert?: boolean
) =>
  fetcher<IContractSearchParams, IContractSearchResponse>(
    `${CONTRACT_SERVICE_URL}/search`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );

export const createContract = (
  body: IContractCreateParams,
  disableAlert?: boolean
) =>
  fetcher<IContractCreateParams, IContractCreateResponse>(
    `${CONTRACT_SERVICE_URL}/create`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );

export const generateContract = (body: any, disableAlert?: boolean) =>
  fetcher<IContractGenerateParams, IContractGenerateResponse>(
    `${CONTRACT_SERVICE_URL}/generate`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );

export const contractReminder = (body: any, disableAlert?: boolean) =>
  fetcher<IYousignContractReminderParams, IYousignContractReminderResponse>(
    `${YOUSING_SERVICE_URL}/contract-reminder`,
    {
      method: 'post',
      body,
    },
    disableAlert
  );

export const updateContractReference = (body: { establishment: string }) =>
  fetcher<{ establishment: string }, string>(
    `${CONTRACT_SERVICE_URL}/update-reference`,
    {
      method: 'post',
      body,
    }
  );

export const cancelContract = (body: IContractCancelParams) =>
  fetcher<{ establishment: string }, string>(`${CONTRACT_SERVICE_URL}/cancel`, {
    method: 'post',
    body,
  });

export const contractSearchHistory = (body: IContractHistorySearchParams) =>
  fetcher<{ establishment: string }, any>(
    `${CONTRACT_SERVICE_URL}/search-history`,
    {
      method: 'post',
      body,
    }
  );
export const replaceContract = (body: IContractReplaceParams) =>
  fetcher<{ establishment: string }, string>(
    `${CONTRACT_SERVICE_URL}/replace`,
    {
      method: 'post',
      body,
    }
  );
