import {
  useUserFindOne,
  REACT_APP_SEED_STAGE_NAME,
  getHumanDate,
  checkUserHasRole,
  ERoles,
  useMe,
} from '@commons';
import {
  CREATED_SF_STATUS,
  NOT_CREATED_SF_STATUS,
  DISPLAY_SF_STATUS,
} from '@commons';
import { Header } from 'components/Header';
import { SideBar } from 'components/SideBar';
import { SideLink } from 'components/SideLink';
import {
  Status,
  StatusVariantColor,
  Avatar,
  Box,
  Container,
  Flex,
  Link,
  Separator,
  SpinnerBox,
  Spinner,
  Text,
  CustomToolTip,
} from 'components/ui';
import { ExclamationCircleIcon, EyeIcon, SendIcon } from 'components/ui/icons';
import { getFirstCaracts, getFullName, impersonateFindOne } from '@commons';
import * as React from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { ProviderContactsEstablishments } from './Establishments';
import { ProviderContactsInformation } from './information';
import { Comments } from './Comment';
import { MissionsList } from './MissionsList';
import PageNotFound from 'routes/NotFound';
import { Theme } from 'styles';

export type InformationPage = {};

export const ContactProviderDetails: React.FunctionComponent<
  React.PropsWithChildren<InformationPage>
> = () => {
  const { me } = useMe();
  const { id } = useParams<{ id: string }>();
  const [isFetchingImperso, setIsFetchingImperso] = React.useState(false);
  const { data: contact, error, status } = useUserFindOne(id);
  const loading = status === 'pending';
  // const [onModal, setOnModal] = React.useState(false);
  const history = useHistory();
  const salesForce = contact?.salesforce;
  if (error) {
    return (
      <PageNotFound
        top={80}
        customText="Le contact que vous cherchez semble introuvable."
      />
    );
  }

  const getContactStatus = (): {
    statusText: string;
    statusColor: StatusVariantColor;
  } => {
    let statusText = '';
    let statusColor: StatusVariantColor = 'grey';
    if (contact?.isConfirmed) {
      if (contact?.onboardingStep?.done) {
        statusText = 'Actif';
        statusColor = 'success';
      } else {
        statusText = `En cours d'embarquement`;
        statusColor = 'warning';
      }
    } else {
      statusText = 'Invitation Envoyée';
      statusColor = 'error';
    }
    return { statusText, statusColor };
  };
  const handleImpersonate = async () => {
    try {
      setIsFetchingImperso(true);
      const providerLink = {
        localhost: 'http://localhost:3001/',
        develop: 'https://develop.connecteed.freelance.com/',
        staging: 'https://staging.connecteed.freelance.com/',
        preprod: 'https://preprod.connecteed.freelance.com/',
        production: 'https://connecteed.freelance.com/',
      };
      const token = await impersonateFindOne({
        cognitoUserId: id,
      });
      const URL =
        providerLink?.[
          REACT_APP_SEED_STAGE_NAME as
            | 'localhost'
            | 'develop'
            | 'staging'
            | 'preprod'
            | 'production'
        ];
      setIsFetchingImperso(false);
      window.open(`${URL}anonyme/${token}`, '_blank');
    } catch (e) {
      setIsFetchingImperso(false);
    }
  };
  const providerName = contact?.firstName
    ? getFullName(contact)
    : contact?.email;
  //@ts-ignore inside
  const emailDelivery = contact?.emailDelivery;
  const emailIsUnreachable = emailDelivery?.isUnreachable;
  return (
    <Box>
      <Header>
        <Flex alignItems="center">
          <Avatar text={contact && getFirstCaracts(contact)} />
          <Flex flexWrap={'wrap'}>
            <Text ml={10} variant="h1">
              {contact && getFullName(contact)}
            </Text>
            {DISPLAY_SF_STATUS && (
              <Box width={1 / 1} ml={10}>
                <Status variantColor={salesForce ? 'success' : 'grey'}>
                  {salesForce ? CREATED_SF_STATUS : NOT_CREATED_SF_STATUS}
                </Status>
              </Box>
            )}

            <Box width={1 / 1} ml={10}>
              <Status variantColor={getContactStatus()?.statusColor ?? 'grey'}>
                {getContactStatus()?.statusText ?? 'contact'}
              </Status>
            </Box>
            {emailIsUnreachable && (
              <Box width={1 / 1} ml={10}>
                <Status variantColor={'warning'}>
                  Email non transmis{' '}
                  {checkUserHasRole(me, ERoles.ADMIN, true) ? (
                    <CustomToolTip
                      id={contact?.cognitoUserId as string}
                      color={Theme?.colors?.warning?.default}
                      //@ts-ignore inside
                      text={`${getHumanDate(emailDelivery?.bounceData?.bouncedAt)} : ${emailDelivery?.bounceData?.bounceSubType}`}
                    >
                      <ExclamationCircleIcon
                        style={{ marginLeft: '10px', cursor: 'help' }}
                        fill={Theme?.colors?.warning?.default}
                      />
                    </CustomToolTip>
                  ) : (
                    <ExclamationCircleIcon
                      style={{ marginLeft: '10px' }}
                      fill={Theme?.colors?.warning?.default}
                    />
                  )}
                </Status>
              </Box>
            )}
          </Flex>
        </Flex>
      </Header>
      <Container display="flex" pt={50}>
        <SideBar>
          <SideLink exact href={`/providers/contacts/${id}/information`}>
            Informations générales
          </SideLink>
          <SideLink exact href={`/providers/contacts/${id}/establishments`}>
            Etablissements
          </SideLink>
          <SideLink exact href={`/providers/contacts/${id}/missions`}>
            Missions
          </SideLink>
          <SideLink exact href={`/providers/contacts/${id}/comments`}>
            Commentaires
          </SideLink>
          <Separator />
          <Link
            onClick={() =>
              history.push('/providers/contacts/send-email', {
                contact: contact,
                forRole: 'PROVIDER',
              })
            }
            isFullWidth
            iconLeft={<SendIcon />}
            mb={10}
            width={'100%'}
          >
            Envoyer un email
          </Link>
          {!isFetchingImperso ? (
            contact?.isConfirmed && !contact?.deactivated ? (
              <Link
                onClick={() => handleImpersonate()}
                isFullWidth
                iconLeft={<EyeIcon />}
                mb={10}
                width={'100%'}
              >
                Se connecter en tant que {providerName}
              </Link>
            ) : (
              ''
            )
          ) : (
            <Flex display="inline-flex" width={1 / 1} justifyContent={'center'}>
              <Spinner />
            </Flex>
          )}
          {/* <Link
            onClick={async () => {
              if (!onModal) {
                setOnModal(true);
                await showDeleteProviderContactModal({
                  contact: contact as FlatUser,
                });
                setOnModal(false);
              }
            }}
            isFullWidth
            iconLeft={<DeleteIcon />}
            mb={10}
            width={'100%'}
          >
            Supprimer le contact
          </Link> */}
        </SideBar>
        <Box flex={1}>
          {loading && <SpinnerBox />}
          {!loading && (
            <Switch>
              <Route exact path="/providers/contacts/:id/information">
                <ProviderContactsInformation />
              </Route>
              <Route exact path="/providers/contacts/:id/establishments">
                <ProviderContactsEstablishments />
              </Route>
              <Route exact path="/providers/contacts/:id/missions">
                <MissionsList />
              </Route>
              <Route exact path="/providers/contacts/:id/comments">
                <Comments />
              </Route>
            </Switch>
          )}
        </Box>
      </Container>
    </Box>
  );
};
