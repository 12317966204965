import * as React from 'react';

function SvgChevronLeftIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M10.333 12l6.256-5.787a1.237 1.237 0 000-1.842 1.433 1.433 0 00-1.925 0l-7.253 6.71a1.236 1.236 0 000 1.841l7.253 6.706c.258.239.605.372.961.372.36 0 .712-.133.973-.384a1.233 1.233 0 00-.012-1.83L10.333 12z"
      />
    </svg>
  );
}

export default SvgChevronLeftIcon;
