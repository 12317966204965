import { IAuthRefreshSessionTokenResponse } from '@freelancelabs/teoreme-commons';
import Axios, { AxiosResponse } from 'axios';
import { AUTH_SERVICE_URL } from '../../constantz';
import { jwtDecode } from 'jwt-decode';
import { REFRESH_TOKEN_BEFORE_MINUTES } from '../../constantz';
import { differenceInMinutes } from 'date-fns';
import { refreshSessionToken } from '../routes/amplifyAuth.routes';
import { cleanSession } from './cleanSession';

/**
 * Will check validity of the provided token and try to refresh in case it's over.
 * @param token jwt token
 * @param refreshToken refresh token
 */
export const checkToken = async (
  token: string | null,
  refreshToken: string | null
) => {
  try {
    if (!token) {
      throw new Error('The token is missing');
    }
    const exp = (jwtDecode(token) as any)?.exp || 0;
    /** If token is expired - try to use the refresh tken */
    const endDate = new Date((exp as number) * 1000);
    const timeLeft = differenceInMinutes(endDate, new Date());
    /* Refresh Token before 10 last minutes */
    if (exp && timeLeft <= REFRESH_TOKEN_BEFORE_MINUTES && timeLeft > 0) {
      if (!refreshToken)
        throw new Error('Token expired - No refresh token has been provided');
      const newToken = await refreshTheToken(refreshToken);
      localStorage.token = newToken;
      localStorage.exp = Number((jwtDecode(newToken) as any)?.exp || 0);
      return newToken;
    } else {
      //the token is still valid
      return token;
    }
  } catch (err) {
    localStorage.clear();
    return null;
  }
};

export const refreshTheToken = async (refreshToken?: string) => {
  let amplifyLogin = true;

  if (amplifyLogin) {
    try {
      let newToken = await refreshSessionToken();
      return newToken;
    } catch (err) {
      cleanSession();
      throw err;
    }
  } else {
    try {
      const response: AxiosResponse = await Axios.post<
        any,
        AxiosResponse<IAuthRefreshSessionTokenResponse>
      >(AUTH_SERVICE_URL + '/refresh-session-token', {
        refreshToken,
      }).catch(err => {
        throw new Error('Cannot refresh the token');
      });
      //@ts-ignore
      const newToken = response?.data?.token;

      if (!newToken) throw new Error('Cannot refresh the token');
      // We have a new token
      localStorage.token = newToken;
      return newToken;
    } catch (err) {
      localStorage.clear();
      throw err;
    }
  }
};
