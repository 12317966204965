import React, { ReactNode } from 'react';
import { ContainerTextInformation } from './styles';
import { BoxProps } from 'components/ui';

type EditCRAPageProps = {
  children?: ReactNode;
  variant: 'primary' | 'error' | 'warning' | 'success';
};

export const TextInformation: React.FC<
  React.PropsWithChildren<EditCRAPageProps & BoxProps>
> = props => {
  return (
    <ContainerTextInformation {...props}>
      {props.children}
    </ContainerTextInformation>
  );
};

TextInformation.displayName = 'TextInformation';

export default TextInformation;
