import * as React from 'react';

function SvgChevronRightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M13.667 12l-6.256 5.787a1.237 1.237 0 000 1.842c.535.495 1.39.495 1.925 0l7.253-6.71a1.236 1.236 0 000-1.841L9.336 4.372A1.42 1.42 0 008.375 4c-.36 0-.712.133-.973.384a1.233 1.233 0 00.012 1.83L13.667 12z"
      />
    </svg>
  );
}

export default SvgChevronRightIcon;
