import { QueryClient } from '@tanstack/react-query';
import { REACT_QUERY_STALETIME, REACT_QUERY_CACHETIME } from '../../constantz';
//https://react-query-v3.tanstack.com/reference/useQuery#_top
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      structuralSharing: false,
      refetchOnMount: true,
      staleTime: REACT_QUERY_STALETIME,
      gcTime: REACT_QUERY_CACHETIME,
      retryOnMount: false, // If passing the boolean to true can generate loops on api error !!
    },
    mutations: {
      //useErrorBoundary: false,
    },
  },
});
