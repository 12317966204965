import {
  EXPENSES_TYPE_TRANSLATE_FR,
  FR_STATUS,
  STAND_BY_DUTY_UNIT_TRANSLATE_FR,
  buildOneLineAddress,
  getDefaultInvoiceAdress,
  getEstablishmentName,
  getFormulaDutyFormulaAmount,
  getFullName,
  isAdditionalActivityInvoice,
  isDisableFONEDate,
  isExpenseInvoice,
  isMileStoneInvoice,
  isStandByDutyInvoice,
  round,
  useAdditionalActivityFindOne,
  useMissionFindOne,
} from '@commons';
import {
  EChangeOwnerType,
  EInvoicePaymentLabel,
  EInvoiceStatus,
  EInvoiceType,
  EMissionStructure,
  EPaymentMethod,
  EStandByDutyUnits,
  IEstablishment,
  IJoinedInvoice,
  IStandByDutyFormulaLine,
} from '@freelancelabs/teoreme-commons';
import Accordion from 'components/Accordion';
import { Section } from 'components/Section';
import { HistoryList } from 'components/lists/HistoryList';
import { EditTimeSpentModal } from 'components/modals/invoices/EditTimeSpentModal';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  Input,
  LabelField,
  StaticSelectControlled,
  Tab,
  Text,
} from 'components/ui';
import { EuroIcon, ExclamationCircleIcon } from 'components/ui/icons';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { columns } from '../HistoryListData';
import { InvoiceLinesForm } from '../InvoiceLinesForm';
import { Theme } from 'styles';
type FormValues = IJoinedInvoice;
type EditTimeSpentFormProps = {
  invoice?: IJoinedInvoice;
};

export const EditTimeSpentForm = ({ invoice }: EditTimeSpentFormProps) => {
  const [formSelected, setFormSelected] = useState<'INFORMATIONS' | 'HISTORY'>(
    'INFORMATIONS'
  );
  const t = useTranslationFormat();
  const { setValue, watch, control, register, handleSubmit } =
    useForm<FormValues>({
      defaultValues: {
        ...invoice,
        totalBeforeTaxes: invoice?.totalBeforeTaxes
          ? Number(invoice?.totalBeforeTaxes?.toFixed(2))
          : 0,
        totalWithTaxes: invoice?.totalWithTaxes
          ? Number(invoice?.totalWithTaxes?.toFixed(2))
          : 0,
        vat: invoice?.vat ? Number(invoice?.vat?.toFixed(2)) : 0,
      },
    });
  const { data: activity } = useAdditionalActivityFindOne(
    invoice?.additionalActivity
  );
  const { data: mission } = useMissionFindOne(invoice?.mission?.reference);
  const [isLoading, setIsLoading] = useState(false);
  // const [loadingFile, setLoadingFile] = useState(false);
  // const [invoiceFormFile, setInvoiceFormFile] = useState<File | null>(null);
  const history = useHistory();
  // const handleRejectInvoice = async () => {
  //   await showRejectInvoiceModal({ invoice }).then(action => {
  //     if (action) {
  //       showMessage('success', 'Facture rejetée');
  //       history.push('/orders/invoices/provider/time-spent/awaiting');
  //     }
  //   });
  // };

  const isEditable =
    invoice?.status === EInvoiceStatus.REJECTED ||
    invoice?.status === EInvoiceStatus.TO_BE_VALIDATED;
  // const invoiceStatus = invoice?.status;
  // const getStatusInfo = () => {
  //   const info = {
  //     text: '',
  //     color: '',
  //   };
  //   if (invoiceStatus === EInvoiceStatus.REJECTED) {
  //     info.text = `Refusée le : ${invoice?.statusChangedAt?.toLocaleDateString()}`;
  //     info.color = 'error';
  //     return info;
  //   }
  //   if (invoiceStatus === EInvoiceStatus.TO_BE_VALIDATED) {
  //     info.text = `A valider`;
  //     info.color = 'warning';
  //     return info;
  //   }
  //   if (invoiceStatus === EInvoiceStatus.VALIDATED) {
  //     info.text = `Validée le : ${invoice?.statusChangedAt?.toLocaleDateString()}`;
  //     info.color = 'success';
  //     return info;
  //   }
  //   return false;
  // };

  const onSubmit = async (formValues: FormValues) => {
    setIsLoading(true);

    setIsLoading(false);
  };
  // const handleChangeInvoiceFile = async (files: File[]) => {
  //   setLoadingFile(true);
  //   setInvoiceFormFile(files[0]);
  //   setLoadingFile(false);
  // };

  const isVat = watch('isVat');
  const vat = watch('vat');
  const totalBeforeTaxes = watch('totalBeforeTaxes');
  const deductionAmount = watch('deductionAmount');
  const isMarginInvoice = invoice?.isMarginInvoice ? ' (marge)' : '';
  const getRate = () => {
    if (invoice?.mission?.isMandateMode) {
      if (invoice?.isMarginInvoice) {
        return invoice?.clientRate || 0;
      } else {
        return invoice?.contractorRate || 0;
      }
    } else {
      return invoice?.clientRate || 0;
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/*<ConfirmNavigationModal active={isDirty} />*/}
      <EditTimeSpentModal
        invoiceType="CUSTOMER"
        isEditable={false}
        isLoading={isLoading}
        title={`Facture client ${t(
          `texts:INVOICETYPE_${invoice?.invoiceType}`
        )} ${isMarginInvoice}`}
        onReject={() => false}
        onClose={() => {
          history?.action === 'PUSH'
            ? history?.goBack()
            : history.push('/orders/invoices/customer/time-spent/projected');
        }}
      >
        <Box pl={'20px'} pr={'20px'} pt={'20px'}>
          <Flex mb={10}>
            <Tab
              onClick={() => setFormSelected('INFORMATIONS')}
              isSelected={formSelected === 'INFORMATIONS'}
              variant="secondary"
              fontSize={12}
            >
              Informations
            </Tab>
            <Tab
              onClick={() => setFormSelected('HISTORY')}
              isSelected={formSelected === 'HISTORY'}
              variant="secondary"
              fontSize={12}
            >
              Historique
            </Tab>
          </Flex>
          {formSelected === 'HISTORY' && (
            <Flex
              flexWrap={'wrap'}
              overflow={'hidden'}
              height={'calc(100vh - 90px)'}
            >
              <Box
                width={3 / 5}
                flex={1}
                overflow={'scroll'}
                height={'calc(100vh - 180px)'}
                pl={'20px'}
                pr={'20px'}
                pt={'20px'}
              >
                <HistoryList
                  columns={columns}
                  filterObject={{
                    ownerId: invoice?.uuid,
                    ownerType: EChangeOwnerType?.INVOICE,
                  }}
                />
              </Box>
            </Flex>
          )}
          {formSelected === 'INFORMATIONS' && (
            <>
              {isDisableFONEDate() &&
                invoice?.mission?.billingInformation?.structure ===
                  EMissionStructure.FONE && (
                  <Flex alignItems={'center'}>
                    <Box width={50}>
                      <ExclamationCircleIcon
                        fontSize={30}
                        fill={Theme?.colors?.error?.default}
                      />
                    </Box>

                    <Box borderRadius={5} border="1px solid red" p={10}>
                      <Text variant="p">
                        <b color="red">
                          Attention depuis le 1 janvier 2024, si vous identifiez
                          une facture émise depuis la structure FONE, il faut
                          alerter Cécile ou Romain.
                        </b>
                      </Text>
                    </Box>
                  </Flex>
                )}
              <Section mt={20} variant="h2" title={'1. Établissement Client'}>
                <Box>
                  <FormControl label="Référence client">
                    <Input
                      width={2 / 3}
                      isDisabled
                      value={invoice?.customerReference}
                    />
                  </FormControl>
                  <Flex>
                    <LabelField
                      underline
                      label="Raison sociale"
                      value={getEstablishmentName(
                        invoice?.estCustomer as IEstablishment,
                        80
                      )}
                    />
                    <LabelField
                      underline
                      label="N°Siret"
                      value={invoice?.estCustomer?.siret}
                    />
                    <LabelField
                      underline
                      label="N°TVA"
                      value={invoice?.vatNumber}
                    />
                  </Flex>
                  <Flex>
                    <LabelField
                      underline
                      label="Adresse de facturation"
                      value={buildOneLineAddress(
                        invoice?.invoiceIssue?.invoiceAddress ||
                          invoice?.mission?.customer?.billingOptions
                            ?.invoiceAddress ||
                          getDefaultInvoiceAdress(invoice?.estCustomer) ||
                          invoice?.estCustomer?.address
                      )}
                    />
                    <LabelField
                      underline
                      label="Conditions de règlement"
                      value={
                        <Flex flexWrap="wrap">
                          <Box width={1 / 1}>
                            Mode de règlement :{' '}
                            {FR_STATUS?.[
                              invoice?.estCustomer?.customer?.paymentDetails
                                ?.paymentMethod as EPaymentMethod
                            ] || 'N/A'}
                          </Box>
                          <Box width={1 / 1}>
                            Délai de paiement :{' '}
                            {invoice?.paymentDeadline as number} jour(s){' '}
                            {
                              FR_STATUS?.[
                                invoice?.invoiceIssue
                                  ?.paymentLabel as EInvoicePaymentLabel
                              ]
                            }
                          </Box>
                        </Flex>
                      }
                    />
                  </Flex>
                </Box>
              </Section>
              <Section mt={20} variant="h2" title={'2. Mission'}>
                <Flex>
                  <LabelField
                    underline
                    label="Référence"
                    value={invoice?.mission?.displayReference}
                  />
                  {!isMileStoneInvoice(invoice?.invoiceType as EInvoiceType) &&
                    (invoice?.month ? (
                      <LabelField
                        underline
                        label="Mois"
                        value={`${
                          invoice?.month && invoice?.month?.getUTCMonth() + 1
                        } / ${
                          invoice?.month && invoice?.month?.getUTCFullYear()
                        } `}
                      />
                    ) : (
                      ''
                    ))}
                  <LabelField
                    underline
                    label="Intervenant"
                    value={getFullName(invoice?.contractor)}
                  />
                </Flex>
                <Flex mt={20} flexWrap="wrap" justifyContent="space-between">
                  <Box
                    hidden={
                      isStandByDutyInvoice(
                        invoice?.invoiceType as EInvoiceType
                      ) ||
                      isExpenseInvoice(invoice?.invoiceType as EInvoiceType) ||
                      isMileStoneInvoice(invoice?.invoiceType as EInvoiceType)
                    }
                  >
                    <FormControl label="TJM PAYÉ PAR LE CLIENT">
                      <Input
                        isDisabled
                        value={
                          !invoice?.isMarginInvoice && mission?.isMandateMode
                            ? invoice?.contractorRate
                            : invoice?.clientRate
                        }
                        type="number"
                        icon={<EuroIcon />}
                      />
                    </FormControl>
                    <FormControl label="Nombre de jours travaillés">
                      <Input
                        isDisabled
                        value={invoice?.nbWorkingDays}
                        maxDecimal={2}
                        step={'0.01'}
                        type="number"
                      />
                    </FormControl>
                  </Box>
                  {isStandByDutyInvoice(
                    invoice?.invoiceType as EInvoiceType
                  ) && (
                    <>
                      <Accordion
                        defaultOpen
                        openOnTop
                        propsBar={{
                          //borderRadius: '8px',
                          backgroundColor: `rgb(251,250,250)`,
                          //border: '1px solid #d0d4e3',
                        }}
                        childrenTop={
                          <Text width={23 / 24} variant="h2">
                            Types de prestation complémentaire
                          </Text>
                        }
                        key={`STAND_BY_DUTIES`}
                      >
                        <Flex
                          width={12 / 12}
                          p={'12px'}
                          justifyContent="flex-wrap"
                          flexWrap="wrap"
                        >
                          {activity?.standByDutyLines?.map(
                            (sdb: any, index: number) => {
                              const formula =
                                mission?.standByDutyFormulas?.find(
                                  f =>
                                    f?.uuid === sdb?.standByDutyFormulaLineUuid
                                ) as IStandByDutyFormulaLine;
                              return (
                                <>
                                  <Box width={2 / 12}>
                                    <LabelField
                                      underline
                                      label="Type de prestation"
                                      value={formula?.name}
                                    />
                                  </Box>
                                  <Box width={4 / 12} pl={'5px'}>
                                    <LabelField
                                      underline
                                      label="Description"
                                      value={formula?.description}
                                    />
                                  </Box>
                                  <Box width={2 / 12}>
                                    <LabelField
                                      label="Déclaré"
                                      underline
                                      value={`${sdb?.formulaParameters?.INPUT} ${
                                        STAND_BY_DUTY_UNIT_TRANSLATE_FR[
                                          formula?.unit
                                        ]
                                      }`}
                                    />
                                  </Box>
                                  <Box width={2 / 12}>
                                    <LabelField
                                      underline
                                      label="Tarif unitaire HT"
                                      value={`${
                                        formula?.unit ===
                                        EStandByDutyUnits.EUROS
                                          ? //sdb?.formulaParameters?.INPUT
                                            round(
                                              (getFormulaDutyFormulaAmount(
                                                sdb,
                                                'CUSTOMER'
                                              ) /
                                                sdb?.formulaParameters
                                                  ?.INPUT) as number
                                            )
                                          : //@ts-ignore
                                            round(
                                              (getFormulaDutyFormulaAmount(
                                                sdb,
                                                'CUSTOMER'
                                              ) /
                                                sdb?.formulaParameters
                                                  ?.INPUT) as number
                                            )
                                      } €`}
                                    />
                                  </Box>
                                  <Box width={2 / 12}>
                                    <LabelField
                                      underline
                                      label="Montant HT"
                                      value={`${getFormulaDutyFormulaAmount(
                                        sdb,
                                        'CUSTOMER'
                                      )} €`}
                                    />
                                  </Box>
                                  {activity?.standByDutyLines &&
                                    index !==
                                      activity?.standByDutyLines?.length -
                                        1 && (
                                      <Box width={12 / 12}>
                                        <Divider mb={10} mt={10} />
                                      </Box>
                                    )}
                                </>
                              );
                            }
                          )}
                        </Flex>
                        <Divider mb={10} mt={10} />
                      </Accordion>
                    </>
                  )}
                  {isExpenseInvoice(invoice?.invoiceType as EInvoiceType) && (
                    <>
                      <Accordion
                        defaultOpen
                        openOnTop
                        propsBar={{
                          //borderRadius: '8px',
                          backgroundColor: `rgb(251,250,250)`,
                          //border: '1px solid #d0d4e3',
                        }}
                        childrenTop={
                          <Text width={23 / 24} variant="h2">
                            Types de frais
                          </Text>
                        }
                        key={`EXEPENSES`}
                      >
                        <Flex
                          width={12 / 12}
                          p={'12px'}
                          justifyContent="flex-wrap"
                          flexWrap="wrap"
                        >
                          {activity?.expenseLines?.map(
                            (expense: any, index: number) => {
                              return (
                                <>
                                  <Box width={2 / 12}>
                                    <LabelField
                                      underline
                                      label="Type de frais"
                                      value={
                                        EXPENSES_TYPE_TRANSLATE_FR[
                                          expense?.type as keyof typeof EXPENSES_TYPE_TRANSLATE_FR
                                        ]
                                      }
                                    />
                                  </Box>
                                  <Box width={4 / 12} pl={'5px'}>
                                    <LabelField
                                      underline
                                      label="Informations complémentaires"
                                      value={expense?.comment || 'N/A'}
                                    />
                                  </Box>
                                  <Box width={2 / 12}>
                                    <LabelField
                                      label="Montant HT"
                                      underline
                                      value={`${
                                        expense?.amount *
                                          ((activity?.customerMargin || 0) /
                                            100) +
                                        expense?.amount
                                      } €`}
                                    />
                                  </Box>
                                  {activity?.expenseLines &&
                                    index !==
                                      activity?.expenseLines?.length - 1 && (
                                      <Box width={12 / 12}>
                                        <Divider mb={10} mt={10} />
                                      </Box>
                                    )}
                                </>
                              );
                            }
                          )}
                        </Flex>
                        <Divider mb={10} mt={10} />
                      </Accordion>
                    </>
                  )}
                  <FormControl label="Montant HT">
                    <Input
                      isDisabled
                      value={
                        !isAdditionalActivityInvoice(
                          invoice?.invoiceType as EInvoiceType
                        )
                          ? round(
                              (invoice?.nbWorkingDays as number) * getRate()
                            )
                          : round(invoice?.totalBeforeTaxes as number)
                      }
                      maxDecimal={2}
                      step={'0.01'}
                      type="number"
                      icon={<EuroIcon />}
                    />
                  </FormControl>
                </Flex>
              </Section>
              <Divider />
              <Section
                mt={20}
                variant="h2"
                title={'3. Services complémentaires et autres frais'}
              >
                <InvoiceLinesForm activity={activity} invoice={invoice} />
              </Section>
              <Divider />

              <Flex justifyContent="flex-end">
                <Box width={6 / 10} />
                <Box width={3 / 10}>
                  <FormControl label={'Montant Net (HT)'}>
                    <Input
                      value={invoice?.totalBeforeTaxes}
                      isDisabled
                      isFullWidth
                      type="number"
                      maxDecimal={2}
                      step={'0.01'}
                      icon={<EuroIcon />}
                    />
                  </FormControl>
                </Box>
              </Flex>
              <Divider />
              <Box>
                <Section mt={20} variant="h2" title={'4. TVA'}>
                  <Flex mt={20} justifyContent="space-between">
                    <>
                      <Box width={2 / 10}>
                        <FormControl required label={'% TVA'}>
                          <StaticSelectControlled
                            isDisabled={!isEditable || (isEditable && !isVat)}
                            control={control}
                            name="vatRate"
                            onChange={(value: any) => {
                              const vatNumber = value[0];
                              //@ts-ignore
                              setValue('vatRate', vatNumber);
                              //@ts-ignore
                              setValue(
                                'totalWithTaxes',
                                Number(totalBeforeTaxes) -
                                  Number(deductionAmount) +
                                  (Number(totalBeforeTaxes) / 100) * vatNumber
                              );
                              return vatNumber;
                            }}
                            options={[
                              { value: 20, label: '20%' },
                              { value: 10, label: '10%' },
                              { value: 5.5, label: '5,5%' },
                              { value: 2.1, label: '2,1%' },
                            ]}
                            placeholder="% TVA"
                          />
                        </FormControl>
                      </Box>
                      <Box width={2 / 10}>
                        <FormControl label={'Montant TVA'}>
                          <Input
                            isDisabled
                            isFullWidth
                            {...register('vat')}
                            maxDecimal={2}
                            step={'0.01'}
                            value={vat}
                            type="number"
                            icon={<EuroIcon />}
                          />
                        </FormControl>
                      </Box>
                      <Box width={2 / 10}>
                        <FormControl label={'Montant Total TTC'}>
                          <Input
                            isDisabled
                            isFullWidth
                            {...register('totalWithTaxes')}
                            type="number"
                            maxDecimal={2}
                            step={'0.01'}
                            icon={<EuroIcon />}
                            value={round(invoice?.totalWithTaxes as number)}
                          />
                        </FormControl>
                      </Box>
                    </>
                  </Flex>
                </Section>
                <Section mt={20} variant="h2" title={'Commentaire interne'}>
                  <Text variant="p">
                    {invoice?.comment || 'Aucun commentaire'}
                  </Text>
                </Section>
              </Box>
            </>
          )}
        </Box>
      </EditTimeSpentModal>
    </form>
  );
};
