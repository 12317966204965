import { addHours, addMinutes, format, subHours, subMinutes } from 'date-fns';
import { IHolidays } from '@freelancelabs/teoreme-commons';
import {
  differenceInCalendarDays,
  differenceInCalendarISOWeeks,
  eachWeekendOfInterval,
  getDaysInMonth,
  isBefore,
  isSameDay,
  isSameMonth,
  isWeekend,
  isWithinInterval,
  setMilliseconds,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { toZonedTime } from 'date-fns-tz';
import { MONTH_FR } from '../constantz';
import { transformText } from './transform';
import { REACT_APP_FONE_PURGATORY_DATE } from '../constantz';

export const formatDate = (date: string | Date | null): string | null => {
  if (!date) {
    return date;
  }

  const dateObj = new Date(date);
  return new Intl.DateTimeFormat('fr-FR').format(dateObj);
};

export const formatDateAndTime = (
  date: string | Date | null
): string | null => {

  if (!date) {
    return date;
  }
  return `Le ${format(
    toZonedTime(new Date(date), 'Europe/Paris'),
    ' dd MMMM yyyy à HH:mm',
    {
      locale: fr,
    }
  )}`;
};
export const formatDateAndTimeComment = (
  date: string | Date | null
): string | null => {
  if (!date) {
    return date;
  }
  return `${format(
    toZonedTime(new Date(date), 'Europe/Paris'),
    ' dd/MM/yyyy à HH:mm',
    {
      locale: fr,
    }
  )}`;
};
export const getHumanDate = (
  date: string | Date | null,
  theFormat?: string
): string | null => {
  if (!date) {
    return date;
  }
  return `${format(
    toZonedTime(new Date(date), 'Europe/Paris'),
    theFormat || 'dd/MM/yyyy',
    {
      locale: fr,
    }
  )}`;
};
export const getHumanDateMonthAndYear = (
  date: string | Date | null,
  capitalizeFirst = true
) => {
  if (!date) {
    return date;
  }
  const jsDate = new Date(date);
  const result = `${
    MONTH_FR[jsDate?.getUTCMonth()]
  } ${jsDate?.getUTCFullYear()}`;
  if (capitalizeFirst) {
    return transformText(result, 'capitalize-first');
  } else {
    return result;
  }
};
export const getNumberWeeks = (startAt: Date, endAt: Date) => {
  return differenceInCalendarISOWeeks(endAt, startAt);
};

export const dateAreSameMonth = (startAt: Date, endAt: Date) => {
  return isSameMonth(startAt, endAt);
};
export const dateIsWithinInterval = (
  date: Date,
  startIntervalDate: Date,
  endIntervalDate: Date
) => {
  return isWithinInterval(date, {
    start: startIntervalDate,
    end: endIntervalDate,
  });
};

export const dateIsWithinIntervalByMonth = (
  date: Date,
  startIntervalDate: Date,
  endIntervalDate: Date
) => {
  const startIntervalDateAtFirstDay = new Date(startIntervalDate).setDate(1);
  const lastDay = getDaysInMonth(endIntervalDate);
  const endIntervalDateAtLastDay = new Date(endIntervalDate).setDate(lastDay);
  return isWithinInterval(date, {
    start: removeOffsetDate(new Date(startIntervalDateAtFirstDay)),
    end: removeOffsetDate(new Date(endIntervalDateAtLastDay)),
  });
};

export const getFirstDayOfMonth = (date: Date) => {
  return removeOffsetDate(
    new Date(date.getUTCFullYear(), date.getUTCMonth(), 1)
  );
};
export const getLastDayOfMonth = (date: Date) => {
  return removeOffsetDate(
    new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)
  );
};

export const getHolidaysWithinInterval = (
  startAt: Date,
  endAt: Date,
  holidays: IHolidays[]
) => {
  return holidays?.filter(day =>
    dateIsWithinInterval(new Date(day?.date), startAt, endAt)
  );
};

export const getHolidaysWithinIntervalByMonth = (
  startAt: Date,
  endAt: Date,
  holidays: IHolidays[]
) => {
  return holidays?.filter(day =>
    dateIsWithinIntervalByMonth(new Date(day?.date), startAt, endAt)
  );
};

export const getWorkingDays = (startAt: Date, endAt: Date) => {
  if (
    isSameDay(endAt, startAt) &&
    eachWeekendOfInterval({
      start: startAt,
      end: startAt,
    }).length === 0
  ) {
    return 1;
  }
  if (isBefore(endAt, startAt)) {
    return 0;
  }

  startAt = toZonedTime(startAt, 'Europe/Paris');
  endAt = toZonedTime(endAt, 'Europe/Paris');
  const weekendDaysNumber = eachWeekendOfInterval({
    start: startAt,
    end: endAt,
  }).length;
  // + 1 to include first day
  const allDays = differenceInCalendarDays(endAt, startAt) + 1;
  return allDays - weekendDaysNumber;
};

/**
 * return the holidays that fall on a week day
 * @param holidays
 * @returns holidays
 */

export const getHolidaysInWeek = (holidays: IHolidays[]) => {
  return holidays?.filter(
    holiday =>
      !isWeekend(toZonedTime(new Date(holiday?.date), 'Europe/Paris'))
  );
};

export const getWorkingDaysWithoutHolidays = (
  startAt: Date,
  endAt: Date,
  holidays: IHolidays[]
) => {
  const weekHolidays = getHolidaysInWeek(holidays) ?? [];
  const holidaysInInterval = getHolidaysWithinInterval(
    startAt,
    endAt,
    weekHolidays
  );
  const holidaysNumber = holidaysInInterval?.length
    ? holidaysInInterval?.length
    : 0;
  const nbWorkingDays = getWorkingDays(startAt, endAt);
  return nbWorkingDays - holidaysNumber;
};

/*
To detect duplicate contractors, an exact match on the date is necessary
Transforms a date with time into a date at 00:00
              GMT+0200
ex: "1992-03-21T22:00:00.000Z"   => "1992-03-22T00:00:00.000Z"
*/
export const setBirthdayDateToZeroHours = (birthday: Date) => {
  let formatedDate = new Date(birthday);
  formatedDate.setHours(0, 0, 0);
  const offset = formatedDate.getTimezoneOffset() / 60;
  if (offset < 0) {
    formatedDate = addHours(formatedDate, Math.abs(offset));
    formatedDate = setMilliseconds(formatedDate, 0);
  } else {
    formatedDate = subHours(formatedDate, Math.abs(offset));
    formatedDate = setMilliseconds(formatedDate, 0);
  }
  return formatedDate;
};

/*
the api sage does not use the timezone and only takes the days, the month and the year of a date
Transforms a date with time into a date at 00:00
              GMT+0200
ex: "1992-03-21T22:00:00.000Z"   => "1992-03-22T00:00:00.000Z"
*/
export const removeOffsetDate = (date: Date) => {
  let formatedDate = new Date(date);
  formatedDate.setHours(0, 0, 0);
  const offset = formatedDate.getTimezoneOffset() / 60;
  const hours = offset.toFixed(0);
  const minutes = (offset - Number(hours)) * 60;
  if (offset < 0) {
    formatedDate = addHours(formatedDate, Math.abs(offset));
    formatedDate = addMinutes(formatedDate, minutes);
    formatedDate = setMilliseconds(formatedDate, 0);
  } else {
    formatedDate = subHours(formatedDate, Math.abs(offset));
    formatedDate = subMinutes(formatedDate, minutes);
    formatedDate = setMilliseconds(formatedDate, 0);
  }
  return formatedDate;
};
export const displayMonth = (date: Date | string) => {
  if (date) {
    const jsDate = new Date(date);
    const year = jsDate?.getUTCFullYear();
    const month = jsDate?.getUTCMonth() + 1;
    return `${month < 10 ? '0' + month : month} / ${year}`;
  }
  return date;
};

export const setDateTimeToZero = (date: Date) => {
  const dateCopy = new Date(date); // avoid changing the original date and make sure we're dealing with a Date object
  dateCopy.setUTCHours(0);
  dateCopy.setUTCMinutes(0);
  dateCopy.setUTCSeconds(0);
  dateCopy.setUTCMilliseconds(0);

  return dateCopy;
};

export const daysDiffBetweenDates = (
  mostRecentDate: Date = new Date(),
  pastDate: Date = new Date()
): number => {
  const difference = mostRecentDate.getTime() - pastDate.getTime();
  const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));

  return TotalDays;
};
//2023-03-01
export const createDateToUrl = (date: Date) => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  return `${year}-${month < 10 ? '0' + month : month}-${
    day < 10 ? '0' + day : day
  }`;
};

export const isDisableFONEDate = () => {
  if (REACT_APP_FONE_PURGATORY_DATE) {
    //@ts-ignore
    if (isBefore(new Date(), new Date(REACT_APP_FONE_PURGATORY_DATE))) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};
