import {
  ECustomerReferencePatternVars,
  EStandByDutyFormulaVars,
  IActivityReportData,
  IChangeRetentionPolicy,
} from '@freelancelabs/teoreme-commons';
import { DURATION_TAG_WHITELIST } from '../constantz';
export const transformText = (
  text: string | null | undefined,
  transformProperty: string,
  replaceRegex?: any
): string => {
  if (!text) return '';

  let transformedText = text;
  if (replaceRegex) {
    transformedText = transformedText.replace(replaceRegex, '');
  }
  if (transformProperty === 'uppercase') {
    transformedText = transformedText.toUpperCase();
  } else if (transformProperty === 'capitalize-first') {
    transformedText = transformedText.charAt(0).toUpperCase() + text.slice(1);
  } else if (transformProperty === 'lowercase') {
    transformedText = transformedText.toLowerCase();
  } else if (transformProperty === 'capitalize') {
    let splitText = transformedText.split('-');
    for (let i = 0; i < splitText.length; i++) {
      splitText[i] =
        splitText[i].charAt(0).toUpperCase() +
        splitText[i].substring(1).toLowerCase();
    }
    transformedText = splitText.join('-');
    splitText = transformedText.split(' ');
    for (let i = 0; i < splitText.length; i++) {
      splitText[i] =
        splitText[i].charAt(0).toUpperCase() + splitText[i].substring(1);
    }
    transformedText = splitText.join(' ');
  }
  return transformedText;
};

export const getScopedDataBySiret = (scopedData: any, siret: string) => {
  const index = scopedData?.findIndex(
    (data: any) => data?.establishment?.siret === siret
  );
  return scopedData?.[index];
};
export const getScopedDataByKey = (
  scopedData: any,
  key: string,
  valueMatch: any
) => {
  const index = scopedData?.findIndex(
    (data: any) => data?.establishment?.[key] === valueMatch
  );
  return scopedData?.[index];
};
export const getScopedDataMinified = (scopedData: any, estaUuid: any) => {
  const index = scopedData?.findIndex(
    (data: any) => data?.establishment === estaUuid
  );
  return scopedData?.[index];
};

export const buildContractorByScopedData = (
  contractor: any,
  scopedData: any
) => {
  const contractorByScopedData = contractor;
  return {
    ...contractorByScopedData,
    ...scopedData,
    email: contractor?.user?.email || contractor?.email,
    alternateEmail: contractor?.alternateEmail || scopedData?.email,
  };
};

export const transformTagToFormula = (value: string) => {
  const formatValue = value;
  const getVar = formatValue?.split('[[');
  let strJs = '';
  if (getVar?.length > 1) {
    getVar?.forEach(element => {
      if (element?.length > 0) {
        // IS TAG
        if (element?.includes(']]')) {
          const tag = element?.split(']]');
          try {
            const tagParsed = JSON?.parse(tag[0]);
            if (tag?.[1] !== undefined) {
              strJs = strJs += tagParsed?.var + tag[1];
            }
          } catch (e) {
            // tag is not var return string
            strJs = strJs += tag?.[0];
          }
          // IS NOT TAG
        } else {
          strJs = strJs += element;
        }
      }
    });
  } else {
    return value;
  }
  return strJs;
};
/*  !! THIS FUNCTION MUST RECEIVE THE FORMAT :
 * { id: number; value: string; var: string } !!!
 * TODO IF FORMAT IS NOT COMPATIBLE CHANGE FORMAT wlvalue
 */
export const transformFormulaToTag = (
  value: string,
  whiteList: { id: number; value: string; var: string }[],
  customVars?: any
) => {
  const allVars = {
    ...EStandByDutyFormulaVars,
    ...ECustomerReferencePatternVars,
  };
  const formatValue = value;
  let strJs = formatValue;
  Object?.keys(customVars ? customVars : allVars)?.forEach(key => {
    const varKey = `{{${key}}}`;
    // const split = formatValue?.split(varKey);

    strJs = strJs?.replaceAll(`\{\{${key}\}\}`, (m, i) => {
      const wl = whiteList?.filter(wl => wl?.var === varKey);
      const tag = wl?.[0];
      const wlprefix = `}}\",\"prefix\":\"@\"}`;
      const wlOthersData = `{\"id\":${tag?.id},\"value\":\"${tag?.value}\",\"var\":\"{{`;
      return `[[${wlOthersData}${key}${wlprefix}]]`;
    });
  });
  return strJs;
};
// convert { days: 10, months: 20, years: 30 } TO
//'10 [[{"id":1,"value":"Jours","var":"{{DAYS}}","prefix":"@"}]] 20 [[{"id":2,"value":"Mois","var":"{{MONTHS}}","prefix":"@"}]] 30[[{"id":3,"value":"Années","var":"{{YEARS}}","prefix":"@"}]]'
export const transformDurationToTag = (
  obj: IChangeRetentionPolicy['duration']
) => {
  const days = obj?.days;
  const months = obj?.months;
  const years = obj?.years;
  const hours = obj?.hours;
  const minutes = obj?.minutes;
  const seconds = obj?.seconds;
  const resultArray = [];

  if (seconds !== undefined) {
    resultArray.push(`${seconds} {{SECONDS}}`);
  }
  if (minutes !== undefined) {
    resultArray.push(`${minutes} {{MINUTES}}`);
  }
  if (hours !== undefined) {
    resultArray.push(`${hours} {{HOURS}}`);
  }
  if (days !== undefined) {
    resultArray.push(`${days} {{DAYS}}`);
  }
  if (months !== undefined) {
    resultArray.push(`${months} {{MONTHS}}`);
  }
  if (years !== undefined) {
    resultArray.push(`${years} {{YEARS}}`);
  }

  let str = '';
  resultArray?.forEach(r => (str = str + r));
  const customVars = {
    SECONDS: 'SECONDS',
    MINUTES: 'MINUTES',
    HOURS: 'HOURS',
    DAYS: 'DAYS',
    MONTHS: 'MONTHS',
    YEARS: 'YEARS',
  };
  return transformFormulaToTag(str, DURATION_TAG_WHITELIST, customVars);
};
// convert 10 {{DAYS}} 20 {{MONTHS}} 30{{YEARS}} TO
//{ days: 10, months: 20, years: 30 }
export const transformDurationFormulaToObject = (str: string) => {
  const vars = ['SECONDS', 'MINUTES', 'HOURS', 'DAYS', 'MONTHS', 'YEARS'];
  let strclean = str?.replaceAll('{{', '+')?.replaceAll('}}', '+');
  const strSplitted = strclean?.split('+');
  let obj = {};
  strSplitted?.forEach((sp, index) => {
    let next = strSplitted[index + 1];
    const isVar = vars?.includes(next);
    if (next) {
      if (isVar) {
        let key = next?.toLocaleLowerCase();
        let value = Number(sp?.trim());
        if (!isNaN(value)) {
          //@ts-ignore
          obj[key] = value;
        }
      }
    }
  });
  if (Object.keys(obj)?.length === 0) {
    return undefined;
  }
  return obj;
};
export const transformActivitiesForTable = (
  activitiesData: IActivityReportData[]
) => {
  if (activitiesData) {
    return activitiesData?.map((ac: IActivityReportData) => {
      return {
        key: ac?.cra?.refCra,
        ...ac,
        isOpen: ac?.cra?.isOpen,
        type: 'CRA',
        state: ac?.cra?.state,
        children: [
          {
            key: `CRA_${'cra'}`,
            ...ac?.cra,
            mission: ac?.mission,
            type: 'CRA',
          },
          ...ac?.standByDuties?.map((sd, index) => {
            return {
              key: `DUTY_${index}`,
              ...sd,
              isOpen: ac?.cra?.isOpen,
              state: sd?.status,
              mission: ac?.mission,
              type: 'DUTY',
            };
          }),
          ...ac?.expenses?.map((f, index) => {
            return {
              key: `FEES_${index}`,
              ...f,
              isOpen: ac?.cra?.isOpen,
              state: f?.status,
              mission: ac?.mission,
              type: 'FEES',
            };
          }),
        ],
      };
    });
  }
  return [];
};

type customerRefPatternVars = {
  [ECustomerReferencePatternVars.CRA_FULL_YEAR]?: string | undefined;
  [ECustomerReferencePatternVars.CRA_YEAR]?: string | undefined;
  [ECustomerReferencePatternVars.CRA_MONTH]?: string | undefined;
  [ECustomerReferencePatternVars.MISSION_REF]?: string | undefined;
  [ECustomerReferencePatternVars.CUSTOMER_REF]?: string | undefined;
};

export const getCustomerRefPattern = (
  customerRefPattern: string | undefined,
  vars: customerRefPatternVars
): string | undefined => {
  if (customerRefPattern) {
    let str = customerRefPattern;
    Object?.keys(vars)?.forEach(key => {
      //@ts-ignore
      str = str?.replaceAll(`{{${key}}}`, vars?.[key]);
    });
    return str;
  }
  return vars?.CUSTOMER_REF;
};
