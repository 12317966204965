import {
  EInvoiceType,
  EadditionalActivityType,
} from '@freelancelabs/teoreme-commons';
/**
 * Mapping which determine which type of credit note should be used
 * for which invoice type.
 */
export const CREDIT_INVOICE_TYPE_MAPPING = {
  [EInvoiceType.CUSTOMER]: EInvoiceType.CUSTOMER_CREDIT_NOTE,
  [EInvoiceType.CUSTOMER_EXPENSE]: EInvoiceType.CUSTOMER_EXPENSE_CREDIT_NOTE,
  [EInvoiceType.CUSTOMER_STAND_BY_DUTY]:
    EInvoiceType.CUSTOMER_STAND_BY_DUTY_CREDIT_NOTE,
  [EInvoiceType.CUSTOMER_MILESTONE]:
    EInvoiceType.CUSTOMER_MILESTONE_CREDIT_NOTE,
  [EInvoiceType.PROVIDER]: EInvoiceType.PROVIDER_CREDIT_NOTE,
  [EInvoiceType.PROVIDER_EXPENSE]: EInvoiceType.PROVIDER_EXPENSE_CREDIT_NOTE,
  [EInvoiceType.PROVIDER_STAND_BY_DUTY]:
    EInvoiceType.PROVIDER_STAND_BY_DUTY_CREDIT_NOTE,
  [EInvoiceType.PROVIDER_MILESTONE]:
    EInvoiceType.PROVIDER_MILESTONE_CREDIT_NOTE,
};
/**
 * Mapping which determine the opposite invoice type (customer => provider)
 */
export const OPPOSITE_INVOICE_TYPE_MAPPING = {
  [EInvoiceType.CUSTOMER]: EInvoiceType.PROVIDER,
  [EInvoiceType.CUSTOMER_EXPENSE]: EInvoiceType.PROVIDER_EXPENSE,
  [EInvoiceType.CUSTOMER_STAND_BY_DUTY]: EInvoiceType.PROVIDER_STAND_BY_DUTY,
  [EInvoiceType.CUSTOMER_MILESTONE]: EInvoiceType.PROVIDER_MILESTONE,
  [EInvoiceType.PROVIDER]: EInvoiceType.CUSTOMER,
  [EInvoiceType.PROVIDER_EXPENSE]: EInvoiceType.CUSTOMER_EXPENSE,
  [EInvoiceType.PROVIDER_STAND_BY_DUTY]: EInvoiceType.CUSTOMER_STAND_BY_DUTY,
  [EInvoiceType.PROVIDER_MILESTONE]: EInvoiceType.CUSTOMER_MILESTONE,
};
/**
 * List of the PROVIDER invoice types
 */
export const PROVIDER_INVOICE_TYPES = [
  EInvoiceType.PROVIDER,
  EInvoiceType.PROVIDER_CREDIT_NOTE,
  EInvoiceType.PROVIDER_EXPENSE,
  EInvoiceType.PROVIDER_EXPENSE_CREDIT_NOTE,
  EInvoiceType.PROVIDER_STAND_BY_DUTY,
  EInvoiceType.PROVIDER_STAND_BY_DUTY_CREDIT_NOTE,
  EInvoiceType.PROVIDER_MILESTONE,
  EInvoiceType.PROVIDER_MILESTONE_CREDIT_NOTE,
];
/**
 * List of the CUSTOMER invoice types
 */
export const CUSTOMER_INVOICE_TYPES = [
  EInvoiceType.CUSTOMER,
  EInvoiceType.CUSTOMER_CREDIT_NOTE,
  EInvoiceType.CUSTOMER_EXPENSE,
  EInvoiceType.CUSTOMER_EXPENSE_CREDIT_NOTE,
  EInvoiceType.CUSTOMER_STAND_BY_DUTY,
  EInvoiceType.CUSTOMER_STAND_BY_DUTY_CREDIT_NOTE,
  EInvoiceType.CUSTOMER_MILESTONE,
  EInvoiceType.CUSTOMER_MILESTONE_CREDIT_NOTE,
];
/**
 * List of the CREDIT NOTE invoice type (provider or customer)
 */
export const CREDIT_NOTE_INVOICE_TYPES = [
  EInvoiceType.CUSTOMER_CREDIT_NOTE,
  EInvoiceType.CUSTOMER_EXPENSE_CREDIT_NOTE,
  EInvoiceType.CUSTOMER_STAND_BY_DUTY_CREDIT_NOTE,
  EInvoiceType.CUSTOMER_MILESTONE_CREDIT_NOTE,
  EInvoiceType.PROVIDER_CREDIT_NOTE,
  EInvoiceType.PROVIDER_EXPENSE_CREDIT_NOTE,
  EInvoiceType.PROVIDER_STAND_BY_DUTY_CREDIT_NOTE,
  EInvoiceType.PROVIDER_MILESTONE_CREDIT_NOTE,
];
/**
 * List of the DELIVERY NOTE (frais) invoice types (provider or customer)
 */
export const DELIVERY_NOTE_INVOICE_TYPES = [
  EInvoiceType.PROVIDER_EXPENSE,
  EInvoiceType.PROVIDER_EXPENSE_CREDIT_NOTE,
  EInvoiceType.CUSTOMER_EXPENSE,
  EInvoiceType.CUSTOMER_EXPENSE_CREDIT_NOTE,
];
/**
 * List of the STAND BY DUTY (astreinte) invoice type (provider or customer)
 */
export const STAND_BY_DUTY_INVOICE_TYPES = [
  EInvoiceType.PROVIDER_STAND_BY_DUTY,
  EInvoiceType.PROVIDER_STAND_BY_DUTY_CREDIT_NOTE,
  EInvoiceType.CUSTOMER_STAND_BY_DUTY,
  EInvoiceType.CUSTOMER_STAND_BY_DUTY_CREDIT_NOTE,
];
/**
 * List of the STAND BY DUTY (astreinte) invoice type (provider or customer)
 */
export const EXPENSE_INVOICE_TYPES = [
  EInvoiceType.PROVIDER_EXPENSE,
  EInvoiceType.PROVIDER_EXPENSE_CREDIT_NOTE,
  EInvoiceType.CUSTOMER_EXPENSE,
  EInvoiceType.CUSTOMER_EXPENSE_CREDIT_NOTE,
];
/**
 * List of the MILESTONE (jalons) invoice type (provider or customer)
 */
export const MILESTONE_INVOICE_TYPES = [
  EInvoiceType.PROVIDER_MILESTONE,
  EInvoiceType.PROVIDER_MILESTONE_CREDIT_NOTE,
  EInvoiceType.CUSTOMER_MILESTONE,
  EInvoiceType.CUSTOMER_MILESTONE_CREDIT_NOTE,
];
/** Mapping between EadditionalActivityType and their corresponding EInvoiceType */
export const MAPPING_ACTIVITY_INVOICE_TYPES = {
  [EadditionalActivityType.STAND_BY_DUTY]: {
    PROVIDER: EInvoiceType.PROVIDER_STAND_BY_DUTY,
    CUSTOMER: EInvoiceType.CUSTOMER_STAND_BY_DUTY,
  },
  [EadditionalActivityType.EXPENSE]: {
    PROVIDER: EInvoiceType.PROVIDER_EXPENSE,
    CUSTOMER: EInvoiceType.CUSTOMER_EXPENSE,
  },
  [EadditionalActivityType.MILESTONE]: {
    PROVIDER: EInvoiceType.PROVIDER_MILESTONE,
    CUSTOMER: EInvoiceType.CUSTOMER_MILESTONE,
  },
};
