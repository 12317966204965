import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../queryConfig';
import {
  additionalActivityFindMany,
  additionalActivityFindOne,
  additionalActivityPatch,
  additionalActivityUpdateOne,
  createAdditionalActivity,
  mileStoneReportFindMany,
} from '../routes';
import {
  IJoinedAdditionalActivity,
  IMilestoneReportMilestoneData,
} from '@freelancelabs/teoreme-commons';
// IMilestoneReportExpenseData,
// IMilestoneReportMilestoneData,
// MilestoneReportProviderInvoiceProjection,
// IInvoice,
// EInvoiceStatus,
// } from '@freelancelabs/teoreme-commons';

export const useCreateAditionalActivity = (): any =>
  useMutation<any, unknown, any>({
    mutationFn: params => createAdditionalActivity(params),
    onSuccess: data => {
      queryClient.setQueryData([data.uuid], data);
    },
  });
export const useAdditionalActivityFindMany = (
  variables?: any,
  config?: any //UseQueryOptions
): {
  data: any;
  error: any;
  isFetching: boolean;
  status: any;
  isPending: boolean;
} => {
  return useQuery({
    queryKey: ['additionalActivities', variables],
    queryFn: async () => additionalActivityFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    keepPreviousData: true,
    ...config,
  });
};
export const useAdditionalActivityFindOne = (
  uuid?: string,
  joinInvoice?: boolean
): {
  data: any;
  error: any;
  status: any;
  isFetching: boolean;
} => {
  return useQuery({
    queryKey: [uuid, uuid],
    queryFn: async () =>
      additionalActivityFindOne(
        { uuid: uuid as string, joinInvoice },
        joinInvoice
      ),
    enabled: uuid ? true : false,
    // DISABLE CACHE
    refetchOnMount: false,
    staleTime: 0,
    gcTime: 0,
  });
};

export const useAdditionalActivityUpdateOne = (): any =>
  useMutation<any, unknown, any>({
    mutationFn: params => additionalActivityUpdateOne(params),
    onSuccess: data => {
      queryClient.setQueryData([data.uuid], data);
    },
  });
export const useAdditionalActivityPatch = (): any =>
  useMutation<any, unknown, any>({
    mutationFn: params => additionalActivityPatch(params),
    onSuccess: data => {
      queryClient.setQueryData([data.uuid], data);
    },
  });

export const useMileStoneReportFindMany = (
  variables?: any,
  config?: any //UseQueryOptions
): {
  data: any;
  error: any;
  isFetching: boolean;
} => {
  return useQuery({
    queryKey: ['milestoneReport', variables],
    queryFn: async () => mileStoneReportFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    keepPreviousData: true,
    ...config,
  });
};
