import {
  IAddress,
  IInvoiceAddress,
  EPriceUnit,
  EMissionStructure,
  IActivityReportData,
  ECraStatus,
  EadditionalActivityType,
  IJoinedAdditionalActivity,
} from '@freelancelabs/teoreme-commons';
import { FREELANCE_STRUCTURES } from '../constantz/index';
import { CountryCode } from 'libphonenumber-js';
import parsePhoneNumber from 'libphonenumber-js';
import * as TRANSLATES from '../translates';

export type UserWithName = {
  lastName?: string;
  firstName?: string;
};

export const getFullName = (
  data?: UserWithName,
  maxLength?: number
): string => {
  if (data) {
    const name = `${data.lastName ? data.lastName.toUpperCase() : ''} ${
      data.firstName || ''
    }`;
    if (maxLength) {
      return cutLongText(name, maxLength, false);
    } else {
      return name;
    }
  } else {
    return '';
  }
};
export const getFirstCaracts = (data: UserWithName): string => {
  let fc = '';
  if (data) {
    fc = `${data.lastName?.[0]?.toUpperCase() || ''}${
      data.firstName?.[0]?.toUpperCase() || ''
    }`;
    return fc;
  } else {
    return '';
  }
};

// establishment

type EstablmishmentWithName = {
  siret?: string;
  identifier?: string;
  tradeName?: string;

  signBoard1?: string;
  signBoard2?: string;
  signBoard3?: string;

  businessName?: string;
};
export const getTradeNameSafe = (
  data: EstablmishmentWithName,
  maxLength = 40,
  displaySiret?: boolean
): string => {
  let establishmentName = '';
  let identifier = data?.siret || data?.identifier;
  if (data) {
    establishmentName = data.tradeName
      ? data.tradeName
      : data.signBoard1
        ? `${data.signBoard1} ${data.signBoard2 || ''} ${data.signBoard3 || ''}`
        : data.businessName || '';
  }
  if (maxLength) {
    if (displaySiret) {
      return `${cutLongText(establishmentName, maxLength, false)} (${identifier})`;
    }
    return cutLongText(establishmentName, maxLength, false);
  }
  if (displaySiret) {
    return `${establishmentName} (${identifier})`;
  }
  return establishmentName;
};

export const getEstablishmentName = (
  data?: EstablmishmentWithName,
  maxLength = 40,
  displaySiret?: boolean
): string => {
  if (data) {
    return getTradeNameSafe(data, maxLength, displaySiret);
  } else {
    return '';
  }
};

export const getFirstCaractsEstablisement = (
  data: EstablmishmentWithName
): string => {
  let fc = '';
  if (data) {
    fc =
      getTradeNameSafe(data).length > 0
        ? getTradeNameSafe(data)[0].toUpperCase()
        : '';
    return fc;
  } else {
    return '';
  }
};

export const trimedSiret = (siret: string): string => {
  return siret?.replace(/\s/g, '').replace(/\D/g, '') || '';
};
export const trimedEmail = (email: string): string => {
  return email.trim();
};

export const buildOneLineAddress = (
  objAddress?: IAddress | IInvoiceAddress,
  addBR?: boolean
): any => {
  let strAddress = '';
  if (addBR) {
    if (typeof objAddress === 'object') {
      return (
        <>
          {objAddress?.street} {objAddress?.additional} <br />
          {objAddress?.postalCode} {objAddress?.city} <br />
          {objAddress?.country}
        </>
      );
    }
  } else {
    if (typeof objAddress === 'object') {
      if (objAddress.street) {
        strAddress += `${objAddress.street}, `;
      }
      if (objAddress.postalCode) {
        strAddress += `${objAddress.postalCode}, `;
      }
      if (objAddress.city) {
        strAddress += `${objAddress.city}, `;
      }
      if (objAddress.country) {
        strAddress += objAddress.country;
      }
    }
  }

  return strAddress;
};

export const cutLongText = (
  text: string,
  maxLength: number,
  disablePoints?: boolean
): string => {
  if (!text) return '';
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + (!disablePoints ? '...' : '');
  } else return text;
};

export const checkPostalCode = (postalCode?: string): boolean =>
  !!postalCode && postalCode.length === 5;
export const checkStartEndDates = (startAt: Date, endAt: Date): boolean =>
  endAt >= startAt;

export const checkValidPassword = (password: string) => {
  /*
  Il doit contenir au moins 8 caractères, 1 majuscule, 1 minuscule et 1 caractère spécial ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~.
  */
  const passwordPattern =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[\^$*.\[\]{}\(\)?\-"!@#%&/,><':;|_~`])\S{8,99}$/;
  return passwordPattern.test(password);
};
/*
    For use only in react-hook-form validator !
*/
export const checkFormPassword = (password: string) => {
  if (checkInvalidCognitoCharacters(password)) {
    return 'Doit contenir au moins 1 caractère spécial parmi: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~';
  }
  if (checkValidPassword(password)) {
    return undefined;
  }
  return 'Doit contenir au moins 8 caractères, 1 majuscule, 1 minuscule et 1 caractère spécial';
};

export const checkInvalidCognitoCharacters = (password: string) => {
  /*
  Il doit contenir 1 caractère spécial reconu par cognito : ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~.
  */
  const patternInvalidPassword =
    /[^a-zA-Z0-9^$*.\[\]{}\(\)?\-"!@#%&/,><':;|_~`]/;
  return patternInvalidPassword.test(password);
};

export const getDecimalInput = (value: string | number, nbdecimal: number) => {
  if (typeof value !== 'string' && typeof value !== 'number') {
    return value;
  }
  if (typeof value === 'number') {
    return Number(value.toFixed(nbdecimal));
  }

  const t = value;
  return t.indexOf('.') >= 0
    ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), nbdecimal + 1)
    : t;
};
export const escapeEmail = (str?: string | undefined) => {
  if (typeof str === 'string') {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  return '';
};

export const getPriceByUnit = (unit: EPriceUnit): string => {
  switch (unit) {
    case EPriceUnit.PERCENT_INVOICE:
      return '% du montant de la facture du payeur';

    case EPriceUnit.FLAT_PRICE:
    default:
      return `€`;

    case EPriceUnit.PRICE_PER_WORKING_DAY:
      return `€ /jour`;

    case EPriceUnit.PRICE_PER_DAY:
      return `€ /jour`;

    case EPriceUnit.PRICE_PER_MONTH:
      return `€ /mois`;

    case EPriceUnit.PRICE_PER_YEAR:
      return `€ /ans`;
    case EPriceUnit.ONE_TIME_PERCENT:
      return `% du montant de la facture en paiement unique`;
    case EPriceUnit.ONE_TIME_PRICE:
      return `€ en paiement unique`;
  }
};
/*
CONVERT  0682616929 to +33 6 82 61 69 29
WARNING ONLY FOR READ !!
DO NOT USE THIS FUNCTION TO UPDATE DATA !
*/
export const transformPhoneToInternationalSafe = (
  phone: string | undefined,
  countryCode?: CountryCode
) => {
  if (typeof phone === 'string') {
    const phoneWrapped = parsePhoneNumber(`${phone}`, countryCode);
    if (phoneWrapped) {
      return phoneWrapped.format('INTERNATIONAL');
    }
  }
  return phone;
};

export const replaceSpecificCaractereInFileName = (fileName: string) => {
  const fileNameSafe = fileName
    .replace(/(?!.pdf)([^A-Za-z0-9_ ]+)/gi, '')
    .trim();
  return fileNameSafe.replace(/ /g, '_');
};

export const validateFormPhoneFormatFR = (phone: string) => {
  if (phone) {
    if (phone.length > 0 && phone.length < 11) {
      return 'Le format du téléphone est invalide';
    }
  }
};
export const replaceAllKeysDoesntExistOnParams = (
  params: any,
  reference: string,
  replaceStr?: string
) => {
  const paramsCustom = params;
  if (params && typeof params === 'object') {
    const data = {
      ...TRANSLATES.FR_NOTIFICATIONS,
      ...TRANSLATES.FR_NOTIFICATIONS_PROVIDER,
      ...TRANSLATES.FR_TRACES,
    };
    //@ts-ignore
    const translateReference = data?.[reference];
    const regex = /{{(.*?)}}/g;
    let translateParams = translateReference?.match(regex);
    translateParams = translateParams?.map((tp: any) =>
      tp?.replaceAll('{{', '')?.replaceAll('}}', '')
    );
    let paramsKey = Object?.keys(params);
    translateParams?.forEach((tp: any) => {
      let isFound = paramsKey?.find(tk => tp === tk);
      if (!isFound) {
        paramsCustom[tp] = replaceStr || '';
      }
    });
  }
  return paramsCustom;
};
export const getCivilityAllKeys = (params: any, reference: string) => {
  let paramsCustom = params;
  if (params && typeof params === 'object') {
    paramsCustom = replaceAllKeysDoesntExistOnParams(params, reference);
    Object?.keys(params).forEach(key => {
      if (key?.toLocaleLowerCase()?.includes('civility')) {
        const civility = params[key];
        if (parseInt(civility) === 0) {
          paramsCustom[key] = '';
        }
        if (parseInt(civility) === 1) {
          paramsCustom[key] = 'M.';
        }
        if (parseInt(civility) === 2) {
          paramsCustom[key] = 'Mme';
        }
      }
    });
  }
  return params;
};

export const getSructure = (structure: EMissionStructure) => {
  return FREELANCE_STRUCTURES?.[structure];
};
export const getActivityStatus = (
  activity: IActivityReportData,
  exclude: any
): ECraStatus => {
  const orderState = [
    ECraStatus?.TO_BE_FILLED,
    ECraStatus?.TO_BE_SUBMITTED,
    ECraStatus?.REJECTED,
    ECraStatus?.TO_BE_VALIDATED,
    ECraStatus?.VALIDATED,
    ECraStatus?.ARCHIVED,
  ];
  const states = [];

  if (!exclude?.find((value: string) => value === 'CRA')) {
    states?.push(activity?.cra?.state);
  }
  if (!exclude?.find((value: string) => value === 'DUTY')) {
    activity?.standByDuties?.forEach(sd => states?.push(sd?.status));
  }
  if (!exclude?.find((value: string) => value === 'FEES')) {
    activity?.expenses?.forEach(sd => states?.push(sd?.status));
  }
  let smallStatus: number | undefined;
  states?.forEach(state => {
    if (state === ECraStatus?.TO_BE_FILLED) {
      if (!smallStatus) {
        smallStatus = 1;
      } else {
        if (smallStatus > 1) {
          smallStatus = 1;
        }
      }
    }
    if (state === ECraStatus?.TO_BE_SUBMITTED) {
      if (!smallStatus) {
        smallStatus = 2;
      } else {
        if (smallStatus > 2) {
          smallStatus = 2;
        }
      }
    }
    if (state === ECraStatus?.REJECTED) {
      if (!smallStatus) {
        smallStatus = 3;
      } else {
        if (smallStatus > 3) {
          smallStatus = 3;
        }
      }
    }
    if (state === ECraStatus?.TO_BE_VALIDATED) {
      if (!smallStatus) {
        smallStatus = 4;
      } else {
        if (smallStatus > 4) {
          smallStatus = 4;
        }
      }
    }
    if (state === ECraStatus?.VALIDATED) {
      if (!smallStatus) {
        smallStatus = 5;
      } else {
        if (smallStatus > 5) {
          smallStatus = 5;
        }
      }
    }
    if (state === ECraStatus?.ARCHIVED) {
      if (!smallStatus) {
        smallStatus = 6;
      } else {
        if (smallStatus > 6) {
          smallStatus = 6;
        }
      }
    }
  });
  if (smallStatus) {
    return orderState[smallStatus - 1];
  }
  //
  return orderState[0];
};

export const stripHtmlTags = (html?: string) => html?.replace(/<[^>]+>/g, '');

export const getAdditionActivityTextType = (
  additionalActivity: IJoinedAdditionalActivity
) => {
  switch (additionalActivity?.type) {
    case EadditionalActivityType.EXPENSE:
      return 'le frais';
    case EadditionalActivityType.STAND_BY_DUTY:
      return 'la prestation complémentaire';
    case EadditionalActivityType.MILESTONE:
      return 'le jalon';
  }
};

export const formatInvoiceOrAdditionalActivitySort = (sort?: string) => {
  let finalSort = sort;
  if (finalSort) {
    if (finalSort?.includes('month')) {
      finalSort = finalSort?.replaceAll('month', 'normalizedDate');
    }
    if (!finalSort?.includes('uuid')) {
      finalSort = finalSort += ' uuid';
    }
  }

  return finalSort;
};

export const fnBrowserDetect = () => {
  const userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge';
  } else {
    browserName = 'No browser detection';
  }

  return browserName;
};

export const replaceAllFontsInTextEditor = (
  html: string,
  font?: string
): string => {
  let newFont = font || 'Montserrat Regular 400';
  let fontFamily = html?.split('font-family:');
  let fontsFinded: any = [];
  fontFamily?.forEach((e, index) => {
    if ((index -= 0)) {
      fontsFinded?.push(e?.split('"')?.[0]);
    }
  });
  fontsFinded?.forEach((font: string) => {
    html = html?.replace(font, ` ${newFont};`);
  });
  return html;
};

export const replaceAllSizeInTextEditor = (
  html: string,
  size?: string
): string => {
  let newFontSize = size || '10px';
  let fontSizes = html?.split('font-size:');
  let fontsFSizeinded: any = [];
  fontSizes?.forEach((e, index) => {
    if ((index -= 0)) {
      fontsFSizeinded?.push(e?.split('"')?.[0]);
    }
  });
  fontsFSizeinded?.forEach((font: string) => {
    html = html?.replace(font, ` ${newFontSize};`);
  });
  return html;
};
