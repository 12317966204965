import { EMissionStructure } from '@freelancelabs/teoreme-commons';
import { Roles as RolesTypes } from '@freelancelabs/inside-commons/dist/lib/models/enum/role.enum';
import {
  Flex,
  Box,
  FormLabel,
  DatePickerRange,
  Input,
  Link,
  DatePicker,
} from 'components/ui';
import {
  INVOICE_PROVIDER_TYPE_SELECT,
  INVOICE_CUSTOMER_TYPE_SELECT,
  INVOICE_CREDIT_NOTE_PROVIDER_TYPE_SELECT,
  INVOICE_CREDIT_NOTE_CUSTOMER_TYPE_SELECT,
  INVOICE_STATUS_SELECT,
  INVOICE_FOREIGN_SEARCH_FIELDS,
} from '@commons';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { UserSelect } from 'components/selects/UserSelect';
import { StaticSelect } from 'components/selects/StaticSelect';
import { RefreshIcon } from 'components/ui/icons';
import { Theme } from 'styles';
import { ContractorSelect } from 'components/selects/ContractorSelect';
import { RadioSelectEstablishments } from 'components/selects/RadioSelectEstablishments';
const row = 1 / 1;
export type InvoicesFiltersListProps = {
  filterInvoiceType:
    | 'PROVIDER'
    | 'CUSTOMER'
    | 'PROVIDER_CREDIT_NOTE'
    | 'CUSTOMER_CREDIT_NOTE';
  hookStore: any;
};
export const InvoicesFiltersList = ({
  filterInvoiceType,
  hookStore,
}: InvoicesFiltersListProps) => {
  const { filter: filterParams } = useParams<{
    filter: 'all' | 'validated' | 'draft' | 'aborted' | 'terminated';
  }>();
  const {
    initialSate,
    selectedStatus,
    selectedType,
    startDate,
    endDate,
    searchQuery,
    accountManagerId,
    commercialSelected,
    structureSelected,
    // clientSelected,
    // providerSelected,
    multiProviderSelected,
    multiClientSelected,
    contractorSelected,
    invoiceTypeSelected,
    invoiceDate,
    locked, // only for provider invoice
    createdFromGescomFlow, // only for provider invoice
    limit,
    searchInKeys,
    updateStore,
    resetStore,
  } = hookStore();

  React.useEffect(() => {
    updateStore({ page: 0, initialSate });
  }, [filterParams, invoiceTypeSelected, accountManagerId]);
  const getInvoiceTypeSelect = () => {
    switch (filterInvoiceType) {
      case 'CUSTOMER':
        return INVOICE_CUSTOMER_TYPE_SELECT;
      case 'PROVIDER':
        return INVOICE_PROVIDER_TYPE_SELECT;
      case 'CUSTOMER_CREDIT_NOTE':
        return INVOICE_CREDIT_NOTE_CUSTOMER_TYPE_SELECT;
      case 'PROVIDER_CREDIT_NOTE':
        return INVOICE_CREDIT_NOTE_PROVIDER_TYPE_SELECT;
    }
  };
  return (
    <Box>
      <Flex display="inline-flex" justifyContent="flex-start" flexWrap={'wrap'}>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>RECHERCHER</FormLabel>
          <Input
            isFullWidth
            type="search"
            onChange={e => {
              //@ts-ignore
              updateStore({ searchQuery: e.target.value });
            }}
            value={searchQuery}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Rechercher dans</FormLabel>
          {/* <StaticMultiSelect
            onChange={value => {
              // Default value required !
              if (!value?.find(v => v?.value === 'default_invoice')) {
                updateStore({
                  searchInKeys: [INVOICE_FOREIGN_SEARCH_FIELDS[0], ...value],
                });
              } else {
                updateStore({ searchInKeys: value });
              }
            }}
            options={INVOICE_FOREIGN_SEARCH_FIELDS}
            values={searchInKeys}
          /> */}
          <StaticSelect
            isClearable={false}
            options={INVOICE_FOREIGN_SEARCH_FIELDS}
            value={searchInKeys?.[0]?.value}
            onChange={value =>
              updateStore({
                searchInKeys: [
                  INVOICE_FOREIGN_SEARCH_FIELDS?.find(
                    e => e?.value === value
                  ) as any,
                ],
              })
            }
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Chargés de comptes</FormLabel>
          <UserSelect
            placeholder="Tous les Chargés de comptes"
            role={RolesTypes.ACCOUNT_MANAGER}
            onChange={value => updateStore({ accountManagerId: value })}
            value={accountManagerId}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Commercial interne</FormLabel>
          <UserSelect
            data-clarity-mask="True"
            name="internalCommercial"
            role={'INTERNAL_COMMERCIAL'}
            onChange={value => updateStore({ commercialSelected: value })}
            value={commercialSelected}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Type de facture</FormLabel>
          <StaticSelect
            placeholder="Tous les types"
            options={getInvoiceTypeSelect()}
            onChange={value => updateStore({ selectedType: value as any })}
            value={selectedType}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Date d'échéance</FormLabel>
          <DatePicker
            isClearable
            //valueName="selected"
            name="invoiceDate"
            value={invoiceDate as any}
            onChange={value => updateStore({ invoiceDate: value })}
          />
        </Box>
        <Box width={row} pt={'5px'}>
          <FormLabel>Période</FormLabel>
          <DatePickerRange
            startDate={startDate}
            endDate={endDate}
            isClearable={true}
            setStartDate={value => {
              updateStore({ startDate: value, page: 0 });
            }}
            setEndDate={value => {
              updateStore({ endDate: value, page: 0 });
            }}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Structure</FormLabel>
          <StaticSelect
            placeholder="Toutes les structure"
            isClearable
            options={[
              {
                label: 'FCOM',
                value: EMissionStructure.FCOM,
                key: EMissionStructure.FCOM,
              },
              {
                label: 'FONE',
                value: EMissionStructure.FONE,
                key: EMissionStructure.FONE,
              },
              {
                label: 'INOPS',
                value: EMissionStructure.INOPS,
                key: EMissionStructure.INOPS,
              },
              {
                label: 'COWORKEES',
                value: EMissionStructure.COWORKEES,
                key: EMissionStructure.COWORKEES,
              },
            ]}
            onChange={value =>
              updateStore({ structureSelected: value as string })
            }
            value={structureSelected}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Clients</FormLabel>
          <RadioSelectEstablishments
            filter={{ customer: { $exists: true } }}
            referenceValue="uuid"
            placeholder="Tous les clients"
            onChange={value =>
              updateStore({
                multiClientSelected: value,
              })
            }
            values={multiClientSelected}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Fournisseurs</FormLabel>
          <RadioSelectEstablishments
            filter={{ 'provider.manager': { $exists: true } }}
            referenceValue="uuid"
            placeholder="tous les fournisseurs"
            onChange={value =>
              updateStore({
                multiProviderSelected: value,
              })
            }
            values={multiProviderSelected}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Intervenant</FormLabel>
          <ContractorSelect
            placeholder="Tous les intervenants"
            onChange={value =>
              updateStore({ contractorSelected: value as string })
            }
            value={contractorSelected}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'}>
          <FormLabel>Statuts</FormLabel>
          <StaticSelect
            isDisabled={filterParams !== 'all'}
            placeholder="Tous les statuts"
            options={INVOICE_STATUS_SELECT}
            onChange={value => updateStore({ selectedStatus: value as string })}
            value={selectedStatus}
          />
        </Box>
        <Box
          width={row}
          pr={'5px'}
          pt={'5px'}
          hidden={
            filterInvoiceType !== 'PROVIDER' && filterInvoiceType !== 'CUSTOMER'
          }
        >
          <FormLabel>Source des factures</FormLabel>
          <StaticSelect
            isClearable
            //isDisabled={filterParams !== 'all'}
            placeholder="Toutes les sources"
            options={[
              {
                label: 'Créée depuis Gescom',
                value: 1,
                key: 1,
              },
            ]}
            onChange={value =>
              updateStore({ createdFromGescomFlow: value === 1 ? true : false })
            }
            value={createdFromGescomFlow ? 1 : 0}
          />
        </Box>
        <Box width={row} pr={'5px'} pt={'5px'} mb={10}>
          <FormLabel>NB. LIGNES AFFICHÉES</FormLabel>
          <StaticSelect
            isClearable={false}
            placeholder=" "
            options={[
              {
                label: '20',
                value: 20,
                key: 20,
              },
              {
                label: '50',
                value: 50,
                key: 50,
              },
              {
                label: '100',
                value: 100,
                key: 100,
              },
            ]}
            onChange={value => updateStore({ limit: value as number })}
            value={limit}
          />
        </Box>
        <Box>
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            flexWrap="wrap"
            alignContent="center"
            width={1 / 1}
            height={'100%'}
          >
            <Link
              isDisabled={initialSate}
              iconLeft={
                <RefreshIcon
                  fill={initialSate ? Theme?.colors?.grey?.default : undefined}
                />
              }
              mt={'5px'}
              onClick={() => (!initialSate ? resetStore({}) : false)}
            >
              Réinitialiser
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
