import * as React from 'react';

function ValidateIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fill-rule="evenodd">
        <g fill={props?.fill || '#00ABEF'}>
          <g>
            <g>
              <g>
                <path
                  d="M17.911 6.358l-8.688 8.689-3.134-3.134c-.478-.478-1.253-.478-1.73 0-.478.477-.478 1.25-.001 1.728l4 4c.478.479 1.253.478 1.73 0l9.554-9.554c.477-.477.477-1.251 0-1.728-.478-.478-1.253-.478-1.731 0z"
                  transform="translate(-1242 -425) translate(1242 425)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ValidateIcon;
