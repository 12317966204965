import { create } from 'zustand';

type State = {
  message: string;
  open: boolean;
  variant: 'success' | 'error' | 'info';
  errorLogs: any; //OBJECT
  updateAlert: (state: Partial<State>) => void;
  resetAlert: (state: Partial<State>) => void;
};

const initialStates = {
  message: '',
  open: false,
  variant: 'success' as const,
  errorLogs: undefined,
};

const store = (set: any) => ({
  ...initialStates,
  updateAlert: (state: Partial<State>) => set(state),
  resetAlert: (state: Partial<State>) => set({ ...initialStates, ...state }),
});

export const useAlertStore = create<State>(store);
