import {
  IAdminTaskSearchParams,
  IAdminValidationSearchParams,
  IAdminValidationUpdateParams,
  IAdminSettingSearchParams,
  IAdminSettingUpdateParams,
} from '@freelancelabs/teoreme-commons';
import {
  taskFindMany,
  taskFindOne,
  taskManage,
  adminValidationFindOne,
  adminValidationFindMany,
  updateAdminValidation,
  adminSettingFindOne,
  adminSettingFindMany,
  adminSettingUpdateMany,
} from '../routes';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useAdminTaskFindOne = (
  identifier?: string,
  config?: any
): {
  data: any;
  error: any;
  status: any;
  isFetching: boolean;
  isPending: boolean;
} => {
  return useQuery({
    queryKey: [identifier],
    queryFn: async () => taskFindOne(identifier),
    enabled: identifier ? true : false,
    ...config,
  });
};

export const useAdminTaskFindMany = (
  variables?: IAdminTaskSearchParams,
  config?: any
): {
  data: any;
  error: any;
  status: any;
  isFetching: boolean;
  isPending: boolean;
} => {
  return useQuery({
    queryKey: ['admin-tasks-search', variables],
    queryFn: async () => taskFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    keepPreviousData: true,
    ...config,
  });
};

export const useAdminTaskManage = (): any =>
  useMutation<any, unknown, any>({
    mutationFn: params => taskManage(params),
  });

// VALIDATION

export const useAdminValidationFindOne = (uuid: string, config?: any) => {
  return useQuery({
    queryKey: [uuid],
    queryFn: async () => adminValidationFindOne(uuid),
    enabled: uuid ? true : false,
    ...config,
  });
};

export const useAdminValidationFindMany = (
  variables: IAdminValidationSearchParams,
  config?: any
): {
  data: any;
  error: any;
  status: any;
  isFetching: boolean;
} => {
  return useQuery({
    queryKey: ['admin-validation-search', variables],
    queryFn: async () => adminValidationFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    keepPreviousData: true,
    ...config,
  });
};

export const useAdminValidationUpdateOne = (): any =>
  useMutation<any, unknown, IAdminValidationUpdateParams<any>>({
    mutationFn: params => updateAdminValidation(params),
    onSuccess: data => {
      //queryClient.setQueryData([data.refCra], data);
    },
  });

export const useAdminSettingFindOne = (
  key: string,
  config?: any
): {
  data: any;
  error: any;
  status: any;
  isFetching: boolean;
  isPending: boolean;
} => {
  return useQuery({
    queryKey: [key],
    queryFn: async () => adminSettingFindOne(key),
    enabled: key ? true : false,
    ...config,
  });
};

export const useAdminSettingFindMany = (
  variables: IAdminSettingSearchParams,
  config?: any
) => {
  return useQuery({
    queryKey: ['admin-setting-search', variables],
    queryFn: async () => adminSettingFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    keepPreviousData: true,
    ...config,
  });
};

export const useAdminSettingUpdateOne = (): any =>
  useMutation<any, unknown, IAdminSettingUpdateParams>({
    mutationFn: params => adminSettingUpdateMany(params),
    onSuccess: data => {
      //queryClient.setQueryData([data.refCra], data);
    },
  });
