import { ICra, ICraUpdateParams } from '@freelancelabs/teoreme-commons';

import { useMutation, useQuery, keepPreviousData } from '@tanstack/react-query';
import { queryClient } from '../';
import {
  craFindOne,
  craFindMany,
  craUpdateOne,
  verifyCustomerToken,
  activitiesFindMany,
} from '../routes';
export const useCraFindOne = (identifier?: string, joinInvoice?: boolean) => {
  return useQuery({
    queryKey: [identifier, identifier],
    queryFn: async () =>
      craFindOne(
        { identifier: identifier as string, joinInvoice },
        joinInvoice
      ),
    enabled: identifier ? true : false,
    // DISABLE CACHE
    refetchOnMount: 'always',
    staleTime: 0,
    gcTime: 0,
  });
};
export const useCraFindMany = (variables?: any, queryKey?: string) => {
  if (queryKey) {
    queryClient.removeQueries({ queryKey: [queryKey], type: 'inactive' });
  }
  return useQuery({
    queryKey: queryKey ? [queryKey, variables] : ['Cras', variables],
    queryFn: async () => craFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    placeholderData: keepPreviousData,
  });
};

export const useCraUpdateOne = (): any =>
  useMutation<ICra, unknown, ICraUpdateParams>({
    mutationFn: params => craUpdateOne(params),
    onSuccess: data => {
      queryClient.setQueryData([data.refCra], data);
    },
  });

export const useVerifyCustomerToken = (token: string) => {
  return useQuery({
    queryKey: [token],
    queryFn: async () => verifyCustomerToken({ token }),
    enabled: token ? true : false,
    // DISABLE CACHE
    refetchOnMount: false,
    staleTime: 0,
    gcTime: 0,
  });
};
export const useActivitiesReportFindMany = (
  variables?: any,
  config?: any //UseQueryOptions
): {
  data: any;
  error: any;
  status: any;
  isFetching: boolean;
} => {
  return useQuery({
    queryKey: ['ActivitiesReport', variables],
    queryFn: async () => activitiesFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    keepPreviousData: true,
    // DISABLE CACHE
    refetchOnMount: false,
    staleTime: 0,
    gcTime: 0,
    ...config,
  });
};
