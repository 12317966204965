import * as React from 'react';

function SvgCloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M13.416 12l6.292-6.292a1 1 0 10-1.415-1.415L12 10.585 5.708 4.293a1 1 0 10-1.415 1.415L10.586 12l-6.293 6.292a1 1 0 101.415 1.415l6.293-6.292 6.292 6.292a.998.998 0 001.415 0 1 1 0 000-1.415L13.416 12z"
      />
    </svg>
  );
}

export default SvgCloseIcon;
