import { jwtDecode } from 'jwt-decode';
import {
  HOME_URL,
  REACT_APP_SEED_STAGE_NAME,
  REACT_APP_LOCALHOST_SPACE,
  isNotLocal,
  getCognitoCookies,
  getCognitoLocalStorage,
  cleanSession,
  queryClient,
} from '..';
export const isExpTokenValid = (exp: number): boolean => {
  try {
    const currentTime = Math.floor(Date.now() / 1000);
    return exp > currentTime;
  } catch (error) {
    console.error('token Expired', error);
    return false;
  }
};

export const checkAuthByCognitoCookies = () => {
  let isValide = false;
  let isProviderApp;
  let cognitoCookies = getCognitoCookies();
  if (isNotLocal(REACT_APP_SEED_STAGE_NAME)) {
    isProviderApp = !HOME_URL?.includes('manager.connecteed');
  } else {
    isProviderApp = REACT_APP_LOCALHOST_SPACE === 'provider';
  }

  let accessToken = cognitoCookies?.find(
    cc => cc?.key === 'accessToken'
  )?.value;
  let refreshToken = cognitoCookies?.find(
    cc => cc?.key === 'refreshToken'
  )?.value;
  let LastAuthUser = cognitoCookies?.find(
    cc => cc?.key === 'LastAuthUser'
  )?.value;

  // IMPERSONATE EXCEPTIONS
  const isAnonymePath = window?.location?.pathname?.includes('/anonyme');
  if (localStorage?.isImpersonate === 'true' || isAnonymePath) {
    if (isProviderApp) {
      if (localStorage.token) {
        try {
          let jwtLocal: any = jwtDecode(localStorage.token);
          let localExp = jwtLocal?.exp;
          // console.log('CHECK AUTH IMPERSONATE', {
          //   cookies: cognitoCookies,
          //   accessToken,
          //   localToken: localStorage.token,
          //   jwtLocal,
          //   localExp,
          //   isExpTokenValid: isExpTokenValid(localExp),
          // });
          if (isExpTokenValid(localExp)) {
            return true;
          } else {
            cleanSession();
          }
        } catch (e) {
          console.log('Error JwtDecode', e);
          return false;
        }
      }
      //  ???? CLEAN ???
    }
  }
  if (accessToken && !isAnonymePath) {
    try {
      const jwt: any = jwtDecode(accessToken);
      const exp = jwt?.exp;

      // console.log('CHECK AUTH', {
      //   cookies: cognitoCookies,
      //   accessToken,
      //   jwt,
      //   exp,
      //   isExpTokenValid: isExpTokenValid(exp),
      // });
      if (isExpTokenValid(exp)) {
        localStorage.token = accessToken;
        localStorage.exp = exp;
        localStorage.refreshToken = refreshToken;
        localStorage.cognitoUserId = LastAuthUser;
        isValide = true;
        return true;
      } else {
        isValide = false;
        cleanSession();
      }
    } catch (e) {
      console.log('Error JwtDecode', e);
      return false;
    }
  }
  return isValide;
};
export const checkAuthValid = () => {
  let isAmplifyLogin = true;
  if (isAmplifyLogin) {
    return checkAuthByCognitoCookies();
  } else {
    const token = localStorage.getItem('token');
    if (token) {
      //@ts-ignore
      const jwt: any = jwtDecode(token);
      const current_time = Date.now() / 1000;
      return jwt.exp > current_time;
    }
    return false;
  }
};
