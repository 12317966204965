import {
  IInvoiceUpdateParams,
  IInvoiceUpdateResponse,
  IInvoicePatchCustomerParams,
  IInvoicePatchCustomerResponse,
} from '@freelancelabs/teoreme-commons';

import { useMutation, useQuery, keepPreviousData } from '@tanstack/react-query';
import {
  invoiceFindOne,
  invoiceFindMany,
  invoiceUpdateOne,
  invoiceIbanFindMany,
  invoicePatchCustomer,
} from '../routes';

export const useInvoiceFindOne = (identifier?: string) => {
  return useQuery({
    queryKey: [identifier, identifier],
    queryFn: async () => invoiceFindOne(identifier as string),
    enabled: identifier ? true : false,
    placeholderData: keepPreviousData,
    // DISABLE CACHE
    refetchOnMount: 'always',
    staleTime: 0,
    gcTime: 0,
  });
};

export const useInvoiceFindMany = (
  variables?: any,
  config?: any // UseQueryOptions
): {
  data: { invoices: any[]; totalCount: number; limit: number };
  status: String;
  isFetching: boolean;
  isPending: boolean;
} => {
  return useQuery({
    queryKey: ['Invoices', variables],
    queryFn: async () => invoiceFindMany(variables),
    enabled: variables && variables.filterObject ? true : false,
    keepPreviousData: true,
    ...config,
  });
};

export const useInvoiceUpdateOne = () =>
  useMutation<IInvoiceUpdateResponse, unknown, IInvoiceUpdateParams>({
    mutationFn: params => invoiceUpdateOne(params),
    onSuccess: data => {
      // api values don't match with invoice model
      //queryClient.setQueryData([data.uuid], data);
    },
  });
export const useInvoiceIbanFindMany = (variables?: any) => {
  return useQuery({
    queryKey: ['InvoicesIbans', variables],
    queryFn: async () => invoiceIbanFindMany(variables),
    enabled: variables ? true : false,
    placeholderData: keepPreviousData,
  });
};

export const useInvoicePatchCustomerOne = () =>
  useMutation<
    IInvoicePatchCustomerResponse,
    unknown,
    IInvoicePatchCustomerParams
  >({
    mutationFn: params => invoicePatchCustomer(params),
    onSuccess: data => {
      // api values don't match with invoice model
      //queryClient.setQueryData([data.uuid], data);
    },
  });
