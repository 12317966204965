import React from 'react';
import { ERoles, checkPathPermission } from '@commons';
import { useMe } from '@commons'; // moved
import { checkAuthValid, checkUserHasRole } from '@commons';
import { cleanSession } from '@commons';
import {
  Route,
  Redirect,
  RouteProps,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useRouterStore } from 'store';
import { FlatUser } from '@freelancelabs/teoreme-commons';
export const PrivateRoute: React.FC<
  React.PropsWithChildren<
    RouteProps & { component: React.FC<React.PropsWithChildren<any>> }
  >
> = ({ component: Component, ...rest }) => {
  const { me } = useMe();
  const isAuth = checkAuthValid();
  const { pathname } = useLocation();
  const history = useHistory();
  // use this after login on redirect on targetLocation
  const { targetlocation, updateRouteStoreState, resetRouteStoreSates } =
    useRouterStore();
  React.useEffect(() => {
    // use this after login on redirect on targetLocation
    if (!me) {
      if (
        targetlocation === undefined &&
        pathname !== targetlocation &&
        pathname !== '/'
      ) {
        if (pathname) {
          sessionStorage.setItem('targetLocation', pathname);
        }
        updateRouteStoreState({ targetlocation: pathname });
      }
    }
  }, [pathname, me, targetlocation]);

  const redirectOnTarget = (defaultPathname: string, user?: FlatUser) => {
    if (targetlocation) {
      if (checkPathPermission(user, targetlocation)) {
        resetRouteStoreSates({});
        history?.push(targetlocation);
      } else {
        resetRouteStoreSates({});
        history?.push(defaultPathname);
      }
    } else {
      history?.push(defaultPathname);
    }
  };
  if (!isAuth) {
    return <Redirect to="/login" />;
  } else {
    if (me) {
      if (
        checkUserHasRole(me, ERoles.ACCOUNT_MANAGER) ||
        checkUserHasRole(me, ERoles?.LEGAL_SQUAD) ||
        checkUserHasRole(me, 'TEAM_LEADER') ||
        checkUserHasRole(me, 'SUPPORT') ||
        checkUserHasRole(me, ERoles.ADMIN) ||
        checkUserHasRole(me, 'MANAGER_READ_ONLY') ||
        checkUserHasRole(me, 'PORTAL_CLIENT_ADMIN')
      ) {
        if (
          pathname === './login' &&
          checkUserHasRole(me, ERoles?.LEGAL_SQUAD)
        ) {
          redirectOnTarget('/legals/contracts', me);
          //history.push('/legals/contracts');
        }
        if (pathname === './login' && checkUserHasRole(me, ERoles?.ADMIN)) {
          redirectOnTarget('/admin/users', me);
          //history.push('/admin/users');
        }
        if (pathname === './login' && checkUserHasRole(me, 'SUPPORT')) {
          redirectOnTarget('/support/documents/provider', me);
          //history.push('/support/documents/provider');
        }
        if (pathname === './login') {
          redirectOnTarget('/dashboard', me);
          //history.push('/dashboard');
        }
        if (pathname === './login') {
          redirectOnTarget('/clients/contacts', me);
          //history.push('/dashboard');
        }
      } else {
        // Disconect invalide role
        cleanSession();
        history.push('/login');
      }
    }
  }
  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default PrivateRoute;
