export class ApiError extends Error {
  code: number;
  errorCode: string;
  requestId?: string;
  constructor(code: number, error: any) {
    super(error.message);
    this.code = error.code;
    this.errorCode = error.errorCode;
    this.requestId = error.requestId;

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}
