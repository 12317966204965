import {
  FR_STATUS,
  buildOneLineAddress,
  getFullName,
  getHumanDate,
  round,
  stripHtmlTags,
  useChangeHistoryFindMany,
  useUserFindMany,
} from '@commons';
import {
  EBillingType,
  EChangeAction,
  EInvoicePaymentLabel,
  EInvoiceSendMethod,
  EMarginType,
  EMissionType,
  ERejectCraReason,
  IChangeHistory,
  IChangeTypeActivity,
  IChangeTypeCra,
  IChangeTypeInvoice,
  IChangeTypeMission,
} from '@freelancelabs/teoreme-commons';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import {
  ALink,
  Box,
  Row,
  Flex,
  Itemize,
  LabelField,
  Link,
  SpinnerBox,
  TableColumn,
  Text,
  Divider,
} from 'components/ui';
import { EyeIcon } from 'components/ui/icons';
import { getStatusText } from 'helpers';
import { useEffect, useState } from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useListChangeHistoryStore } from 'store';
import { Theme } from 'styles';
import { getTimeLineColorsByState, getTimeLineIconByAction } from './helpers';

type HistoryListProps = {
  columns?: () => TableColumn<Itemize<IChangeHistory<any, any>>>[];
  filterObject: any;
};

type ICombineChangeType = IChangeTypeCra &
  IChangeTypeActivity &
  IChangeTypeInvoice &
  IChangeTypeMission;
export const HistoryList = ({ columns, filterObject }: HistoryListProps) => {
  // const history = useHistory();
  const { page, resetListChangeHistoryStore } = useListChangeHistoryStore();
  const LIMIT = 100;
  const [usersUuid, setUserUuid] = useState<string[] | undefined>();
  const { data: histories, isPending } = useChangeHistoryFindMany(
    {
      filterObject: filterObject,
      limit: LIMIT,
      skip: page * LIMIT,
      sort: '-changedAt',
    },
    {
      // DISABLE CACHE
      refetchOnMount: false,
      staleTime: 0,
      gcTime: 0,
    }
  );
  const items = histories?.changes
    ?.map((change: any) => ({
      key: change?.uuid,
      ...change,
    }))
    ?.filter((e: any) => e?.to !== undefined);

  // const totalCount = histories?.totalCount || 0;
  useEffect(() => {
    resetListChangeHistoryStore({});
  }, [filterObject]);
  const getStatus = (item: any) => {
    const from = item?.from as ICombineChangeType;
    const to = item?.to as ICombineChangeType;
    const fromStatus = from?.state || from?.status;
    const toStatus = to?.state || to?.status;

    // const fromData = getStatusText(fromStatus);
    // const toData = getStatusText(toStatus);
    if (fromStatus && toStatus) {
      return `${getStatusText(fromStatus)?.statusText} → ${
        getStatusText(toStatus)?.statusText
      }`;
    }
    if (!fromStatus && toStatus) {
      return `${getStatusText(toStatus)?.statusText}`;
    }
  };
  // return (
  //   <Box>
  //     {isLoading && <SpinnerBox />}
  //     {!isLoading &&
  //       (items?.length === 0 ? (
  //         <Text>Aucun historique</Text>
  //       ) : (
  //         <Table<Itemize<IChangeHistory>>
  //           isLoading={isFetching}
  //           columns={columns()}
  //           items={items as Item[]}
  //           sortedBy={sortedBy}
  //           order={order}
  //           page={page}
  //           total={totalCount}
  //           perPage={totalCount < LIMIT ? totalCount : LIMIT}
  //           onSortedChange={() => false}
  //           onChangePage={page => updateListChangeHistoryStore({ page })}
  //         />
  //       ))}
  //   </Box>
  // );
  const SAGE_DOCTYPE = {
    INVOICE: 'Facture',
    DELIVERY_ORDER: 'Bon de livraison',
    CREDIT_NOTE: 'Avoir',
  };
  const MARGINTYPE = {
    [EMarginType.MARGIN]: 'Marque',
    [EMarginType.MARKUP]: 'Marge',
  };
  const PAYMENTLABEL = {
    [EInvoicePaymentLabel.FIXED]: "Date d'émission de la facture",
    [EInvoicePaymentLabel.END_OF_MONTH]: 'Fin de mois',
  };
  const SENDMETHOD = {
    [EInvoiceSendMethod.EMAIL]: 'Email',
    [EInvoiceSendMethod.MAIL]: 'Courrier',
    [EInvoiceSendMethod.OTHER]: 'Autre',
  };
  const { data: usersFind } = useUserFindMany(
    {
      filterObject: usersUuid
        ? {
            cognitoUserId: { $in: usersUuid },
          }
        : undefined,
      limit: 100,
      skip: 0,
      sort: 'email',
    },
    undefined,
    {
      enabled: usersUuid ? true : false,
    }
  );
  const getUserFullNameFromUuid = (uuid: string) => {
    if (usersFind?.users) {
      let user = usersFind?.users?.find((u: any) => u?.cognitoUserId === uuid);
      if (user) {
        return getFullName(user);
      }
    }
    return uuid;
  };
  useEffect(() => {
    if (histories?.changes && histories?.changes?.length > 0 && !usersUuid) {
      const uuid: string[] = [];
      histories?.changes?.forEach((c: any) => {
        // DATA
        if (c?.data?.validationRequest?.createdBy) {
          if (!uuid?.find(u => u === c?.data?.validationRequest?.createdBy)) {
            uuid?.push(c?.data?.validationRequest?.createdBy as string);
          }
        }
        if (c?.data?.validationRequest?.validatedBy) {
          if (!uuid?.find(u => u === c?.data?.validationRequest?.validatedBy)) {
            uuid?.push(c?.data?.validationRequest?.validatedBy as string);
          }
        }
        // FROM / TO
        if (c?.from?.provider?.manager) {
          if (!uuid?.find(u => u === c?.from?.provider?.manager)) {
            uuid?.push(c?.from?.provider?.manager as string);
          }
        }
        if (c?.to?.provider?.manager) {
          if (!uuid?.find(u => u === c?.to?.manager)) {
            uuid?.push(c?.to?.provider?.manager as string);
          }
        }

        if (c?.from?.accountManager) {
          if (!uuid?.find(u => u === c?.from?.accountManager)) {
            uuid?.push(c?.from?.accountManager as string);
          }
        }
        if (c?.to?.accountManager) {
          if (!uuid?.find(u => u === c?.to?.accountManager)) {
            uuid?.push(c?.to?.accountManager as string);
          }
        }
      });
      if (uuid?.length > 0 && !usersUuid) {
        setUserUuid(uuid);
      }
      if (uuid?.length === 0 && usersUuid) {
        setUserUuid(undefined);
      }
    }
  }, [histories, usersUuid]);
  return (
    <Box>
      {isPending && <SpinnerBox />}
      {!isPending &&
        (items?.length === 0 ? (
          <Text>Aucun historique</Text>
        ) : (
          <Flex>
            <VerticalTimeline
              layout="1-column-left"
              lineColor={Theme?.colors?.grayBlue}
            >
              {/* {!items?.find((i)=> i?.action === EChangeAction.CREATE) && } */}
              {items?.map((item: any, index: number) => {
                const from = item?.from as any;
                const to = item?.to as any;
                let backgroundColor = Theme?.colors?.darkBlue;
                if (item?.action === EChangeAction.CREATE) {
                  backgroundColor = Theme?.colors?.grayBlue;
                } else {
                  // if (!to?.state && !to?.status && index > 0) {
                  //   backgroundColor = getTimeLineColorsByState(
                  //     items?.[index - 1]?.to?.state ||
                  //       items?.[index - 1]?.to?.status
                  //   );
                  // } else {
                  //   backgroundColor = getTimeLineColorsByState(
                  //     to?.state || to?.status
                  //   );
                  // }
                  backgroundColor = getTimeLineColorsByState(
                    to?.state || to?.status
                  );
                }

                const title = FR_STATUS[item?.action as keyof typeof FR_STATUS];
                const subTitle = getStatus(item);
                const date = `${
                  getHumanDate(
                    item?.changedAt as Date,
                    'dd/MM/yyyy à HH:mm'
                  ) as string
                } par ${
                  item?.changedBy
                    ? getFullName(item.changedBy as any)
                    : 'le système'
                }`;
                return (
                  <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{
                      background: backgroundColor,
                      color: '#fff',
                    }}
                    contentArrowStyle={{
                      borderRight: `7px solid ${backgroundColor}`,
                    }}
                    date={date}
                    iconStyle={{
                      background: backgroundColor,
                      color: '#fff',
                    }}
                    icon={getTimeLineIconByAction(
                      item.action,
                      to?.state || to?.status
                    )}
                  >
                    <Text variant="b" color="white">
                      {title}
                    </Text>
                    <h4 className="vertical-timeline-element-subtitle">
                      {subTitle}
                    </h4>
                    {to?.providerComment && (
                      <Box width={1 / 1}>
                        <Text color="white">
                          Commentaire pour le fournisseur :{' '}
                          {to?.providerComment}
                        </Text>
                      </Box>
                    )}
                    {to?.customerComment && (
                      <Box width={1 / 1}>
                        <Text color="white">
                          Commentaire pour le client : {to?.customerComment}
                        </Text>
                      </Box>
                    )}
                    {to?.accountManagerComment && (
                      <Box width={1 / 1}>
                        <Text color="white">
                          Commentaire pour le chargé de compte :{' '}
                          {to?.accountManagerComment}
                        </Text>
                      </Box>
                    )}
                    {to?.comment && (
                      <Box width={1 / 1}>
                        <Text color="white">
                          Commentaire pour le chargé de compte : {to?.comment}
                        </Text>
                      </Box>
                    )}
                    {to?.accountManager && from?.accountManager && (
                      <Box width={1 / 1}>
                        <Text color="white">
                          Changement du chargé de compte :{' '}
                          {from?.accountManager &&
                            ` ${getUserFullNameFromUuid(
                              from?.accountManager
                            )} → `}
                          {getUserFullNameFromUuid(to?.accountManager)}
                        </Text>
                      </Box>
                    )}
                    {to?.customerReference && (
                      <Box width={1 / 1}>
                        <Text color="white">
                          Changement référence client :{' '}
                          {from?.customerReference &&
                            ` ${from?.customerReference} →`}
                          {to?.customerReference}
                        </Text>
                      </Box>
                    )}
                    {to?.providerInvoiceRef && (
                      <Box width={1 / 1}>
                        <Text color="white">
                          Changement référence fournisseur :{' '}
                          {from?.providerInvoiceRef &&
                            ` ${from?.providerInvoiceRef} →`}
                          {to?.providerInvoiceRef}
                        </Text>
                      </Box>
                    )}
                    {(to?.rejectReason || to?.rejectMetaData) && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Motif du refus :{' '}
                            {to?.rejectReason ===
                            ERejectCraReason?.NON_COMPLIANT_PROOF
                              ? 'Justificatif non conforme'
                              : to?.rejectMetaData || to?.rejectReason}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.invoiceDate && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Date de facture : {getHumanDate(to?.invoiceDate)}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.dueDate && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Date d'écheance : {getHumanDate(to?.dueDate)}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.rpaSentAt && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Date d'envoi du RPA : {getHumanDate(to?.rpaSentAt)}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.sage && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text variant="b" color="white">
                            Informations Gescom :
                          </Text>
                          <ul style={{ marginLeft: '20px' }}>
                            {to?.sage?.documentType && (
                              <li>
                                {/*@ts-ignore*/}
                                Type: {SAGE_DOCTYPE?.[to?.sage?.documentType]}
                              </li>
                            )}
                            {to?.sage?.invoiceId && (
                              <li>N°: {to?.sage?.invoiceId}</li>
                            )}
                            {to?.sage?.structure && (
                              <li>Structure: {to?.sage?.structure}</li>
                            )}
                            {to?.sage?.references &&
                              to?.sage?.references?.map((r: any) => (
                                <>
                                  <li>Structure: {r.structure}</li>
                                </>
                              ))}
                          </ul>
                        </Box>
                      </Box>
                    )}
                    {to?.attachments && to?.attachments?.length > 0 && (
                      <Box style={{ marginTop: '10px' }}>
                        <Text color="white">Nouveaux justificatifs : </Text>
                        {to?.attachments?.map(
                          (attachment: any, index: number) => {
                            return (
                              <ul>
                                <Link
                                  color="white"
                                  iconLeft={<EyeIcon fill="white" />}
                                  onClick={() =>
                                    showDisplayPdfModal({
                                      fileLocation:
                                        attachment?.fileLocation || '',
                                      fileName: `${attachment?.fileName}.pdf`,
                                      layer: 200,
                                    })
                                  }
                                >
                                  Voir le fichier {index + 1}
                                </Link>
                              </ul>
                            );
                          }
                        )}
                      </Box>
                    )}
                    {to?.proof && (
                      <ul>
                        <Flex alignItems={'center'}>
                          <Text color="white">Nouveau justificatif : </Text>
                          <Link
                            color="white"
                            style={{ marginTop: '10px' }}
                            iconLeft={<EyeIcon fill="white" />}
                            onClick={() =>
                              showDisplayPdfModal({
                                fileLocation: to?.proof?.fileLocation || '',
                                fileName: `${to?.proof?.fileName}.pdf`,
                                layer: 200,
                              })
                            }
                          >
                            Voir le fichier justificatif
                          </Link>
                        </Flex>
                      </ul>
                    )}
                    {to?.invoiceFile && (
                      <ul>
                        <Flex alignItems={'center'}>
                          <Text color="white">Facture fournisseur : </Text>
                          <Link
                            color="white"
                            style={{ marginTop: '10px' }}
                            iconLeft={<EyeIcon fill="white" />}
                            onClick={() =>
                              showDisplayPdfModal({
                                fileLocation:
                                  to?.invoiceFile?.fileLocation || '',
                                fileName: `${to?.invoiceFile?.fileName}.pdf`,
                                layer: 200,
                              })
                            }
                          >
                            Voir la facture
                          </Link>
                        </Flex>
                      </ul>
                    )}
                    {/* MISSION */}
                    {to?.startAt && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Date de début :{' '}
                            {from?.startAt
                              ? getHumanDate(from?.startAt) + ' à '
                              : ''}
                            {getHumanDate(to?.startAt)}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.endAt && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Date de fin :{' '}
                            {from?.endAt
                              ? getHumanDate(from?.endAt) + ' à '
                              : ''}
                            {getHumanDate(to?.endAt)}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.estimatedEndAt && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Date de fin éstimée :{' '}
                            {from?.estimatedEndAt
                              ? getHumanDate(from?.estimatedEndAt) + ' à '
                              : ''}
                            {getHumanDate(to?.estimatedEndAt)}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.isMandateMode !== undefined && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Mandat de gestion :{' '}
                            {to?.isMandateMode ? 'Oui' : 'Non'}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.isRemote && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Télétravail : {to?.isRemote ? 'Oui' : 'Non'}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.nbRemoteDaysPerWeek && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Jours de télétravail : {to?.nbRemoteDaysPerWeek}{' '}
                            jours
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.location && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          {to?.location?.recipient && (
                            <Text color="white">
                              Lieu de mission: {to?.location?.recipient}
                            </Text>
                          )}

                          {to?.location?.street && (
                            <Text color="white">
                              Rue: {to?.location?.street}
                            </Text>
                          )}
                          {to?.location?.additional && (
                            <Text color="white">
                              Complément d'adresse: {to?.location?.additional}
                            </Text>
                          )}
                          {to?.location?.city && (
                            <Text color="white">
                              Ville: {to?.location?.city}
                            </Text>
                          )}
                          {to?.location?.country && (
                            <Text color="white">
                              Pays: {to?.location?.country}
                            </Text>
                          )}
                          {to?.location?.postalCode && (
                            <Text color="white">
                              Code postal: {to?.location?.postalCode}
                            </Text>
                          )}
                          {to?.location?.brand && (
                            <Text color="white">
                              Marque: {to?.location?.brand}
                            </Text>
                          )}
                          {to?.location?.businessUnit && (
                            <Text color="white">
                              Département service ou BU :{' '}
                              {to?.location?.businessUnit}
                            </Text>
                          )}
                        </Box>
                      </Box>
                    )}
                    {to?.description && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Description : {stripHtmlTags(to?.description)}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.billing?.estimatedDays && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Nombre de jours commandé :{' '}
                            {to?.billing?.estimatedDays} jours
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.billing?.type && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Type de facturation :{' '}
                            {to?.billing?.type === EBillingType?.DAY
                              ? 'Temps passé'
                              : 'Forfait'}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.billing?.clientRate !== undefined && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Montant payé par le client :{' '}
                            {from?.billing?.clientRate !== undefined
                              ? round(from?.billing?.clientRate) + ' € à '
                              : ''}
                            {round(to?.billing?.clientRate)} €
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.billing?.contractorRate && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Montant perçu par l'intervenant :{' '}
                            {from?.billing?.contractorRate !== undefined
                              ? round(from?.billing?.contractorRate) + ' € à '
                              : ''}
                            {round(to?.billing?.contractorRate)} €
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.billing?.marginType && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Changement du type de taux :{' '}
                            {from?.billing?.marginType &&
                              'De ' +
                                MARGINTYPE?.[
                                  from?.billing?.marginType as EMarginType
                                ] +
                                ' à '}
                            {
                              MARGINTYPE?.[
                                to?.billing?.marginType as EMarginType
                              ]
                            }
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.billing?.margin !== undefined && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Taux :{' '}
                            {from?.billing?.margin !== undefined
                              ? round(from?.billing?.margin) + ' % à '
                              : ''}
                            {round(to?.billing?.margin)} %
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.billingInformation?.structure && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Structure : {to?.billingInformation?.structure}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.billingInformation?.missionType?.label && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Type de mission :{' '}
                            {to?.billingInformation?.missionType?.label ===
                            EMissionType?.CLASSIC
                              ? 'Talent Acquisition'
                              : 'Ressource management'}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.customer?.contact && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Flex>
                            Changement du contact client :
                            <ALink
                              href={`/clients/contacts/${to?.customer?.contact}`}
                              target="_blank"
                            >
                              <Link
                                color="white"
                                iconLeft={<EyeIcon fill="white" />}
                              >
                                Voir la fiche
                              </Link>
                            </ALink>
                          </Flex>
                        </Box>
                      </Box>
                    )}
                    {to?.customer?.establishment && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            <Flex>
                              Changement de l'établissement client :{' '}
                              <ALink
                                href={`/clients/establishments/${to?.customer?.establishment}`}
                                target="_blank"
                              >
                                <Link
                                  color="white"
                                  iconLeft={<EyeIcon fill="white" />}
                                >
                                  Voir la fiche
                                </Link>
                              </ALink>
                            </Flex>
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.customer?.billingOptions?.invoiceAddress && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Changement de l'adresse de facturation client :{' '}
                            {from?.customer?.billingOptions?.invoiceAddress &&
                              'De ' +
                                buildOneLineAddress(
                                  from?.customer?.billingOptions?.invoiceAddress
                                ) +
                                ' à '}
                            {buildOneLineAddress(
                              to?.customer?.billingOptions?.invoiceAddress
                            )}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.expensesConfig?.marginType && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Changement de taux des frais :{' '}
                            {from?.expensesConfig?.marginType
                              ? MARGINTYPE?.[
                                  from?.expensesConfig
                                    ?.marginType as EMarginType
                                ] + ' à '
                              : ''}
                            {
                              MARGINTYPE?.[
                                to?.expensesConfig?.marginType as EMarginType
                              ]
                            }
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.expensesConfig?.saleBudget !== undefined && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Montant enveloppe client :{' '}
                            {from?.expensesConfig?.saleBudget !== undefined
                              ? round(from?.expensesConfig?.saleBudget) +
                                ' € à '
                              : ''}
                            {round(to?.expensesConfig?.saleBudget)} €
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.expensesConfig?.purchaseBudget !== undefined && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Montant enveloppe fournisseur :{' '}
                            {from?.expensesConfig?.purchaseBudget !== undefined
                              ? round(from?.expensesConfig?.purchaseBudget) +
                                ' € à '
                              : ''}
                            {round(to?.expensesConfig?.purchaseBudget)} €
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.expensesConfig?.description && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Changement de la description de l'enveloppe de frais
                            :{' '}
                            {from?.expensesConfig?.description
                              ? from?.expensesConfig?.description + ' à '
                              : ''}
                            {to?.expensesConfig?.description}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.customer?.quote?.reference && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Changement du devis client :{' '}
                            {from?.customer?.quote?.reference
                              ? from?.customer?.quote?.reference + ' à '
                              : ''}
                            {to?.customer?.quote?.reference}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.customer?.purchaseOrder?.reference && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Changement du BDC client :{' '}
                            {from?.customer?.purchaseOrder?.reference
                              ? from?.customer?.purchaseOrder?.reference + ' à '
                              : ''}
                            {to?.customer?.purchaseOrder?.reference}{' '}
                          </Text>
                          <Text color="white">
                            {to?.customer?.purchaseOrder &&
                              'Statut : ' +
                                FR_STATUS[
                                  to?.customer?.purchaseOrder
                                    ?.status as 'IMPORTED'
                                ]}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.customer?.customMessageForProvider && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Changement du message à afficher au partenaire lors
                            du dépôt de son jalon :{' '}
                            {from?.customer?.customMessageForProvider
                              ? from?.customer?.customMessageForProvider + ' à '
                              : ''}
                            {to?.customer?.customMessageForProvider}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.customer?.billingOptions?.isAutoBilling !==
                      undefined && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Changement de la facturation automatique :{' '}
                            {from?.customer?.billingOptions?.isAutoBilling !==
                            undefined
                              ? (from?.customer?.billingOptions?.isAutoBilling
                                  ? 'Oui'
                                  : 'Non') + ' à '
                              : ''}
                            {to?.customer?.billingOptions?.isAutoBilling
                              ? 'Oui'
                              : 'Non'}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.customer?.billingOptions?.isAutoSendEmail !==
                      undefined && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Changement de l'envoie par email automatique :{' '}
                            {from?.customer?.billingOptions?.isAutoSendEmail !==
                            undefined
                              ? (from?.customer?.billingOptions?.isAutoSendEmail
                                  ? 'Oui'
                                  : 'Non') + ' à '
                              : ''}
                            {to?.customer?.billingOptions?.isAutoSendEmail
                              ? 'Oui'
                              : 'Non'}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.customer?.billingOptions?.customerRefPattern && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Changement du message à afficher au partenaire lors
                            du dépôt de son jalon :{' '}
                            {from?.customer?.billingOptions?.customerRefPattern
                              ? from?.customer?.billingOptions
                                  ?.customerRefPattern + ' à '
                              : ''}
                            {to?.customer?.billingOptions?.customerRefPattern}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.customer?.billingOptions?.iban && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white" data-clarity-mask="True">
                            Changement des coordonnées bancaire de Freelance.com
                            :{' '}
                            {from?.customer?.billingOptions?.iban
                              ? from?.customer?.billingOptions?.iban + ' à '
                              : ''}
                            {to?.customer?.billingOptions?.iban}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.customer?.billingOptions?.paymentDeadline && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Changement du délais de paiement :{' '}
                            {from?.customer?.billingOptions?.paymentDeadline
                              ? from?.customer?.billingOptions
                                  ?.paymentDeadline + ' jours à '
                              : ''}
                            {to?.customer?.billingOptions?.paymentDeadline}{' '}
                            jours
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.customer?.billingOptions?.paymentLabel && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Changement de la modalité de paiement :{' '}
                            {from?.customer?.billingOptions?.paymentLabel
                              ? PAYMENTLABEL[
                                  from?.customer?.billingOptions
                                    ?.paymentLabel as EInvoicePaymentLabel
                                ] + ' à '
                              : ''}
                            {
                              PAYMENTLABEL[
                                to?.customer?.billingOptions
                                  ?.paymentLabel as EInvoicePaymentLabel
                              ]
                            }
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.customer?.billingOptions?.sendMethod && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Changement du mode de distribution :{' '}
                            {from?.customer?.billingOptions?.sendMethod
                              ? SENDMETHOD[
                                  from?.customer?.billingOptions
                                    ?.sendMethod as EInvoiceSendMethod
                                ] + ' à '
                              : ''}
                            {
                              SENDMETHOD[
                                to?.customer?.billingOptions
                                  ?.sendMethod as EInvoiceSendMethod
                              ]
                            }
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {/* MISSION PROVIDER */}
                    {to?.provider?.contact && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Flex>
                            Changement du contact fournisseur :
                            <ALink
                              href={`/providers/contacts/${to?.provider?.contact}`}
                              target="_blank"
                            >
                              <Link
                                color="white"
                                iconLeft={<EyeIcon fill="white" />}
                              >
                                Voir la fiche
                              </Link>
                            </ALink>
                          </Flex>
                        </Box>
                      </Box>
                    )}
                    {to?.provider?.establishment && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            <Flex>
                              Changement de l'établissement fournisseur :{' '}
                              <ALink
                                href={`/providers/establishments/${to?.provider?.establishment}`}
                                target="_blank"
                              >
                                <Link
                                  color="white"
                                  iconLeft={<EyeIcon fill="white" />}
                                >
                                  Voir la fiche
                                </Link>
                              </ALink>
                            </Flex>
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.provider?.contractor && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            <Flex>
                              Changement de l'intervenant :{' '}
                              <ALink
                                href={`/contractors/${to?.customer?.contractor}`}
                                target="_blank"
                              >
                                <Link
                                  color="white"
                                  iconLeft={<EyeIcon fill="white" />}
                                >
                                  Voir la fiche
                                </Link>
                              </ALink>
                            </Flex>
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {to?.provider?.purchaseOrders?.[0]?.reference && (
                      <Box mt={'5px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          <Text color="white">
                            Changement du BDC fournisseur :{' '}
                            {from?.provider?.purchaseOrders?.[0]?.reference
                              ? from?.provider?.purchaseOrders?.[0]?.reference +
                                ' à '
                              : ''}
                            {to?.provider?.purchaseOrders?.[0]?.reference}{' '}
                          </Text>
                          <Text color="white">
                            {to?.provider?.purchaseOrders?.[0] &&
                              'Statut : ' +
                                FR_STATUS[
                                  to?.provider?.purchaseOrders?.[0]
                                    ?.status as 'IMPORTED'
                                ]}
                          </Text>
                        </Box>
                      </Box>
                    )}
                    {/** COMPANY_PROVIDER */}
                    {to?.manager && from?.manager && (
                      <Box width={1 / 1}>
                        <Text color="white">
                          Changement du chargé de compte :{' '}
                          {from?.manager &&
                            ` ${getUserFullNameFromUuid(from?.manager)} → `}
                          {getUserFullNameFromUuid(to?.manager)}
                        </Text>
                      </Box>
                    )}
                    {to?.bankAccount && (
                      <Box mt={'10px'} width={1 / 1}>
                        <Box width={1 / 1}>
                          {from?.bankAccount && (
                            <Box>
                              <Text variant="b" color="white">
                                Anciennes coordonnées bancaires :
                              </Text>
                              <Row spacing={0}>
                                <LabelField
                                  mt={0}
                                  mb={0}
                                  colorLabel={'white'}
                                  colorValue={'white'}
                                  label={'Titulaire du compte : '}
                                  value={
                                    <Box mb={20} ml={10}>
                                      {from?.bankAccount?.owner || 'N/A'}
                                    </Box>
                                  }
                                />
                                <LabelField
                                  mt={0}
                                  mb={0}
                                  colorLabel={'white'}
                                  colorValue={'white'}
                                  label={'Nom de la banque :'}
                                  value={
                                    <Box mb={20} ml={10}>
                                      {from?.bankAccount?.bankName || 'N/A'}
                                    </Box>
                                  }
                                />
                              </Row>
                              <Row spacing={0}>
                                <LabelField
                                  data-clarity-mask="True"
                                  mt={0}
                                  mb={0}
                                  colorLabel={'white'}
                                  colorValue={'white'}
                                  label={'IBAN :'}
                                  value={
                                    <Box mb={20} ml={10}>
                                      {from?.bankAccount?.iban || 'N/A'}
                                    </Box>
                                  }
                                />
                                <LabelField
                                  data-clarity-mask="True"
                                  mt={0}
                                  mb={0}
                                  colorLabel={'white'}
                                  colorValue={'white'}
                                  label={'BIC : '}
                                  value={
                                    <Box mb={20} ml={10}>
                                      {from?.bankAccount?.bic || 'N/A'}
                                    </Box>
                                  }
                                />
                              </Row>
                              {from?.bankAccount?.rib && (
                                <Row spacing={0}>
                                  <LabelField
                                    data-clarity-mask="True"
                                    mt={0}
                                    mb={0}
                                    colorLabel={'white'}
                                    colorValue={'white'}
                                    label={'RIB :'}
                                    value={
                                      <Box mb={20} ml={10}>
                                        <ALink
                                          target={'_blank'}
                                          href={`/file/${from?.bankAccount?.rib?.fileLocation.replaceAll(
                                            '/',
                                            '§'
                                          )}`}
                                        >
                                          <Link color="white" flex={1}>
                                            {from?.bankAccount?.rib?.fileName}
                                          </Link>
                                        </ALink>
                                      </Box>
                                    }
                                  />
                                  <></>
                                </Row>
                              )}
                              <Divider />
                            </Box>
                          )}
                          <Box>
                            <Text variant="b" color="white">
                              Nouvelles coordonnées bancaires :
                            </Text>
                            <Row spacing={0}>
                              <LabelField
                                data-clarity-mask="True"
                                mt={0}
                                mb={0}
                                colorLabel={'white'}
                                colorValue={'white'}
                                label={'Titulaire du compte : '}
                                value={
                                  <Box mb={20} ml={10}>
                                    {to?.bankAccount?.owner || 'N/A'}
                                  </Box>
                                }
                              />
                              <LabelField
                                data-clarity-mask="True"
                                mt={0}
                                mb={0}
                                colorLabel={'white'}
                                colorValue={'white'}
                                label={'Nom de la banque :'}
                                value={
                                  <Box mb={20} ml={10}>
                                    {to?.bankAccount?.bankName || 'N/A'}
                                  </Box>
                                }
                              />
                            </Row>
                            <Row spacing={0}>
                              <LabelField
                                data-clarity-mask="True"
                                mt={0}
                                mb={0}
                                colorLabel={'white'}
                                colorValue={'white'}
                                label={'IBAN :'}
                                value={
                                  <Box mb={20} ml={10}>
                                    {to?.bankAccount?.iban || 'N/A'}
                                  </Box>
                                }
                              />
                              <LabelField
                                data-clarity-mask="True"
                                mt={0}
                                mb={0}
                                colorLabel={'white'}
                                colorValue={'white'}
                                label={'BIC :'}
                                value={
                                  <Box mb={20} ml={10}>
                                    {to?.bankAccount?.bic || 'N/A'}
                                  </Box>
                                }
                              />
                            </Row>
                            {to?.bankAccount?.rib && (
                              <Row spacing={0}>
                                <LabelField
                                  data-clarity-mask="True"
                                  mt={0}
                                  mb={0}
                                  colorLabel={'white'}
                                  colorValue={'white'}
                                  label={'RIB :'}
                                  value={
                                    <Box mb={20} ml={10}>
                                      <ALink
                                        target={'_blank'}
                                        href={`/file/${to?.bankAccount?.rib?.fileLocation.replaceAll(
                                          '/',
                                          '§'
                                        )}`}
                                      >
                                        <Link color="white" flex={1}>
                                          {to?.bankAccount?.rib?.fileName}
                                        </Link>
                                      </ALink>
                                    </Box>
                                  }
                                />
                                <></>
                              </Row>
                            )}
                            {item?.data?.validationRequest && (
                              <Row spacing={0}>
                                <LabelField
                                  mt={0}
                                  mb={0}
                                  colorLabel={'white'}
                                  colorValue={'white'}
                                  label={'Créé par :'}
                                  value={
                                    <Box mb={20} ml={10}>
                                      {getUserFullNameFromUuid(
                                        item?.data?.validationRequest?.createdBy
                                      )}
                                    </Box>
                                  }
                                />
                                <LabelField
                                  mt={0}
                                  mb={0}
                                  colorLabel={'white'}
                                  colorValue={'white'}
                                  label={'Validé par :'}
                                  value={
                                    <Box mb={20} ml={10}>
                                      {getUserFullNameFromUuid(
                                        item?.data?.validationRequest
                                          ?.validatedBy
                                      )}
                                    </Box>
                                  }
                                />
                              </Row>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    )}
                    {to?.isContractRequired && (
                      <Box width={1 / 1}>
                        <Text color="white">
                          Contrat-cadre requis :{' '}
                          {from?.provider?.isContractRequired !== undefined &&
                            ` ${
                              from?.provider?.isContractRequired ? 'Oui' : 'Non'
                            } → `}
                          {to?.provider?.isContractRequired ? 'Oui' : 'Non'}
                        </Text>
                      </Box>
                    )}
                    {to?.signOfficer && (
                      <Box width={1 / 1}>
                        <Text color="white">
                          Changement du signataire du contrat cadre :{' '}
                          {from?.manager &&
                            ` ${getFullName(from?.provider?.signOfficer)} → `}
                          {getFullName(to?.provider?.signOfficer)}
                        </Text>
                      </Box>
                    )}
                    {item?.retentionPolicyApplied && (
                      <Box width={1 / 1}>
                        <Text variant="b" color="white">
                          Politique de rétention appliquée le :{' '}
                          {getHumanDate(item?.retentionPolicyAppliedAt as Date)}
                        </Text>
                      </Box>
                    )}
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          </Flex>
        ))}
    </Box>
  );
};
