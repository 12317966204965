import * as React from 'react';

function FeesIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 14V2H2V13.25C2 13.4489 2.07902 13.6397 2.21967 13.7803C2.36032 13.921 2.55109 14 2.75 14H11ZM13.25 15.5H2.75C2.15326 15.5 1.58097 15.2629 1.15901 14.841C0.737053 14.419 0.5 13.8467 0.5 13.25V1.25C0.5 1.05109 0.579018 0.860322 0.71967 0.71967C0.860322 0.579018 1.05109 0.5 1.25 0.5H11.75C11.9489 0.5 12.1397 0.579018 12.2803 0.71967C12.421 0.860322 12.5 1.05109 12.5 1.25V6.5H15.5V13.25C15.5 13.8467 15.2629 14.419 14.841 14.841C14.419 15.2629 13.8467 15.5 13.25 15.5ZM12.5 8V13.25C12.5 13.4489 12.579 13.6397 12.7197 13.7803C12.8603 13.921 13.0511 14 13.25 14C13.4489 14 13.6397 13.921 13.7803 13.7803C13.921 13.6397 14 13.4489 14 13.25V8H12.5ZM3.5 3.5H8V8H3.5V3.5ZM5 5V6.5H6.5V5H5ZM3.5 8.75H9.5V10.25H3.5V8.75ZM3.5 11H9.5V12.5H3.5V11Z"
        fill={props?.fill || '#003CC2'}
      />
    </svg>
  );
}

export default FeesIcon;
