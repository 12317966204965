import { useInvoiceFindOne } from '@commons';
import { Flex, Spinner } from 'components/ui';
import { EditTimeSpentForm } from 'forms/invoices/Customer/EditTimeSpentForm';
import { EditTimeSpentFormFromGescom } from 'forms/invoices/Customer/EditTimeSpentFormFromGescom';
import { useParams } from 'react-router-dom';

export const EditTimeSpent = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data: invoice, status } = useInvoiceFindOne(uuid);

  const loading = status === 'pending';
  if (loading) {
    return (
      <Flex
        top={0}
        left={0}
        bottom={0}
        right={0}
        alignItems="center"
        justifyContent="center"
        position="fixed"
      >
        <Spinner />
      </Flex>
    );
  }
  if (invoice?.locked || invoice?.createdFromGescomFlow) {
    return <EditTimeSpentFormFromGescom invoice={invoice} />;
  }
  return <EditTimeSpentForm invoice={invoice} />;
};
