import {
  IStatisticsToBeValidatedResponse,
  IStatisticsToBeValidatedParams,
  IStatisticsProviderSpace,
  IStatisticsInvoicesParams,
  IStatisticsInvoicesResponse,
} from '@freelancelabs/teoreme-commons';
import { STATISTICS_SERVICE_URL } from '../../constantz';
import { fetcher } from '../helpers/fetcher';

export const toBeValidatedFindOne = (disableAlert?: boolean) =>
  fetcher<IStatisticsToBeValidatedParams, IStatisticsToBeValidatedResponse>(
    STATISTICS_SERVICE_URL + `/to-be-validated`,
    undefined,
    disableAlert
  );
export const providerStatsFindOne = (disableAlert?: boolean) =>
  fetcher<any, IStatisticsProviderSpace>(
    STATISTICS_SERVICE_URL + `/provider`,
    undefined,
    disableAlert
  );

export const billedInvoicesStats = (body: any, disableAlert?: boolean) =>
  fetcher<IStatisticsInvoicesParams, IStatisticsInvoicesResponse>(
    STATISTICS_SERVICE_URL + `/invoices`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );
