import {
  downloadFile,
  uploadFile,
  unsecuredDownloadFile,
  exportFileXlxs,
} from '../routes';
import { useMutation, useQuery, keepPreviousData } from '@tanstack/react-query';
export const useDownloadFile = (
  fileLocation: string,
  config?: any,
  unsecured?: boolean
): {
  data: any;
  error: any;
  status: any;
  isFetching: boolean;
} => {
  return useQuery({
    queryKey: [fileLocation],
    queryFn: async () =>
      unsecured
        ? unsecuredDownloadFile(fileLocation)
        : downloadFile(fileLocation),
    enabled: fileLocation ? true : false,
    ...config,
  });
};

export const useFileUpload = () => useMutation({ mutationFn: uploadFile });

export const useExportFileXlsx = (variables?: any, queryKey?: string) => {
  return useQuery({
    queryKey: variables ? ['exportFileXlsx', variables] : [],
    queryFn: async () => exportFileXlxs(variables),
    enabled: variables && variables.filterObject ? true : false,
    placeholderData: keepPreviousData,
  });
};
