import {
  EContractLabel,
  EContractState,
  IPurchaseOrder,
  IJoinedMission,
  EPurchaseOrderStatus,
  EMissionStatus,
  ECraStatus,
  EadditionalActivityType,
  EadditionalActivityStatus,
  EBillingType,
} from '@freelancelabs/teoreme-commons';
import { EstablishmentStructuredCard } from 'components/cards/EstablishmentStructuredCard';
import { StructuredCard } from 'components/cards/StructuredCard';
import { UserStructuredCard } from 'components/cards/UserStructuredCard';
import { DpaeEditor } from 'components/DpaeEditor';
import { ContractList } from 'components/lists/ContractList';
import { showGenerateOrderModal } from 'components/modals/generateFile/GenerateOrderModal';
import { showImportProviderPurchaseOrderModal } from 'components/modals/ImportProviderPurchaseOrderModal';
import {
  Box,
  Button,
  Flex,
  Itemize,
  Link,
  Menu,
  MenuItem,
  Status,
  Table,
  Text,
  BlocInformation,
  Spinner,
  FormLabel,
} from 'components/ui';
import {
  DotsIcon,
  SendIcon,
  UploadIcon,
  EyeIcon,
  EditIcon,
  RefreshIcon,
  FileSignatureIcon,
} from 'components/ui/icons';
import {
  formatDate,
  purchaseOrderResendEmail,
  FR_STATUS,
  STATUS_COLOR,
  getFirstCaracts,
  getFullName,
  useContractorFindOne,
  useContractFindMany,
  CONTRACTOR_SOCIAL_STATUS,
  checkAllStrcutureCreatedInGescomForContractor,
  queryClient,
  checkUserHasRole,
  purchaseOrderGetSignatureRequest,
  useMe,
  craFindMany,
  additionalActivityFindMany,
} from '@commons';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useGetMission } from 'hooks/missionHooks';
import { useEffect, useState } from 'react';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { showGenerateExecutiveContractModal } from 'components/modals/generateFile/GenerateExecutiveContractModal';
import { showImportExecutiveContractModal } from 'components/modals/ImportExecutiveContractModal';
import { showAddMissionProviderModal } from 'components/modals/AddMissionProviderModal';
import { Section } from 'components/Section';
import AddBloc from 'components/AddBloc';
import { useShowMessage } from 'hooks/useShowMessage';
import { useMutation } from '@tanstack/react-query';
import { Theme } from 'styles';
type ProviderProps = {
  isDisabled: boolean;
  amendments: Array<IJoinedMission> | [];
};
export const Provider = ({ isDisabled, amendments }: ProviderProps) => {
  const showMessage = useShowMessage();
  const { me } = useMe();
  const { mission, reference } = useGetMission();
  const { mutateAsync: counterSign } = useMutation({
    mutationFn: (item: any) =>
      purchaseOrderGetSignatureRequest({
        procedureId: item?.procedureId,
      }),
  });
  const [missionHasMilestonesOrCras, setMissionHasMilestonesOrCras] =
    useState(true);
  let cras: any = [];

  useEffect(() => {
    const getCras = async () => {
      if (reference) {
        try {
          const craData = await craFindMany({
            filterObject: {
              mission: reference,
              state: {
                $in: [ECraStatus.VALIDATED, ECraStatus.TO_BE_VALIDATED],
              },
            },
            limit: 1,
            skip: 0,
          });
          cras = craData?.cras;
          if (cras?.length > 0) {
            setMissionHasMilestonesOrCras(true);
          } else {
            setMissionHasMilestonesOrCras(false);
          }
        } catch (error) {
          console.log('error getting cras of this mission', error);
        }
      }
    };

    const getMilestones = async () => {
      if (reference) {
        try {
          const mileStonesData = await additionalActivityFindMany({
            filterObject: {
              mission: reference,
              status: {
                $in: [
                  EadditionalActivityStatus.VALIDATED,
                  EadditionalActivityStatus.TO_BE_VALIDATED,
                ],
              },
              type: EadditionalActivityType.MILESTONE,
            },
            limit: 1,
            skip: 0,
          });
          cras = mileStonesData?.additionalActivities;
          if (cras?.length > 0) {
            setMissionHasMilestonesOrCras(true);
          } else {
            setMissionHasMilestonesOrCras(false);
          }
        } catch (error) {
          console.log('error getting cras of this mission', error);
        }
      }
    };

    if (mission?.billing?.type === EBillingType.FLAT_RATE) {
      getMilestones();
    } else {
      getCras();
    }
  }, [reference]);

  const getEstablishmentContractFilter = (establishment: any) => {
    if (establishment?.parent) {
      return {
        $or: [
          {
            'establishment.groupPath': {
              $regex: establishment?.parent,
              $options: 'i',
            },
          },
          { establishment: establishment.uuid },
        ],
      };
    }
    return {
      $or: [
        {
          'establishment.groupPath': {
            $regex: establishment?.uuid,
            $options: 'i',
          },
        },
        { establishment: establishment.uuid },
      ],
    };
  };

  const { data: eContracts } = useContractFindMany(
    mission?.provider?.establishment?.uuid
      ? {
          filterObject: {
            ...getEstablishmentContractFilter(mission?.provider?.establishment),
            label: EContractLabel.EXECUTIVE,
            state: {
              $in: [
                EContractState.DONE,
                EContractState.TO_SIGN_PROVIDER,
                EContractState.SIGNED_BY_PROVIDER,
                EContractState.SIGNED_BY_FCOM,
              ],
            },
          },
        }
      : undefined
  );

  useSetCrumbs(
    [],
    [
      { label: 'prestations', path: '/delivery' },
      { label: 'missions', path: '/delivery/missions' },
      {
        label: mission?.displayReference || 'nouvelle mission',
        path: `/delivery/missions/${reference}`,
      },
      {
        label: 'fournisseur',
        path: `/delivery/missions/${reference}/provider`,
      },
    ]
  );
  const provider = mission?.provider;

  const { data: contractor, status: contractorStatus } = useContractorFindOne(
    provider?.contractor?.uuid
  );
  const loadingContractor = contractorStatus === 'pending';

  const contractorScopedData = contractor?.scopedData?.find(
    sd => sd.establishment.uuid === provider?.establishment.uuid
  );
  const missionHasNoValidatedCraOrMilestone =
    mission?.billingInformation?.missionType?.label !== 'CLASSIC' &&
    mission?.status === EMissionStatus?.VALIDATED &&
    !missionHasMilestonesOrCras;

  const ReSendButton = ({ refPO }: { refPO: string }) => {
    const { mutateAsync: onResend, status } = useMutation({
      mutationFn: () =>
        purchaseOrderResendEmail({
          purchaseOrder: {
            reference: refPO || '',
          },
        }),
    });
    if (status === 'pending') return <Spinner size={20} />;
    if (status === 'success')
      showMessage('success', 'Bon de commande fournisseur envoyé');
    return (
      <Link iconLeft={<RefreshIcon />} onClick={() => onResend()}>
        Relancer
      </Link>
    );
  };
  const isContractRequired =
    mission?.provider?.establishment?.provider?.isContractRequired;
  const canUploadOrder =
    !!(
      (isContractRequired && eContracts?.contracts?.length) ||
      !isContractRequired
    ) &&
    !!mission?.billing &&
    !!mission?.provider?.updatedAt &&
    !!mission?.customer?.updatedAt &&
    !!mission?.sage;
  if (!provider || !Object.keys(provider).length)
    return (
      <Flex flexDirection="column" alignItems="center" p="50px 0">
        <Text variant="h2" color="primary.light" mb="10">
          Aucun fournisseur sélectionné
        </Text>
        <Text color="primary.light" mb={20}>
          Vous n’avez pas encore sélectionné de fournisseur pour cette
          prestation.
        </Text>
        <Button
          data-cy="mission-provider-form-select-provider"
          onClick={() => showAddMissionProviderModal({ method: 'CREATE' })}
        >
          Sélectionner un fournisseur
        </Button>
      </Flex>
    );
  //TEOR-5351 UPDATE RULES
  const enableUpdate =
    mission?.status === EMissionStatus?.DRAFT ||
    missionHasNoValidatedCraOrMilestone;
  //mission?.status === EMissionStatus?.DRAFT && amendments?.length === 0;
  const checkSageData = checkAllStrcutureCreatedInGescomForContractor(
    provider?.contractor?.sage
  );
  return (
    <Box>
      <Section variant="h2" title="Informations du fournisseur" mb={40}>
        <Section variant="h3" title="Établissement fournisseur" mb={40}>
          <EstablishmentStructuredCard
            forRole={'PROVIDER'}
            checkSage
            mb={20}
            establishment={provider?.establishment}
            renderActions={
              enableUpdate && (
                <Box>
                  <Link
                    ml={10}
                    mt={'-5px'}
                    iconLeft={<EditIcon />}
                    isDisabled={isDisabled}
                    onClick={() => {
                      if (isDisabled) return;
                      showAddMissionProviderModal({
                        method: 'UPDATE',
                        mission: mission,
                        missionHasNoValidatedCraOrMilestone,
                      });
                    }}
                  >
                    Modifier
                  </Link>
                </Box>
              )
            }
          />
        </Section>
        <Section variant="h3" title="Contrat cadre" mb={40}>
          <Flex mb={10}>
            <Link
              iconLeft={<SendIcon />}
              mr={20}
              isDisabled={isDisabled}
              onClick={() => {
                if (isDisabled) return;
                showGenerateExecutiveContractModal({
                  id: mission?.provider.establishment.uuid as string,
                  refOrder: mission?.reference || undefined,
                  contractLabel: EContractLabel.EXECUTIVE,
                }).then(refresh => {
                  if (refresh) {
                    queryClient.refetchQueries({
                      queryKey: ['contracts'],
                      type: 'active',
                    });
                    queryClient.refetchQueries({
                      queryKey: ['establishments'],
                      type: 'active',
                    });
                  }
                });
              }}
            >
              Envoyer un nouveau contrat
            </Link>

            <Link
              iconLeft={<UploadIcon />}
              mr={20}
              isDisabled={isDisabled}
              onClick={() => {
                if (isDisabled) return;
                showImportExecutiveContractModal({
                  establishmentUuid: mission?.provider.establishment.uuid || '',
                  refOrder: mission?.reference || undefined,
                  contractLabel: EContractLabel.EXECUTIVE,
                });
              }}
            >
              Importer un contrat-cadre signé
            </Link>
            {/* <Menu
              menuItems={
                <>
                  <MenuItem>
                    <Link iconLeft={<SendIcon />}>Envoyer un avenant</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link iconLeft={<UploadIcon />}>
                      Importer un avenant signé
                    </Link>
                  </MenuItem>
                </>
              }
            >
              <DotsIcon fill="#00ABEF" fontSize={20} />
            </Menu> */}
          </Flex>

          <ContractList
            minified={true}
            isDisabled={isDisabled}
            filterObject={{
              $and: [
                {
                  ...getEstablishmentContractFilter(provider?.establishment),
                },
                { label: EContractLabel.EXECUTIVE },
                {
                  state: {
                    $in: [
                      EContractState.DONE,
                      EContractState.TO_SIGN_PROVIDER,
                      EContractState.SIGNED_BY_PROVIDER,
                      EContractState.SIGNED_BY_FCOM,
                    ],
                  },
                },
              ],
            }}
          />
        </Section>
        {mission?.isMandateMode && (
          <Section variant="h3" title="Mandats" mb={40}>
            <Flex mb={10}>
              <Link
                iconLeft={<SendIcon />}
                mr={20}
                onClick={() =>
                  showGenerateExecutiveContractModal({
                    id: mission?.provider.establishment.uuid as string,
                    refOrder: mission?.reference || undefined,
                    contractLabel: EContractLabel.MANDATE,
                  }).then(refresh => {
                    if (refresh) {
                      queryClient.refetchQueries({
                        queryKey: ['contracts'],
                        type: 'active',
                      });
                      queryClient.refetchQueries({
                        queryKey: ['establishments'],
                        type: 'active',
                      });
                    }
                  })
                }
              >
                Envoyer un nouveau mandat
              </Link>

              <Link
                iconLeft={<UploadIcon />}
                mr={20}
                onClick={() =>
                  showImportExecutiveContractModal({
                    establishmentUuid:
                      mission?.provider.establishment.uuid || '',
                    refOrder: mission?.reference || undefined,
                    contractLabel: EContractLabel.MANDATE,
                  })
                }
              >
                Importer un mandat signé
              </Link>
              {/* <Menu
              menuItems={
                <>
                  <MenuItem>
                    <Link iconLeft={<SendIcon />}>Envoyer un avenant</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link iconLeft={<UploadIcon />}>
                      Importer un avenant signé
                    </Link>
                  </MenuItem>
                </>
              }
            >
              <DotsIcon fill="#00ABEF" fontSize={20} />
            </Menu> */}
            </Flex>

            <ContractList
              minified={true}
              filterObject={{
                $and: [
                  {
                    ...getEstablishmentContractFilter(provider?.establishment),
                  },
                  { label: EContractLabel.MANDATE },
                  {
                    state: {
                      $in: [
                        EContractState.DONE,
                        EContractState.TO_SIGN_PROVIDER,
                        EContractState.SIGNED_BY_PROVIDER,
                        EContractState.SIGNED_BY_FCOM,
                      ],
                    },
                  },
                ],
              }}
            />
          </Section>
        )}

        <Section mb={40}>
          {!loadingContractor && provider.contact ? (
            <>
              <FormLabel>Contact fournisseur </FormLabel>
              <UserStructuredCard
                mb={20}
                user={provider.contact}
                establishment={provider?.establishment}
                forRole={'PROVIDER'}
                checkSage
                renderActions={
                  mission?.status !== EMissionStatus?.TERMINATED &&
                  mission?.status !== EMissionStatus?.ARCHIVED && (
                    <Link
                      ml={10}
                      mt={'-5px'}
                      iconLeft={<EditIcon />}
                      isDisabled={isDisabled}
                      onClick={() => {
                        if (isDisabled) return;
                        showAddMissionProviderModal({
                          method: 'UPDATE',
                          mission: mission,
                          missionHasNoValidatedCraOrMilestone,
                        });
                      }}
                    >
                      Modifier
                    </Link>
                  )
                }
              />
            </>
          ) : (
            <>
              <FormLabel>Contact fournisseur</FormLabel>
              <>N/A</>
            </>
          )}
          {loadingContractor && <Spinner />}
        </Section>

        <Section mb={20}>
          {!loadingContractor && provider?.contractor ? (
            <>
              <FormLabel>Intervenant</FormLabel>
              <Box
              //style={{ cursor: 'pointer' }}
              // onClick={() =>
              //   history.push(
              //     `/providers/contractors/${provider?.contractor?.uuid}`
              //   )
              // }
              >
                <StructuredCard
                  style={{ cursor: 'pointer' }}
                  redirect={`/providers/contractors/${provider?.contractor?.uuid}`}
                  renderActions={
                    enableUpdate && (
                      <Link
                        ml={10}
                        mt={'-5px'}
                        iconLeft={<EditIcon />}
                        // isDisabled={isDisabled}
                        onClick={() => {
                          // if (isDisabled) return;
                          showAddMissionProviderModal({
                            method: 'UPDATE',
                            mission: mission,
                            missionHasNoValidatedCraOrMilestone,
                          });
                        }}
                      >
                        Modifier
                      </Link>
                    )
                  }
                  checkSage
                  sage={checkSageData}
                  salesforce={contractorScopedData?.salesforce}
                  mb={20}
                  avatarLetter={getFirstCaracts(
                    contractor || provider?.contractor
                  )}
                  primaryTopText={getFullName(
                    contractor || provider?.contractor
                  )}
                  secondaryTopText={contractorScopedData?.jobTitle || 'N/A'}
                  primaryBottomText={`Téléphone lié à l'établissement : ${
                    contractorScopedData?.phone || 'N/A'
                  }`}
                  secondaryBottomText={`Email : ${
                    contractor?.user?.email || 'N/A'
                  }`}
                  secondLineBottomText={`Statut social : ${
                    contractorScopedData?.socialStatus
                      ? CONTRACTOR_SOCIAL_STATUS[
                          contractorScopedData?.socialStatus
                        ]
                      : '-'
                  } - Email secondaire : ${
                    contractorScopedData?.email || 'N/A'
                  }`}
                />
              </Box>
            </>
          ) : (
            <>
              <FormLabel>Intervenant</FormLabel>
              <>N/A</>
            </>
          )}
          {loadingContractor && <Spinner />}
        </Section>

        {contractor && contractorScopedData && (
          <Section>
            <DpaeEditor
              contractor={contractor}
              scopedData={contractorScopedData}
              isDisabled={isDisabled}
            />
          </Section>
        )}
      </Section>

      <Section
        variant="h2"
        title={!mission?.isMandateMode ? 'Bon de commande fournisseur' : ''}
        mb={40}
      >
        {!canUploadOrder && (
          <BlocInformation mb={20}>
            <p>
              Avant de pouvoir envoyer votre premier bon de commande
              fournisseur, merci de renseigner toutes les informations du client
              et du fournisseur (établissement et contact) et de créer votre
              mission dans <b>GESCOM</b> en complétant les champs{' '}
              <b>
                “Structure de facturation”, "Tarification", “Intitulé de la
                mission”, “date de début” et “date de fin”
              </b>{' '}
              depuis vos rubriques{' '}
              <b>“Informations de la mission“ et “Informations générales“.</b>
            </p>
          </BlocInformation>
        )}

        {!!canUploadOrder &&
          !isDisabled &&
          !mission?.isMandateMode &&
          (!provider?.purchaseOrders ||
            provider?.purchaseOrders?.length < 1) && (
            <AddBloc>
              <Link
                iconLeft={<SendIcon />}
                onClick={() =>
                  showGenerateOrderModal({
                    missionReference: mission?.reference,
                    isDisabled: isDisabled,
                  })
                }
              >
                Envoyer un BDC fournisseur
              </Link>
              <Text m="0 10px">ou</Text>
              <Link
                onClick={() => showImportProviderPurchaseOrderModal()}
                iconLeft={<UploadIcon />}
              >
                Importer un bon de commande
              </Link>
            </AddBloc>
          )}
        {/* {isDisabled &&
          (!provider?.purchaseOrders ||
            provider?.purchaseOrders?.length === 0) && (
            <BlocInformation>
              <p>Aucun bon de commande fournisseur</p>
            </BlocInformation>
          )} */}
        {!!canUploadOrder &&
          !isDisabled &&
          provider.purchaseOrders &&
          !mission?.isMandateMode &&
          provider.purchaseOrders?.length > 0 && (
            <>
              <Flex mb={20}>
                <Link
                  iconLeft={<SendIcon />}
                  mr={20}
                  onClick={() =>
                    showGenerateOrderModal({
                      missionReference: mission?.reference,
                      isDisabled: isDisabled,
                    })
                  }
                >
                  Envoyer un bon de commande
                </Link>
                <Link
                  iconLeft={<UploadIcon />}
                  onClick={() => showImportProviderPurchaseOrderModal()}
                >
                  Importer un bon de commande
                </Link>
              </Flex>
            </>
          )}
        {provider?.purchaseOrders?.length ? (
          <Table<Itemize<IPurchaseOrder>>
            columns={[
              {
                key: 'Date',
                text: 'Date',
                render: item => item.createdAt && formatDate(item.createdAt),
              },
              { key: 'reference', text: 'Ref' },
              {
                key: 'status',
                text: 'Status',
                render: item => (
                  <Status
                    variantColor={
                      item.status === EPurchaseOrderStatus.SIGNED
                        ? 'warning'
                        : STATUS_COLOR[item.status] || 'grey'
                    }
                  >
                    {item.status === EPurchaseOrderStatus.SIGNED
                      ? 'En cours signature'
                      : FR_STATUS[item.status]}{' '}
                    le {formatDate(item.updatedAt || item.createdAt || '')}
                  </Status>
                ),
              },
              {
                key: 'Actions',
                text: 'Actions',
                render: (item: any) => (
                  <Menu
                    align="right"
                    ml={10}
                    menuItems={
                      <>
                        {[
                          EPurchaseOrderStatus.TO_SIGN,
                          EPurchaseOrderStatus.SIGNED,
                        ].includes(item?.status) && (
                          <MenuItem>
                            <ReSendButton refPO={item?.reference as string} />
                          </MenuItem>
                        )}
                        <MenuItem>
                          <Link
                            iconLeft={<EyeIcon />}
                            mb={10}
                            onClick={() => {
                              showDisplayPdfModal({
                                fileLocation: item.file.fileLocation,
                                fileName: item.file.fileName,
                              });
                            }}
                          >
                            Voir le bon de commande
                          </Link>
                        </MenuItem>
                        <MenuItem
                          hidden={
                            item?.status !== EPurchaseOrderStatus.SIGNED ||
                            !checkUserHasRole(me, 'TEAM_LEADER')
                          }
                        >
                          <Link
                            iconLeft={
                              <FileSignatureIcon
                                height="14px"
                                style={{ marginTop: '5px' }}
                              />
                            }
                            mb={10}
                            onClick={async () => {
                              try {
                                const res = await counterSign(item);
                                const fileName = res?.url;
                                const a = document.createElement('a');
                                a.href = fileName || '';
                                a.target = '_blank';
                                a.click();
                              } catch (error) {
                                console.log(error);
                                showMessage(
                                  'error',
                                  'Erreur lors de la demande de signature'
                                );
                              }
                            }}
                          >
                            Signer le bon de commande
                          </Link>
                        </MenuItem>
                      </>
                    }
                  >
                    <DotsIcon
                      fill={Theme?.colors?.primary?.default}
                      fontSize={20}
                    />
                  </Menu>
                ),
              },
            ]}
            items={
              provider.purchaseOrders
                ?.slice(0)
                .reverse()
                .map(po => ({
                  ...po,
                  key: po.reference || '',
                })) || []
            }
          />
        ) : (
          <></>
        )}
      </Section>
    </Box>
  );
};
