import * as React from 'react';

function SvgEyeSlashIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M19.898 11.971a13.223 13.223 0 00-2.188-2.525l-7.867 7.859a7.126 7.126 0 002.157.348c4.714 0 7.77-4.77 7.898-4.973a.665.665 0 000-.709zm-.76-6.776a.667.667 0 00-.943 0L15.49 7.897a7.511 7.511 0 00-3.49-.9c-4.714 0-7.77 4.77-7.898 4.974a.665.665 0 000 .709 13.28 13.28 0 003.24 3.357l-1.813 1.811a.666.666 0 10.942.942L19.137 6.136a.665.665 0 000-.941zm-9.805 7.13A2.665 2.665 0 0112 9.661a2.59 2.59 0 011.341.383l-3.625 3.62a2.591 2.591 0 01-.383-1.339z"
      />
    </svg>
  );
}

export default SvgEyeSlashIcon;
