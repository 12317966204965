import {
  IAdminUserManageParams,
  IAdminUserManageResponse,
  IAdminTaskGetParams,
  IAdminTaskGetResponse,
  IAdminTaskSearchParams,
  IAdminTaskSearchResponse,
  IAdminTaskManageParams,
  IAdminTaskManageResponse,
  IAdminValidationGetParams,
  IAdminValidationGetResponse,
  IAdminValidationSearchParams,
  IAdminValidationSearchResponse,
  IAdminValidationCreateParams,
  IAdminValidationCreateResponse,
  IAdminValidationUpdateParams,
  IAdminValidationUpdateResponse,
  EValidationType,
  IAdminSettingGetParams,
  IAdminSettingGetResponse,
  IAdminSettingSearchParams,
  IAdminSettingSearchResponse,
  IAdminSettingUpdateParams,
  IAdminSettingUpdateResponse,
  IAdminTaskCreateParams,
  IAdminTaskCreateResponse,
} from '@freelancelabs/teoreme-commons';
import { fetcher } from '../helpers/fetcher';
import { ADMIN_SERVICE_URL } from '../../constantz';
export const createOrUpdateUser = (
  params: IAdminUserManageParams,
  disableAlert?: boolean
) =>
  fetcher<IAdminUserManageParams, IAdminUserManageResponse>(
    ADMIN_SERVICE_URL + '/user/manage',
    {
      method: 'POST',
      body: params,
    },
    disableAlert
  );

export const createTask = (
  params: IAdminTaskCreateParams,
  disableAlert?: boolean
) =>
  fetcher<IAdminTaskCreateParams, IAdminTaskCreateResponse>(
    ADMIN_SERVICE_URL + '/task/create',
    {
      method: 'POST',
      body: params,
    },
    disableAlert
  );

export const taskFindOne = (identifier?: string, disableAlert?: boolean) =>
  fetcher<IAdminTaskGetParams, IAdminTaskGetResponse>(
    ADMIN_SERVICE_URL + `/task/get?uuid=${identifier}`,
    {
      method: 'GET',
    },
    disableAlert
  );
export const taskFindMany = (searchParams: any, disableAlert?: boolean) =>
  fetcher<IAdminTaskSearchParams, IAdminTaskSearchResponse>(
    ADMIN_SERVICE_URL + `/task/search`,
    {
      method: 'POST',
      body: searchParams,
    },
    disableAlert
  );
export const taskManage = (
  params: IAdminTaskManageParams,
  disableAlert?: boolean
) =>
  fetcher<IAdminTaskManageParams, IAdminTaskManageResponse>(
    ADMIN_SERVICE_URL + `/task/manage`,
    {
      method: 'POST',
      body: params,
    },
    disableAlert
  );

// VALIDATION
// TODO ADD VALIDATION TYPE ON BODY !! EX IProviderBankAccountValidationRequest
export const createAdminValidation = (
  body: IAdminValidationCreateParams<EValidationType>,
  disableAlert?: boolean
) =>
  fetcher<
    IAdminValidationCreateParams<EValidationType>,
    IAdminValidationCreateResponse
  >(
    `${ADMIN_SERVICE_URL}/validation/create`,
    { method: 'post', body },
    disableAlert
  );
export const updateAdminValidation = (
  body: IAdminValidationUpdateParams<any>,
  disableAlert?: boolean
) =>
  fetcher<IAdminValidationUpdateParams<any>, IAdminValidationUpdateResponse>(
    ADMIN_SERVICE_URL + `/validation/update`,
    {
      method: 'PUT',
      body,
    },
    disableAlert
  );
export const adminValidationFindOne = (uuid: string, disableAlert?: boolean) =>
  fetcher<IAdminValidationGetParams, IAdminValidationGetResponse>(
    ADMIN_SERVICE_URL + `/validation/get?uuid=${uuid}`,
    {
      method: 'GET',
    },
    disableAlert
  );

export const adminValidationFindMany = (
  params: IAdminValidationSearchParams,
  disableAlert?: boolean
) =>
  fetcher<IAdminValidationSearchParams, IAdminValidationSearchResponse>(
    ADMIN_SERVICE_URL + `/validation/search`,
    {
      method: 'POST',
      body: params,
    },
    disableAlert
  );

export const adminSettingFindOne = (key: string, disableAlert?: boolean) =>
  fetcher<IAdminSettingGetParams, IAdminSettingGetResponse>(
    ADMIN_SERVICE_URL + `/setting/get?key=${key}`,
    {
      method: 'GET',
    },
    disableAlert
  );

export const adminSettingFindMany = (
  params: IAdminSettingSearchParams,
  disableAlert?: boolean
) =>
  fetcher<IAdminSettingSearchParams, IAdminSettingSearchResponse>(
    ADMIN_SERVICE_URL + `/setting/search`,
    {
      method: 'POST',
      body: params,
    },
    disableAlert
  );

export const adminSettingUpdateMany = (
  params: IAdminSettingUpdateParams,
  disableAlert?: boolean
) =>
  fetcher<IAdminSettingUpdateParams, IAdminSettingUpdateResponse>(
    ADMIN_SERVICE_URL + `/setting/update`,
    {
      method: 'PUT',
      body: params,
    },
    disableAlert
  );
