import { create } from 'zustand';
type State = {
  currentLocation?: string;
  targetlocation?: string;
  updateRouteStoreState: (state: Partial<State>) => void;
  resetRouteStoreSates: (state: Partial<State>) => void;
};

const initialStates = {
  currentLocation: undefined,
  targetlocation: undefined,
};

const store = (set: any) => ({
  ...initialStates,
  updateRouteStoreState: (state: Partial<State>) => set(state),
  resetRouteStoreSates: (state: Partial<State>) =>
    set({ ...initialStates, ...state }),
});

export const useRouterStore = create<State>(store);
