import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../';
import {
  toBeValidatedFindOne,
  providerStatsFindOne,
  billedInvoicesStats,
} from '../routes';

export const useToBeValidatedStats = () => {
  return useQuery({
    queryKey: ['statistics', 'statistics'],
    queryFn: async () => toBeValidatedFindOne(),
  });
};

export const useProviderStatistics = () => {
  const me = queryClient.getQueriesData({ queryKey: ['me'] })?.[0]?.[1];

  return useQuery({
    queryKey: ['providerStats', 'providerStats'],
    queryFn: async () => providerStatsFindOne(),
    enabled: me ? true : false,
  });
};

export const useBilledInvoicesStats = (
  missionRef: string | undefined,
  disableAlert?: boolean
) => {
  return useQuery({
    queryKey: ['statistics-billed-invoices', missionRef],
    queryFn: async () => billedInvoicesStats({ missionRef }),
  });
};
