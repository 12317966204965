import {
  ICompanyCreateParams,
  ICompanyCreateResponse,
  ICompanyGetParams,
  ICompanyGetResponse,
  ICompanySearchParams,
  ICompanySearchResponse,
  ICompanySireneParams,
  ICompanySireneResponse,
  ICompanyUpdateParams,
  ICompanyUpdateResponse,
  IEstablishment,
  ICompanySearchRcProParams,
  ICompanySearchRcproResponse,
  ICompanyHqSearchInputParams,
  IHqSearchElementResponse,
} from '@freelancelabs/teoreme-commons';
import { COMPANY_SERVICE_URL } from '../../constantz';
import { fetcher } from '../helpers/fetcher';

export const establishmentFindMany = (
  searchParams: any,
  disableAlert?: boolean
) =>
  fetcher<ICompanySearchParams, ICompanySearchResponse>(
    COMPANY_SERVICE_URL + `/search`,
    {
      method: 'post',
      body: searchParams,
    },
    disableAlert
  );

export const establishmentCreateOne = (
  establishment: Partial<IEstablishment>,
  disableAlert?: boolean
) =>
  fetcher<ICompanyCreateParams, ICompanyCreateResponse>(
    COMPANY_SERVICE_URL + `/create`,
    {
      method: 'post',
      body: { establishment },
    },
    disableAlert
  );

export const establishmentFindOne = (
  identifier: string,
  type: 'uuid' | 'siret',
  disableAlert?: boolean
) =>
  fetcher<ICompanyGetParams, ICompanyGetResponse>(
    COMPANY_SERVICE_URL + `/get?${type}=${identifier}`,
    undefined,
    disableAlert
  );

export const establishmentUpdateOne = (
  body: ICompanyUpdateParams,
  disableAlert?: boolean
) =>
  fetcher<ICompanyUpdateParams, ICompanyUpdateResponse>(
    COMPANY_SERVICE_URL + `/update`,
    {
      method: 'POST',
      body,
    },
    disableAlert
  );

export const establishmentLookupIdentifier = (
  identifier: string,
  disableAlert?: boolean
) =>
  fetcher<ICompanySireneParams, ICompanySireneResponse>(
    COMPANY_SERVICE_URL + `/sirene?identifier=` + identifier,
    undefined,
    disableAlert
  );
export const establishmentFindRCPRO = (
  searchParams: any,
  disableAlert?: boolean
) =>
  fetcher<ICompanySearchRcProParams, ICompanySearchRcproResponse>(
    COMPANY_SERVICE_URL + `/search-rcpro`,
    {
      method: 'post',
      body: searchParams,
    },
    disableAlert
  );
// TODO MAKE TYPES AFTER !!!!
export const headQuarterFindOne = (uuid: any, disableAlert?: boolean) =>
  fetcher<ICompanyHqSearchInputParams, any>(
    COMPANY_SERVICE_URL + `/search-hq?uuid=${uuid}`,
    {
      method: 'get',
    },
    disableAlert
  );
// TODO MAKE TYPES AFTER !!!!
export const headQuarterFindMany = (
  searchParams: any,
  disableAlert?: boolean
) =>
  fetcher<ICompanyHqSearchInputParams, IHqSearchElementResponse>(
    COMPANY_SERVICE_URL + `/search-hq`,
    {
      method: 'get',
      body: searchParams,
    },
    disableAlert
  );
