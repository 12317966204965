import {
  useInvoiceFindMany,
  additionalActivityFindMany,
  INVOICE_STATUS_SELECT,
  INVOICE_PROVIDER_TYPE_SELECT,
  INVOICE_PROVIDER_TYPES,
  sageCreateInvoice,
  invoiceSendReminder,
  createCreditNote,
  displayMonth,
  isMileStoneInvoice,
  formatInvoiceOrAdditionalActivitySort,
} from '@commons';
import {
  EInvoiceStatus,
  IJoinedInvoice,
  IJoinedMission,
  IAdditionalActivity,
  EInvoiceDocumentType,
  EInvoiceType,
  EBillingType,
} from '@freelancelabs/teoreme-commons';
import { showDialogModal } from 'components/modals/DialogModal';
import { showDisplayPdfModal } from 'components/modals/DisplayPdfModal';
import { showCreateGescomBDLModal } from 'components/modals/gescom/CreateGescomBDLModal';
import { StaticSelect } from 'components/selects/StaticSelect';
import { ALink } from 'components/ALink';
import {
  Box,
  Container,
  DatePickerRange,
  Flex,
  FormLabel,
  Input,
  Itemize,
  SpinnerBox,
  Status,
  Table,
  TableColumn,
  Text,
  Link,
  Menu,
  MenuItem,
  Spinner,
  BlocInformation,
  LabelField,
} from 'components/ui';
import {
  DotsIcon,
  EditIcon,
  EyeIcon,
  RefreshIcon,
  ValidateIcon,
} from 'components/ui/icons';
import { add, getTime } from 'date-fns';
import { useDebounce } from 'hooks/useDebounce';
import { useShowMessage } from 'hooks/useShowMessage';
import { useTranslationFormat } from 'hooks/useTranslateFormat';
import { kebabCase } from 'lodash';
import * as React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Theme } from 'styles';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@commons';
import { useHistory } from 'react-router-dom';
import {
  canDisplayPoviderInvoiceResendButton,
  isValidableInvoice,
  canCreateBldInGescom,
  canDisplayPoviderCreateCreditNoteButton,
  canCreateSageInvoice,
} from 'helpers';

type Item = Itemize<IJoinedInvoice>;
const INVOICE_STATUS_SELECT_CUSTOM = INVOICE_STATUS_SELECT;
const Findex = INVOICE_STATUS_SELECT.findIndex(
  state => state.key === EInvoiceStatus.REJECTED
);
INVOICE_STATUS_SELECT_CUSTOM[Findex].label = 'Refusé';
const LIMIT = 5;

const columns = (
  statusSelect: string,
  typeSelect: string,
  invoiceQuery: any,
  ReSendButton: any,
  invoiceTypeText: any,
  history: any,
  showMessage: any
) => {
  let status = kebabCase(statusSelect).toLocaleLowerCase();
  if (
    statusSelect === EInvoiceStatus.TO_BE_FILLED ||
    statusSelect === EInvoiceStatus.TO_BE_SUBMITTED ||
    statusSelect === EInvoiceStatus.REJECTED
  ) {
    status = 'awaiting';
  }
  if (statusSelect === 'N/A') {
    status = 'awaiting';
  }
  return [
    {
      key: 'month',
      sortKey: 'month',
      text: 'MOIS',
      render: (item: IJoinedInvoice) => {
        if (
          isMileStoneInvoice(item?.invoiceType) ||
          item?.mission?.billing?.type === EBillingType.FLAT_RATE
        ) {
          return `${
            new Date(item?.createdAt as Date)?.getUTCMonth() + 1
          }/${new Date(item?.createdAt as Date)?.getUTCFullYear()}`;
        }

        return `${new Date(item?.month as Date)?.getUTCMonth() + 1}/${new Date(
          item?.month as Date
        )?.getUTCFullYear()}`;
      },
    },
    {
      key: 'invoiceType',
      sortKey: 'invoiceType',
      text: 'Type',
      render: (item: IJoinedInvoice) => {
        const isMarginInvoice = item?.isMarginInvoice ? ' (marge)' : '';
        return invoiceTypeText(item.invoiceType) + isMarginInvoice;
      },
    },
    {
      key: 'totalBeforeTaxes',
      sortKey: 'totalBeforeTaxes',
      text: `Montant HT`,
      //hidden: status === 'awaiting',
      render: (item: IJoinedInvoice) =>
        `${item?.totalBeforeTaxes?.toFixed(2) || 'N/A'} €`,
    },
    {
      key: 'status',
      sortKey: 'status',
      text: 'Statut',
      hidden: status === 'to-be-validated',
      render: (item: IJoinedInvoice) => {
        const status = item.status;
        const statusChangedAt = item?.statusChangedAt;
        let variantColor = 'grey';
        let text = '';
        switch (status) {
          case EInvoiceStatus.PROJECTED:
            variantColor = 'grey';
            text = 'Prévisionnelle';
            break;
          case EInvoiceStatus.TO_BE_FILLED:
            variantColor = 'grey';
            text = 'A saisir';
            break;
          case EInvoiceStatus.TO_BE_SUBMITTED:
            variantColor = 'grey';
            text = 'Non soumise';
            break;
          case EInvoiceStatus.TO_BE_VALIDATED:
            variantColor = 'warning';
            text = `Transmise le : ${
              statusChangedAt && statusChangedAt.toLocaleDateString()
            }`;
            break;
          case EInvoiceStatus.VALIDATED:
            variantColor = 'success';
            text = `Validé  ${
              statusChangedAt && statusChangedAt.toLocaleDateString()
            }`;
            break;
          case EInvoiceStatus.ARCHIVED:
            variantColor = 'grey';
            text = 'Archivé';
            break;
          case EInvoiceStatus.REJECTED:
            variantColor = 'error';
            text = `Refusé le ${
              statusChangedAt && statusChangedAt.toLocaleDateString()
            }`;
            break;
          case EInvoiceStatus.CANCELLED:
            variantColor = 'error';
            text = `Annulé le ${
              statusChangedAt && statusChangedAt.toLocaleDateString()
            }`;
            break;
          case EInvoiceStatus.PAID:
            variantColor = 'success';
            text = `Payé le ${item?.sage?.paymentDetails?.paymentDate?.toLocaleDateString()}`;
            break;
          default:
            variantColor = 'grey';
        }

        return (
          <Flex>
            <Status
              //@ts-ignore
              variantColor={variantColor}
            >
              {text}
            </Status>
          </Flex>
        );
      },
    },
    {
      key: 'sageStatus',
      //sortKey: 'invoice',
      text: 'STATUTS GESCOM',
      render: (item: IJoinedInvoice) => {
        if (item?.status === EInvoiceStatus.PROJECTED) {
          return <> N/A</>;
        }
        if (!item?.mission?.customer?.purchaseOrder?.reference) {
          return (
            <a data-for={item.uuid} data-tip="" data-iscapture="true">
              <ReactTooltip
                id={item.uuid}
                place={'top'}
                variant={'dark'}
                float={false}
                style={{ backgroundColor: Theme.colors.error.default }}
              >
                <Text color="white" fontSize={11}>
                  Le bon de commande client est requis pour créer les bons de
                  livraison dans gescom.
                </Text>
              </ReactTooltip>
              <Status variantColor="error">BDC client manquant</Status>
            </a>
          );
        }
        if (!item?.sage?.documentType) {
          return (
            <Status variantColor="warning">BDL à créer dans GESCOM</Status>
          );
        }
        if (item?.sage?.documentType === EInvoiceDocumentType.DELIVERY_ORDER) {
          return (
            <Status variantColor="grey">Facture à créer dans GESCOM</Status>
          );
        }
        if (item?.sage?.documentType === EInvoiceDocumentType.INVOICE) {
          return (
            <Status variantColor="success">Facture créée dans GESCOM</Status>
          );
        }
      },
    },
    {
      key: 'sageInvoice',
      text: 'FACTURE N°',
      render: (item: IJoinedInvoice) => {
        if (
          item?.sage?.invoiceId &&
          item?.sage?.documentType === EInvoiceDocumentType.INVOICE
        ) {
          return (
            <Status variantColor="success">{item?.sage?.invoiceId}</Status>
          );
        }
        return <Status variantColor="error"> N/A </Status>;
      },
    },
    {
      key: 'actions',
      text: 'ACTIONS',
      align: 'flex-end',
      render: (
        item: IJoinedInvoice & { additionalActivity?: IAdditionalActivity }
      ) => {
        const icon = <EyeIcon style={{ marginTop: 4 }} />;
        const invoiceId = item?.uuid;
        const missionRef = item?.mission?.reference;
        const contractorId = item?.contractor?.uuid;
        const invoiceFile = item?.invoiceFile;
        return (
          <Flex alignItems="center">
            {canDisplayPoviderInvoiceResendButton(item) && (
              <Flex mr={'5px'} mt={'10px'}>
                <ReSendButton invoice={item} />
              </Flex>
            )}
            {isValidableInvoice(item) && (
              <Flex mr={'5px'} mt={'10px'} mb={'10px'}>
                {(item?.sage ||
                  (!item?.sage && item?.mission?.isMandateMode)) && (
                  <>
                    <ValidateIcon fill={'red !important'} />
                    <ALink
                      href={`/orders/invoices/provider/time-spent/${invoiceId}`}
                      params={{ invoicePathFrom: 'MISSION_INVOICE' }}
                    >
                      <Link>Valider</Link>
                    </ALink>
                  </>
                )}
              </Flex>
            )}
            {canDisplayPoviderCreateCreditNoteButton(item) && (
              <MenuItem>
                <Link
                  onClick={() =>
                    showDialogModal({
                      title: "Création d'un avoir",
                      text: (
                        <Box>
                          <BlocInformation mb={10}>
                            Cette fonctionnalité est à utiliser uniquement si la
                            facture fournisseur doit être corrigée en raison
                            d'une erreur lié à l'absence de services
                            complémentaires. Pour toute autre modification
                            touchant cette facture (comme le nombre de jours à
                            facturer par exemple), merci d'utiliser la
                            fonctionnalité de Correction/Refus de CRA.
                          </BlocInformation>
                          <Text mb={20} variant="p">
                            Vous êtes sur le point de créer un avoir à la
                            facture fournisseur suivante :
                          </Text>
                          <LabelField
                            label="Mission :"
                            value={item?.mission?.displayReference}
                          />
                          <LabelField
                            label="Mois concerné :"
                            value={displayMonth(item?.month as Date)}
                          />
                          <Text mt={10} variant="b">
                            Cette action passera la facture actuelle au statut
                            "annulée" et générera une facture d'avoir ainsi
                            qu'une nouvelle facture fournisseur au statut "à
                            saisir" pour ce mois.
                          </Text>
                        </Box>
                      ),
                      cancelLabel: 'Annuler',
                      confirmLabel: "Créer l'avoir",
                      beforeValidation: async () => {
                        try {
                          const responseCreditNote = await createCreditNote({
                            originalInvoice: item?.uuid,
                          });
                          queryClient?.refetchQueries({
                            queryKey: ['Invoices'],
                            type: 'active',
                          });
                          showMessage('success', "L'avoir à bien été crée");
                          history?.push(
                            `/orders/invoices/provider/time-spent/${responseCreditNote?.creditNote?.uuid}`
                          );
                        } catch (e) {
                          //
                        }
                      },
                    })
                  }
                  iconLeft={<EditIcon />}
                >
                  Créer un avoir
                </Link>
              </MenuItem>
            )}
            <ALink
              href={`/orders/invoices/provider/time-spent/${invoiceId}`}
              params={{ invoicePathFrom: 'MISSION_INVOICE' }}
            >
              <Link iconLeft={icon}></Link>
            </ALink>
            <Menu
              align="right"
              menuItems={
                <>
                  {invoiceFile && (
                    <MenuItem>
                      <Link
                        onClick={() =>
                          showDisplayPdfModal({
                            fileLocation: invoiceFile.fileLocation,
                            //@ts-ignore
                            fileName: invoiceFile.fileName,
                          })
                        }
                        iconLeft={icon}
                      >
                        Voir la facture
                      </Link>
                    </MenuItem>
                  )}
                  <MenuItem>
                    <ALink href={`/delivery/missions/${missionRef}`}>
                      <Link iconLeft={icon}>Voir la mission</Link>
                    </ALink>
                  </MenuItem>
                  <MenuItem>
                    <ALink href={`/providers/contractors/${contractorId}`}>
                      <Link iconLeft={icon}>Voir la fiche intervenant</Link>
                    </ALink>
                  </MenuItem>

                  {canCreateBldInGescom(item) && !item?.sage && (
                    <MenuItem>
                      <Link
                        onClick={() =>
                          showCreateGescomBDLModal({
                            invoice: item,
                            cra: item?.cra,
                            mission: item?.mission,
                            delivreyOrders: {
                              bdlProvider: false,
                              bdlCustomer: true,
                            },
                            customerReference:
                              item?.mission?.customer?.purchaseOrder
                                ?.reference || '',
                            refetchQueryKey: 'Invoices',
                          })
                        }
                        iconLeft={<EditIcon />}
                      >
                        Créer dans GESCOM
                      </Link>
                    </MenuItem>
                  )}
                  {/* FIX TEOR-5294 */}
                  {canCreateSageInvoice(item) && (
                    <MenuItem>
                      <Link
                        onClick={async () =>
                          await showDialogModal({
                            title:
                              'Êtes-vous sûr.e de vouloir valider cette facture ?',
                            text: 'Les données de facturation saisies dans le formulaire seront envoyées en l’état à GESCOM pour comptabilisation et le bon de livraison fournisseur sera remplacé par la facture fournisseur GESCOM.',
                            confirmLabel: 'Valider',
                          }).then(async action => {
                            if (action) {
                              try {
                                await sageCreateInvoice({
                                  invoice: item?.uuid,
                                });
                                showMessage(
                                  'success',
                                  'La facture à été créé dans GESCOM'
                                );
                                queryClient.refetchQueries({
                                  queryKey: ['Invoices'],
                                  type: 'active',
                                });
                              } catch (e) {
                                //
                              }
                            }
                          })
                        }
                        iconLeft={<EditIcon />}
                      >
                        Créer dans GESCOM
                      </Link>
                    </MenuItem>
                  )}
                </>
              }
            >
              <DotsIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
            </Menu>
          </Flex>
        );
      },
    },
  ].filter(Boolean) as TableColumn<Item>[];
};

type InvoiceMissionProps = {
  mission: IJoinedMission;
};
export const ProviderInvoicesMission = ({ mission }: InvoiceMissionProps) => {
  const [items, setItems] = React.useState<IJoinedInvoice[]>([]);
  const history = useHistory();
  const [order, setOrder] = React.useState<'desc' | 'asc' | undefined>('desc');
  const [sortedBy, setSortedBy] = React.useState('month');
  const sortString = (order === 'desc' ? '-' : '') + sortedBy;
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>();
  const debouncedFilterQuery = useDebounce(searchQuery, 500);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [statusSelect, setStatusSelect] = React.useState<string>('ALL');
  const [typeSelect, setTypeSelect] = React.useState<string>('ALL');
  const [page, setPage] = React.useState(0);

  const {
    data: invoiceQuery,
    status,
    isFetching,
  } = useInvoiceFindMany({
    filterObject: {
      // invoiceType: 'PROVIDER',
      status: statusSelect !== 'ALL' ? statusSelect : undefined,
      invoiceType:
        typeSelect && typeSelect !== 'ALL'
          ? typeSelect
          : { $in: INVOICE_PROVIDER_TYPES },
      mission: mission?.reference,
      $and:
        startDate && endDate
          ? [
              {
                $expr: {
                  $gte: [{ $toLong: '$month' }, getTime(startDate)],
                },
              },
              {
                $expr: {
                  $lte: [
                    { $toLong: '$month' },
                    getTime(add(endDate, { months: 1 })),
                  ],
                },
              },
            ]
          : undefined,
      $or: debouncedFilterQuery
        ? [
            // customer
            {
              'estCustomer.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estCustomer.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            // provider
            {
              'estProvider.businessName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.tradeName': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard1': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard2': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.signBoard3': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.identifier': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.siret': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'estProvider.siren': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
            {
              'sage.invoiceId': {
                $regex: debouncedFilterQuery,
                $options: 'i',
              },
            },
          ]
        : undefined,
    },
    limit: LIMIT,
    skip: LIMIT * page,
    sort: formatInvoiceOrAdditionalActivitySort(sortString),
  });

  const loading = status === 'pending';
  const totalCount = invoiceQuery?.totalCount || 0;

  // const items = invoiceQuery?.invoices?.map(invoice => ({
  //   key: invoice?.uuid,
  //   ...invoice,
  // }));

  const onSortedChange = React.useCallback(
    (shortBy: string, order: 'asc' | 'desc'): void => {
      if (mission?.billing?.type === EBillingType.FLAT_RATE) {
        if (shortBy === 'month') {
          shortBy = 'createdAt';
        }
      }
      setSortedBy(shortBy);
      setOrder(order);
    },
    []
  );

  const showMessage = useShowMessage();
  const t = useTranslationFormat();

  const invoiceTypeText = (invoiceType: EInvoiceType) => {
    return t(`texts:INVOICETYPE_${invoiceType}`, 'capitalize-first');
  };
  const ReSendButton = ({ invoice }: { invoice: IJoinedInvoice }) => {
    const { mutateAsync: onResend, status } = useMutation({
      mutationFn: () =>
        invoiceSendReminder({
          uuid: invoice.uuid,
        }),
    });
    if (status === 'pending') return <Spinner size={20} />;
    if (status === 'success') {
      showMessage('success', 'Votre relance a bien été envoyée');
    }
    if (status === 'error') {
      showMessage(
        'error',
        'Suite à une erreur technique, votre relance n’a pas pu être envoyée. Veuillez réessayer'
      );
    }

    return (
      <Link
        style={{ paddingBottom: 15 }}
        iconLeft={<RefreshIcon />}
        onClick={() => onResend()}
      >
        Relancer
      </Link>
    );
  };

  const handleMergeInvoiceProviderAndAA = async (
    invoices: IJoinedInvoice[]
  ) => {
    const aaUuid = invoices?.filter(i => {
      return i?.additionalActivity;
    });
    const findAA = aaUuid?.length > 0;
    try {
      const aa = findAA
        ? await additionalActivityFindMany({
            filterObject: {
              uuid: { $in: aaUuid?.map(i => i?.additionalActivity) },
            },
          })
        : undefined;
      let items = invoiceQuery?.invoices?.map(invoice => ({
        key: invoice.uuid || '',
        ...invoice,
      }));
      items = items?.map((invoicesProvider: any) => ({
        ...invoicesProvider,
        additionalActivity: findAA
          ? aa?.additionalActivities?.find(
              a => a?.uuid === invoicesProvider?.additionalActivity
            )
          : undefined,
      }));
      //@ts-ignore
      setItems(items);
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    if (invoiceQuery) {
      handleMergeInvoiceProviderAndAA(invoiceQuery?.invoices);
    }
  }, [invoiceQuery]);

  React.useEffect(() => {
    setPage(0);
  }, [statusSelect, typeSelect]);

  return (
    <>
      <Container p="20px 0">
        <Flex justifyContent="space-between" ml={20} mr={20} mb={10}>
          <Box width={4 / 12} mr={10}>
            <FormLabel>RECHERCHER</FormLabel>
            <Input
              isFullWidth
              type="search"
              value={searchQuery}
              onChange={e => {
                setPage(0);
                //@ts-ignore
                setSearchQuery(e.target.value);
              }}
            />
          </Box>

          <Box width={4 / 12} mr={10}>
            <FormLabel pl={20}>PÉRIODE</FormLabel>
            <DatePickerRange
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              setStartDate={value => {
                setStartDate(value as Date);
                setPage(0);
              }}
              setEndDate={value => {
                setEndDate(value as Date);
                setPage(0);
              }}
            />
          </Box>

          <Box width={4 / 12} mr={10}>
            <FormLabel>Statuts</FormLabel>
            <StaticSelect
              placeholder="Tous les statuts"
              options={INVOICE_STATUS_SELECT_CUSTOM}
              onChange={value => setStatusSelect(value as string)}
              value={statusSelect}
            />
          </Box>
          <Box width={4 / 12} mr={10}>
            <FormLabel>Type</FormLabel>
            <StaticSelect
              placeholder="Tous les types"
              options={INVOICE_PROVIDER_TYPE_SELECT}
              onChange={value => setTypeSelect(value as string)}
              value={typeSelect}
            />
          </Box>
        </Flex>
        {loading && <SpinnerBox />}
        {!loading && items && items?.length > 0 ? (
          <Table<Itemize<IJoinedInvoice>>
            isLoading={isFetching}
            columns={columns(
              statusSelect,
              typeSelect,
              invoiceQuery,
              ReSendButton,
              invoiceTypeText,
              history,
              showMessage
            )}
            items={items as Itemize<IJoinedInvoice>[]}
            sortedBy={sortedBy}
            order={order}
            page={page}
            total={totalCount}
            perPage={totalCount < LIMIT ? totalCount : LIMIT}
            onSortedChange={onSortedChange}
            onChangePage={page => setPage(page)}
          />
        ) : (
          <Flex
            height={'50vh'}
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Box>
              <Text>Aucune facture fournisseur pour cette mission</Text>
            </Box>
          </Flex>
        )}
      </Container>
    </>
  );
};
