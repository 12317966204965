import {
  craSendReminder,
  cutLongText,
  getAdditionalActivitiesTotalAmount,
  getEstablishmentName,
  getFullName,
  getHumanDate,
  getStandByDutiesTotalAmount,
  MONTH_FR,
  queryClient,
  round,
  transformText,
  useActivitiesReportFindMany,
  useAdditionalActivityFindMany,
  useCraFindOne,
  useMissionFindOne,
} from '@commons';
import {
  EadditionalActivityStatus,
  EadditionalActivityType,
  ECraStatus,
  IJoinedAdditionalActivity,
  IJoinedAdditionalActivityWithInvoice,
  IJoinedCra,
  IJoinedCraWithInvoice,
} from '@freelancelabs/teoreme-commons';
import { Accordion } from 'components/Accordion';
import { Header } from 'components/Header';
import { showCreateOrUpdateStandByDutyModal } from 'components/modals/activities/CreateOrUpdateStandByDutyModal';
import { showCreateOrUpdateExpensesModal } from 'components/modals/activities/CreateOrUpdateExpensesModal';
import { showCraDetailsModal } from 'components/modals/cra/CraDetailsModal';
import { showValidateCraModal } from 'components/modals/DisplayValidateCraModal';
import { showRejectCraModal } from 'components/modals/RejectCraModal';
import {
  Box,
  Button,
  ColorTextBox,
  Container,
  Divider,
  Flex,
  LabelField,
  Link,
  Spinner,
  SpinnerBox,
  Status,
  Text,
} from 'components/ui';
import {
  FeesIcon,
  DutyTimeIcon,
  EyeIcon,
  MailIcon,
  RefreshIcon,
  TimeLapsIcon,
  ValidateIcon,
} from 'components/ui/icons';
import { getStatusData } from 'helpers/string';
import { useSetCrumbs } from 'hooks/breadCrumb';
import { useShowMessage } from 'hooks/useShowMessage';
import * as React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Theme } from 'styles';
export type CraDetailsProps = {};

export const CRADetails: React.FunctionComponent<
  React.PropsWithChildren<CraDetailsProps>
> = () => {
  const showMessage = useShowMessage();
  const { refCra } = useParams<{ refCra: string }>();
  const {
    data: cra,
    status: craStatus,
    isFetching: isFetchingCRA,
  } = useCraFindOne(refCra, true);
  useSetCrumbs(
    [cra],
    [
      {
        label: 'CRA et factures',
        path: '/orders',
      },
      {
        label: 'CRA',
        path: '/orders/cra',
      },
      {
        label: cra ? `CRA N°${cra.refCra}` : '-',
        path: `/orders/cra/${refCra}`,
      },
      {
        label: 'informations',
        path: `/orders/cra/${refCra}/informations`,
      },
    ]
  );
  const { data: mission } = useMissionFindOne(cra?.mission?.reference);
  const { data: reports, isFetching: isFetchingAR } =
    useActivitiesReportFindMany(
      cra && mission
        ? {
            filterObject: {
              month: cra?.month,
              mission: mission?.reference,
            },
            limit: 1,
            skip: 0,
            //sort: '-month',
          }
        : undefined
    );
  const activity = reports?.reports?.[0];
  const expensesUuids = activity?.expenses?.map((f: any) => f?.uuid as string);
  const standByDutiesUuids = activity?.standByDuties?.map(
    (sb: any) => sb?.uuid as string
  );
  const uuids =
    expensesUuids && standByDutiesUuids
      ? [...(expensesUuids as string[]), ...(standByDutiesUuids as string[])]
      : [];
  const {
    data: aAResponse,
    isFetching: isFetchingAA,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useAdditionalActivityFindMany(
    uuids?.length
      ? {
          filterObject:
            uuids?.length > 0
              ? {
                  uuid: {
                    $in: uuids,
                  },
                  status: {
                    $nin: [
                      EadditionalActivityStatus?.TO_BE_FILLED,
                      // EadditionalActivityStatus?.TO_BE_SUBMITTED,
                    ],
                  },
                }
              : undefined,
          limit: uuids?.length,
          skip: 0,
        }
      : undefined
  );
  const additionalActivities = aAResponse?.additionalActivities;
  const standByDuties =
    additionalActivities?.filter(
      (aa: IJoinedAdditionalActivity) =>
        aa?.type === EadditionalActivityType?.STAND_BY_DUTY
    ) || [];
  const expenses =
    additionalActivities?.filter(
      (aa: IJoinedAdditionalActivity) =>
        aa?.type === EadditionalActivityType?.EXPENSE
    ) || [];
  const textMonth = cra
    ? `${transformText(
        MONTH_FR[new Date(cra?.month)?.getUTCMonth()],
        'capitalize-first'
      )}  ${new Date(cra?.month)?.getUTCFullYear()}`
    : '';

  const ReSendButton = ({ cra }: { cra: IJoinedCra }) => {
    const { mutateAsync: onResend, status } = useMutation({
      mutationFn: () =>
        craSendReminder({
          refCra: cra.refCra,
        }),
    });
    if (status === 'pending') return <Spinner size={20} />;
    if (status === 'success')
      showMessage('success', 'Votre demande de CRA a bien été renvoyée');
    return (
      <Link iconLeft={<RefreshIcon />} onClick={() => onResend()}>
        Relancer
      </Link>
    );
  };

  const CraAction = ({ cra }: { cra: IJoinedCraWithInvoice }) => {
    return (
      <Flex justifyContent="flex-start">
        <Link
          mr={'5px'}
          onClick={() => showCraDetailsModal({ cra, layer: 101 })}
          iconLeft={
            <EyeIcon fill={Theme?.colors?.primary?.default} fontSize={20} />
          }
        >
          Voir le details
        </Link>

        {cra?.state === ECraStatus?.TO_BE_VALIDATED && (
          <Link
            onClick={async () => {
              await showValidateCraModal({
                layer: -1,
                fileLocation: cra?.proof?.fileLocation,
                fileName: cra?.proof?.fileName,
                confirmLabel: `Valider ( ${cra?.nbWorkingDays} jours )`,
                refCra: cra.refCra,
                onConfirm: async () => {
                  await queryClient.refetchQueries({ queryKey: [cra?.refCra] });
                  await queryClient.refetchQueries({
                    queryKey: ['Cras'],
                    type: 'active',
                  });
                },
                rejectLabel: 'Refuser',
                onRejectCra: async () => {
                  await showRejectCraModal({
                    cra: cra,
                    layer: 103,
                  });
                },
              });
            }}
            iconLeft={
              <ValidateIcon
                fill={Theme?.colors?.primary?.default}
                fontSize={20}
              />
            }
          >
            Valider / Refuser
          </Link>
        )}
        {(cra?.state === ECraStatus?.TO_BE_FILLED ||
          cra?.state === ECraStatus?.TO_BE_SUBMITTED) && (
          <ReSendButton cra={cra} />
        )}
      </Flex>
    );
  };
  const ExpensesActions = ({
    expense,
  }: {
    expense: IJoinedAdditionalActivityWithInvoice;
  }) => {
    return (
      <Flex justifyContent="flex-start">
        <Link
          onClick={() =>
            showCreateOrUpdateExpensesModal({
              missionRef: expense?.mission?.reference as string,
              month: expense?.month as Date,
              method: 'UPDATE',
              uuid: expense?.uuid as string,
              layer: 104,
            })
          }
          iconLeft={<EyeIcon />}
        >
          {'Voir le details'}
        </Link>
      </Flex>
    );
  };
  const StandByDutyActions = ({
    sbd,
  }: {
    sbd: IJoinedAdditionalActivityWithInvoice;
  }) => {
    return (
      <Flex justifyContent="flex-start">
        <Link
          onClick={() =>
            showCreateOrUpdateStandByDutyModal({
              missionRef: sbd?.mission?.reference,
              month: sbd?.month as Date,
              method: 'UPDATE',
              uuid: sbd?.uuid,
              layer: 104,
            })
          }
          iconLeft={<EyeIcon />}
        >
          {'Voir le details'}
        </Link>
        {/* {!sbd?.quote && (
          <>
            <Link
              ml={'5px'}
              iconLeft={<SendIcon />}
              onClick={() =>
                showGenerateActivityQuoteModal({
                  additionalActivity: sbd,
                })
              }
            >
              Générer un devis
            </Link>
          </>
        )} */}
      </Flex>
    );
  };
  type ShowMoreTextProps = {
    text: string;
    maxLength: number;
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const ShowMoreText = ({ text, maxLength }: ShowMoreTextProps) => {
    const [more, setMore] = React.useState(false);

    return (
      <Box>
        {text?.length > maxLength && more === false && (
          <Box>
            {cutLongText(text, maxLength)}
            <Link ml={'5px'} onClick={() => setMore(true)}>
              Voir plus
            </Link>
          </Box>
        )}
        {text?.length > maxLength && more === true && (
          <Box>
            {text}
            <Link ml={'5px'} onClick={() => setMore(false)}>
              Voir moins
            </Link>
          </Box>
        )}
        {text?.length < maxLength && <Box>{text}</Box>}
      </Box>
    );
  };
  const craNeedActionCount = cra?.state === ECraStatus?.TO_BE_VALIDATED ? 1 : 0;
  const standByDutiesNeedActionCount = standByDuties?.filter(
    (sbd: any) => sbd?.status === EadditionalActivityStatus?.TO_BE_VALIDATED
  )?.length;
  const expensesNeedActionCount = expenses?.filter(
    (sbd: any) => sbd?.status === EadditionalActivityStatus?.TO_BE_VALIDATED
  )?.length;
  let messagesCount = 0;
  standByDuties
    ?.filter(
      (sbd: any) => sbd?.status === EadditionalActivityStatus?.TO_BE_VALIDATED
    )
    ?.forEach((sbd: any) =>
      sbd?.providerComment ? (messagesCount = messagesCount + 1) : false
    );
  expenses
    ?.filter(
      (sbd: any) => sbd?.status === EadditionalActivityStatus?.TO_BE_VALIDATED
    )
    ?.forEach((sbd: any) =>
      sbd?.providerComment ? (messagesCount = messagesCount + 1) : false
    );
  cra?.accountManagerComment && (messagesCount = messagesCount + 1);
  const missionConsumedBudget =
    (mission?.billing?.declaredWorkingDaysInCras || 0) *
      (mission?.billing?.contractorRate || 0) || 0;
  //const contractorRate = mission?.billing?.contractorRate || 0;
  const missionBudget =
    (mission?.billing?.estimatedDays || 0) *
      (mission?.billing?.contractorRate || 0) || 0;
  const exepensesIsEnabled = mission?.expensesConfig?.isEnabled;
  const isStandByDutiesEnabled =
    (mission?.standByDutyFormulas
      ?.map(f => f?.active)
      ?.includes(true) as boolean) || false;
  const expenseBudget = mission?.expensesConfig?.purchaseBudget ?? 0;
  const haveExpenseBudget = exepensesIsEnabled && expenseBudget > 0;
  const expenseConsumedBudget = mission?.expensesConfig?.consumedPurchaseBudget;
  const isOutOffBudget = haveExpenseBudget
    ? (expenseConsumedBudget || 0) >= (expenseBudget || 0)
    : false;

  const stepperPercentMission = (): { percent: string; color: string } => {
    let color = '';
    const percent = (missionConsumedBudget / (missionBudget || 0)) * 100;
    if (percent > 100) {
      color = Theme?.colors?.error?.default;
      return { percent: '100%', color };
    }
    if (percent >= 80) {
      color = Theme?.colors?.warning?.default;
    } else {
      color = Theme?.colors?.primary?.default;
    }
    return { percent: `${round(percent)}%`, color };
  };
  const stepperPercentExpenses = (): { percent: string; color: string } => {
    let color = '';
    const percent = ((expenseConsumedBudget || 0) / (expenseBudget || 0)) * 100;
    if (percent > 100) {
      color = Theme?.colors?.error?.default;
      return { percent: '100%', color };
    }
    if (percent >= 80) {
      color = Theme?.colors?.warning?.default;
    } else {
      color = Theme?.colors?.primary?.default;
    }
    return { percent: `${round(percent)}%`, color };
  };
  const stepperWidthMission = stepperPercentMission()?.percent;
  const colorStepperMission = stepperPercentMission()?.color;
  const stepperWidthExpense = stepperPercentExpenses()?.percent;
  const colorStepperExpense = stepperPercentExpenses()?.color;
  return (
    <Box>
      <Header
        actions={
          <Flex>
            <Button
              ml={10}
              isDisabled={isOutOffBudget || !exepensesIsEnabled}
              onClick={() =>
                showCreateOrUpdateExpensesModal({
                  method: 'CREATE',
                  missionRef: mission?.reference as string,
                  month: cra?.month,
                })
              }
            >
              Créer un frais
            </Button>
            <Button
              ml={10}
              isDisabled={isOutOffBudget || !isStandByDutiesEnabled}
              onClick={() =>
                showCreateOrUpdateStandByDutyModal({
                  method: 'CREATE',
                  missionRef: mission?.reference as string,
                  month: cra?.month as Date,
                })
              }
            >
              Créer une prestation complémentaire
            </Button>
          </Flex>
        }
      >
        <Flex
          display="inline-flex"
          justifyContent="flex-start"
          flexWrap="wrap"
          alignItems="center"
          p={10}
        >
          <Box width={1 / 1} mt={'5px'}>
            <Text variant="h1">{textMonth}</Text>
          </Box>

          <Flex width={1 / 1}>
            {craNeedActionCount > 0 && (
              <Status circleHidden variantColor="warning" mb={'5px'}>
                <Box mr={'5px'}>
                  <TimeLapsIcon
                    width={15}
                    height={15}
                    fill={Theme?.colors?.warning?.default}
                  />
                </Box>
                {craNeedActionCount} CRA à traiter
              </Status>
            )}
          </Flex>
          <Flex width={1 / 1}>
            {standByDutiesNeedActionCount > 0 && (
              <Status circleHidden variantColor="warning" mb={'5px'}>
                <Box mr={'5px'}>
                  <DutyTimeIcon
                    width={15}
                    height={15}
                    fill={Theme?.colors?.warning?.default}
                  />
                </Box>
                {standByDutiesNeedActionCount} prestation(s) complémentaire(s) à
                traiter
              </Status>
            )}
          </Flex>
          <Flex width={1 / 1}>
            {expensesNeedActionCount > 0 && (
              <Status circleHidden variantColor="warning" mb={'5px'}>
                <Box mr={'5px'}>
                  <FeesIcon
                    width={15}
                    height={15}
                    fill={Theme?.colors?.warning?.default}
                  />
                </Box>
                {expensesNeedActionCount} frais à traiter
              </Status>
            )}
          </Flex>
          <Flex width={1 / 1}>
            {messagesCount > 0 && (
              <Status circleHidden variantColor="warning" mb={'5px'}>
                <Box mr={'5px'} width={15} height={15}>
                  <MailIcon fill={Theme?.colors?.warning?.default} />
                </Box>
                {messagesCount} Message(s) de la part du fournisseur
              </Status>
            )}
          </Flex>
        </Flex>
      </Header>
      {!isFetchingCRA ? (
        <Container display="flex" pt={50}>
          <Flex
            display="inline-flex"
            justifyContent="flex-start"
            flexWrap="wrap"
            width={1 / 1}
          >
            <Box p={10} width={8 / 12}>
              <Box>
                <Accordion
                  defaultOpen
                  openOnTop
                  propsBar={{
                    borderRadius: '8px',
                    backgroundColor: 'white',
                    border: '1px solid #d0d4e3',
                    p: 12,
                  }}
                  childrenTop={
                    <Flex
                      alignContent="center"
                      alignItems="center"
                      justifyContent="space-between"
                      width={1 / 1}
                    >
                      <Flex alignContent="center" alignItems="center">
                        <TimeLapsIcon
                          width={'30px'}
                          height={'30px'}
                          fill={Theme?.colors?.primary?.default}
                        />
                        <Text ml={10} variant="h2">
                          Prestations standard ( CRA )
                        </Text>
                      </Flex>
                      <Flex alignContent="center" alignItems="center">
                        <ColorTextBox variantColor="primary" ml={5} mr={'5px'}>
                          {`Fournisseur: ${
                            cra?.nbWorkingDays
                              ? `${round(
                                  cra?.nbWorkingDays * cra?.contractorRate
                                )} €`
                              : 'N/A'
                          }`}
                        </ColorTextBox>
                        <ColorTextBox variantColor="primary" ml={5} mr={'5px'}>
                          {`Client: ${
                            cra?.nbWorkingDays
                              ? `${round(
                                  cra?.nbWorkingDays *
                                    (cra?.mission?.billing?.clientRate || 0)
                                )} €`
                              : 'N/A'
                          }`}
                        </ColorTextBox>
                      </Flex>
                    </Flex>
                  }
                  key={`cras`}
                >
                  <Box mt={20}>
                    {craStatus === 'success' && (
                      <Box
                        position="relative"
                        top={-25}
                        p={10}
                        borderLeft="1px solid"
                        borderRight="1px solid"
                        borderBottom="1px solid"
                        borderColor={'#d0d4e3'}
                      >
                        <Flex
                          width={1 / 1}
                          alignContent="center"
                          alignItems="center"
                        >
                          <Box width={10 / 50}>
                            <LabelField
                              label="Référence"
                              value={
                                <Box mt={'10px'}>
                                  <ColorTextBox variantColor="primary">
                                    {cra?.refCra}
                                  </ColorTextBox>
                                </Box>
                              }
                              underline
                            />
                          </Box>
                          <Box width={6 / 50}>
                            <LabelField
                              label="Déclaré le"
                              value={
                                <Box mt={'10px'}>
                                  <ColorTextBox variantColor="primary">
                                    {getHumanDate(cra?.stateChangedAt as Date)}
                                  </ColorTextBox>
                                </Box>
                              }
                              underline
                            />
                          </Box>
                          <Box width={9 / 50}>
                            <LabelField
                              label="Nombre de jours"
                              value={
                                <Box mt={'10px'}>
                                  <ColorTextBox variantColor="primary">
                                    {cra?.nbWorkingDays
                                      ? `${cra?.nbWorkingDays} jours `
                                      : 'N/A'}
                                  </ColorTextBox>
                                </Box>
                              }
                              underline
                            />
                          </Box>
                          <Box width={9 / 50}>
                            <LabelField
                              label="Statut"
                              value={
                                <Box mt={'10px'}>
                                  <ColorTextBox
                                    variantColor={
                                      getStatusData(
                                        cra as IJoinedCraWithInvoice
                                      )?.variantColor
                                    }
                                  >
                                    {
                                      getStatusData(
                                        cra as IJoinedCraWithInvoice
                                      )?.statusText
                                    }
                                  </ColorTextBox>
                                </Box>
                              }
                              underline
                            />
                          </Box>
                          <Flex
                            width={19 / 50}
                            flexWrap="wrap"
                            display="inline-flex"
                            justifyContent="flex-end"
                          >
                            <Box width={1 / 1} ml={20}>
                              <LabelField
                                label="Actions"
                                value={
                                  <CraAction
                                    cra={cra as IJoinedCraWithInvoice}
                                  />
                                }
                                underline
                              />
                            </Box>
                          </Flex>
                        </Flex>
                      </Box>
                    )}
                  </Box>
                </Accordion>
                {mission?.standByDutyFormulas &&
                  mission?.standByDutyFormulas?.length > 0 && (
                    <Accordion
                      defaultOpen={true}
                      disableOpen={standByDuties?.length === 0}
                      openOnTop
                      propsBar={{
                        borderRadius: '8px',
                        backgroundColor: 'white',
                        border: '1px solid #d0d4e3',
                        p: 12,
                      }}
                      childrenTop={
                        <Flex justifyContent="space-between" width={1 / 1}>
                          <Flex alignContent="center" alignItems="center">
                            <DutyTimeIcon
                              width={'30px'}
                              height={'30px'}
                              fill={Theme?.colors?.primary?.default}
                            />
                            <Text ml={10} variant="h2">
                              Prestations complémentaires
                            </Text>
                          </Flex>
                          <Flex alignContent="center" alignItems="center">
                            {isFetchingAR || isFetchingAA ? (
                              <Spinner />
                            ) : (
                              <Box>
                                <ColorTextBox variantColor="primary" ml={5}>
                                  Fournisseur:{' '}
                                  {getAdditionalActivitiesTotalAmount(
                                    standByDuties,
                                    EadditionalActivityType?.STAND_BY_DUTY,
                                    'PROVIDER'
                                  )}{' '}
                                  €
                                </ColorTextBox>
                                <ColorTextBox variantColor="primary" ml={5}>
                                  Client:{' '}
                                  {getAdditionalActivitiesTotalAmount(
                                    standByDuties,
                                    EadditionalActivityType?.STAND_BY_DUTY,
                                    'CUSTOMER'
                                  )}{' '}
                                  €
                                </ColorTextBox>
                              </Box>
                            )}
                          </Flex>
                        </Flex>
                      }
                      key={`STAND_BY_DUTIES`}
                    >
                      {standByDuties?.length > 0 && (
                        <Box
                          position="relative"
                          top={-5}
                          p={10}
                          borderLeft="1px solid"
                          borderRight="1px solid"
                          borderBottom="1px solid"
                          borderColor={'#d0d4e3'}
                        >
                          {standByDuties?.map((aa: any, index: number) => {
                            return (
                              <Box width={1 / 1}>
                                <Flex
                                  width={1 / 1}
                                  alignItems="center"
                                  alignContent="center"
                                >
                                  <Box width={6 / 50}>
                                    <LabelField
                                      label="Déclaré le"
                                      value={
                                        <Box mt={'10px'}>
                                          <ColorTextBox variantColor="primary">
                                            {getHumanDate(
                                              aa?.createdAt as Date
                                            )}
                                          </ColorTextBox>
                                        </Box>
                                      }
                                      underline
                                    />
                                  </Box>
                                  <Box width={10 / 50}>
                                    <LabelField
                                      label="Montant HT Fournisseur"
                                      value={
                                        <Box mt={'10px'}>
                                          <ColorTextBox
                                            mr={'5px'}
                                            variantColor="primary"
                                          >
                                            {getStandByDutiesTotalAmount(
                                              aa?.standByDutyLines as any,
                                              'PROVIDER'
                                            )}{' '}
                                            €
                                          </ColorTextBox>
                                        </Box>
                                      }
                                      underline
                                    />
                                  </Box>
                                  <Box width={10 / 50}>
                                    <LabelField
                                      label="Montant HT Client"
                                      value={
                                        <Box mt={'10px'}>
                                          <ColorTextBox variantColor="primary">
                                            {getStandByDutiesTotalAmount(
                                              aa?.standByDutyLines as any,
                                              'CUSTOMER'
                                            )}{' '}
                                            €
                                          </ColorTextBox>
                                        </Box>
                                      }
                                      underline
                                    />
                                  </Box>
                                  <Box width={9 / 50}>
                                    <LabelField
                                      label="Statut"
                                      value={
                                        <Box mt={'10px'}>
                                          <ColorTextBox
                                            variantColor={
                                              getStatusData(aa)?.variantColor
                                            }
                                          >
                                            {getStatusData(aa)?.statusText}
                                          </ColorTextBox>
                                        </Box>
                                      }
                                      underline
                                    />
                                  </Box>
                                  <Flex
                                    width={19 / 50}
                                    flexWrap="wrap"
                                    display="inline-flex"
                                    justifyContent="flex-end"
                                  >
                                    <Box width={1 / 1} ml={20}>
                                      <LabelField
                                        label="Actions"
                                        value={<StandByDutyActions sbd={aa} />}
                                        underline
                                      />
                                    </Box>
                                  </Flex>
                                </Flex>
                                {index !== standByDuties?.length - 1 && (
                                  <Divider />
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                    </Accordion>
                  )}
                {expenses && expenses?.length > 0 && (
                  <Accordion
                    defaultOpen={true}
                    disableOpen={standByDuties?.length === 0}
                    openOnTop
                    propsBar={{
                      borderRadius: '8px',
                      backgroundColor: 'white',
                      border: '1px solid #d0d4e3',
                      p: 12,
                    }}
                    childrenTop={
                      <Flex justifyContent="space-between" width={1 / 1}>
                        <Flex alignContent="center" alignItems="center">
                          <FeesIcon
                            width={'30px'}
                            height={'30px'}
                            fill={Theme?.colors?.primary?.default}
                          />
                          <Text ml={10} variant="h2">
                            Frais
                          </Text>
                        </Flex>
                        <Flex alignContent="center" alignItems="center">
                          {isFetchingAR || isFetchingAA ? (
                            <Spinner />
                          ) : (
                            <Box>
                              <ColorTextBox variantColor="primary" ml={5}>
                                Fournisseur:{' '}
                                {getAdditionalActivitiesTotalAmount(
                                  expenses,
                                  EadditionalActivityType?.EXPENSE,
                                  'PROVIDER'
                                )}{' '}
                                €
                              </ColorTextBox>
                              <ColorTextBox variantColor="primary" ml={5}>
                                Client:{' '}
                                {getAdditionalActivitiesTotalAmount(
                                  expenses,
                                  EadditionalActivityType?.EXPENSE,
                                  'CUSTOMER',
                                  false,
                                  expenses[0]?.customerMargin
                                )}{' '}
                                €
                              </ColorTextBox>
                            </Box>
                          )}
                        </Flex>
                      </Flex>
                    }
                    key={`EXEPENSES`}
                  >
                    {expenses?.length > 0 && (
                      <Box
                        position="relative"
                        top={-5}
                        p={10}
                        borderLeft="1px solid"
                        borderRight="1px solid"
                        borderBottom="1px solid"
                        borderColor={'#d0d4e3'}
                      >
                        {expenses?.map((aa: any, index: number) => {
                          return (
                            <Box width={1 / 1}>
                              <Flex
                                width={1 / 1}
                                alignItems="center"
                                alignContent="center"
                              >
                                <Box width={6 / 50}>
                                  <LabelField
                                    label="Déclaré le"
                                    value={
                                      <Box mt={'10px'}>
                                        <ColorTextBox variantColor="primary">
                                          {getHumanDate(aa?.createdAt as Date)}
                                        </ColorTextBox>
                                      </Box>
                                    }
                                    underline
                                  />
                                </Box>
                                <Box width={10 / 50}>
                                  <LabelField
                                    label="Montant HT Fournisseur"
                                    value={
                                      <Box mt={'10px'}>
                                        <ColorTextBox
                                          mr={'5px'}
                                          variantColor="primary"
                                        >
                                          {getAdditionalActivitiesTotalAmount(
                                            [aa],
                                            EadditionalActivityType?.EXPENSE,
                                            'PROVIDER'
                                          )}{' '}
                                          €
                                        </ColorTextBox>
                                      </Box>
                                    }
                                    underline
                                  />
                                </Box>
                                <Box width={10 / 50}>
                                  <LabelField
                                    label="Montant HT Client"
                                    value={
                                      <Box mt={'10px'}>
                                        <ColorTextBox variantColor="primary">
                                          {getAdditionalActivitiesTotalAmount(
                                            [aa],
                                            EadditionalActivityType?.EXPENSE,
                                            'CUSTOMER',
                                            false,
                                            aa?.customerMargin
                                          )}{' '}
                                          €
                                        </ColorTextBox>
                                      </Box>
                                    }
                                    underline
                                  />
                                </Box>
                                <Box width={9 / 50}>
                                  <LabelField
                                    label="Statut"
                                    value={
                                      <Box mt={'10px'}>
                                        <ColorTextBox
                                          variantColor={
                                            getStatusData(aa)?.variantColor
                                          }
                                        >
                                          {getStatusData(aa)?.statusText}
                                        </ColorTextBox>
                                      </Box>
                                    }
                                    underline
                                  />
                                </Box>
                                <Flex
                                  width={19 / 50}
                                  flexWrap="wrap"
                                  display="inline-flex"
                                  justifyContent="flex-end"
                                >
                                  <Box width={1 / 1} ml={20}>
                                    <LabelField
                                      label="Actions"
                                      value={<ExpensesActions expense={aa} />}
                                      underline
                                    />
                                  </Box>
                                </Flex>
                              </Flex>
                              {index !== expenses?.length - 1 && <Divider />}
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </Accordion>
                )}
              </Box>
            </Box>
            <Box width={4 / 12} mt={10}>
              <Box>
                <Box
                  width={1 / 1}
                  borderRadius={'5px'}
                  backgroundColor="#e5f5ff"
                >
                  <Box p={10}>
                    <LabelField
                      data-clarity-mask="True"
                      label="Responsable de la mission"
                      value={getFullName(mission?.accountManager)}
                      underline
                      mb={10}
                    />
                    <LabelField
                      label="Référence de la mission"
                      value={mission?.displayReference}
                      linkValue={`/delivery/missions/${mission?.reference}/gescom-create`}
                      underline
                      mb={10}
                    />
                    <LabelField
                      label="Date de mission"
                      value={`${getHumanDate(
                        mission?.startAt as Date
                      )} au ${getHumanDate(mission?.endAt as Date)}`}
                      linkValue={`/delivery/missions/${mission?.reference}/gescom-create`}
                      underline
                      mb={10}
                    />
                    <LabelField
                      label="Client"
                      value={getEstablishmentName(cra?.estCustomer, 70)}
                      linkValue={`/clients/establishments/${cra?.estCustomer?.uuid}/information`}
                      underline
                      mb={10}
                    />
                    <LabelField
                      label="Fournisseur"
                      value={getEstablishmentName(cra?.estProvider, 70)}
                      linkValue={`/providers/establishments/${cra?.estProvider?.uuid}/information`}
                      underline
                      mb={10}
                    />
                    <LabelField
                      label="Intervenant"
                      value={getFullName(cra?.contractor, 70)}
                      linkValue={`/providers/contractors/${cra?.contractor?.uuid}`}
                      underline
                      mb={10}
                    />
                    <LabelField
                      label="Budget mission consommé"
                      value={
                        <Box mb={20}>
                          <Flex
                            width={1 / 1}
                            zIndex={100}
                            justifyContent="space-around"
                            borderBottom={'12px solid #eaeefc'}
                            borderRadius={'6px'}
                          >
                            <Box
                              position="absolute"
                              left={0}
                              bottom={-12}
                              //@ts-ignore
                              width={stepperWidthMission}
                              borderRadius={'6px'}
                              borderBottom={`12px solid ${colorStepperMission}`}
                            />
                            <Box
                              width={1 / 1}
                              position="absolute"
                              left={0}
                              bottom={-12}
                            >
                              <Text
                                top="2px"
                                textAlign={'center'}
                                color={
                                  Number(
                                    stepperWidthMission?.replace('%', '')
                                  ) > 47
                                    ? 'white'
                                    : 'black'
                                }
                                fontSize={10}
                              >
                                {stepperWidthMission}
                              </Text>
                            </Box>
                          </Flex>
                          <Text mt={20} width={1 / 1}>
                            {`Le fournisseur à consommé ${round(
                              missionConsumedBudget
                            )} € sur ${missionBudget} € prévus sur cette mission.`}
                          </Text>
                        </Box>
                      }
                      underline
                      mb={-10}
                    />
                    {haveExpenseBudget && (
                      <LabelField
                        label="Budget des frais consommé"
                        value={
                          <Box mb={20}>
                            <Flex
                              width={1 / 1}
                              zIndex={100}
                              justifyContent="space-around"
                              borderBottom={'12px solid #eaeefc'}
                              borderRadius={'6px'}
                            >
                              <Box
                                position="absolute"
                                left={0}
                                bottom={-12}
                                //@ts-ignore
                                width={stepperWidthExpense}
                                borderRadius={'6px'}
                                borderBottom={`12px solid ${colorStepperExpense}`}
                              />
                              <Box
                                width={1 / 1}
                                position="absolute"
                                left={0}
                                bottom={-12}
                              >
                                <Text
                                  top="2px"
                                  textAlign={'center'}
                                  color={
                                    Number(
                                      stepperWidthExpense?.replace('%', '')
                                    ) > 47
                                      ? 'white'
                                      : 'black'
                                  }
                                  fontSize={10}
                                >
                                  {stepperWidthExpense}
                                </Text>
                              </Box>
                            </Flex>
                            <Text mt={20} width={1 / 1}>
                              {`Le fournisseur à consommé ${round(
                                expenseConsumedBudget || 0
                              )} € de frais sur ${round(
                                expenseBudget || 0
                              )} € prévus sur cette mission.`}
                            </Text>
                          </Box>
                        }
                        underline
                        mb={10}
                      />
                    )}
                  </Box>
                </Box>
                {/* {messagesCount > 0 && (
                  <Box
                    mt={20}
                    width={1 / 1}
                    borderRadius={'5px'}
                    backgroundColor={'#ffedcc'}
                  >
                    <Box p={10}>
                      <LabelField
                        label={
                          <Flex>
                            <MailIcon
                              width={15}
                              height={15}
                              style={{ marginRight: '5px' }}
                              fill={Theme?.colors?.grayBlue}
                            />
                            Messages de la part du fournisseur
                          </Flex>
                        }
                        value={
                          <Box width={1 / 1}>
                            {cra?.accountManagerComment &&
                              cra?.accountManagerComment?.length > 0 && (
                                <Flex width={1 / 1}>
                                  -&nbsp;
                                  <ShowMoreText
                                    maxLength={200}
                                    key={cra?.refCra}
                                    text={cra?.accountManagerComment}
                                  />
                                </Flex>
                              )}
                            {standByDuties &&
                              standByDuties
                                ?.filter(
                                  sdb =>
                                    sdb?.status ===
                                    EadditionalActivityStatus?.TO_BE_VALIDATED
                                )
                                ?.map(sbd => {
                                  if (
                                    sbd?.providerComment &&
                                    sbd?.providerComment?.length > 0
                                  ) {
                                    return (
                                      <Flex width={1 / 1}>
                                        -&nbsp;
                                        <ShowMoreText
                                          maxLength={200}
                                          key={sbd?.uuid}
                                          text={sbd?.providerComment}
                                        />
                                      </Flex>
                                    );
                                  }
                                })}
                          </Box>
                        }
                        underline
                        mb={10}
                      />
                    </Box>
                  </Box>
                )} */}
              </Box>
            </Box>
          </Flex>
        </Container>
      ) : (
        <SpinnerBox />
      )}
    </Box>
  );
};
