import { missionUpdateOne, queryClient } from '@commons';
import {
  EMissionStatus,
  IJoinedMission,
  IUpdateMissionData,
} from '@freelancelabs/teoreme-commons';
import { Button, Flex, Form, Text } from 'components/ui';
import { useShowMessage } from 'hooks/useShowMessage';
import React from 'react';
import { useForm } from 'react-hook-form';
import { create } from 'react-modal-promise';
import { ModalFrame, ModalProps } from '../ModalFrame';
type FormValues = {
  endDate: Date;
  state: 'DONE';
};
type EndMissionModalProps = ModalProps & {
  beforeValidation?: () => void;
  mission: IJoinedMission;
};

export const EndMissionModal: React.FC<
  React.PropsWithChildren<EndMissionModalProps>
> = ({ onResolve, isOpen, mission }) => {
  const form = useForm<FormValues>({
    defaultValues: {
      endDate: mission?.endAt,
    },
  });
  const { handleSubmit } = form;
  const [isLoading, setIsLoading] = React.useState(false);
  const showMessage = useShowMessage();
  const onSubmit = async (data: IUpdateMissionData) => {
    setIsLoading(true);
    try {
      await missionUpdateOne({
        reference: mission?.reference,
        mission: {
          status: EMissionStatus.TERMINATED,
        },
      });
      showMessage('success', 'La mission à bien été terminée');
      onResolve(true);
    } catch (e) {
      //
    }
    queryClient.refetchQueries({ queryKey: ['Cras'], type: 'active' });
    queryClient.refetchQueries({ queryKey: [mission?.reference] });
    setIsLoading(false);
  };

  return (
    <ModalFrame
      isOpen={isOpen}
      onClose={() => onResolve(false)}
      width={800}
      closeIcon
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        data-cy="mission-form"
      >
        <Text variant="h1" mb={20}>
          Passage au statut Terminé
        </Text>
        <Text variant="p" mb={20}>
          La mission va repasser au statut "Terminée". Etes-vous sur de vouloir
          continuer ?
        </Text>
        <Flex mt={20}>
          <Button variant="filled" type="submit" isLoading={isLoading} mr={10}>
            Confirmer
          </Button>
          <Button
            onClick={() => onResolve(false)}
            variant="ghost"
            type="button"
            isLoading={isLoading}
          >
            Annuler
          </Button>
        </Flex>
      </Form>
    </ModalFrame>
  );
};

export const showEndMissionModal =
  create<EndMissionModalProps>(EndMissionModal);
