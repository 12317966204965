import {
  COOKIES_DOMAIN,
  REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_SEED_STAGE_NAME,
} from '../../constantz';
import { isNotLocal } from '../../helpers';
import { transformText } from '../../helpers/transform';
// const dateFormat =
//   /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
const dateFormat =
  /^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))$/;
/**
 * Function to get date object when parsing JSON
 * @param key
 * @param value
 */
export const parser = (key: string, value: string) => {
  if (typeof value === 'string' && dateFormat.test(value)) {
    return new Date(value);
  }
  return value;
};

/**
 * Function formatData
 * @param dataObject
 */
export const formatData = (dataObject: any, replaceNamesRegex?: any) => {
  const keys: any = [];
  const formatedObj = dataObject;
  if (dataObject && typeof dataObject === 'object') {
    Object.keys(dataObject).map((key, index) => keys.push(key));
  }
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] === 'firstName' && typeof dataObject[keys[i]] === 'string') {
      formatedObj[keys[i]] = transformText(
        dataObject[keys[i]].trim(),
        'capitalize',
        replaceNamesRegex
      );
    }
    if (keys[i] === 'lastName' && typeof dataObject[keys[i]] === 'string') {
      formatedObj[keys[i]] = transformText(
        dataObject[keys[i]].trim(),
        'uppercase',
        replaceNamesRegex
      );
    }
    if (keys[i] === 'jobTitle' && typeof dataObject[keys[i]] === 'string') {
      formatedObj[keys[i]] = transformText(
        dataObject[keys[i]].trim(),
        'capitalize-first'
      );
    }
    if (keys[i] === 'businessUnit' && typeof dataObject[keys[i]] === 'string') {
      formatedObj[keys[i]] = transformText(
        dataObject[keys[i]].trim(),
        'capitalize-first'
      );
    }
  }
  return formatedObj;
};

export const formatAllObject = (dataObject: any, replaceNamesRegex?: any) => {
  const keys1: any = [];
  let formatedObj = dataObject;
  if (dataObject && typeof dataObject === 'object') {
    Object.keys(dataObject).map(key => keys1.push(key));
    //check type of keys
    formatedObj = formatData(dataObject, replaceNamesRegex);
    for (let i = 0; i < keys1.length; i++) {
      // formatLvl1
      if (typeof formatedObj[keys1[i]] === 'object') {
        // lvl2
        //format objects lvl2
        formatedObj[keys1[i]] = formatData(
          formatedObj[keys1[i]],
          replaceNamesRegex
        );
      }
      if (Array.isArray(formatedObj[keys1[i]])) {
        for (let y = 0; y < formatedObj[keys1[i]].length; y++) {
          if (typeof formatedObj[keys1[i]][y] === 'object') {
            formatedObj[keys1[i]][y] = formatData(
              formatedObj[keys1[i]][y],
              replaceNamesRegex
            );
          }
        }
      }
    }
  }
  return formatedObj;
};

export const getCookies = () => {
  let cookies = document?.cookie?.split(';');
  return cookies?.map(c => {
    return {
      key: c?.split('=')?.[0],
      value: c?.split('=')?.[1],
    };
  });
};

export const getCognitoCookies = () => {
  let cookies = document?.cookie?.split(';');
  let cognitoCookies: any[] = [];
  cookies?.forEach(c => {
    let name = c?.split('=')?.[0];
    let key = name?.split('.')?.pop();
    let uuid = name?.split('.')[2];
    let clientId = name?.split('.')[1];
    if (
      name?.includes('CognitoIdentityServiceProvider') &&
      clientId === REACT_APP_COGNITO_CLIENT_ID
    ) {
      cognitoCookies?.push({
        name: name,
        key: key,
        uuid: uuid?.includes('-') ? name?.split('.')[2] : undefined,
        clientId: clientId,
        value: c?.split('=')?.[1],
        isSameClient: clientId === REACT_APP_COGNITO_CLIENT_ID,
      });
    }
  });
  return cognitoCookies;
};
export const getCognitoLocalStorage = () => {
  let cookies = Object?.keys(localStorage);
  let cognitoCookies: any[] = [];
  cookies?.forEach(c => {
    let name = c?.split('=')?.[0];
    let key = name?.split('.')?.pop();
    let uuid = name?.split('.')[2];
    let clientId = name?.split('.')[1];
    if (
      name?.includes('CognitoIdentityServiceProvider') &&
      clientId === REACT_APP_COGNITO_CLIENT_ID
    ) {
      cognitoCookies?.push({
        name: name,
        key: key,
        uuid: uuid?.includes('-') ? name?.split('.')[2] : undefined,
        clientId: clientId,
        value: localStorage?.getItem(c),
        isSameClient: clientId === REACT_APP_COGNITO_CLIENT_ID,
      });
    }
  });
  return cognitoCookies;
};
export const deleteAllCognitoCookies = () => {
  const cookies = document.cookie.split(';');

  cookies.forEach(cookie => {
    let name = cookie?.split('=')?.[0];
    let clientId = name?.split('.')[1];
    const isSameClient = clientId === REACT_APP_COGNITO_CLIENT_ID;
    const domain = isNotLocal(REACT_APP_SEED_STAGE_NAME)
      ? COOKIES_DOMAIN
      : 'localhost';
    let trimmedCookie = cookie.trim();
    if (
      trimmedCookie.indexOf('CognitoIdentityServiceProvider') !== -1 &&
      isSameClient
    ) {
      const nomCookie = trimmedCookie.split('=')[0];
      document.cookie = `${nomCookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;
    }
  });
};
export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};
